import { computed, inject, Injectable } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'

import { produce } from 'immer'
import { cloneDeep, isEmpty, set } from 'lodash'
import { has, isEqual, isNumber, last } from 'lodash-es'
import { divide as mathDivide } from 'mathjs'
import { BehaviorSubject, combineLatest, combineLatestWith, distinctUntilChanged, filter, Observable, of, switchMap, zip } from 'rxjs'
import { map } from 'rxjs/operators'

import {
  AxisDirectionType,
  AxisGridType,
  AxisKeys,
  AxisPositionType,
  barLabelPosition,
  basicPieLabelPosition,
  BorderOption,
  ChartRotateDirection,
  ChartType,
  columnLabelPosition,
  DefaultColor,
  defaultLabelPosition,
  funnelLabelPosition,
  IColDef,
  IColor,
  jadeLabelPosition,
  LabelKeys,
  LineType,
  orderType,
  sankeyLabelPosition,
  ShadowOption,
  switchChartProps,
  treemapLabelPosition,
  voronoiShapeType,
  voronoiStyleType
} from '@editorup/charts'
import { SettingSchema, SettingSchemaRow, ST } from '@editorup/settings'
import { AppStore } from '@libs/ng-core/store'
import { checkChartFill, getChartDimension } from '@libs/ng-shared/utils/chart'
import { ElementTypeEnum, IChart, IPageElementBase, IPageElementSetting } from '@libs/payload'

import {
  animationSelectResolver,
  borderRadiusResolver,
  buttonToggleResolver,
  chartColorResolver,
  chartThemeResolver,
  chartTypeResolver,
  colorRowResolver,
  editDataResolver,
  fontSizeResolver,
  gridBackgroundColorResolver,
  inputResolver,
  numberInputResolver,
  positionResolver,
  rangeInputResolver,
  selectFontResolver,
  selectNumberResolver,
  selectResolver,
  strokeColorResolver,
  strokeStyleResolver,
  themeSelectResolver,
  valueColumnResolver
} from '../../components/setting/setting-widget'
import { AnimationSelectComponent } from '../../components/setting/setting-widget/animation-select'
import { BorderRadiusComponent } from '../../components/setting/setting-widget/border-radius'
import { ButtonToggleComponent } from '../../components/setting/setting-widget/button-toggle'
import { ChartColorComponent } from '../../components/setting/setting-widget/chart-color'
import { ChartThemeComponent } from '../../components/setting/setting-widget/chart-theme'
import { ChartTypeComponent } from '../../components/setting/setting-widget/chart-type'
import { ColorRowComponent } from '../../components/setting/setting-widget/color-row'
import { EditDataComponent } from '../../components/setting/setting-widget/edit-data'
import { FontSizeComponent } from '../../components/setting/setting-widget/font-size'
import { GridBackgroundColorComponent } from '../../components/setting/setting-widget/grid-background-color'
import { InputComponent } from '../../components/setting/setting-widget/input'
import { NumberInputComponent } from '../../components/setting/setting-widget/number-input'
import { PositionComponent } from '../../components/setting/setting-widget/position'
import { RangeInputComponent } from '../../components/setting/setting-widget/range-input'
import { SelectComponent } from '../../components/setting/setting-widget/select'
import { SelectFontComponent } from '../../components/setting/setting-widget/select-font'
import { SelectNumberComponent } from '../../components/setting/setting-widget/select-number'
import { DisplayType, IWholeBorder, OptionsKey, WholeShadow } from '../../components/setting/setting-widget/setting'
import { StrokeColorComponent } from '../../components/setting/setting-widget/stroke-color'
import { StrokeStyleComponent } from '../../components/setting/setting-widget/stroke-style'
import { ThemeSelectComponent } from '../../components/setting/setting-widget/theme-select'
import { ValueColumn, ValueColumnComponent } from '../../components/setting/setting-widget/value-column'
import { disableBorderSetting, isChartSetting } from '../../components/setting/utils'
import { SettingPanelStore } from '../../store/setting-panel.store'
import { StageUiStore } from '../../store/stage-ui.store'
import { WorkspaceService } from '../workspace.service'

interface IWholeSetting {
  shadow: WholeShadow
  border: IWholeBorder
}

const chartRotateDirectionOptions: Array<{ text: string; value: ChartRotateDirection }> = [
  { text: '顺时针', value: 'clockwise' },
  { text: '逆时针', value: 'counterclockwise' }
]

const barOrderOptions: Array<{ text: string; value: orderType }> = [
  { text: '整体-部分', value: 'whole-part' },
  { text: '部分-整体', value: 'part-whole' }
]

const ChartLabels: Array<{
  name: string
  key: LabelKeys
}> = [
  { name: '数值', key: 'numberLabel' },
  { name: '柱形数值', key: 'barLabel' },
  { name: '箭头数值', key: 'arrowLabel' },
  { name: '折线数值', key: 'lineLabel' },
  { name: '面积数据', key: 'areaLabel' },
  { name: '百分比数值', key: 'percentLabel' },
  { name: '文字标签', key: 'textLabel' }
]

/**
 * 定义数值标签中哪些图表类型需要限制字体大小
 */
const numberLabelFontSizeRestrain: ChartType[] = ['basic-pie', 'check-in-bubble', 'single-layer-treemap']

const getYAxisLabelPosition = (chartType: ChartType): { text: string; value: AxisPositionType }[] => {
  // 蝴蝶图不需要Y轴位置配置 默认使用中间的Y轴
  if (chartType === 'butterfly') return []
  return [
    { text: '左侧', value: 'left' },
    { text: '右侧', value: 'right' }
  ]
}

/**
 * 违诺图形的形状类型
 */
const voronoiShapeTypeOptions: Array<{ text: string; value: voronoiShapeType }> = [
  { text: '圆形', value: 'circle' },
  { text: '三角形', value: 'triangle' },
  { text: '矩形', value: 'rectangle' },
  { text: '菱形', value: 'diamond' },
  { text: '五边形', value: 'pentagon' },
  { text: '六边形', value: 'hexagon' },
  { text: '八边形', value: 'octagon' }
]

/**
 * 违诺图形的绘制比例
 */
const voronoiDrawStyleOptions: Array<{ text: string; value: voronoiStyleType }> = [
  { text: '自由比例', value: 'auto' },
  { text: '固定比例', value: 'fixed' }
]

/**
 * 获取坐标轴模板列表
 * @param type
 */
const axisTemplateList = (
  type: ChartType
): Array<{
  title: { root: string; line: string; label: string; grid: string }
  key: AxisKeys
  positions: { text: string; value: AxisPositionType }[]
  directions: { text: string; value: AxisDirectionType }[]
  gridStyles: { text: string; value: AxisGridType }[]
}> => {
  return [
    {
      title: { root: 'X轴', line: 'X轴线', label: 'X轴标签', grid: '纵向网格线' },
      key: 'xAxis',
      positions: [
        { text: '顶部', value: 'top' },
        { text: '底部', value: 'bottom' }
      ],
      directions: [
        { text: '横向', value: 'horizontal' },
        { text: '自动', value: 'auto' }
      ],
      gridStyles: []
    },
    {
      title: { root: 'Y轴', line: 'Y轴线', label: 'Y轴标签', grid: '横向网格线' },
      key: 'yAxis',
      positions: getYAxisLabelPosition(type),
      directions: [],
      gridStyles: []
    },
    {
      title: {
        root: '周向',
        label: '标签',
        line: '轴线',
        grid: '周向'
      },
      key: 'angleAxis',
      positions: [
        { text: '内部', value: 'inside' },
        { text: '外部', value: 'outside' }
      ],
      directions: [
        { text: '水平', value: 'horizontal' },
        { text: '周向', value: 'circumference' },
        { text: '径向', value: 'radial' }
      ],
      gridStyles: []
    },
    {
      title: {
        root: '径向',
        label: '标签',
        line: '轴线',
        grid: '径向'
      },
      key: 'radiusAxis',
      positions: [],
      directions: [],
      gridStyles: [
        { text: '多边形', value: 'polygon' },
        { text: '圆形', value: 'circle' }
      ]
    }
  ]
}

const LineStyleOptions: Array<{ text: string; value: LineType }> = [
  { text: '直线', value: 'straight' },
  { text: '曲线', value: 'curve' }
]
// 部分图表不更新colDef 按照模版的colDef进行渲染
const filterTypeList: Array<ChartType> = [
  'basic-column',
  'basic-pie',
  'rose-pie',
  'difference-arrow-bar',
  'difference-arrow-column',
  'sankey',
  'jade-jue',
  'donut-progress',
  'funnel',
  'basic-bar',
  'compose-waterfall',
  'voronoi'
]
@Injectable({
  providedIn: 'root'
})
export class ChartSettingService {
  uiStore = inject(StageUiStore)
  appStore = inject(AppStore)
  settingPanelStore = inject(SettingPanelStore)
  workspaceService = inject(WorkspaceService)

  chartElement = computed(() => {
    const selectedElements = this.uiStore.selectedElements()
    const shadowSetting = this.uiStore.interacting.shadowData.setting()
    return selectedElements.length === 1 && isChartSetting(selectedElements[0])
      ? produce(selectedElements[0], draft => {
          if (!isEmpty(shadowSetting)) {
            draft.setting = shadowSetting as IPageElementSetting['chart']
          }
        })
      : null
  })

  /**
   * 整体投影 通过fill.props中进行计算
   * 只要其中一个打开那么整体投影的状态为打开状态，且后续更改仅更改已被打开的子项，同时整体投影的属性仅依赖已打开项
   */
  wholeShadow = computed(() => {
    const setting = this.chartElement()
    if (!setting) return

    // 获取当前fill中的shadow 并过滤不存在shadow的子项
    const shadows = setting.setting.props.fill.props.map(p => p?.shadow).filter((s): s is ShadowOption => !!s) || []

    // 不存在任何投影时返回
    if (shadows.length === 0) {
      return
    }

    const show = shadows.some(shadow => !!shadow?.show)
    const showShadows = shadows.filter(shadow => !!shadow?.show)
    const colors = showShadows.map(shadow => shadow?.color)
    const colorValue = colors.map(color => color?.color)
    const opacity = colors.map(color => color?.opacity)
    // 依次对比shadows中每一项的值，如果一样则取值，否则为 '- -'
    const result: WholeShadow = {
      show: show,
      color:
        show && this.same(colorValue) && this.same(opacity)
          ? {
              color: colorValue[0],
              opacity: opacity[0]
            }
          : undefined,
      blur: show && this.same(showShadows.map(shadow => shadow?.blur)) ? showShadows[0]?.blur : undefined,
      angle: show && this.same(showShadows.map(shadow => shadow?.angle)) ? showShadows[0]?.angle : undefined,
      radius: show && this.same(showShadows.map(shadow => shadow?.radius)) ? showShadows[0]?.radius : undefined,
      type: show && this.same(showShadows.map(shadow => shadow?.type)) ? showShadows[0]?.type : undefined
    }
    return result
  })

  /**
   * 整体描边样式，通过fill.props中的每一项border进行计算
   */
  wholeBorder = computed(() => {
    const element = this.chartElement()
    if (!element) return

    const props = element.setting.props
    // 获取当前fill中的border 并过滤不存在border的子项
    const colDefTypes = props.map.slice(1).map(item => item.type)
    const borders =
      props.fill.props
        .map(p => p?.border)
        .filter((s): s is BorderOption => !!s)
        .filter((_, index) => {
          const type = colDefTypes[index]
          // 混合图形中需要排除当前ColDefType为line的类型
          return !disableBorderSetting(type)
        }) || []

    // const showBorders = borders.filter(border => !!border?.width)
    const colors = borders.map(shadow => shadow?.color)
    const isAutoColor = colors.every(color => color === null)
    let borderColor: null | IColor | undefined = null
    if (!isAutoColor) {
      const colorValue = colors.map(color => color?.color)
      const opacity = colors.map(color => color?.opacity)
      borderColor =
        this.same(colorValue) && this.same(opacity)
          ? ({
              color: colorValue[0],
              opacity: opacity[0]
            } as IColor)
          : undefined
    }
    return {
      type: this.same(borders.map(border => border?.type)) ? borders[0]?.type : undefined,
      width: this.same(borders.map(border => border?.width)) ? borders[0]?.width : undefined,
      color: borderColor
    } as IWholeBorder
  })

  /** 整体投影 */
  wholeShadow$ = toObservable(this.wholeShadow)
  /** 整体投影是否隐藏 */
  hiddenWholeShadow$ = this.wholeShadow$.pipe(map(s => !s))
  /** 整体投影类型转换 */
  wholeShadowOption$ = this.wholeShadow$.pipe(filter((s): s is WholeShadow => !!s))
  /** 整体描边 */
  wholeBorder$ = toObservable(this.wholeBorder).pipe(filter((s): s is IWholeBorder => !!s))

  elementScale = computed(() => this.chartElement()?.scale || 1)
  elementScale$ = toObservable(this.elementScale).pipe(
    map(scale => scale || 1),
    distinctUntilChanged()
  )

  shadowScale$ = toObservable(this.uiStore.interacting.shadowData.scale)
  element$ = toObservable(this.chartElement).pipe(filter((s): s is IPageElementBase<ElementTypeEnum.Chart> => !!s))
  setting$ = this.element$.pipe(map(s => s.setting))
  chartProps$ = this.setting$.pipe(map(s => s.props))
  title$ = this.chartProps$.pipe(map(c => c.title))
  mainTitle$ = this.title$.pipe(map(t => t?.mainTitle))
  subTitle$ = this.title$.pipe(map(t => t?.subTitle))
  background$ = this.chartProps$.pipe(map(c => c.background))
  legend$ = this.chartProps$.pipe(map(c => c.legend))

  chartType$ = this.chartProps$.pipe(
    map(s => s.type),
    distinctUntilChanged()
  )

  // dimensions$ = this.setting$.pipe(map(s => s.data[0]?.[0].filter(item => !emptyValue(item)) || []))
  map$ = this.chartProps$.pipe(map(c => c.map))

  selectedIdsSet = this.uiStore.selectedIds
  chartType = computed(() => this.chartElement()?.setting.props.type)
  chartTemplate = computed(() => cloneDeep(this.appStore.metaCharts().find(item => item.props.type === this.chartType())))
  chartTemplate$ = toObservable(this.chartTemplate).pipe(filter((c): c is IChart => !!c))
  basePropsTitle$ = toObservable(
    computed(() => {
      const allCharts = this.appStore.metaCharts()
      return allCharts.find(item => item.props.type === this.chartType())?.name || ''
    })
  )
  private _isPageChangeAction = computed(() => {
    return this.uiStore.isElementInteractingOnPage()
  })

  constructor() {
    // 监听当前维度的变化
    this.setting$.pipe(takeUntilDestroyed()).subscribe(setting => {
      const dimensions = setting.data[0][0]
      const maps = setting.props.map
      const chartType = setting.props.type

      // 当前数据维度和数据映射不等时修改colType的映射关系
      if (dimensions && maps && dimensions.length !== maps.length && !filterTypeList.includes(chartType)) {
        // 依据dimensions更新maps
        // 在当前的chart json中找到当前图表的默认值
        const chartTemplate = this.chartTemplate()
        if (chartTemplate) {
          const diff = dimensions.length - maps.length
          if (diff < 0) {
            this.update('setting.props.map', maps.slice(0, dimensions.length), { skipHistory: true })
          } else {
            const mapTemplate = chartTemplate.props.map
            // 不会等于 0
            // diff > 0
            const newMaps: IColDef[] = []
            for (let i = 0; i < dimensions.length; i++) {
              // 优先获取当前map中的值，如果超过索引获取模版中的值，如果超出模版索引使用模版中最后一个值
              const cloneMap = cloneDeep(newMaps[i] || mapTemplate[i] || (last(mapTemplate) as IColDef))
              cloneMap.index = i
              cloneMap.name = String(dimensions[i])
              newMaps.push(cloneMap)
            }
            this.update('setting.props.map', newMaps, { skipHistory: true })
          }
        }
      }
    })
  }

  /* 部分属性在element进行缩放时需要在计算中乘以当前scale 比如font-size */
  multiply = (source$: Observable<number | number[] | undefined>): Observable<number | number[] | undefined> =>
    this.elementScale$.pipe(
      combineLatestWith(source$, this.shadowScale$),
      switchMap(([scale = 1, value, shadowScale]) => {
        const scaleValue = shadowScale === undefined ? scale : shadowScale
        return of(value === undefined || isNaN(Number(value)) ? value : Array.isArray(value) ? value.map(v => v * scaleValue) : value * scaleValue)
      })
    )
  /* 部分属性在提交时需要除以scale以还原真实数据 比如font-size */
  divide = (val: unknown) => (isNaN(Number(val)) ? val : mathDivide(Number(val), this.elementScale()))

  /**
   * 用于修改通过fill.props进行computed计算出来的属性，此时需要批量修改
   * @param path 属性路径
   * @param data 将要修改的值
   * @param options options.preview表示当前的属性修改是一个预览状态，此时不需要提交给服务端进行修改操作，当preview为false后会使用reset触发提交操作
   */
  updateWhole(path: OptionsKey<IWholeSetting>, data: unknown, options?: { preview?: boolean }) {
    console.log('update shadow', path, data)
    const elementId = Array.from(this.selectedIdsSet())[0] as string
    const elementData = cloneDeep(this.chartElement() as IPageElementBase<ElementTypeEnum.Chart>)
    const { preview = false } = options || {}
    const paths = path.split('.')
    const firstPath = paths[0]
    const lastPath = paths.slice(1).join('.')
    let props = elementData.setting.props.fill.props
    if (firstPath === 'shadow' && lastPath !== 'show') {
      // 此时整体投影一定时打开状态才可能触发非 ‘show’ 的属性 又因为投影可以部分开启，所以此时更新属性仅更新已开启的部分
      props = props.filter(prop => prop.shadow?.show)
    }
    if (firstPath === 'border') {
      // 整体描边的调整需要考虑混合图中的折线图，此时的描边配置不展示不修改也不影响结算结果
      const colDefTypes = elementData.setting.props.map.slice(1).map(item => item.type)
      props = props.filter((_, index) => !disableBorderSetting(colDefTypes[index]))
    }
    props.forEach(prop => {
      set(prop, path, data)
    })
    this.uiStore.setSettingElement(elementId, elementData.setting)
    if (!preview) {
      this.uiStore.resetInteractingElement()
    }
  }

  /**
   *
   * @param path 当前修改对象的属性路径
   * @param data 将要修改的值
   * @param options options.preview表示当前的属性修改是一个预览状态，此时不需要提交给服务端进行修改操作，当preview为false后会使用reset触发提交操作
   */
  update(path: OptionsKey<IPageElementBase<ElementTypeEnum.Chart>>, data: unknown, options?: { preview?: boolean; skipHistory?: boolean }) {
    const elementId = Array.from(this.selectedIdsSet())[0] as string
    console.log('update', path, data, options)
    if (this._isPageChangeAction()) {
      return
    }
    const { preview = false } = options || {}
    const element = this.chartElement()
    if (element) {
      const elementData = produce(element, draft => {
        set(draft, path, data)
      })
      const setting = path !== '' ? elementData.setting : (cloneDeep(data) as IPageElementSetting['chart'])
      this.uiStore.setSettingElement(elementId, setting)
    }
    if (!preview) {
      this.uiStore.resetInteractingElement({ skipHistory: !!options?.skipHistory })
    }
  }

  /** 生成一个NumberInputComponent 在 Slide开启时的Outputs */
  generateSlideOutputs = (
    path: OptionsKey<IPageElementBase<ElementTypeEnum.Chart>>,
    scale = false,
    options?: {
      // 是否需要对值进行转换
      transformer?: (val: unknown) => unknown
    }
  ) => {
    const transformer = options?.transformer || (val => val)
    return {
      valueChange: (val: number) => this.update(path, scale ? this.divide(transformer(val)) : transformer(val)),
      valueSlideChange: ({ value, dragging }: { value: number; dragging: boolean }) =>
        this.update(path, scale ? this.divide(transformer(value)) : transformer(value), { preview: dragging })
    }
  }
  /** 生成一个NumberInputComponent 在 Slide开启时的Outputs */
  generateShadowSlideOutputs = (path: OptionsKey<IWholeSetting>, scale = false) => {
    return {
      valueChange: (val: number) => this.updateWhole(path, scale ? this.divide(val) : val),
      valueSlideChange: ({ value, dragging }: { value: number; dragging: boolean }) =>
        this.updateWhole(path, scale ? this.divide(value) : value, { preview: dragging })
    }
  }

  // 判断当前数组中的数据是否相同
  same = (dataList: unknown[]) => new Set(dataList).size === 1

  /**
   * 获取图表的配置面板
   * 所有图表只需要创建一次，在内部使用rxjs进行动态显示或者隐藏
   */
  getSettingSchema(): SettingSchema[] {
    const element$ = this.element$
    // 申明当前service中的一些方法，主要目的是为了避免写 “this.“
    const multiply = this.multiply.bind(this)
    const update = this.update.bind(this)
    const chartProps$ = this.chartProps$
    // .pipe(tap(setting => console.log('setting update', setting)))
    const wholeShadowOption$ = this.wholeShadowOption$
    const generateSlideOutputs = this.generateSlideOutputs.bind(this)
    const generateShadowSlideOutputs = this.generateShadowSlideOutputs.bind(this)
    const updateWhole = this.updateWhole.bind(this)
    const chartType$ = this.chartType$
    return [
      // 基础属性
      {
        title: this.basePropsTitle$,
        icon: 'ss:base',
        interaction: 'none',
        children: [
          // 皮肤
          {
            flexible: true,
            child: {
              widget: chartThemeResolver,
              inputs: {
                chartType: chartType$
              },
              outputs: {
                valueChange: val => {
                  const data = this.chartElement()?.setting.data[0]
                  if (data) {
                    update('setting.props', checkChartFill(val, data, this.settingPanelStore.themeList()))
                  }
                }
              }
            } as ST<ChartThemeComponent>
          },
          // 图表类型
          {
            // title: '图表类型',
            child: {
              widget: chartTypeResolver,
              inputs: {
                chartType: chartType$
              },
              outputs: {
                valueChange: val => {
                  const setting = cloneDeep(this.chartElement()?.setting) as IPageElementSetting['chart']
                  //TODO 临时用来制作皮肤
                  const props = switchChartProps(cloneDeep(setting.props), cloneDeep(val.props))
                  // set(setting, 'data', val.data)
                  set(setting, 'pipe', val.pipe)
                  set(setting, 'props', props)
                  if (setting.data[0]) {
                    update(
                      'setting',
                      produce(setting, draft => {
                        draft.props = checkChartFill(draft.props, setting.data[0], this.settingPanelStore.themeList())
                      })
                    )
                  }
                }
              }
            } as ST<ChartTypeComponent>
          },
          // 编辑图表数据
          {
            flexible: true,
            child: {
              widget: editDataResolver
            } as ST<EditDataComponent>
          },
          ...this.workspaceService.getSettingSchema(),
          // 主题色
          {
            title: '主题色',
            child: {
              widget: themeSelectResolver,
              inputs: {
                value: element$.pipe(map(m => m.setting))
              },
              outputs: {
                valueChange: val => update('', val)
              }
            } as ST<ThemeSelectComponent>
          }
        ]
      },
      // 标题
      {
        title: '标题',
        icon: 'ss:title',
        interaction: 'switch',
        active: this.title$.pipe(map(s => s?.show)),
        activeChange: state => update('setting.props.title.show', state),
        children: [
          {
            title: '主标题',
            interaction: 'popup',
            children: [
              {
                title: '主标题',
                interaction: 'switch',
                active: this.mainTitle$.pipe(map(s => s?.show)),
                activeChange: state => update('setting.props.title.mainTitle.show', state),
                children: [
                  {
                    title: '文本',
                    child: {
                      widget: inputResolver,
                      inputs: {
                        value: this.mainTitle$.pipe(map(s => s?.text)),
                        maxLength: 20
                      },
                      outputs: {
                        valueChange: val => update('setting.props.title.mainTitle.text', val)
                      }
                    } as ST<InputComponent>
                  },
                  {
                    title: '字体',
                    child: {
                      widget: selectFontResolver,
                      inputs: {
                        value: this.mainTitle$.pipe(map(s => s?.fontFamily))
                      },
                      outputs: {
                        valueChange: val => update('setting.props.title.mainTitle.fontFamily', val)
                      }
                    } as ST<SelectFontComponent>
                  },
                  // 字号
                  {
                    title: '字号',
                    child: {
                      widget: selectNumberResolver,
                      inputs: {
                        value: multiply(this.mainTitle$.pipe(map(s => s?.fontSize)))
                      },
                      outputs: {
                        valueChange: size => update('setting.props.title.mainTitle.fontSize', this.divide(size))
                      }
                    } as ST<SelectNumberComponent>
                  },
                  // 颜色
                  {
                    title: '颜色',
                    child: {
                      widget: colorRowResolver,
                      inputs: {
                        gradient: false,
                        value: this.mainTitle$.pipe(map(s => s?.color))
                      },
                      outputs: {
                        valueChange: ({ color, dragging }) => update('setting.props.title.mainTitle.color', color, { preview: dragging })
                      }
                    } as ST<ColorRowComponent>
                  },
                  // 位置
                  {
                    title: '位置',
                    child: {
                      widget: positionResolver,
                      inputs: {
                        arrangeMode: 'horizontal',
                        value: this.mainTitle$.pipe(map(s => s?.position))
                      },
                      outputs: {
                        valueChange: val => update('setting.props.title.mainTitle.position', val)
                      }
                    } as ST<PositionComponent>
                  }
                ]
              }
            ]
          },
          // 副标题

          {
            title: '副标题',
            interaction: 'popup',
            children: [
              {
                title: '副标题',
                interaction: 'switch',
                active: this.subTitle$.pipe(map(s => s?.show)),
                activeChange: state => update('setting.props.title.subTitle.show', state),
                children: [
                  {
                    title: '文本',
                    child: {
                      widget: inputResolver,
                      inputs: {
                        value: this.subTitle$.pipe(map(s => s?.text)),
                        maxLength: 20
                      },
                      outputs: {
                        valueChange: val => update('setting.props.title.subTitle.text', val)
                      }
                    } as ST<InputComponent>
                  },
                  {
                    title: '字体',
                    child: {
                      widget: selectFontResolver,
                      inputs: {
                        value: this.subTitle$.pipe(map(s => s?.fontFamily))
                      },
                      outputs: {
                        valueChange: val => update('setting.props.title.subTitle.fontFamily', val)
                      }
                    } as ST<SelectFontComponent>
                  },
                  // 字号
                  {
                    title: '字号',
                    child: {
                      widget: selectNumberResolver,
                      inputs: {
                        value: multiply(this.subTitle$.pipe(map(s => s?.fontSize)))
                      },
                      outputs: {
                        valueChange: size => update('setting.props.title.subTitle.fontSize', this.divide(size))
                      }
                    } as ST<SelectNumberComponent>
                  },
                  // 颜色
                  {
                    title: '颜色',
                    child: {
                      widget: colorRowResolver,
                      inputs: {
                        gradient: false,
                        value: this.subTitle$.pipe(map(s => s?.color))
                      },
                      outputs: {
                        valueChange: ({ color, dragging }) => update('setting.props.title.subTitle.color', color, { preview: dragging })
                      }
                    } as ST<ColorRowComponent>
                  }
                ]
              }
            ]
          }
        ]
      },
      // 图表背景
      {
        title: '图表背景',
        icon: 'ss:background',
        interaction: 'switch',
        active: this.background$.pipe(map(s => s.show)),
        activeChange: state => update('setting.props.background.show', state),
        children: [
          {
            title: '颜色',
            child: {
              widget: colorRowResolver,
              inputs: {
                value: this.background$.pipe(map(s => s.color))
              },
              outputs: {
                valueChange: ({ color, dragging }) => update('setting.props.background.color', color, { preview: dragging })
              }
            } as ST<ColorRowComponent>
          },
          // 背景圆角
          {
            title: '圆角',
            child: {
              widget: borderRadiusResolver,
              inputs: {
                value: this.background$.pipe(map(s => s.border.radius))
              },
              outputs: {
                value: val => update('setting.props.background.border.radius', val)
              }
            } as ST<BorderRadiusComponent>
          },
          // 毛玻璃
          {
            title: '毛玻璃',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: this.background$.pipe(map(s => s.blur)),
                aceMaxValue: 100,
                aceMinValue: 0,
                useSlide: true
              },
              outputs: generateSlideOutputs('setting.props.background.blur', false)
            } as ST<NumberInputComponent>
          }
        ]
      },
      // 数据映射
      {
        title: '数据映射',
        icon: 'ss:map',
        interaction: 'collapse',
        active: true,
        children: this.getValueMapSchema()
      },
      // 图表颜色
      {
        title: '图表颜色',
        icon: 'ss:chart-color',
        interaction: 'collapse',
        active: true,
        children: this.getChartColorSchema()
      },
      // 样式
      {
        title: '样式',
        icon: 'ss:display',
        interaction: 'collapse',
        children: this.getDisplaySchema()
      },
      // 图例
      {
        title: '图例',
        icon: 'ss:legend',
        interaction: 'switch',
        active: this.legend$.pipe(map(s => s.show)),
        activeChange: state => update('setting.props.legend.show', state),
        children: [
          // 方向
          {
            title: '方向',
            child: {
              widget: buttonToggleResolver,
              inputs: {
                value: this.legend$.pipe(map(s => s.display)),
                options: [
                  { text: '横向', value: 'horizontal' },
                  { text: '纵向', value: 'vertical' }
                ]
              },
              outputs: {
                value: val => update('setting.props.legend.display', val)
              }
            } as ST<ButtonToggleComponent>
          },
          // 位置
          {
            title: '位置',
            child: {
              widget: positionResolver,
              inputs: {
                arrangeMode: this.legend$.pipe(map(s => s.display)),
                value: this.legend$.pipe(map(s => s.position))
              },
              outputs: {
                valueChange: val => update('setting.props.legend.position', val)
              }
            } as ST<PositionComponent>
          },
          // 字体
          {
            title: '字体',
            child: {
              widget: selectFontResolver,
              inputs: {
                value: this.legend$.pipe(map(s => s.fontFamily))
              },
              outputs: {
                valueChange: val => update('setting.props.legend.fontFamily', val)
              }
            } as ST<SelectFontComponent>
          },
          // 字号
          {
            title: '字号',
            child: {
              widget: selectNumberResolver,
              inputs: {
                value: multiply(this.legend$.pipe(map(s => s.fontSize)))
              },
              outputs: {
                valueChange: size => update('setting.props.legend.fontSize', this.divide(size))
              }
            } as ST<SelectNumberComponent>
          },
          // 文字颜色
          {
            title: '文字颜色',
            child: {
              widget: colorRowResolver,
              inputs: {
                gradient: false,
                value: this.legend$.pipe(map(s => s.color))
              },
              outputs: {
                valueChange: ({ color, dragging }) => update('setting.props.legend.color', color, { preview: dragging })
              }
            } as ST<ColorRowComponent>
          }
        ]
      },
      // 数据标签
      {
        title: '数据标签',
        icon: 'ss:label',
        interaction: 'switch',
        active: chartProps$.pipe(map(s => s.label?.show)),
        activeChange: state => update('setting.props.label.show', state),
        children: zip(
          ...ChartLabels.map<Observable<SettingSchema>>(({ name, key }) => {
            return of({
              title: name,
              interaction: 'popup',
              hidden: chartProps$.pipe(map(c => !has(c.label, key))),
              children: [
                {
                  title: name,
                  interaction: 'switch',
                  active: chartProps$.pipe(map(s => s.label?.[key]?.show)),
                  activeChange: state => update(`setting.props.label.${key}.show`, state),
                  children: [
                    // 位置
                    {
                      title: '位置',
                      hidden: chartProps$.pipe(map(s => !has(s.label?.[key], 'positionChoice'))),
                      child: {
                        widget: selectResolver,
                        inputs: {
                          value: chartProps$.pipe(map(s => s.label?.[key]?.positionChoice)),
                          options: this.chartType$.pipe(
                            map(type => {
                              return this.getLabelOptions(type, key)
                            })
                          )
                        },
                        outputs: {
                          value: val => update(`setting.props.label.${key}.positionChoice`, val)
                        }
                      } as ST<SelectComponent>
                    },
                    // 字体
                    {
                      title: '字体',
                      child: {
                        widget: selectFontResolver,
                        inputs: {
                          value: chartProps$.pipe(map(s => s.label?.[key]?.fontFamily))
                        },
                        outputs: {
                          valueChange: val => update(`setting.props.label.${key}.fontFamily`, val)
                        }
                      } as ST<SelectFontComponent>
                    },
                    // 字号
                    {
                      title: '字号',
                      hidden: this.chartType$.pipe(map(c => numberLabelFontSizeRestrain.includes(c))),
                      child: {
                        widget: selectNumberResolver,
                        inputs: {
                          value: this.multiply(chartProps$.pipe(map(s => s.label?.[key]?.fontSize as number | undefined)))
                        },
                        outputs: {
                          valueChange: size => update(`setting.props.label.${key}.fontSize`, this.divide(size))
                        }
                      } as ST<SelectNumberComponent>
                    },
                    // 字号约束
                    {
                      flexible: true,
                      hidden: this.chartType$.pipe(map(c => !numberLabelFontSizeRestrain.includes(c))),
                      child: {
                        widget: fontSizeResolver,
                        inputs: {
                          value: this.multiply(this.chartProps$.pipe(map(c => c.label?.[key]?.fontSize)))
                        },
                        outputs: {
                          valueChange: size => update(`setting.props.label.${key}.fontSize`, this.divide(size))
                        }
                      } as ST<FontSizeComponent>
                    },
                    // 颜色
                    {
                      title: '颜色',
                      child: {
                        widget: colorRowResolver,
                        inputs: {
                          gradient: false,
                          value: chartProps$.pipe(map(s => s.label?.[key]?.color))
                        },
                        outputs: {
                          valueChange: ({ color, dragging }) => update(`setting.props.label.${key}.color`, color, { preview: dragging })
                        }
                      } as ST<ColorRowComponent>
                    },
                    // 单位
                    {
                      title: '单位',
                      hidden: chartProps$.pipe(map(s => !has(s.label?.[key], 'suffix'))),
                      child: {
                        widget: inputResolver,
                        inputs: {
                          value: chartProps$.pipe(map(s => s.label?.[key]?.suffix)),
                          maxLength: 10
                        },
                        outputs: {
                          valueChange: val => update(`setting.props.label.${key}.suffix`, val)
                        }
                      } as ST<InputComponent>
                    }
                  ]
                }
              ]
            })
          }),
          of<SettingSchema>({
            title: '反白效果',
            interaction: 'radio',
            active: chartProps$.pipe(map(s => s.label?.highlight)),
            activeChange: state => update('setting.props.label.highlight', state)
          }),
          of<SettingSchema>({
            title: '重叠避让',
            interaction: 'radio',
            active: chartProps$.pipe(map(s => s.label?.overlap)),
            activeChange: state => update('setting.props.label.overlap', state)
          })
        )
      },
      // 坐标轴
      {
        title: '坐标轴',
        icon: 'ss:axis',
        interaction: 'switch',
        active: this.chartProps$.pipe(map(s => s.axis?.show)),
        // 饼图没有坐标轴
        hidden: this.chartProps$.pipe(map(s => !has(s, 'axis'))),
        activeChange: state => update('setting.props.axis.show', state),
        children: chartProps$.pipe(
          map(s => {
            return (
              axisTemplateList(s.type)
                .map(item => {
                  const axisList = s.axis?.[item.key] || []
                  // 数据中可能存在多轴，通过json中的axis及模版来生成多个SettingSchema
                  return axisList.map((axis, index) => ({
                    axis,
                    index,
                    ...item
                  }))
                })
                // 将多个SettingSchema合并为一个数组
                .flat()
                .map<SettingSchema>(item => {
                  const { title, index, key } = item
                  const axis$ = chartProps$.pipe(map(s1 => s1?.axis?.[key]?.[index]))

                  const rangeHidden$ = axis$.pipe(map(axis => axis?.type !== 'value'))
                  const positionsHidden$ = of(item.positions).pipe(map(positions => positions.length === 0))

                  return {
                    title: axis$.pipe(map(c => `${title.root}${this.getAxisPositionName(c?.position, s.type, index)}`)),
                    interaction: 'popup',
                    children: [
                      // 轴线
                      {
                        title: title.line,
                        interaction: 'switch',
                        active: axis$.pipe(map(axis => axis?.line.show)),
                        activeChange: state => update(`setting.props.axis.${key}[${index}].line.show`, state),
                        children: [
                          // 粗细
                          {
                            title: '粗细',
                            child: {
                              widget: numberInputResolver,
                              inputs: {
                                value: axis$.pipe(map(axis => axis?.line.width)),
                                aceMinValue: 0,
                                aceMaxValue: 100,
                                useStep: true,
                                aceSlideStep: 1
                              },
                              outputs: {
                                valueChange: value => update(`setting.props.axis.${key}[${index}].line.width`, value)
                              }
                            } as ST<NumberInputComponent>
                          },

                          // 颜色
                          {
                            title: '颜色',
                            child: {
                              widget: colorRowResolver,
                              inputs: {
                                gradient: false,
                                value: axis$.pipe(map(axis => axis?.line.color))
                              },
                              outputs: {
                                valueChange: ({ color, dragging }) => update(`setting.props.axis.${key}[${index}].line.color`, color, { preview: dragging })
                              }
                            } as ST<ColorRowComponent>
                          }
                        ]
                      },
                      {
                        // 轴标签
                        title: title.label,
                        interaction: 'switch',
                        active: axis$.pipe(map(axis => axis?.label.show)),
                        activeChange: state => update(`setting.props.axis.${key}[${index}].label.show`, state),
                        children: [
                          // 方向
                          {
                            title: '方向',
                            hidden: of(item.directions).pipe(map(directions => directions.length === 0)),
                            child: {
                              widget: buttonToggleResolver,
                              inputs: {
                                value: axis$.pipe(map(axis => axis?.label.direction)),
                                options: item.directions
                              },
                              outputs: {
                                value: val => update(`setting.props.axis.${key}[${index}].label.direction`, val)
                              }
                            } as ST<ButtonToggleComponent>
                          },
                          // 角度
                          {
                            title: '角度',
                            // 标签方向不为自动 且存在angle
                            hidden: axis$.pipe(map(a => !(a?.label && has(a.label, 'angle') && has(a.label, 'direction') && a.label.direction !== 'auto'))),
                            child: {
                              widget: numberInputResolver,
                              inputs: {
                                value: axis$.pipe(map(axis => axis?.label.angle)),
                                aceMinValue: -180,
                                aceMaxValue: 180,
                                useSlide: true
                              },
                              outputs: generateSlideOutputs(`setting.props.axis.${key}[${index}].label.angle`, false)
                            } as ST<NumberInputComponent>
                          },
                          // 单位
                          {
                            title: '单位',
                            hidden: axis$.pipe(map(axis => axis?.type !== 'value')),
                            child: {
                              widget: inputResolver,
                              inputs: {
                                value: axis$.pipe(map(axis => axis?.label.suffix)),
                                maxLength: 10
                              },
                              outputs: {
                                valueChange: val => update(`setting.props.axis.${key}[${index}].label.suffix`, val)
                              }
                            } as ST<InputComponent>
                          },
                          // 字体
                          {
                            title: '字体',
                            child: {
                              widget: selectFontResolver,
                              inputs: {
                                value: axis$.pipe(map(axis => axis?.label.fontFamily))
                              },
                              outputs: {
                                valueChange: val => update(`setting.props.axis.${key}[${index}].label.fontFamily`, val)
                              }
                            } as ST<SelectFontComponent>
                          },
                          // 字号
                          {
                            title: '字号',
                            child: {
                              widget: selectNumberResolver,
                              inputs: {
                                value: this.multiply(axis$.pipe(map(axis => axis?.label.fontSize)))
                              },
                              outputs: {
                                valueChange: val => update(`setting.props.axis.${key}[${index}].label.fontSize`, this.divide(val))
                              }
                            } as ST<SelectNumberComponent>
                          },
                          // 颜色
                          {
                            title: '颜色',
                            child: {
                              widget: colorRowResolver,
                              inputs: {
                                gradient: false,
                                value: axis$.pipe(map(axis => axis?.label.color))
                              },
                              outputs: {
                                valueChange: ({ color, dragging }) => update(`setting.props.axis.${key}[${index}].label.color`, color, { preview: dragging })
                              }
                            } as ST<ColorRowComponent>
                          }
                        ]
                      },
                      // 网格线
                      {
                        title: title.grid,
                        interaction: 'switch',
                        active: axis$.pipe(map(axis => axis?.grid.show)),
                        activeChange: state => update(`setting.props.axis.${key}[${index}].grid.show`, state),
                        children: [
                          // 背景样式
                          {
                            title: '背景样式',
                            hidden: of(item.gridStyles).pipe(
                              combineLatestWith(axis$),
                              map(([styles, axis]) => styles.length === 0 || !has(axis, 'grid.background'))
                            ),
                            child: {
                              widget: buttonToggleResolver,
                              inputs: {
                                value: axis$.pipe(map(axis => axis?.grid.background?.style)),
                                // 圆形 多边形
                                options: item.gridStyles
                              },
                              outputs: {
                                value: val => update(`setting.props.axis.${key}[${index}].grid.background.style`, val)
                              }
                            } as ST<ButtonToggleComponent>
                          },
                          // 线条样式
                          {
                            title: '线条样式',
                            child: {
                              widget: strokeStyleResolver,
                              inputs: {
                                value: axis$.pipe(map(axis => axis?.grid.type))
                              },
                              outputs: {
                                valueChange: val => update(`setting.props.axis.${key}[${index}].grid.type`, val)
                              }
                            } as ST<StrokeStyleComponent>
                          },
                          // 粗细
                          {
                            title: '粗细',
                            child: {
                              widget: numberInputResolver,
                              inputs: {
                                value: axis$.pipe(map(axis => axis?.grid.width)),
                                aceMinValue: 0,
                                aceMaxValue: 100,
                                useStep: true
                              },
                              outputs: {
                                valueChange: val => update(`setting.props.axis.${key}[${index}].grid.width`, val)
                              }
                            } as ST<NumberInputComponent>
                          },
                          // 颜色
                          {
                            title: '颜色',
                            child: {
                              widget: colorRowResolver,
                              inputs: {
                                gradient: false,
                                value: axis$.pipe(map(axis => axis?.grid.color))
                              },
                              outputs: {
                                valueChange: ({ color, dragging }) => update(`setting.props.axis.${key}[${index}].grid.color`, color, { preview: dragging })
                              }
                            } as ST<ColorRowComponent>
                          },
                          // 背景颜色
                          {
                            title: '背景颜色',
                            hidden: axis$.pipe(map(axis => !has(axis, 'grid.background.color'))),
                            child: {
                              widget: gridBackgroundColorResolver,
                              inputs: {
                                value: axis$.pipe(map(axis => axis?.grid.background?.color))
                              },
                              outputs: {
                                valueChange: ({ color, dragging }) =>
                                  update(`setting.props.axis.${key}[${index}].grid.background.color`, color, { preview: dragging })
                              }
                            } as ST<GridBackgroundColorComponent>
                          }
                        ]
                      },
                      // 位置
                      {
                        title: '',
                        interaction: 'none',
                        hidden: combineLatest([rangeHidden$, positionsHidden$]).pipe(map(([rangeHidden, positionsHidden]) => rangeHidden && positionsHidden)),
                        children: [
                          {
                            title: '位置',
                            hidden: positionsHidden$,
                            child: {
                              widget: buttonToggleResolver,
                              inputs: {
                                // 上面 下面
                                options: item.positions,
                                value: axis$.pipe(map(axis => axis?.position))
                              },
                              outputs: {
                                value: val => update(`setting.props.axis.${key}[${index}].position`, val)
                              }
                            } as ST<ButtonToggleComponent>
                          },
                          // 标签间隔
                          {
                            title: '标签间隔',
                            hidden: axis$.pipe(map(axis => axis?.type !== 'value')),
                            children: axis$.pipe(
                              map(axisSource => axisSource?.stepOfLabel),
                              distinctUntilChanged(),
                              map(stepOfLabel => {
                                const gap = {
                                  widget: selectResolver,
                                  inputs: {
                                    value: axis$.pipe(map(axis => (axis?.stepOfLabel === 'auto' ? 'auto' : 'custom'))),
                                    // 自定义 自动
                                    options: [
                                      { text: '自定义', value: 'custom' },
                                      { text: '自动', value: 'auto' }
                                    ]
                                  },
                                  outputs: {
                                    value: val => update(`setting.props.axis.${key}[${index}].stepOfLabel`, val === 'custom' ? 0 : 'auto')
                                  }
                                } as ST<SelectComponent>
                                const step = {
                                  widget: numberInputResolver,
                                  inputs: {
                                    value: axis$.pipe(map(axis => axis?.stepOfLabel)),
                                    aceMinValue: 0
                                  },
                                  outputs: {
                                    valueChange: val => update(`setting.props.axis.${key}[${index}].stepOfLabel`, val)
                                  }
                                } as ST<NumberInputComponent>
                                return stepOfLabel === 'auto' ? [gap] : [set(gap, 'className', ['mr-1', 'min-w-19.5']), step]
                              })
                            )
                          },
                          // 轴范围
                          {
                            title: '轴范围',
                            hidden: rangeHidden$,
                            child: {
                              widget: rangeInputResolver,
                              inputs: {
                                value: axis$.pipe(map(axis => axis?.range))
                              },
                              outputs: {
                                valueChange: value => this.update(`setting.props.axis.${key}[${index}].range`, value)
                              }
                            } as ST<RangeInputComponent>
                          }
                        ]
                      }
                    ]
                  }
                })
            )
          })
        )
      },
      // 数据格式
      {
        title: '数据格式',
        icon: 'ss:format',
        interaction: 'collapse',
        children: [
          // 千位符
          {
            title: '千位符',
            child: {
              widget: selectResolver,
              inputs: {
                options: ['1000.00', '1,000.00', '1000,00', '1.000,00', '1 000.00', '1 000,00'],
                value: chartProps$.pipe(map(s => s.numberFormat.separatorType))
              },
              outputs: {
                value: val => update('setting.props.numberFormat.separatorType', val)
              }
            } as ST<SelectComponent>
          },
          // 小数位数
          {
            title: '小数位数',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: chartProps$.pipe(map(s => s.numberFormat.decimalPlaces || 0)),
                aceMinValue: 0,
                aceMaxValue: 10,
                useStep: true
              },
              outputs: {
                valueChange: val => update('setting.props.numberFormat.decimalPlaces', val)
              }
            } as ST<NumberInputComponent>
          }
        ]
      },
      // 整体投影
      {
        title: '整体投影',
        icon: 'ss:shadow',
        interaction: 'switch',
        hidden: this.hiddenWholeShadow$,
        active: wholeShadowOption$.pipe(map(s => s.show)),
        activeChange: state => updateWhole('shadow.show', state),
        children: [
          // 类型
          {
            title: '类型',
            child: {
              widget: buttonToggleResolver,
              inputs: {
                value: wholeShadowOption$.pipe(map(s => s.type)),
                options: [
                  { text: '内阴影', value: 'inner' },
                  { text: '外阴影', value: 'outer' }
                ]
              },
              outputs: {
                value: val => updateWhole('shadow.type', val)
              }
            } as ST<ButtonToggleComponent>
          },
          // 颜色
          {
            title: '颜色',
            child: {
              widget: colorRowResolver,
              inputs: {
                gradient: false,
                value: wholeShadowOption$.pipe(map(s => s.color))
              },
              outputs: {
                valueChange: ({ color, dragging }) => updateWhole('shadow.color', color, { preview: dragging })
              }
            } as ST<ColorRowComponent>
          },
          // 偏移
          {
            title: '偏移',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: wholeShadowOption$.pipe(map(s => s.radius)),
                aceMinValue: 0,
                aceMaxValue: 100,
                useSlide: true
              },
              outputs: generateShadowSlideOutputs('shadow.radius', false)
            } as ST<NumberInputComponent>
          },
          // 角度
          {
            title: '角度',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: wholeShadowOption$.pipe(map(s => s.angle)),
                aceMinValue: 0,
                aceMaxValue: 360,
                useSlide: true
              },
              outputs: generateShadowSlideOutputs('shadow.angle', false)
            } as ST<NumberInputComponent>
          },
          // 模糊
          {
            title: '模糊',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: wholeShadowOption$.pipe(map(s => s.blur)),
                aceMinValue: 0,
                aceMaxValue: 100,
                useSlide: true
              },
              outputs: generateShadowSlideOutputs('shadow.blur', false)
            } as ST<NumberInputComponent>
          }
        ]
      },
      // 动画
      {
        title: '动画',
        icon: 'ss:animate',
        interaction: 'switch',
        active: chartProps$.pipe(map(s => s.animation.transition)),
        activeChange: state => update('setting.props.animation.transition', state),
        children: [
          // 类型
          {
            title: '类型',
            child: {
              widget: animationSelectResolver,
              inputs: {
                value: chartProps$.pipe(map(s => s.animation.moveStyle ?? null)),
                options: this.chartType$.pipe(
                  map(type => {
                    return this.getAnimationOption(type)
                  })
                )
              },
              outputs: {
                value: val => update('setting.props.animation.moveStyle', val)
              }
            } as ST<AnimationSelectComponent>
          },
          // 持续（秒）
          {
            title: '持续（秒）',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: chartProps$.pipe(map(s => s.animation.duration)),
                aceMinValue: 0,
                aceMaxValue: 10,
                useSlide: true
              },
              outputs: generateSlideOutputs('setting.props.animation.duration')
            } as ST<NumberInputComponent>
          },
          // 开始延迟
          {
            title: '开始延迟',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: chartProps$.pipe(map(s => s.animation.startDelay)),
                aceMinValue: 0,
                aceMaxValue: 10,
                useStep: true
              },
              outputs: {
                valueChange: value => update('setting.props.animation.startDelay', value)
              }
            } as ST<NumberInputComponent>
          },
          // 结束延迟
          {
            title: '结束延迟',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: chartProps$.pipe(map(s => s.animation.endPause)),
                aceMinValue: 0,
                aceMaxValue: 10,
                useStep: true
              },
              outputs: {
                valueChange: value => update('setting.props.animation.endPause', value)
              }
            } as ST<NumberInputComponent>
          },
          // 循环播放
          {
            title: '循环播放',
            interaction: 'radio',
            active: chartProps$.pipe(map(s => s.animation.loop)),
            activeChange: state => update('setting.props.animation.loop', state)
          }
        ]
      },
      // 交互提示
      {
        title: '交互提示',
        icon: 'ss:tooltip',
        interaction: 'switch',
        active: chartProps$.pipe(map(s => s.tooltip)),
        activeChange: state => update('setting.props.tooltip', state)
      }
    ]
  }

  private getValueMapSchema(): Observable<Array<SettingSchema | SettingSchemaRow>> {
    return this.chartType$.pipe(
      distinctUntilChanged(),
      switchMap(type => {
        if (filterTypeList.includes(type)) {
          // 通过模版中的map来渲染，比如柱状图中的名称/值 及其他编辑数据不会改变map长度的图表
          return this.chartTemplate$.pipe(
            map(s => {
              return s.props.map.map<SettingSchemaRow>((colDef, colDefIndex) => {
                return {
                  title: colDef.name,
                  child: {
                    widget: selectResolver,
                    inputs: {
                      value: this.map$.pipe(map(m => m[colDefIndex]?.index)),
                      options: this.element$.pipe(
                        map(d =>
                          d.setting.data[0][0].map((item1, index1) => ({
                            text: String(item1),
                            value: index1
                          }))
                        )
                      )
                    },
                    outputs: {
                      indexChange: val => this.update(`setting.props.map[${colDefIndex}].index`, val)
                    }
                  } as ST<SelectComponent>
                }
              })
            })
          )
        } else if (type === 'mixed-line-grouped-column' || type === 'mixed-line-stacked-column') {
          return of({
            title: this.chartProps$.pipe(map(s => s.map[0].name)),
            child: {
              widget: inputResolver,
              inputs: {
                disabled: true,
                value: this.setting$.pipe(map(s => s.data[0][0][0]))
              }
            } as ST<InputComponent>
          })
            .pipe(
              combineLatestWith(
                this.chartProps$.pipe(
                  distinctUntilChanged((p, v) => isEqual(p.map.length, v.map.length)),
                  map(p =>
                    p.map.slice(1).map((item, index) => {
                      return {
                        title: this.setting$.pipe(map(setting => String(setting.data[0][0][item.index]))),
                        child: {
                          widget: buttonToggleResolver,
                          inputs: {
                            value: item.type,
                            options: [
                              { icon: 'editorup:bar', value: 'bar' },
                              { icon: 'editorup:line', value: 'line' }
                            ]
                          },
                          outputs: {
                            value: val =>
                              this.update(`setting.props.map[${index + 1}]`, {
                                ...item,
                                type: val,
                                // 切换折线和柱状图时默认切换轴映射
                                yAxisIndex: val === 'line' ? 1 : val === 'bar' ? 0 : item.yAxisIndex
                              })
                          }
                        } as ST<ButtonToggleComponent>
                      }
                    })
                  )
                )
              )
            )
            .pipe(map(([s1, s2]) => [s1, ...s2]))
        } else {
          return of<Array<SettingSchema | SettingSchemaRow>>([
            {
              title: this.chartProps$.pipe(map(s => s.map[0].name)),
              child: {
                widget: inputResolver,
                inputs: {
                  disabled: true,
                  value: this.setting$.pipe(map(s => s.data[0][0][0]))
                }
              } as ST<InputComponent>
            },
            {
              title: '数值列',
              interaction: 'popup',
              children: [
                {
                  flexible: true,
                  child: {
                    widget: valueColumnResolver,
                    inputs: {
                      value: this.setting$.pipe(
                        map(s => {
                          const columns = s.data[0][0].slice(1)
                          const colors = s.props.fill.props.map(p => p.color.color)
                          return columns.map<ValueColumn>((c, index) => {
                            return {
                              label: String(c),
                              color: colors.length !== columns.length ? undefined : (colors[index] as string),
                              checked: s.props.map[index + 1].configurable || false
                            }
                          })
                        })
                      )
                    },
                    outputs: {
                      valueChange: ({ checked, index }) => this.update(`setting.props.map[${index + 1}].configurable`, checked)
                    }
                  } as ST<ValueColumnComponent>
                }
              ]
            }
          ])
        }
      })
    )
  }

  /**
   * 获取图表颜色配置
   * @private
   */
  private getChartColorSchema(): Array<SettingSchema | SettingSchemaRow> {
    return [
      // 图表颜色配置
      {
        flexible: true,
        child: {
          widget: chartColorResolver,
          inputs: {
            colDefType: this.element$.pipe(map(element => element.setting.props.map.map(item => item.type).slice(1))),
            chartType: this.chartType$,
            // enableControl: this.chartType$.pipe(map(type => enableControlType.includes(type))),
            dimension: this.setting$.pipe(map(s => getChartDimension(s.props.map, s.props.type, s.data[0]))),
            value: this.chartProps$.pipe(map(s => cloneDeep(s.fill)))
          },
          outputs: {
            valueChange: val => this.update('setting.props.fill', val),
            valuePathChange: ({ path, value, index, preview = false }) =>
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              this.update(`setting.props.fill.props[${index}].${path}` as OptionsKey<IPageElementBase<ElementTypeEnum.Chart>>, value, {
                preview
              })
          }
        } as ST<ChartColorComponent>
      }
    ]
  }

  /**
   * 获取样式配置
   * @private
   */
  private getDisplaySchema(): Observable<Array<SettingSchema | SettingSchemaRow>> {
    // 存在多个配置时，需要切换配置
    const displayType = new BehaviorSubject<DisplayType>('bar')
    const displayType$ = displayType.asObservable()
    return this.chartProps$.pipe(
      map(s => {
        // 根据display中的属性判断出现什么类型的样式配置
        const { bar, arrow, line, area, pie, bubble, sankey, voronoi } = s.display
        // 柱形样式和折线样式需要判断映射中是有当前类型的图形
        return {
          bar: !!bar && s.map.some(m => m.type === 'bar'),
          line: !!line && s.map.some(m => m.type === 'line'),
          arrow: !!arrow,
          area: !!area,
          pie: !!pie,
          bubble: !!bubble,
          sankey: !!sankey,
          voronoi: !!voronoi
        }
      }),
      distinctUntilChanged((p, v) => isEqual(p, v)),
      switchMap(({ bar, line, arrow, area, pie, bubble, sankey, voronoi }) => {
        const options = [
          { text: '柱形', value: 'bar' },
          { text: '箭头', value: 'arrow' },
          { text: '线形', value: 'line' },
          { text: '面积', value: 'area' },
          { text: '饼图', value: 'pie' },
          { text: '气泡', value: 'bubble' },
          { text: '桑基', value: 'sankey' },
          { text: '维诺', value: 'voronoi' }
        ].filter(s => {
          return (
            (s.value === 'bar' && bar) ||
            (s.value === 'arrow' && arrow) ||
            (s.value === 'line' && line) ||
            (s.value === 'area' && area) ||
            (s.value === 'pie' && pie) ||
            (s.value === 'bubble' && bubble) ||
            (s.value === 'sankey' && sankey) ||
            (s.value === 'voronoi' && voronoi)
          )
        }) as { text: string; value: DisplayType }[]
        // 如果需要多个样式配置，那么增加toggleButton进行切换配置
        return options.length > 1
          ? of({
              title: '',
              child: {
                widget: buttonToggleResolver,
                inputs: {
                  full: true,
                  value: displayType$,
                  options: options
                },
                outputs: {
                  value: val => displayType.next(val as DisplayType)
                }
              } as ST<ButtonToggleComponent>
            }).pipe(
              combineLatestWith(
                displayType$.pipe(
                  distinctUntilChanged(),
                  map(t => this.getDisplaySchemaWithType(t))
                )
              ),
              map(([s1, s2]) => [s1, ...s2])
            )
          : of(this.getDisplaySchemaWithType(options[0]?.value)) // 仅存在一个时
      })
    )
  }

  /**
   * 根据样式的类型获取对应的配置
   * @param type
   * @private
   */
  private getDisplaySchemaWithType(type?: DisplayType): Array<SettingSchemaRow | SettingSchema> {
    if (type === 'bar') {
      return this.getDisplayBarSchema()
    } else if (type === 'line') {
      return this.getDisplayLineSchema()
    } else if (type === 'area') {
      return this.getDisplayAreaSchema()
    } else if (type === 'arrow') {
      return this.getDisplayArrowSchema()
    } else if (type === 'pie') {
      return this.getDisplayPieSchema()
    } else if (type === 'bubble') {
      return this.getDisplayBubbleSchema()
    } else if (type === 'sankey') {
      return this.getDisplaySankeySchema()
    } else if (type === 'voronoi') {
      return this.getDisplayVoronoiSchema()
    } else {
      return [
        {
          hidden: of(true)
        }
      ]
    }
  }

  /**
   * 获取存在描边效果的的样式
   * @private
   */
  private getDisplayBarSchema(): SettingSchemaRow[] {
    return [
      // 显示顺序
      {
        title: '显示顺序',
        hidden: this.chartProps$.pipe(map(s => !has(s, 'display.bar.order'))),
        child: {
          widget: selectResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.bar?.order)),
            options: barOrderOptions
          },
          outputs: {
            value: val => this.update('setting.props.display.bar.order', val)
          }
        } as ST<SelectComponent>
      },
      //宽度比例
      {
        title: '宽度比例',
        hidden: this.chartProps$.pipe(map(s => !has(s, 'display.bar.widthPercent'))),
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(
              map(s => s.display?.bar?.widthPercent),
              map(val => (val !== undefined ? val * 100 : undefined))
            ),
            aceMinValue: 0,
            aceMaxValue: 100,
            useSlide: true,
            aceSlideStep: 1
          },
          outputs: this.generateSlideOutputs('setting.props.display.bar.widthPercent', false, {
            transformer: val => (isNumber(val) ? val / 100 : undefined)
          })
        } as ST<NumberInputComponent>
      },
      //间隙
      {
        title: '间隙',
        hidden: this.chartProps$.pipe(map(s => !has(s, 'display.bar.gapDistance'))),
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display?.bar?.gapDistance)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useSlide: true,
            aceSlideStep: 1
          },
          outputs: this.generateSlideOutputs('setting.props.display.bar.gapDistance', false)
        } as ST<NumberInputComponent>
      },
      // 柱形圆角
      {
        title: '柱形圆角',
        hidden: this.chartProps$.pipe(map(s => !has(s, 'display.bar.border.radius'))),
        child: {
          widget: borderRadiusResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display?.bar?.border?.radius))
          },
          outputs: {
            value: val => this.update('setting.props.display.bar.border.radius', val)
          }
        } as ST<BorderRadiusComponent>
      },
      // 描边样式
      {
        title: '描边样式',
        child: {
          widget: strokeStyleResolver,
          inputs: {
            value: this.wholeBorder$.pipe(map(s => s.type))
          },
          outputs: {
            valueChange: val => this.updateWhole('border.type', val)
          }
        } as ST<StrokeStyleComponent>
      },
      // 描边粗细
      {
        title: '描边粗细',
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.wholeBorder$.pipe(map(s => s.width)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useStep: true
          },
          outputs: {
            valueChange: val => this.updateWhole('border.width', val)
          }
        } as ST<NumberInputComponent>
      },
      // 描边颜色
      {
        flexible: true,
        child: {
          widget: strokeColorResolver,
          inputs: {
            label: '描边颜色',
            value: this.wholeBorder$.pipe(map(s => s.color))
          },
          outputs: {
            valueChange: value => this.updateWhole('border.color', value?.color || value, { preview: value?.dragging || false })
          }
        } as ST<StrokeColorComponent>
      },
      // 背景填充
      {
        flexible: true,
        hidden: this.chartProps$.pipe(map(s => !has(s.display.bar, 'backgroundColor'))),
        child: {
          widget: strokeColorResolver,
          inputs: {
            label: '背景填充',
            value: this.chartProps$.pipe(map(s => s.display.bar?.backgroundColor))
          },
          outputs: {
            valueChange: value => this.update('setting.props.display.bar.backgroundColor', value?.color || value, { preview: value?.dragging || false })
          }
        } as ST<StrokeColorComponent>
      },
      // 透明度
      {
        title: '透明度',
        hidden: this.chartProps$.pipe(map(s => !has(s, 'display.bar.fillOpacity'))),
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => (s.display?.bar?.fillOpacity || 0) * 100)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useSlide: true
          },
          outputs: this.generateSlideOutputs('setting.props.display.bar.fillOpacity', false, {
            transformer: val => (isNumber(val) ? val / 100 : undefined)
          })
        } as ST<NumberInputComponent>
      }
    ]
  }

  /**
   * 获取箭头样式
   * @private
   */
  private getDisplayArrowSchema(): SettingSchemaRow[] {
    return [
      // 增长颜色
      {
        title: '增长颜色',
        child: {
          widget: colorRowResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.arrow?.growthArrowColor))
          },
          outputs: {
            valueChange: ({ color, dragging }) => this.update('setting.props.display.arrow.growthArrowColor', color, { preview: dragging })
          }
        } as ST<ColorRowComponent>
      },
      // 減少颜色
      {
        title: '减少颜色',
        child: {
          widget: colorRowResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.arrow?.decreaseArrowColor))
          },
          outputs: {
            valueChange: ({ color, dragging }) => this.update('setting.props.display.arrow.decreaseArrowColor', color, { preview: dragging })
          }
        } as ST<ColorRowComponent>
      },
      // 箭头粗细
      {
        title: '箭头粗细',
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.arrow?.width)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useSlide: true
          },
          outputs: this.generateSlideOutputs('setting.props.display.arrow.width', false)
        } as ST<NumberInputComponent>
      },
      // 终止线
      {
        title: '终止线',
        child: {
          widget: strokeStyleResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.arrow?.endLine.type))
          },
          outputs: {
            valueChange: val => this.update('setting.props.display.arrow.endLine.type', val)
          }
        } as ST<StrokeStyleComponent>
      },
      // 终止线宽
      {
        title: '终止线宽',
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.arrow?.endLine.width)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useSlide: true
          },
          outputs: this.generateSlideOutputs('setting.props.display.arrow.endLine.width', false)
        } as ST<NumberInputComponent>
      },
      // 终止线颜色
      {
        title: '终止线颜色',
        child: {
          widget: colorRowResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.arrow?.endLine.color))
          },
          outputs: {
            valueChange: ({ color, dragging }) => this.update('setting.props.display.arrow.endLine.color', color, { preview: dragging })
          }
        } as ST<ColorRowComponent>
      }
    ]
  }

  /**
   * 获取线形样式
   * @private
   */
  private getDisplayLineSchema(): SettingSchemaRow[] {
    return [
      // 折线样式
      {
        title: '折线样式',
        hidden: this.chartProps$.pipe(map(s => !has(s.display?.line, 'type'))),
        child: {
          widget: buttonToggleResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display?.line?.type)),
            options: LineStyleOptions
          },
          outputs: {
            value: val => this.update(`setting.props.display.line.type`, val)
          }
        } as ST<ButtonToggleComponent>
      },
      // 线宽
      {
        title: '线宽',
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.line?.width)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useStep: true
          },
          outputs: {
            valueChange: val => this.update(`setting.props.display.line.width`, val)
          }
        } as ST<NumberInputComponent>
      },
      // 节点半径
      {
        title: '节点半径',
        hidden: this.chartProps$.pipe(map(s => !has(s.display.line?.endPoint, 'radius'))),
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.line?.endPoint?.radius)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useStep: true
          },
          outputs: {
            valueChange: val => this.update(`setting.props.display.line.endPoint.radius`, val)
          }
        } as ST<NumberInputComponent>
      },
      // 节点描边
      {
        title: '节点描边',
        hidden: this.chartProps$.pipe(map(s => !has(s.display.line?.endPoint, 'type'))),
        child: {
          widget: strokeStyleResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.line?.endPoint?.type))
          },
          outputs: {
            valueChange: val => this.update(`setting.props.display.line.endPoint.type`, val)
          }
        } as ST<StrokeStyleComponent>
      },
      // 节点描边线宽
      {
        title: '节点线宽',
        hidden: this.chartProps$.pipe(map(s => !has(s.display.line?.endPoint, 'width'))),
        child: {
          widget: numberInputResolver,
          inputs: {
            aceMinValue: 0,
            aceMaxValue: 100,
            useStep: true,
            value: this.chartProps$.pipe(map(s => s.display.line?.endPoint?.width))
          },
          outputs: {
            valueChange: val => this.update(`setting.props.display.line.endPoint.width`, val)
          }
        } as ST<NumberInputComponent>
      },
      // 节点描边色
      {
        flexible: true,
        hidden: this.chartProps$.pipe(map(s => !has(s.display.line?.endPoint, 'color'))),
        child: {
          widget: strokeColorResolver,
          inputs: {
            label: '节点描边色',
            value: this.chartProps$.pipe(map(s => s.display.line?.endPoint?.color))
          },
          outputs: {
            valueChange: value => this.update(`setting.props.display.line.endPoint.color`, value?.color || value, { preview: !!value?.dragging })
          }
        } as ST<StrokeColorComponent>
      },
      // 节点填充色
      {
        flexible: true,
        hidden: this.chartProps$.pipe(map(s => !has(s.display.line?.endPoint, 'fill'))),
        child: {
          widget: strokeColorResolver,
          inputs: {
            label: '节点填充色',
            value: this.chartProps$.pipe(map(s => s.display.line?.endPoint?.fill))
          },
          outputs: {
            valueChange: value => this.update(`setting.props.display.line.endPoint.fill`, value?.color || value, { preview: !!value?.dragging })
          }
        } as ST<StrokeColorComponent>
      }
    ]
  }

  /**
   * 获取饼图样式
   * @private
   */
  private getDisplayPieSchema(): SettingSchemaRow[] {
    return [
      // 圆角半径
      {
        title: '圆角半径',
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.pie?.border?.radius)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useStep: true
          },
          outputs: {
            valueChange: val => this.update('setting.props.display.pie.border.radius', val)
          }
        } as ST<NumberInputComponent>
      },
      // 起始角度
      {
        title: '起始角度',
        hidden: this.chartProps$.pipe(map(s => !has(s.display.pie, 'startAngle'))),
        child: {
          widget: buttonToggleResolver,
          inputs: {
            full: true,
            value: this.chartProps$.pipe(map(s => s.display.pie?.startAngle)),
            options: [
              { text: '0', value: 0 },
              { text: '90', value: 90 },
              { text: '180', value: 180 },
              { text: '270', value: 270 }
            ]
          },
          outputs: {
            value: val => this.update('setting.props.display.pie.startAngle', val)
          }
        } as ST<ButtonToggleComponent>
      },
      // 绘制方向
      {
        title: '绘制方向',
        hidden: this.chartProps$.pipe(map(s => !has(s.display.pie, 'rotateDirection'))),
        child: {
          widget: buttonToggleResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.pie?.rotateDirection)),
            options: chartRotateDirectionOptions
          },
          outputs: {
            value: val => this.update('setting.props.display.pie.rotateDirection', val)
          }
        } as ST<ButtonToggleComponent>
      },
      // 绘制角度
      {
        title: '绘制角度',
        hidden: this.chartProps$.pipe(map(s => !has(s.display.pie, 'drawAngle'))),
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.pie?.drawAngle)),
            aceMinValue: 0,
            aceMaxValue: 360,
            useSlide: true
          },
          outputs: this.generateSlideOutputs('setting.props.display.pie.drawAngle', false)
        } as ST<NumberInputComponent>
      },
      // 内径占比
      {
        title: '内径占比',
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => (s.display.pie?.innerRadiusRatio || 0) * 100)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useSlide: true
          },
          outputs: this.generateSlideOutputs('setting.props.display.pie.innerRadiusRatio', false, {
            transformer: val => (isNumber(val) ? val / 100 : undefined)
          })
        } as ST<NumberInputComponent>
      },
      // 间隙
      {
        title: '间隙',
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.pie?.gapPercentage)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useSlide: true
          },
          outputs: this.generateSlideOutputs('setting.props.display.pie.gapPercentage', false)
        } as ST<NumberInputComponent>
      },
      // 描边样式
      {
        title: '描边样式',
        child: {
          widget: strokeStyleResolver,
          inputs: {
            value: this.wholeBorder$.pipe(map(s => s?.type))
          },
          outputs: {
            valueChange: val => this.updateWhole('border.type', val)
          }
        } as ST<StrokeStyleComponent>
      },
      // 描边粗细
      {
        title: '描边粗细',
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.wholeBorder$.pipe(map(s => s?.width)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useStep: true
          },
          outputs: {
            valueChange: val => this.updateWhole('border.width', val)
          }
        } as ST<NumberInputComponent>
      },
      // 描边颜色
      {
        flexible: true,
        child: {
          widget: strokeColorResolver,
          inputs: {
            label: '描边颜色',
            value: this.wholeBorder$.pipe(map(s => s.color))
          },
          outputs: {
            valueChange: value => this.updateWhole('border.color', value?.color || value, { preview: value?.dragging || false })
          }
        } as ST<StrokeColorComponent>
      },
      // 背景填充
      {
        flexible: true,
        hidden: this.chartProps$.pipe(map(s => !has(s.display.pie, 'backgroundColor'))),
        child: {
          widget: strokeColorResolver,
          inputs: {
            label: '背景填充',
            value: this.chartProps$.pipe(map(s => s.display.pie?.backgroundColor))
          },
          outputs: {
            valueChange: value => this.update('setting.props.display.pie.backgroundColor', value?.color || value, { preview: value?.dragging || false })
          }
        } as ST<StrokeColorComponent>
      }
    ]
  }

  /**
   * 获取坐标轴附加名称
   * @param position
   * @param type
   * @param index
   * @private
   */
  private getAxisPositionName(position: AxisPositionType | undefined, type: ChartType, index: number): string {
    if (!position) return ''
    let extra = ''
    if (type === 'butterfly') {
      extra = index === 0 ? '左侧' : '右侧'
    }
    let positionName = ''
    switch (position) {
      case 'top':
        positionName = '（上面）'
        break
      case 'bottom':
        positionName = '（下面）'
        break
      case 'left':
        positionName = '（左侧）'
        break
      case 'right':
        positionName = '（右侧）'
        break
      case 'inside':
        positionName = '（内部）'
        break
      case 'outside':
        positionName = '（外部）'
        break
      default:
        positionName = ''
    }
    return `${extra}${positionName}`
  }

  /**
   * 获取样式中 面积 的属性配置
   * @private
   */
  private getDisplayAreaSchema(): SettingSchemaRow[] {
    return [
      // 折线样式
      {
        title: '折线样式',
        hidden: this.chartProps$.pipe(map(s => !has(s.display.area, 'type'))),
        child: {
          widget: buttonToggleResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.area?.type)),
            options: LineStyleOptions
          },
          outputs: {
            value: val => this.update(`setting.props.display.area.type`, val)
          }
        } as ST<ButtonToggleComponent>
      },
      // 线宽
      {
        title: '线宽',
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.area?.width)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useStep: true
          },
          outputs: {
            valueChange: val => this.update(`setting.props.display.area.width`, val)
          }
        } as ST<NumberInputComponent>
      },
      // 透明度
      {
        title: '透明度',
        hidden: this.chartProps$.pipe(map(s => !has(s.display.area, 'fillOpacity'))),
        child: {
          widget: numberInputResolver,
          inputs: {
            useSlide: true,
            aceSlideStep: 0.1,
            aceMinValue: 0,
            aceMaxValue: 1,
            aceNumberPrecision: 1,
            value: this.chartProps$.pipe(map(s => s.display.area?.fillOpacity))
          },
          outputs: this.generateSlideOutputs('setting.props.display.area.fillOpacity', false)
        } as ST<NumberInputComponent>
      },
      // 节点半径
      {
        title: '节点半径',
        hidden: this.chartProps$.pipe(map(s => !has(s.display.area?.endPoint, 'radius'))),
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.area?.endPoint?.radius)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useStep: true
          },
          outputs: {
            valueChange: val => this.update(`setting.props.display.area.endPoint.radius`, val)
          }
        } as ST<NumberInputComponent>
      },
      // 节点描边
      {
        title: '节点描边',
        hidden: this.chartProps$.pipe(map(s => !has(s.display.area?.endPoint, 'type'))),
        child: {
          widget: strokeStyleResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.area?.endPoint?.type))
          },
          outputs: {
            valueChange: val => this.update(`setting.props.display.area.endPoint.type`, val)
          }
        } as ST<StrokeStyleComponent>
      },
      {
        title: '节点线宽',
        hidden: this.chartProps$.pipe(map(s => !has(s.display.area?.endPoint, 'width'))),
        child: {
          widget: numberInputResolver,
          inputs: {
            aceMinValue: 0,
            aceMaxValue: 100,
            useStep: true,
            value: this.chartProps$.pipe(map(s => s.display.area?.endPoint?.width))
          },
          outputs: {
            valueChange: val => this.update(`setting.props.display.area.endPoint.width`, val)
          }
        } as ST<NumberInputComponent>
      },
      // 节点描边色
      {
        flexible: true,
        hidden: this.chartProps$.pipe(map(s => !has(s.display.area?.endPoint, 'color'))),
        child: {
          widget: strokeColorResolver,
          inputs: {
            label: '节点描边色',
            value: this.chartProps$.pipe(map(s => s.display.area?.endPoint?.color))
          },
          outputs: {
            valueChange: value => this.update(`setting.props.display.area.endPoint.color`, value?.color || null, { preview: !!value?.dragging })
          }
        } as ST<StrokeColorComponent>
      },
      // 节点填充色
      {
        flexible: true,
        hidden: this.chartProps$.pipe(map(s => !has(s.display.area?.endPoint, 'fill'))),
        child: {
          widget: strokeColorResolver,
          inputs: {
            label: '节点填充色',
            value: this.chartProps$.pipe(map(s => s.display.area?.endPoint?.fill))
          },
          outputs: {
            valueChange: value => this.update(`setting.props.display.area.endPoint.fill`, value?.color || null, { preview: !!value?.dragging })
          }
        } as ST<StrokeColorComponent>
      }
    ]
  }

  private getLabelOptions(type: ChartType, key: LabelKeys) {
    if (type === 'funnel') {
      const positions: Array<{ text: string; value: funnelLabelPosition }> = [
        { value: 'outside-follow', text: '外部跟随' },
        { value: 'outside-edge', text: '外部边缘' },
        { value: 'inside-horizontal', text: '内部居中' }
      ]
      return positions
    } else if (type === 'jade-jue') {
      const positions: Array<{ text: string; value: jadeLabelPosition }> = [
        { value: 'inside-left', text: '内部居左' },
        { value: 'inside-right', text: '内部居右' },
        { value: 'outside', text: '外部' }
      ]
      return positions
    } else if (type === 'single-layer-treemap') {
      // 矩形树图
      const positions: Array<{ text: string; value: treemapLabelPosition }> = [
        { text: '内部居中', value: 'inside-center' },
        { text: '内部左上', value: 'inside-left-top' }
      ]
      return positions
    } else if (type === 'sankey') {
      // 数值标签和文本标签位置相同
      const positions: Array<{ text: string; value: sankeyLabelPosition }> = [
        { text: '内部', value: 'inside' },
        { text: '外部', value: 'outside' },
        { text: '水平居中', value: 'horizontal-center' },
        { text: '垂直居中', value: 'vertical-center' }
      ]
      return positions
    } else if (type === 'basic-pie') {
      //inside-circumference  内部周向
      // inside-horizontal  内部水平
      // outside-edge
      // outside-ellipse
      const positions: Array<{ text: string; value: basicPieLabelPosition }> = [
        { text: '内部周向', value: 'inside-circumference' },
        { text: '内部水平', value: 'inside-horizontal' },
        { text: '外部边缘', value: 'outside-edge' },
        { text: '外部椭圆', value: 'outside-ellipse' }
      ]
      return positions
    } else if (type === 'rose-pie') {
      const positions: Array<{ text: string; value: defaultLabelPosition }> = [
        // 内部
        { text: '内部', value: 'inside' },
        // 外部
        { text: '外部', value: 'outside' }
      ]
      return positions
    } else if (type === 'bar-progress') {
      const positions: Array<{ text: string; value: barLabelPosition }> = [
        { text: '中间', value: 'center' },
        { text: '右边', value: 'right' }
      ]
      return positions
    } else {
      if (key === 'lineLabel') {
        const positions: Array<{ text: string; value: defaultLabelPosition }> = [
          { text: '上面', value: 'top' },
          { text: '下面', value: 'bottom' }
        ]
        return positions
      } else if (key === 'barLabel') {
        const barTypeList: Array<ChartType> = ['grouped-bar', 'stacked-bar', 'difference-arrow-bar', 'basic-bar', 'butterfly']
        if (barTypeList.includes(type)) {
          const positions: Array<{ text: string; value: barLabelPosition }> = [
            { text: '内部居左', value: 'left' },
            { text: '内部居中', value: 'center' },
            { text: '内部居右', value: 'right' }
          ]
          // 除了堆叠图形，其余条形图需要外部标签的位置选项
          if (type !== 'stacked-bar') {
            positions.unshift({ text: '外部', value: 'outside' })
          }
          return positions
        } else {
          const positions: Array<{ text: string; value: columnLabelPosition }> = [
            { text: '内部居上', value: 'top' },
            { text: '内部居中', value: 'center' },
            { text: '内部居下', value: 'bottom' }
          ]
          // 除了堆叠图形，其余柱状图需要外部标签的位置选项
          if (type !== 'stacked-column') {
            positions.unshift({ text: '外部', value: 'outside' })
          }
          return positions
        }
      } else if (key === 'arrowLabel') {
      } else if (key === 'percentLabel') {
        // 饼图
      } else if (key === 'numberLabel') {
        // 饼图
      } else if (key === 'textLabel') {
        // 饼图
      }
      const defaultPositions: Array<{ text: string; value: defaultLabelPosition }> = [
        { text: '上面', value: 'top' },
        { text: '下面', value: 'bottom' }
      ]
      return defaultPositions
    }
  }

  private getAnimationOption(type: ChartType) {
    const barOption = [
      {
        gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
        cn_name: '无动画',
        en_name: null
      },
      {
        gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_column/vertical_synchronous_stretching.gif',
        cn_name: '纵向同步拉伸',
        en_name: 'vertical-sync-stretch'
      },
      {
        gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_column/horizontal_classified_expansion.gif',
        cn_name: '纵向依次拉伸',
        en_name: 'vertical-staggered-stretch'
      },
      {
        gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_column/horizontal_synchronous_expansion.gif',
        cn_name: '横向同步展开',
        en_name: 'horizontal-sync-expand'
      },
      {
        gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_column/horizontal_sequential_expansion.gif',
        cn_name: '横向依次展开',
        en_name: 'horizontal-staggered-expand'
      }
    ]

    switch (type) {
      case 'basic-pie':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_pie/wheel.gif',
            cn_name: '轮子',
            en_name: 'wheel'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_pie/blinds.gif',
            cn_name: '百叶窗',
            en_name: 'louver'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_pie/folding_fan.gif',
            cn_name: '折扇',
            en_name: 'folding-fan'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_pie/radial_expansion.gif',
            cn_name: '径向展开',
            en_name: 'radial-expand'
          }
        ]
      case 'grouped-bar':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/grouped_bar/vertical_synchronous_stretching.gif',
            cn_name: '纵向同步拉伸',
            en_name: 'vertical-sync-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/grouped_bar/vertical_sequential_stretching.gif',
            cn_name: '纵向依次拉伸',
            en_name: 'vertical-staggered-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/grouped_bar/horizontal_synchronous_expansion.gif',
            cn_name: '横向同步展开',
            en_name: 'horizontal-sync-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/grouped_bar/horizontal_sequential_expansion.gif',
            cn_name: '横向依次展开',
            en_name: 'horizontal-staggered-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/grouped_bar/vertical_classified_expansion.gif',
            cn_name: '纵向分类拉伸',
            en_name: 'horizontal-categorical-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/grouped_bar/horizontal_classified_expansion.gif',
            cn_name: '横向分类展开',
            en_name: 'vertical-categorical-expand'
          }
        ]
      case 'grouped-column':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/grouped_column/vertical_synchronous_stretching.gif',
            cn_name: '纵向同步拉伸',
            en_name: 'vertical-sync-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/grouped_column/vertical_sequential_stretching.gif',
            cn_name: '纵向依次拉伸',
            en_name: 'vertical-staggered-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/grouped_column/horizontal_synchronous_expansion.gif',
            cn_name: '横向同步展开',
            en_name: 'horizontal-sync-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/grouped_column/horizontal_sequential_expansion.gif',
            cn_name: '横向依次展开',
            en_name: 'horizontal-staggered-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/grouped_column/horizontal_classified_expansion.gif',
            cn_name: '纵向分类拉伸',
            en_name: 'vertical-categorical-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/grouped_column/vertical_classified_expansion.gif',
            cn_name: '横向分类展开',
            en_name: 'horizontal-categorical-expand'
          }
        ]
      case 'stacked-bar':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/stacked_bar/vertical_synchronous_stretching.gif',
            cn_name: '横向同步拉伸',
            en_name: 'horizontal-sync-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/stacked_bar/vertical_sequential_stretching.gif',
            cn_name: '横向依次拉伸',
            en_name: 'horizontal-staggered-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/stacked_bar/horizontal_synchronous_expansion.gif',
            cn_name: '纵向同步展开',
            en_name: 'vertical-sync-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/stacked_bar/horizontal_sequential_expansion.gif',
            cn_name: '纵向依次展开',
            en_name: 'vertical-staggered-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/stacked_bar/vertical_classified_stretching.gif',
            cn_name: '横向分类拉伸',
            en_name: 'horizontal-categorical-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/stacked_bar/horizontal_classified_expansion.gif',
            cn_name: '横向整体拉伸',
            en_name: 'horizontal-unified-expand'
          }
        ]
      case 'stacked-column':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/stacked_column/vertical_synchronous_stretching.gif',
            cn_name: '纵向同步拉伸',
            en_name: 'vertical-sync-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/stacked_column/vertical_sequential_stretching.gif',
            cn_name: '纵向依次拉伸',
            en_name: 'vertical-staggered-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/stacked_column/horizontal_synchronous_expansion.gif',
            cn_name: '横向同步展开',
            en_name: 'horizontal-sync-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/stacked_column/horizontal_sequential_expansion.gif',
            cn_name: '横向依次展开',
            en_name: 'horizontal-staggered-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/stacked_column/vertical_classified_stretching.gif',
            cn_name: '纵向分类拉伸',
            en_name: 'vertical-categorical-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/stacked_column/horizontal_classified_expansion.gif',
            cn_name: '纵向整体拉伸',
            en_name: 'vertical-unified-stretch'
          }
        ]
      case 'basic-line':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_line/sequential_drawing.gif',
            cn_name: '依次绘制',
            en_name: 'sequential-draw'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_line/simultaneous_drawing.gif',
            cn_name: '同时绘制',
            en_name: 'simultaneous-draw'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_line/thread_a_needle.gif',
            cn_name: '穿针引线',
            en_name: 'threading'
          }
        ]
      case 'cascaded-area':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/cascaded_area/horizontal_expansion.gif',
            cn_name: '横向展开',
            en_name: 'horizontal-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/cascaded_area/vertical_sequential_stretching.gif',
            cn_name: '纵向依次拉伸',
            en_name: 'vertical-sequential-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/cascaded_area/vertical_synchronous_stretching.gif',
            cn_name: '纵向同步拉伸',
            en_name: 'vertical-synchronous-expand'
          }
        ]
      case 'stacked-area':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/stacked_area/horizontal_expansion.gif',
            cn_name: '横向展开',
            en_name: 'horizontal-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/stacked_area/synchronized_display.gif',
            cn_name: '同步展示面',
            en_name: 'synchronous-display'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/stacked_area/vertical_synchronous_stretching.gif',
            cn_name: '纵向同步拉伸',
            en_name: 'vertical-synchronous-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/stacked_area/distributed_display.gif',
            cn_name: '分步展示面',
            en_name: 'distributed-display'
          }
        ]
      case 'river-area':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/river/horizontal_expansion.gif',
            cn_name: '横向展开',
            en_name: 'horizontal-expand'
          }
        ]
      case 'mixed-line-grouped-column':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/mixed_line_and_grouped_column/vertical_synchronous_stretching.gif',
            cn_name: '纵向同步拉伸',
            en_name: 'vertical-sync-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/mixed_line_and_grouped_column/vertical_sequential_stretching.gif',
            cn_name: '纵向依次拉伸',
            en_name: 'vertical-staggered-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/mixed_line_and_grouped_column/horizontal_synchronous_expansion.gif',
            cn_name: '横向同步展开',
            en_name: 'horizontal-sync-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/mixed_line_and_grouped_column/horizontal_sequential_expansion.gif',
            cn_name: '横向依次展开',
            en_name: 'horizontal-staggered-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/mixed_line_and_grouped_column/horizontal_classified_expansion.gif',
            cn_name: '纵向分类拉伸',
            en_name: 'vertical-categorical-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/mixed_line_and_grouped_column/vertical_classified_expansion.gif',
            cn_name: '横向分类展开',
            en_name: 'horizontal-categorical-expand'
          }
        ]
      case 'mixed-line-stacked-column':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/mixed_line_and_stacked_column/vertical_synchronous_stretching.gif',
            cn_name: '纵向同步拉伸',
            en_name: 'vertical-sync-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/mixed_line_and_stacked_column/vertical_sequential_stretching.gif',
            cn_name: '纵向依次拉伸',
            en_name: 'vertical-staggered-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/mixed_line_and_stacked_column/horizontal_synchronous_expansion.gif',
            cn_name: '横向同步展开',
            en_name: 'horizontal-sync-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/mixed_line_and_stacked_column/horizontal_sequential_expansion.gif',
            cn_name: '横向依次展开',
            en_name: 'horizontal-staggered-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/mixed_line_and_stacked_column/vertical_classified_expansion.gif',
            cn_name: '纵向分类拉伸',
            en_name: 'vertical-categorical-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/mixed_line_and_stacked_column/horizontal_classified_expansion.gif',
            cn_name: '纵向整体拉伸',
            en_name: 'vertical-unified-stretch'
          }
        ]
      case 'difference-arrow-column':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/difference_arrow_column/vertical_synchronous_stretching.gif',
            cn_name: '纵向同步拉伸',
            en_name: 'vertical-sync-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/difference_arrow_column/vertical_sequential_stretching.gif',
            cn_name: '纵向依次拉伸',
            en_name: 'vertical-staggered-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/difference_arrow_column/horizontal_synchronous_expansion.gif',
            cn_name: '横向同步展开',
            en_name: 'horizontal-sync-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/difference_arrow_column/horizontal_sequential_expansion.gif',
            cn_name: '横向依次展开',
            en_name: 'horizontal-staggered-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/difference_arrow_column/horizontal_classified_expansion.gif',
            cn_name: '纵向分类拉伸',
            en_name: 'vertical-categorical-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/difference_arrow_column/vertical_classified_expansion.gif',
            cn_name: '横向分类展开',
            en_name: 'horizontal-categorical-expand'
          }
        ]
      case 'difference-arrow-bar':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/difference_arrow_bar/vertical_synchronous_stretching.gif',
            cn_name: '纵向同步拉伸',
            en_name: 'vertical-sync-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/difference_arrow_bar/vertical_sequential_stretching.gif',
            cn_name: '纵向依次拉伸',
            en_name: 'vertical-staggered-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/difference_arrow_bar/horizontal_synchronous_expansion.gif',
            cn_name: '横向同步展开',
            en_name: 'horizontal-sync-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/difference_arrow_bar/horizontal_sequential_expansion.gif',
            cn_name: '横向依次展开',
            en_name: 'horizontal-staggered-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/difference_arrow_bar/horizontal_classified__stretching.gif',
            cn_name: '纵向分类拉伸',
            en_name: 'horizontal-categorical-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/difference_arrow_bar/horizontal_classified_expansion.gif',
            cn_name: '横向分类展开',
            en_name: 'vertical-categorical-expand'
          }
        ]
      case 'basic-column':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_column/vertical_synchronous_stretching.gif',
            cn_name: '纵向同步拉伸',
            en_name: 'vertical-sync-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_column/vertical_sequential_stretching.gif',
            cn_name: '纵向依次拉伸',
            en_name: 'vertical-staggered-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_column/horizontal_synchronous_expansion.gif',
            cn_name: '横向同步展开',
            en_name: 'horizontal-sync-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_column/horizontal_sequential_expansion.gif',
            cn_name: '横向依次展开',
            en_name: 'horizontal-staggered-expand'
          }
        ]
      case 'rose-pie':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/rose/radial_expansion.gif',
            cn_name: '径向展开',
            en_name: 'radial-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/rose/clockwise_expansion.gif',
            cn_name: '顺时针展开',
            en_name: 'clockwise-expand'
          }
        ]
      case 'descartes-heatmap':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/cartesian_heat_map/fade_in.gif',
            cn_name: '淡入',
            en_name: 'fade-in'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/cartesian_heat_map/horizontal_appearance.gif',
            cn_name: '横向出现',
            en_name: 'horizontal-appear'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/cartesian_heat_map/sequential_appearance.gif',
            cn_name: '依次出现',
            en_name: 'sequential-appear'
          }
        ]
      case 'check-in-bubble':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/check_in_bubble/fade_in.gif',
            cn_name: '淡入',
            en_name: 'fade-in'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/check_in_bubble/slide_in_horizontally.gif',
            cn_name: '横向出现',
            en_name: 'horizontal-appear'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/check_in_bubble/sequential_appearance.gif',
            cn_name: '依次出现',
            en_name: 'sequential-appear'
          }
        ]
      case 'basic-radar':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/radar/synchronous_expansion.gif',
            cn_name: '同步展开',
            en_name: 'synchronous-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/radar/sequential_expansion.gif',
            cn_name: '依次展开',
            en_name: 'sequential-expand'
          }
        ]
      case 'single-layer-treemap':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/single_layer_ treemap/diagonal_stretch.gif',
            cn_name: '对角拉伸',
            en_name: 'diagonal-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/single_layer_ treemap/sequential_diagonal_stretch.gif',
            cn_name: '对角逐次拉伸',
            en_name: 'diagonal-sequential-stretch'
          }
        ]
      case 'sankey':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/sankey/horizontal_expansion.gif',
            cn_name: '横向展开',
            en_name: 'horizontal-expand'
          }
        ]
      case 'funnel':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/funnel/vertical_expansion.gif',
            cn_name: '纵向展开',
            en_name: 'vertical-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/funnel/horizontal_synchronous_expansion.gif',
            cn_name: '横向同步展开',
            en_name: 'horizontal-sync-expand'
          }
        ]
      case 'donut-progress':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/donut_progress/wheel.gif',
            cn_name: '轮子',
            en_name: 'wheel'
          }
        ]
      case 'jade-jue':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/jade_jue/clockwise_expansion.gif',
            cn_name: '顺时针展开',
            en_name: 'clockwise-expand'
          }
        ]
      case 'bar-progress':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/image/clzb5c3sn0000j5yc61l9fc97/cm23c9yux0000otruh972f6yi/1728578870279',
            cn_name: '横向展开',
            en_name: 'horizontal-expand'
          }
        ]
      case 'basic-bar':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_bar/vertical_synchronous_stretching.gif',
            cn_name: '纵向同步拉伸',
            en_name: 'vertical-sync-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_bar/vertical_sequential_stretching.gif',
            cn_name: '纵向依次拉伸',
            en_name: 'vertical-staggered-stretch'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_bar/horizontal_synchronous_expansion.gif',
            cn_name: '横向同步展开',
            en_name: 'horizontal-sync-expand'
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/basic_bar/horizontal_sequential_expansion.gif',
            cn_name: '横向依次展开',
            en_name: 'horizontal-staggered-expand'
          }
        ]
      case 'compose-waterfall':
        return [
          {
            gif: 'https://i.postimg.cc/QNJXjRc6/20240705140319.png',
            cn_name: '无动画',
            en_name: null
          },
          {
            gif: 'https://cdn.core.editorup.com/static/images/gifs/compose_waterfall/vertical_synchronous_stretching.gif',
            cn_name: '纵向同步拉伸',
            en_name: 'vertical-sync-stretch'
          }
        ]

      default:
        return barOption
    }
  }

  /**
   * 气泡图样式
   * @private
   */
  private getDisplayBubbleSchema(): Array<SettingSchema | SettingSchemaRow> {
    return [
      //绘制基准
      {
        title: '绘制基准',
        child: {
          widget: buttonToggleResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.bubble?.standard)),
            options: [
              // 气泡面积 / 气泡半径
              { text: '气泡面积', value: 'area' },
              { text: '气泡半径', value: 'radius' }
            ]
          },
          className: 'max-w-full',
          outputs: {
            value: val => this.update('setting.props.display.bubble.standard', val)
          }
        } as ST<ButtonToggleComponent>
      },
      // 形状
      {
        title: '形状',
        child: {
          widget: buttonToggleResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.bubble?.shape)),
            options: [
              { icon: 'editorup:circle', value: 'circle' },
              { icon: 'editorup:rect', value: 'square' },
              // 三角形
              { icon: 'editorup:triangle', value: 'triangle' },
              // 菱形
              { icon: 'editorup:diamond', value: 'diamond' }
            ]
          },
          outputs: {
            value: val => this.update('setting.props.display.bubble.shape', val)
          }
        } as ST<ButtonToggleComponent>
      },
      // 描边粗细
      {
        title: '描边粗细',
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.wholeBorder$.pipe(map(s => s?.width)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useStep: true
          },
          outputs: {
            valueChange: val => this.updateWhole('border.width', val)
          }
        } as ST<NumberInputComponent>
      },
      // 描边颜色
      {
        flexible: true,
        child: {
          widget: strokeColorResolver,
          inputs: {
            label: '描边颜色',
            value: this.wholeBorder$.pipe(map(s => s?.color))
          },
          outputs: {
            valueChange: value => this.updateWhole('border.color', value?.color || value, { preview: value?.dragging || false })
          }
        } as ST<StrokeColorComponent>
      },
      // 气泡半径
      {
        title: '气泡半径',
        child: {
          widget: rangeInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.bubble?.size)),
            aceMinValue: 0
          },
          outputs: {
            valueChange: val => this.update('setting.props.display.bubble.size', val)
          }
        } as ST<RangeInputComponent>
      },
      // 透明度
      {
        title: '透明度',
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.bubble?.fillOpacity)),
            aceMinValue: 0,
            aceMaxValue: 1,
            aceSlideStep: 0.1,
            aceNumberPrecision: 1,
            useSlide: true
          },
          outputs: this.generateSlideOutputs('setting.props.display.bubble.fillOpacity', false)
        } as ST<NumberInputComponent>
      },
      // 参考值
      {
        title: '参考值',
        interaction: 'popup',
        children: [
          //参考值
          {
            title: '参考值',
            interaction: 'switch',
            active: this.chartProps$.pipe(map(s => s.display.bubble?.reference?.show)),
            activeChange: value => this.update('setting.props.display.bubble.reference.show', value),
            children: [
              // 图例名称
              {
                title: '图例名称',
                child: {
                  widget: inputResolver,
                  inputs: {
                    value: this.chartProps$.pipe(map(s => s.display.bubble?.reference?.text))
                  },
                  outputs: {
                    valueChange: val => this.update('setting.props.display.bubble.reference.text', val)
                  }
                } as ST<InputComponent>
              },
              // 值
              {
                title: '值',
                child: {
                  widget: numberInputResolver,
                  inputs: {
                    useSlide: false,
                    useStep: false,
                    value: this.chartProps$.pipe(map(s => s.display.bubble?.reference?.value))
                  },
                  outputs: {
                    valueChange: val => this.update('setting.props.display.bubble.reference.value', val)
                  }
                } as ST<NumberInputComponent>
              },
              // 填充颜色
              {
                title: '填充颜色',
                child: {
                  widget: colorRowResolver,
                  inputs: {
                    value: this.chartProps$.pipe(map(s => s.display.bubble?.reference?.color))
                  },
                  outputs: {
                    valueChange: ({ color, dragging }) => this.update('setting.props.display.bubble.reference.color', color, { preview: dragging })
                  }
                } as ST<ColorRowComponent>
              },
              // 描边样式
              {
                title: '描边样式',
                child: {
                  widget: strokeStyleResolver,
                  inputs: {
                    value: this.chartProps$.pipe(map(s => s.display.bubble?.reference?.border?.type))
                  },
                  outputs: {
                    valueChange: val => this.update('setting.props.display.bubble.reference.border.type', val)
                  }
                } as ST<StrokeStyleComponent>
              },
              // 描边粗细
              {
                title: '描边粗细',
                child: {
                  widget: numberInputResolver,
                  inputs: {
                    value: this.chartProps$.pipe(map(s => s.display.bubble?.reference?.border?.width)),
                    aceMinValue: 0,
                    aceMaxValue: 100,
                    useStep: true
                  },
                  outputs: {
                    valueChange: val => this.update('setting.props.display.bubble.reference.border.width', val)
                  }
                } as ST<NumberInputComponent>
              },
              // 描边颜色
              {
                title: '描边颜色',
                child: {
                  widget: colorRowResolver,
                  inputs: {
                    value: this.chartProps$.pipe(map(s => s.display.bubble?.reference?.border?.color))
                  },
                  outputs: {
                    valueChange: ({ color, dragging }) => this.update('setting.props.display.bubble.reference.border.color', color, { preview: dragging })
                  }
                } as ST<ColorRowComponent>
              }
            ]
          }
        ]
      }
    ]
  }

  private getDisplaySankeySchema(): Array<SettingSchema | SettingSchemaRow> {
    return [
      //间隙
      {
        title: '间隙',
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.sankey?.gapDistance)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useSlide: true
          },
          outputs: {
            valueChange: val => this.update('setting.props.display.sankey.gapDistance', val)
          }
        } as ST<NumberInputComponent>
      },

      // 节点宽度
      {
        title: '节点宽度',
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.sankey?.nodeWidth)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useSlide: true
          },
          outputs: {
            valueChange: val => this.update('setting.props.display.sankey.nodeWidth', val)
          }
        } as ST<NumberInputComponent>
      },
      // 线条颜色
      {
        title: '线条颜色',
        child: {
          widget: buttonToggleResolver,

          inputs: {
            value: this.chartProps$.pipe(
              map(s => {
                const lineColor = s.display.sankey?.lineColor
                if (!lineColor) return 'auto'
                if (lineColor === 'gradient') return 'gradient'
                return 'fixed'
              })
            ),
            //自动/固定/渐变
            options: [
              { text: '自动', value: 'auto' },
              { text: '固定', value: 'fixed' },
              { text: '渐变', value: 'gradient' }
            ]
          },
          outputs: {
            value: val => this.update('setting.props.display.sankey.lineColor', val === 'auto' ? null : val === 'gradient' ? 'gradient' : DefaultColor)
          }
        } as ST<ButtonToggleComponent>
      },
      // 固定颜色
      {
        title: '',
        hidden: this.chartProps$.pipe(map(s => !has(s.display.sankey?.lineColor, 'color'))),
        child: {
          widget: colorRowResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.sankey?.lineColor))
          },
          outputs: {
            valueChange: value => this.update('setting.props.display.sankey.lineColor', value?.color || null, { preview: !!value?.dragging })
          }
        } as ST<ColorRowComponent>
      },
      // 透明度（线)
      {
        title: '透明度(线)',
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => (s.display.sankey?.fillOpacity || 0) * 100)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useSlide: true
          },
          outputs: this.generateSlideOutputs('setting.props.display.sankey.fillOpacity', false, {
            transformer: val => (isNumber(val) ? val / 100 : undefined)
          })
        } as ST<NumberInputComponent>
      }
    ]
  }

  private getDisplayVoronoiSchema(): Array<SettingSchema | SettingSchemaRow> {
    return [
      // 形状
      {
        title: '形状',
        child: {
          widget: selectResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.voronoi?.drawShape)),
            options: voronoiShapeTypeOptions
          },
          outputs: {
            value: val => this.update('setting.props.display.voronoi.drawShape', val)
          }
        } as ST<SelectComponent>
      },
      // 绘制方式
      {
        title: '绘制方式',
        child: {
          widget: buttonToggleResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.voronoi?.drawStyle)),
            options: voronoiDrawStyleOptions,
            full: true
          },
          outputs: {
            value: val => this.update('setting.props.display.voronoi.drawStyle', val)
          }
        } as ST<ButtonToggleComponent>
      },
      // 透明度
      {
        title: '透明度',
        child: {
          widget: numberInputResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.voronoi?.fillOpacity)),
            aceMinValue: 0,
            aceMaxValue: 1,
            aceSlideStep: 0.1,
            aceNumberPrecision: 1,
            useSlide: true
          },
          outputs: this.generateSlideOutputs('setting.props.display.voronoi.fillOpacity', false)
        } as ST<NumberInputComponent>
      },
      // 一级描边样式
      {
        title: '一级描边样式',
        child: {
          widget: strokeStyleResolver,
          inputs: {
            value: this.wholeBorder$.pipe(map(s => s.type))
          },
          outputs: {
            valueChange: val => this.updateWhole('border.type', val)
          }
        } as ST<StrokeStyleComponent>
      },
      // 一级描边
      {
        title: '一级描边',
        child: {
          widget: NumberInputComponent,
          inputs: {
            value: this.wholeBorder$.pipe(map(s => s?.width)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useStep: true
          },
          outputs: {
            valueChange: val => this.updateWhole('border.width', val)
          }
        } as ST<NumberInputComponent>
      },
      // 描边颜色
      {
        flexible: true,
        child: {
          widget: strokeColorResolver,
          inputs: {
            label: '一级描边颜色',
            value: this.wholeBorder$.pipe(map(s => s.color))
          },
          outputs: {
            valueChange: value => this.updateWhole('border.color', value?.color || value, { preview: value?.dragging || false })
          }
        } as ST<StrokeColorComponent>
      },
      // 二级描边样式
      {
        title: '二级描边样式',
        child: {
          widget: strokeStyleResolver,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.voronoi?.secondaryBorder?.type))
          },
          outputs: {
            valueChange: val => this.update('setting.props.display.voronoi.secondaryBorder.type', val)
          }
        } as ST<StrokeStyleComponent>
      },
      // 二级描边
      {
        title: '二级描边',
        child: {
          widget: NumberInputComponent,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.voronoi?.secondaryBorder?.width)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useStep: true
          },
          outputs: {
            valueChange: val => this.update('setting.props.display.voronoi.secondaryBorder.width', val)
          }
        } as ST<NumberInputComponent>
      },
      // 描边颜色
      {
        flexible: true,
        child: {
          widget: strokeColorResolver,
          inputs: {
            label: '二级描边颜色',
            value: this.chartProps$.pipe(map(s => s.display.voronoi?.secondaryBorder?.color))
          },
          outputs: {
            valueChange: value =>
              this.update('setting.props.display.voronoi.secondaryBorder.color', value?.color || value, { preview: value?.dragging || false })
          }
        } as ST<StrokeColorComponent>
      },
      // 圆角半径
      {
        title: '圆角半径',
        child: {
          widget: NumberInputComponent,
          inputs: {
            value: this.chartProps$.pipe(map(s => s.display.voronoi?.cornerRadius)),
            aceMinValue: 0,
            aceMaxValue: 100,
            useStep: true
          },
          outputs: {
            valueChange: val => this.update('setting.props.display.voronoi.cornerRadius', val)
          }
        } as ST<NumberInputComponent>
      }
    ]
  }
}
