import { Injectable } from '@angular/core'

import { CreatePageElementParams, ITextSetting } from '@libs/payload'

import { copyType, ICopyContent, ICopyData } from '../interfaces/copy'
import { targetType } from '../store'

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  write<T extends targetType>(type: T, data: ICopyData[T]) {
    const content: ICopyContent<T> = {
      sign: 'editorup',
      type,
      data
    }

    if (type === 'element') {
      let text = ''
      ;(data as CreatePageElementParams[])
        .map(el => {
          if (el.category === 'text') return el
          else if (el.category === 'group' && el.children) return el.children.filter(c => c.category === 'text')
          else return []
        })
        .flat()
        // .filter(el => el)
        .forEach((el, index) => {
          if (index !== 0) text += '\n'
          ;(el.setting as ITextSetting).paragraphs.forEach((p, i) => {
            if (i !== 0) text += '\n'
            p.chars.forEach(c => {
              text += c.text
            })
          })
        })
      navigator.clipboard.write([
        // Support for multiple ClipboardItems is not implemented.
        new ClipboardItem({
          // TODO: Encrypt data
          'text/plain': new Blob([text], { type: 'text/plain' }),
          'text/html': new Blob([`<div><span data-editorup-clipboard='${JSON.stringify(content).replace(/'/g, '&#39;')}'>${text}</span></div>`], {
            type: 'text/html'
          })
          // https://github.com/w3c/editing/blob/gh-pages/docs/clipboard-pickling/explainer.md
          // 'web text/property': new Blob([JSON.stringify(properties)], { type: 'web text/property' })
        })
      ])
    } else {
      navigator.clipboard.write([
        new ClipboardItem({
          'text/html': new Blob([`<div><span data-editorup-clipboard='${JSON.stringify(content).replace(/'/g, '&#39;')}'></span></div>`], { type: 'text/html' })
        })
      ])
    }

    // // print copied data
    // setTimeout(
    //   () =>
    //     navigator.clipboard.read().then(res => {
    //       // console.log(res)
    //       res[0].getType(res[0].interfaces[0]).then(result => {
    //         const reader = new FileReader()
    //         reader.readAsText(result)
    //         reader.onloadend = () => {
    //           const doc = new DOMParser().parseFromString(reader.result as string, 'text/html')
    //           const spanElement = doc.querySelector('span')
    //           const text = spanElement?.textContent?.replace(/^<meta\s+charset=["']utf-8["']\s*>/i, '') || ''
    //           console.log(JSON.parse(text))
    //         }
    //       })
    //     }),
    //   10
    // )

    return
  }

  async read<T extends copyType>() {
    return new Promise<ICopyContent<T>>((resolve, reject) => {
      navigator.clipboard
        .read()
        .then(async res => {
          if (!res[0] || res[0].types.length === 0) return reject('No interfaces found')

          const promises = res[0].types.map(async type => {
            try {
              const result = await res[0].getType(type)
              return new Promise<ICopyContent<T>>((_resolve, _reject) => {
                const reader = new FileReader()
                if (result.type === 'text/html') {
                  reader.readAsText(result)
                  reader.onloadend = () => {
                    const doc = new DOMParser().parseFromString(reader.result as string, 'text/html')
                    const spanElement = doc.querySelector('span')
                    // result will start with meta tag in macos chrome
                    // text.replace(/^<meta\s+charset=["']utf-8["']\s*>/i, '')
                    const text = spanElement?.dataset['editorupClipboard'] || ''
                    if (!text) return
                    try {
                      const json = JSON.parse(text.replace(/^<meta\s+charset=["']utf-8["']\s*>/i, ''))
                      if (json.sign === 'editorup') _resolve(json as ICopyContent<T>)
                      else _resolve({ type: 'text', data: json } as ICopyContent<T>)
                      // else reject()
                    } catch (e) {
                      console.log('failed to parse', e)
                      _resolve({ type: 'text', data: reader.result } as ICopyContent<T>)
                    }
                  }
                } else if (result.type === 'text/plain') {
                  reader.readAsText(result)
                  reader.onloadend = () => {
                    _resolve({ type: 'text', data: reader.result } as ICopyContent<T>)
                  }
                } else if (result.type.startsWith('image/')) {
                  const file = new File([result], 'image.' + result.type.replace('image/', ''), {
                    type: result.type, // MIME 类型
                    lastModified: Date.now() // 最后修改时间（可选）
                  })
                  _resolve({ type: 'image', data: file } as ICopyContent<T>)
                }
              })
            } catch (e) {
              console.log('failed to read type', e)
              return
            }
          })

          try {
            const _results = await Promise.all(promises)
            const results = _results.filter(r => !!r)
            const editorUpResult = results.find(r => r.sign === 'editorup')
            if (editorUpResult) {
              resolve(editorUpResult)
            } else {
              const imageResult = results.find(r => r.type === 'image')
              if (imageResult) resolve(imageResult)
              else {
                const textResult = results.find(r => r.type === 'text')
                if (textResult) resolve(textResult)
                else reject('No valid data found')
              }
            }
          } catch (e) {
            reject()
          }
        })
        .catch(err => {
          console.log('failed to read', err)
          reject()
        })
    })
  }
}
