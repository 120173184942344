import { computed, inject, Injectable } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'

import { produce } from 'immer'
import { distinctUntilChanged } from 'rxjs'
import { map } from 'rxjs/operators'

import { isGradientColor } from '@editorup/charts'
import { SettingSchema, ST } from '@editorup/settings'
import { parseGradientToString, parseStringToColorStop } from '@libs/ng-shared/utils'

import { colorRowResolver, sizeRowResolver } from '../../components/setting/setting-widget'
import { ColorRowComponent } from '../../components/setting/setting-widget/color-row'
import { SizeRowComponent } from '../../components/setting/setting-widget/size-row'
import { StageUiStore } from '../../store/stage-ui.store'
import { ProjectService } from '../project.service'

@Injectable({
  providedIn: 'root'
})
export class PageSettingService {
  private _uiStore = inject(StageUiStore)

  private _pageWidth$ = toObservable(this._uiStore.pageSize).pipe(map(size => size.width))
  private _pageHeight$ = toObservable(this._uiStore.pageSize).pipe(map(size => size.height))
  private _backgroundColor = computed(() => this._uiStore.onStagePage()?.background.color || '#ffffff')
  private _background$ = toObservable(this._backgroundColor).pipe(
    distinctUntilChanged(),
    map(color => {
      const gradient = parseStringToColorStop(color)
      return {
        color: gradient
          ? produce(gradient, draft => {
              draft.angle -= 90
            })
          : color,
        opacity: 1
      }
    })
  )
  private pageId = computed(() => this._uiStore.onStagePage()?.id as string)
  private _projectService = inject(ProjectService)
  constructor() {}

  getSettingSchema(): SettingSchema[] {
    return [
      {
        title: '基础属性',
        icon: 'ss:base',
        interaction: 'none',
        children: [
          {
            title: '尺寸',
            child: {
              widget: sizeRowResolver,
              inputs: {
                width: this._pageWidth$,
                height: this._pageHeight$,
                maxSize: { width: 8000, height: 8000 },
                minSize: { width: 100, height: 100 }
              },
              outputs: {
                valueChange: size => {
                  const updatePages = this._projectService.currentPages().map(page => ({
                    id: page.id,
                    size: size
                  }))
                  this._projectService.updatePages(updatePages)
                }
              }
            } as ST<SizeRowComponent>
          },
          // {
          //   title: '主题',
          //   child: {
          //     widget: () => import('../setting-widget/theme-select').then(m => m.ThemeSelectComponent)
          //   } as ST<ThemeSelectComponent>
          // },
          {
            title: '背景颜色',
            child: {
              widget: colorRowResolver,
              inputs: {
                gradient: true,
                disableOpacity: true,
                value: this._background$
              },
              outputs: {
                valueChange: value => {
                  const color = value.color
                  const colorString = isGradientColor(color) ? parseGradientToString(color.color) : color.color
                  if (value.dragging) {
                  } else {
                    const background = this._uiStore.onStagePage()?.background
                    this._projectService.updatePage({
                      id: this.pageId(),
                      background: {
                        ...background,
                        locked: background?.locked || false,
                        image: background?.image,
                        color: colorString
                      }
                    })
                  }
                }
              }
            } as ST<ColorRowComponent>
          }
        ]
      }
    ]
  }
}
