import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import _ from 'lodash-es'
import { WithDeleted } from 'rxdb'

import { IPageElementBase, WithId } from '@libs/payload'

import { PageElementDocument } from '../../interfaces'
import { CollectionBase } from './db.base'
import { DB_ELEMENTS_VERSION } from './db.schema'

@Injectable()
export class PageElementCollectionService extends CollectionBase<IPageElementBase> {
  // elements$ = this.collection.find({ selector: {} }).$$ as Signal<RxDocument<IPageElementBase, DocMethods<IPageElementBase>>[]>
  constructor(private _route: ActivatedRoute) {
    const projectID = _route.snapshot.params['projectId'] as string
    const db = _route.snapshot.data['projectDB']
    super(db, 'elements', DB_ELEMENTS_VERSION, true, projectID)
    // this.startReplication()
  }

  addElements(elements: IPageElementBase[]) {
    this.savingState.next(true)

    const timestamp = Date.now()
    return this.collection
      .bulkInsert(
        elements.map(element => ({
          ...element,
          updatedAt: element.updatedAt || timestamp
        }))
      )
      .finally(() => {
        this.savingState.next(false)
      })
  }

  async updateElement(change: Partial<IPageElementBase> & { id: string; pageId: string }) {
    this.savingState.next(true)

    const timestamp = Date.now()
    const doc = await this.collection.findOne(change.id).exec()
    if (doc) {
      return await (doc as PageElementDocument)
        .incrementalPatch({
          ...change,
          updatedAt: change.updatedAt || timestamp
        })
        .finally(() => this.savingState.next(false))
    } else {
      throw new Error('Element not found')
    }
  }

  async updateElements(pageId: string, elements: WithId<Partial<WithDeleted<IPageElementBase>>>[]) {
    this.savingState.next(true)

    const timestamp = Date.now()

    const docs = await this.collection.findByIds(elements.map(el => el.id)).exec()

    return Promise.all(
      Array.from(docs.values()).map(doc => {
        const updateEl = elements.find(el => el.id === doc.id) as Partial<IPageElementBase>
        return doc.incrementalPatch({
          ...updateEl,
          pageId,
          updatedAt: updateEl.updatedAt || timestamp
        })
      })
    ).finally(() => {
      this.savingState.next(false)
    })
  }

  async removeElements(params: { pageIds?: string[]; ids?: string[] }) {
    this.savingState.next(true)

    const timestamp = Date.now()
    let docs: PageElementDocument[]
    if (params.ids) {
      return this.collection.bulkRemove(params.ids).finally(() => {
        this.savingState.next(false)
      })
    } else {
      docs = await this.collection
        .find()
        .where({
          pageId: {
            $in: params.pageIds
          }
        })
        .exec()
      return this.collection.bulkRemove(docs.map(doc => doc.id)).finally(() => {
        this.savingState.next(false)
      })
    }
  }

  async reset(elements: IPageElementBase[]) {
    if (!this.docs) {
      return
    }
    const docs = this.docs()
    this.savingState.next(true)
    const updatedAt = Date.now()
    const deleteElementIds = docs
      .filter(element => !elements.find(c => c.id === element.id))
      .map(element => ({ id: element.id, _deleted: true, updatedAt, pageId: element.pageId }))
    const upsertElements = _.chain(elements)
      .map(element => {
        const existDoc = docs.find(doc => doc.id === element.id)
        if (existDoc) {
          const docData = existDoc.toJSON()
          if (
            _.isEqual(_.omit(docData, ['updatedAt', 'setting']), _.omit(element, ['updatedAt', 'setting'])) &&
            docData.setting.version === element.setting.version
          ) {
            return null
          }
        }
        return { ...element, updatedAt }
      })
      .compact()
      .value()
    return await Promise.all([
      this.collection.bulkRemove(deleteElementIds.map(element => element.id)),
      this.collection.bulkUpsert([...upsertElements])
    ]).finally(() => {
      this.savingState.next(false)
    })
  }

  /**
   * 将对象转换为路径值
   * @param obj
   * @param path
   * @private
   */
  // private flattenObject(obj: object, prefix = '') {
  //   return Object.keys(obj).reduce((acc, k) => {
  //     const pre = prefix.length ? prefix + '.' : ''
  //     // @ts-ignore
  //     if (typeof obj[k] === 'object' && obj[k] !== null && !Array.isArray(obj[k])) {
  //       // @ts-ignore
  //       Object.assign(acc, this.flattenObject(obj[k], pre + k))
  //     } else {
  //       // @ts-ignore
  //       acc[pre + k] = obj[k]
  //     }
  //     return acc
  //   }, {})
  // }
}
