import { computed, inject, Injectable, OnDestroy, signal } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'

import { HotToastService } from '@ngxpert/hot-toast'
import { cloneDeep } from 'lodash'
import { isEqual } from 'lodash-es'
import { filter, map, Subject, take } from 'rxjs'

import { SettingSchemaRow, ST } from '@editorup/settings'
import { boundingRect, rectVertices } from '@libs/algorithm'
import { defaultAdjustmentColor } from '@libs/editor-view'
import { env } from '@libs/ng-env'
import { ExcelData, IContextMenuItem } from '@libs/ng-shared/components'
import { GroupElementTreeNode, PageElementTreeNode } from '@libs/ng-shared/models'
import { CreatePageElementParams, ElementTypeEnum, IChart, IChartSetting, IImageFile, IImageSetting, IPage, IPosition, ISize } from '@libs/payload'

import { numberInputResolver, sizeRowResolver } from '../components/setting/setting-widget'
import { NumberInputComponent } from '../components/setting/setting-widget/number-input'
import { OptionsKey } from '../components/setting/setting-widget/setting'
import { SizeRowComponent } from '../components/setting/setting-widget/size-row'
import { FileUploadService } from '../components/upload/upload.service'
import { UploadData } from '../components/upload/upload.type'
import { copyType, ICopyContent } from '../interfaces'
import { GuideType } from '../store'
import { StageUiStore } from '../store/stage-ui.store'
import { TextStore } from '../store/text.store'
import { EDITOR_API } from './api'
import { ClipboardService } from './clipboard.service'
import { ElementService } from './element.service'
import { ImageService } from './image.service'
import { PageService } from './page.service'
import { ProjectService } from './project.service'

type UpdateHandlers = 'beforeSet' | 'afterSet' | 'beforeCommit' | 'afterCommit'
type UpdateHandler = {
  [key in UpdateHandlers]?: (path: OptionsKey<BaseElementInfo>, data: unknown, options?: { preview?: boolean }) => Promise<boolean | void>
}

export interface BaseElementInfo {
  size: ISize
  position: IPosition
  rotation: number
}

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService implements OnDestroy {
  uiStore = inject(StageUiStore)
  projectService = inject(ProjectService)
  elementService = inject(ElementService)
  apiService = inject(EDITOR_API)
  uploadService = inject(FileUploadService)
  hotToast = inject(HotToastService)
  clipboardService = inject(ClipboardService)
  pageService = inject(PageService)
  textStore = inject(TextStore)
  imageService = inject(ImageService)

  page = this.uiStore.onStagePage
  pageId = computed(() => this.page()?.id || '')

  ctrlKey = navigator.platform.indexOf('Mac') === 0 || navigator.platform === 'iPhone' ? '⌘' : 'Ctrl'
  altKey = navigator.platform.indexOf('Mac') === 0 || navigator.platform === 'iPhone' ? '⌥' : 'Alt'

  element = computed(() => {
    const selectedElements = this.uiStore.selectedElements()
    if (selectedElements.length === 1) {
      const element = selectedElements[0]
      if (element?.parent) {
        return this.uiStore.selectedRootElements().find(root => root.id === element.parent) || null
      } else {
        return element
      }
    } else if (selectedElements.length > 1) {
      return this.uiStore.virtualElement()
    } else {
      return null
    }
  })

  disabled = computed(() => {
    const selectedElements = this.uiStore.selectedElements()
    return selectedElements.length === 1 && !!selectedElements[0].parent
  })

  /**
   * 计算当前选中的元素在交互状态和为交互状态下的位置信息
   */
  baseElementInfo = computed<BaseElementInfo>(
    () => {
      const element = this.element()
      const shadow = this.uiStore.interacting.shadowData()
      const elementSize = element?.size || { width: 0, height: 0 }
      const scale = shadow.scale || element?.scale || 1
      const width = 'width' in shadow.size ? shadow.size.width : elementSize.width
      const height = 'height' in shadow.size ? shadow.size.height : elementSize.height

      const size = { width: width * scale, height: height * scale }
      const position = 'x' in shadow.position && 'y' in shadow.position ? shadow.position : element?.position || { x: 0, y: 0 }
      const rotation = shadow.rotation || element?.rotation || 0

      return {
        size: size,
        position: position,
        rotation: rotation
      }
    },
    {
      equal: (a, b) => {
        return isEqual(a.size, b.size) && isEqual(a.position, b.position) && a.rotation === b.rotation
      }
    }
  )

  baseElementVertices = computed(() => {
    const { position, size, rotation } = this.baseElementInfo()
    // 始终为左上角的位置
    return rectVertices(position, size, (Math.PI * rotation) / 180)
  })

  baseElementBoundings = computed(() => {
    const { position, size, rotation } = this.baseElementInfo()
    return boundingRect(position, size, (Math.PI * rotation) / 180)
  })

  elementsContextMenu = computed<IContextMenuItem[]>(() => {
    const pageLocked = this.page()?.locked
    const hasSelectedLocked = this.elementService.hasSelectedLocked()
    const alignDistributeStatus = this.elementService.alignDistributeStatus()
    const selectedRootElements = this.uiStore.selectedRootElements()
    // const isVirtualElement = this.uiStore.virtualElement()?.id === selectedRootElements[0].id
    return [
      {
        label: '复制',
        icon: 'custom:element-copy',
        shortcut: [this.ctrlKey, 'C'],
        callback: this.elementService.copyElements.bind(this.elementService),
        hide: pageLocked || hasSelectedLocked
      },
      // {
      //   label: '复制样式',
      //   icon: 'custom:copy-style',
      //   shortcut: [this.ctrlKey, this.altKey, 'C'],
      //   hide: true
      // },
      {
        label: '粘贴',
        icon: 'custom:element-paste',
        shortcut: [this.ctrlKey, 'V'],
        callback: this.paste.bind(this),
        hide: pageLocked || hasSelectedLocked
      },
      {
        label: '剪切',
        icon: 'custom:element-cut',
        shortcut: [this.ctrlKey, 'X'],
        callback: () => {
          this.elementService.copyElements()
          this.elementService.deleteElements(...this.uiStore.selectedRootElements().map(el => el.id))
        },
        hide: pageLocked || hasSelectedLocked
      },
      {
        label: '创建副本',
        icon: 'custom:duplicate',
        shortcut: [this.ctrlKey, 'D'],
        callback: this.elementService.duplicateElements.bind(this.elementService),
        hide: pageLocked || hasSelectedLocked
      },
      {
        label: '删除',
        icon: 'custom:page-delete',
        shortcut: ['DELETE'],
        callback: this.elementService.deleteElements.bind(this.elementService, ...selectedRootElements.map(el => el.id)),
        hide: pageLocked || hasSelectedLocked
      },
      {
        label: '图层',
        icon: 'custom:element-layer',
        children: [
          {
            label: '上移',
            icon: 'custom:layer-up',
            disabled: !this.elementService.canMoveUp(),
            shortcut: [this.ctrlKey, ']'],
            callback: this.elementService.moveUp.bind(this.elementService)
          },
          {
            label: '移动到最上层',
            icon: 'custom:layer-top',
            disabled: !this.elementService.canMoveUp(),
            shortcut: [this.ctrlKey, this.altKey, ']'],
            callback: this.elementService.moveTop.bind(this.elementService)
          },
          {
            label: '下移',
            icon: 'custom:layer-down',
            disabled: !this.elementService.canMoveDown(),
            shortcut: [this.ctrlKey, '['],
            callback: this.elementService.moveDown.bind(this.elementService)
          },
          {
            label: '移动到最下层',
            icon: 'custom:layer-bottom',
            disabled: !this.elementService.canMoveDown(),
            shortcut: [this.ctrlKey, this.altKey, '['],
            callback: this.elementService.moveBottom.bind(this.elementService)
          }
        ],
        br: true,
        hide: pageLocked || hasSelectedLocked
      },
      {
        label: '页面对齐',
        icon: 'editorup:align-left',
        children: [
          {
            label: '左对齐',
            icon: 'editorup:align-left',
            disabled: !alignDistributeStatus.alignLeft,
            callback: this.elementService.alignLeft.bind(this.elementService, selectedRootElements)
          },
          {
            label: '水平居中',
            icon: 'editorup:align-horizontally',
            disabled: !alignDistributeStatus.alignHorizontal,
            callback: this.elementService.alignHorizontal.bind(this.elementService, selectedRootElements)
          },
          {
            label: '右对齐',
            icon: 'editorup:align-right',
            disabled: !alignDistributeStatus.alignRight,
            callback: this.elementService.alignRight.bind(this.elementService, selectedRootElements)
          },
          {
            label: '顶部对齐',
            icon: 'editorup:align-top',
            disabled: !alignDistributeStatus.alignTop,
            callback: this.elementService.alignTop.bind(this.elementService, selectedRootElements)
          },
          {
            label: '垂直居中',
            icon: 'editorup:align-vertical',
            disabled: !alignDistributeStatus.alignVertical,
            callback: this.elementService.alignVertical.bind(this.elementService, selectedRootElements)
          },
          {
            label: '底部对齐',
            icon: 'editorup:align-bottom',
            disabled: !alignDistributeStatus.alignBottom,
            callback: this.elementService.alignBottom.bind(this.elementService, selectedRootElements)
          }
        ],
        hide: pageLocked || hasSelectedLocked || selectedRootElements.length > 1
      },
      {
        label: '素材对齐',
        icon: 'editorup:align-left',
        children: [
          {
            label: '左对齐',
            icon: 'editorup:align-left',
            disabled: !alignDistributeStatus.alignLeft,
            callback: this.elementService.alignLeft.bind(this.elementService, selectedRootElements)
          },
          {
            label: '水平居中',
            icon: 'editorup:align-horizontally',
            disabled: !alignDistributeStatus.alignHorizontal,
            callback: this.elementService.alignHorizontal.bind(this.elementService, selectedRootElements)
          },
          {
            label: '右对齐',
            icon: 'editorup:align-right',
            disabled: !alignDistributeStatus.alignRight,
            callback: this.elementService.alignRight.bind(this.elementService, selectedRootElements)
          },
          {
            label: '顶部对齐',
            icon: 'editorup:align-top',
            disabled: !alignDistributeStatus.alignTop,
            callback: this.elementService.alignTop.bind(this.elementService, selectedRootElements)
          },
          {
            label: '垂直居中',
            icon: 'editorup:align-vertical',
            disabled: !alignDistributeStatus.alignVertical,
            callback: this.elementService.alignVertical.bind(this.elementService, selectedRootElements)
          },
          {
            label: '底部对齐',
            icon: 'editorup:align-bottom',
            disabled: !alignDistributeStatus.alignBottom,
            callback: this.elementService.alignBottom.bind(this.elementService, selectedRootElements)
          }
        ],
        hide: pageLocked || hasSelectedLocked || selectedRootElements.length === 1
      },
      {
        label: '等间距',
        icon: 'editorup:distribute-spacing',
        children: [
          {
            label: '水平',
            icon: 'editorup:distribute-horizontal-spacing',
            disabled: !alignDistributeStatus.distributeHorizontal,
            callback: this.elementService.distributeHorizontalSpacing.bind(this.elementService, selectedRootElements)
          },
          {
            label: '垂直',
            icon: 'editorup:distribute-vertical-spacing',
            disabled: !alignDistributeStatus.distributeVertical,
            callback: this.elementService.distributeVerticalSpacing.bind(this.elementService, selectedRootElements)
          }
        ],
        hide: pageLocked || hasSelectedLocked || selectedRootElements.length < 3
      },
      {
        label: '打组',
        icon: 'custom:element-group',
        shortcut: [this.ctrlKey, 'G'],
        callback: this.elementService.createGroupElement.bind(this.elementService, selectedRootElements),
        br: true,
        hide: pageLocked || hasSelectedLocked || selectedRootElements.length === 1
      },
      {
        label: '解组',
        icon: 'custom:element-ungroup',
        shortcut: ['Shift', this.ctrlKey, 'G'],
        callback: this.elementService.ungroupElement.bind(this.elementService, selectedRootElements[0] as GroupElementTreeNode),
        br: true,
        hide: pageLocked || hasSelectedLocked || selectedRootElements.length > 1 || selectedRootElements[0]?.category !== ElementTypeEnum.Group
      },
      {
        label: '隐藏',
        icon: 'custom:page-hidden',
        callback: this.elementService.hideElements.bind(this.elementService),
        hide: pageLocked || hasSelectedLocked
      },
      {
        label: '锁定',
        icon: 'custom:page-lock',
        callback: this.elementService.lockElements.bind(this.elementService),
        hide: pageLocked || hasSelectedLocked
      },
      {
        label: '解锁',
        icon: 'custom:page-unlock',
        callback: this.elementService.unlockElements.bind(this.elementService),
        disabled: pageLocked,
        hide: !pageLocked && !hasSelectedLocked
      },
      {
        label: '替换背景',
        icon: 'custom:img-to-bg',
        br: true,
        callback: () => this.imageService.updatePageBackgroundImage(this.pageId(), selectedRootElements[0] as PageElementTreeNode<ElementTypeEnum.Image>),
        disabled: this.imageService.toBeUpload().includes(selectedRootElements[0]?.id),
        hide: pageLocked || hasSelectedLocked || selectedRootElements.length > 1 || selectedRootElements[0]?.category !== ElementTypeEnum.Image
      },
      // {
      //   label: '下载',
      //   icon: 'custom:element-download',
      // br: true,
      //  },
      {
        label: '保存为素材',
        icon: '',
        br: true,
        callback: this.elementService.saveAsMaterial.bind(this.elementService),
        hide: env.production || selectedRootElements.length > 1
      }
    ]
  })

  backgroundContextMenu = computed(() => {
    const pageLocked = this.page()?.locked
    const background = this.page()?.background
    const isBackgroundEmpty = !background?.image && (!background?.color || background.color.toLowerCase() === '#ffffff')
    const menu: IContextMenuItem[] = [
      {
        label: '复制背景',
        icon: 'custom:element-copy',
        shortcut: [this.ctrlKey, 'C'],
        callback: this.pageService.copyBackground.bind(this.pageService),
        hide: isBackgroundEmpty || pageLocked || background?.locked
      },
      // { label: '复制样式', icon: 'custom:copy-style', shortcut: [this.ctrlKey, 'Alt', 'C'], callback: () => {}, hide: true },
      {
        label: '粘贴',
        icon: 'custom:element-paste',
        shortcut: [this.ctrlKey, 'V'],
        callback: this.paste.bind(this),
        hide: pageLocked || background?.locked
      },
      {
        label: '剪切背景',
        icon: 'custom:element-cut',
        shortcut: [this.ctrlKey, 'X'],
        callback: () => {
          this.pageService.copyBackground()
          this.pageService.deleteBackground()
        },
        hide: isBackgroundEmpty || pageLocked || background?.locked
      },
      {
        label: '删除背景',
        icon: 'custom:page-delete',
        shortcut: ['DELETE'],
        callback: this.pageService.deleteBackground.bind(this.pageService),
        hide: isBackgroundEmpty || pageLocked || background?.locked
      },
      {
        label: '锁定背景',
        icon: 'custom:page-lock',
        callback: this.pageService.lockBackground.bind(this.pageService),
        br: true,
        hide: isBackgroundEmpty || pageLocked || background?.locked
      },
      {
        label: '解锁背景',
        icon: 'custom:page-unlock',
        callback: this.pageService.unlockBackground.bind(this.pageService),
        br: true,
        hide: !pageLocked && !background?.locked
      },
      // {
      //   label: '分离图片',
      //   icon: 'custom:page-unlock',
      //   callback: this.pageService.unlockBackground.bind(this.pageService),
      //   br: true,
      //   hide: pageLocked || background?.locked || !background?.image
      // }
      {
        label: '辅助线',
        icon: 'editorup:标尺',
        br: true,
        children: [
          {
            label: `${this.uiStore.guideLocked() ? '解锁' : '锁定'}辅助线`,
            icon: `custom:page-${this.uiStore.guideLocked() ? 'unlock' : 'lock'}`,
            callback: this.lockGuides.bind(this)
          },
          { label: '清除辅助线', icon: 'editorup:Format (格式/清除)', callback: this.clearGuides.bind(this) }
        ],
        hide: !this.uiStore.ruleEnable()
      }
    ]
    return menu
  })

  // pageContextMenu = computed(() => {})

  baseElementInfo$ = toObservable(this.baseElementInfo)
  baseElementRect$ = toObservable(this.baseElementVertices)
  disabled$ = toObservable(this.disabled)

  private _createGuides = new Subject<{ type: GuideType; point: IPosition }>()

  get createGuides$() {
    return this._createGuides.asObservable()
  }

  ngOnDestroy() {
    console.log('workspace service destroy')
    this._createGuides.complete()
  }

  sendCreateGuides(type: GuideType, point: IPosition) {
    this._createGuides.next({ type, point })
  }

  async update(path: OptionsKey<BaseElementInfo>, data: unknown, options?: { preview?: boolean; handler?: UpdateHandler; skipHistory?: boolean }) {
    console.log('update', path, data)
    const element = this.element()
    if (!element) return
    const elementId = element.id
    const { handler } = options || {}

    // 如果有beforeSet方法，执行beforeSet方法，如果返回false则不继续执行
    if (handler?.beforeSet) {
      const result = await handler.beforeSet(path, data, options)
      if (result === false) return
    }

    // 更新shadowData
    if (path === 'rotation') {
      this.uiStore.setInteractShadowData(elementId, { rotation: data as number })
    } else if (path === 'size' || path === 'size.height' || path === 'size.width') {
      const newSize = cloneDeep(this.baseElementInfo().size)
      const scale = element.scale || 1
      if (path === 'size') {
        newSize.width = (data as ISize).width / scale
        newSize.height = (data as ISize).height / scale
      } else if (path === 'size.width') {
        newSize.width = (data as number) / scale
      } else {
        newSize.height = (data as number) / scale
      }
      this.uiStore.setInteractShadowData(elementId, { size: newSize })
    } else if (path === 'position.x' || path === 'position.y') {
      const vertices = this.baseElementVertices()
      const shadowPosition = { x: vertices.nw.x, y: vertices.nw.y }
      const realPosition = cloneDeep(this.element()?.position) || { x: 0, y: 0 }
      const value = data as number
      // 计算差值并更新到realPosition
      if (path === 'position.x') {
        realPosition.x += value - shadowPosition.x
      } else {
        realPosition.y += value - shadowPosition.y
      }
      this.uiStore.setInteractShadowData(elementId, { position: realPosition })
    } else {
      // 修改全部属性
      this.uiStore.setInteractShadowData(elementId, data as BaseElementInfo)
    }

    // 如果有afterSet方法，执行afterSet方法，如果返回false则不继续执行
    if (handler?.afterSet) {
      const result = await handler.afterSet(path, data, options)
      if (result === false) return
    }
    if (!options?.preview) {
      // 如果有beforeCommit方法，执行beforeCommit方法，如果返回false则不继续执行
      if (handler?.beforeCommit) {
        const result = await handler.beforeCommit(path, data, options)
        if (result === false) return
      }

      // 提交变更数据
      this.uiStore.resetInteractingElement({
        skipHistory: !!options?.skipHistory
      })

      // 如果有afterCommit方法，执行afterCommit方法
      if (handler?.afterCommit) {
        await handler.afterCommit(path, data, options)
      }
    }
  }

  generateRotationSlideOutputs(path: OptionsKey<BaseElementInfo>, handler?: UpdateHandler) {
    return {
      valueChange: (val: number) => this.update(path, val),
      valueSlideChange: ({ value, dragging }: { value: number; dragging: boolean }) => this.update(path, value, { preview: dragging, handler: handler })
    }
  }

  getSettingSchema(options?: { handler?: UpdateHandler }): SettingSchemaRow[] {
    return [
      // 尺寸
      {
        title: '尺寸',
        child: {
          widget: sizeRowResolver,
          inputs: {
            aceDisabled: this.disabled$,
            height: this.baseElementInfo$.pipe(map(s => s.size.height)),
            width: this.baseElementInfo$.pipe(map(s => s.size.width))
          },
          outputs: {
            valueChange: (val: ISize) => this.update('size', val, { handler: options?.handler })
          }
        } as ST<SizeRowComponent>
      },
      //位置
      {
        title: '位置',
        children: [
          {
            widget: numberInputResolver,
            inputs: {
              aceDisabled: this.disabled$,
              acePrefixText: 'X',
              aceNumberPrecision: 0,
              value: this.baseElementRect$.pipe(map(s => s.nw.x))
            },
            outputs: {
              valueChange: val => this.update('position.x', val, { handler: options?.handler })
            },
            className: ['mr-2']
          } as ST<NumberInputComponent>,
          // Y
          {
            widget: numberInputResolver,
            inputs: {
              aceDisabled: this.disabled$,
              acePrefixText: 'Y',
              aceNumberPrecision: 0,
              value: this.baseElementRect$.pipe(map(s => s.nw.y))
            },
            outputs: {
              valueChange: val => this.update('position.y', val, { handler: options?.handler })
            }
          } as ST<NumberInputComponent>
        ]
      },
      // 旋转
      {
        title: '旋转',
        child: {
          widget: numberInputResolver,
          inputs: {
            aceDisabled: this.disabled$,
            aceMinValue: -180,
            aceMaxValue: 180,
            useSlide: true,
            // 优先使用shadow中的值，如果其中没有值就从settingProps中取
            value: this.baseElementInfo$.pipe(map(b => b.rotation))
          },
          outputs: this.generateRotationSlideOutputs('rotation', options?.handler)
        } as ST<NumberInputComponent>
      }
    ]
  }

  /**
   * 添加图片模版数据
   * @param image
   */
  addImage(image: IImageFile) {
    const projectId = this.projectService.projectId
    const path = image.path
    // fork一张新的图片
    this.apiService.forkImage({ projectId, path }).subscribe(res => {
      console.log('fork', res)
      this.addImageElement({ ...image, link: res.link || image.link })
    })
  }

  /**
   * 添加图表模版数据
   * @param chart
   */
  addChart(chart: IChart) {
    const defaultSize = { width: 700, height: 400 }
    const defaultPosition = {
      x: this.uiStore.pageSize().width / 2 - defaultSize.width / 2,
      y: this.uiStore.pageSize().height / 2 - defaultSize.height / 2
    }

    const defaultSetting = {
      version: Date.now(),
      data: chart.data,
      pipe: chart.pipe,
      props: chart.props
    }

    const element = this.elementService.createChartElement(defaultPosition, defaultSize, defaultSetting as IChartSetting)
    if (element) {
      this.uiStore.resetSelection('element', element.id)
    }
  }

  /**
   * 保存数据到已上传
   * @param raw
   */
  uploadData(raw: ExcelData[][], title?: string) {
    // const toastRef = this._hotToast.loading('上传中...')
    const rawStr = JSON.stringify(raw)
    const data: UploadData = {
      name: title || `chart-data-${new Date().getTime()}`,
      raw: rawStr
    }
    const componentId = this.uploadService.generateQueueId()
    this.uploadService.addFilesToQueuePassPre(data, componentId)
    this.uploadService.uploadSuccess$
      .pipe(
        filter(res => res.componentId === componentId),
        take(1)
      )
      .subscribe(() => {
        this.hotToast.success('上传成功')
      })
  }

  addImageElement(image: IImageFile) {
    const defaultSize = { width: 200, height: 200 }
    // 获取当前画布的宽高
    const canvasSize = this.uiStore.pageSize()
    const imageWidth = image.width
    const imageHeight = image.height
    // 图片占据画布的80% 并保持比例
    if (imageWidth / imageHeight > canvasSize.width / canvasSize.height) {
      const width = Math.min(canvasSize.width * 0.8, imageWidth)
      const height = (imageHeight / imageWidth) * width
      defaultSize.width = width
      defaultSize.height = height
    } else {
      const height = Math.min(canvasSize.height * 0.8, imageHeight)
      defaultSize.width = (imageWidth / imageHeight) * height
      defaultSize.height = height
    }
    const defaultPosition = {
      x: this.uiStore.pageSize().width / 2 - defaultSize.width / 2,
      y: this.uiStore.pageSize().height / 2 - defaultSize.height / 2
    }
    const defaultSetting: IImageSetting = {
      version: Date.now(),
      src: image.link,
      alt: image.name,
      fit: 'fill',
      size: defaultSize,
      opacity: 1,
      flip: 'none',
      transform: {
        translate: {
          x: 0,
          y: 0
        },
        scale: 1
      },
      stroke: {
        show: false,
        style: 'solid',
        color: { color: '#000000', opacity: 1 },
        width: 1,
        radius: 0
      },
      adjustment: {
        src: '',
        version: '',
        ...defaultAdjustmentColor
      }
    }
    const element = this.elementService.createImageElement(defaultPosition, defaultSize, defaultSetting)
    if (element) {
      this.uiStore.resetSelection('element', element.id)
    }
  }

  async paste(json?: ICopyContent<copyType>) {
    if (!json || json.sign !== 'editorup') json = await this.clipboardService.read()

    switch (json.type) {
      case 'element':
        this.elementService.pasteElements(json.data as CreatePageElementParams[])
        break
      case 'page':
        this.pageService.pastePages(json.data as IPage[])
        break
      case 'background':
        this.pageService.pasteBackground(json.data as IPage['background'])
        break
      case 'text':
        if (!json.data) return
        // eslint-disable-next-line no-case-declarations
        this.elementService.createTextElement(json.data as string)?.then(element => {
          this.uiStore.resetSelection('element', element.id)
          this.textStore.updateAutoEditing(true)
        })
        break
      case 'image':
        // eslint-disable-next-line no-case-declarations
        const componentId = this.uploadService.generateQueueId()
        this.uploadService.addFilesToQueue(json.data as File, componentId)
        this.uploadService.uploadSuccess$
          .pipe(
            filter(data => data.componentId === componentId),
            take(1)
          )
          .subscribe((data: { fileId: string; componentId: string; type: string }) => {
            if (data.fileId) {
              this.apiService.getImageDetail(data.fileId).subscribe(image => {
                this.addImage(image)
              })
            }
          })
    }
  }

  lockGuides() {
    const lock = !this.uiStore.guideLocked()
    this.uiStore.setGuideLocked(lock)
    if (lock) {
      this.hotToast.info(`辅助线已全部锁定`)
    } else {
      this.hotToast.info(`辅助线已全部解锁`)
    }
  }

  clearGuides() {
    this.uiStore.setGuides([])
    this.uiStore.setGuideLocked(false)
  }
}
