import { CommonModule } from '@angular/common'
import { afterNextRender, ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core'

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'

import { PageElementTreeNode, PageListNode } from '@libs/ng-shared/models'

import { ElementContainerViewComponent } from '../element-container-view/element-container-view.component'
import { PageBackgroundComponent } from '../page-background/page-background.component'

@Component({
  selector: 'ace-page-view',
  standalone: true,
  imports: [CommonModule, PageBackgroundComponent, ElementContainerViewComponent, NgxSkeletonLoaderModule],
  templateUrl: './page-view.component.html',
  styleUrl: './page-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageViewComponent {
  page = input.required<PageListNode>()
  pageScale = input<number>(1)

  elements = computed(() => this.page().elementTreeNodes())
  pageSize = computed(() => this.page().size)
  background = computed(() => this.page().background)

  loading = signal(true)

  pageRootElements = computed<Array<PageElementTreeNode>>(() => {
    return this.elements().filter(node => node.visible)
  })

  constructor() {
    afterNextRender(() => {
      setTimeout(() => {
        this.loading.set(false)
      })
    })
  }
}
