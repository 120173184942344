import { inject, Pipe, PipeTransform } from '@angular/core'

import { TranslocoService } from '@ngneat/transloco'
import { formatDistanceToNow } from 'date-fns'
import { enUS, zhCN } from 'date-fns/locale'

@Pipe({
  name: 'timeAgo',
  standalone: true
})
export class TimeAgoPipe implements PipeTransform {
  locale = 'en'

  private translocoService = inject(TranslocoService)

  constructor() {
    this.locale = this.translocoService.getActiveLang()
  }
  transform(timestamp: number | Date): string {
    const locale = this.locale === 'zh' ? zhCN : enUS
    if (!timestamp) return ''
    return formatDistanceToNow(timestamp, {
      addSuffix: true,
      locale
    })
  }
}
