import { computed, inject, Injectable, signal } from '@angular/core'

import { produce } from 'immer'
import { get, set } from 'lodash'

import { RestService } from '@libs/ng-core/services/rest.service'
import { PageElementTreeNode } from '@libs/ng-shared/models'
import { ElementTypeEnum, IPage } from '@libs/payload'

import { ImageComponent } from '../components'
import { isImageSetting } from '../components/setting/utils'
import { StageUiStore } from '../store/stage-ui.store'
import { ProjectService } from './project.service'

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  restService = inject(RestService)
  projectService = inject(ProjectService)
  uiStore = inject(StageUiStore)

  cropImageElementId = signal<string | undefined>(undefined)
  backgroundRemovingId = signal<string | undefined>(undefined)
  inEditorInstance = signal<Record<string, ImageComponent>>({})

  toBeUpload = computed(() => {
    return Object.values(this.inEditorInstance())
      .filter(item => item.willUpload())
      .map(item => item.elementId())
  })

  register(id: string, instance: ImageComponent) {
    this.inEditorInstance.update(
      produce(draft => {
        set(draft, id, instance)
      })
    )
  }

  unregister(id: string) {
    this.inEditorInstance.update(
      produce(draft => {
        delete draft[id]
      })
    )
  }

  getInEditorInstance(id: string) {
    return get(this.inEditorInstance(), id)
  }

  getEditorState(id: string) {
    const instance = this.getInEditorInstance(id)
    return instance && instance.willUpload()
  }

  /**
   * 更新页面背景图片并删除当前图片
   * @param pageId
   * @param element
   * @private
   */
  updatePageBackgroundImage(pageId: string, element: PageElementTreeNode<ElementTypeEnum.Image>) {
    const imageInstance = this.getInEditorInstance(element.id)
    if (isImageSetting(element) && imageInstance) {
      const filterSrc = element.setting.adjustment.src
      const imageSrc = filterSrc && imageInstance.enableCanvas() ? filterSrc : imageInstance.setting().src
      this.restService
        .forkImage({
          projectId: this.projectService.projectId,
          path: imageSrc.replace(/https?:\/\/[^/]+\//, ''),
          destPath: `${pageId}_${Date.now()}_${Math.random().toString(36).slice(2)}.png`
        })
        .subscribe(res => {
          const page = this.projectService.currentPages().find(item => item.id === pageId) as IPage
          this.projectService.updatePage(
            {
              id: pageId,
              // TODO baseURL需要统一
              background: {
                ...page.background,
                image: res.link,
                opacity: element.setting.opacity,
                flip: element.setting.flip,
                rotation: element.rotation
              }
            },
            true
          )
          // 删除当前图片
          this.projectService.deletePageElements(pageId, element.id)
          this.uiStore.resetSelection('background')
        })
    }
  }
}
