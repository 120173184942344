import { computed, inject, Injectable } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'

import { produce } from 'immer'
import { cloneDeep, set } from 'lodash'
import { filter, map } from 'rxjs'

import { SettingSchema, ST } from '@editorup/settings'
import { ElementTypeEnum, IPageElementBase, IPageElementSetting } from '@libs/payload'

import { buttonToggleResolver, colorRowResolver, numberInputResolver, strokeStyleResolver } from '../../components/setting/setting-widget'
import { ButtonToggleComponent } from '../../components/setting/setting-widget/button-toggle'
import { ColorRowComponent } from '../../components/setting/setting-widget/color-row'
import { NumberInputComponent } from '../../components/setting/setting-widget/number-input'
import { OptionsKey } from '../../components/setting/setting-widget/setting'
import { StrokeStyleComponent } from '../../components/setting/setting-widget/stroke-style'
import { isLineSetting } from '../../components/setting/utils'
import { StageUiStore } from '../../store/stage-ui.store'
import { WorkspaceService } from '../workspace.service'

@Injectable({
  providedIn: 'root'
})
export class LineSettingService {
  element = computed(() => {
    const selectedElements = this._uiStore.selectedElements()
    return selectedElements.length === 1 && isLineSetting(selectedElements[0]) ? selectedElements[0] : null
  })

  element$ = toObservable(this.element).pipe(filter((e): e is IPageElementBase<ElementTypeEnum.Line> => !!e))
  setting$ = this.element$.pipe(map(e => e.setting))
  stroke$ = this.element$.pipe(map(e => e.setting.stroke))

  private _uiStore = inject(StageUiStore)
  private _workspaceService = inject(WorkspaceService)

  constructor() {}

  update = (path: OptionsKey<IPageElementBase<ElementTypeEnum.Line>>, data: unknown, options?: { preview?: boolean }) => {
    const element = this.element()
    if (this._uiStore.isElementInteractingOnPage() || !element) {
      return
    }
    const { preview = false } = options || {}
    if (!path) {
      this._uiStore.setSettingElement(element.id, cloneDeep(data) as IPageElementSetting[ElementTypeEnum.Shape])
    } else {
      this._uiStore.setSettingElement(
        element.id,
        produce(element, draft => {
          set(draft, path, data)
        }).setting
      )
    }
    if (!preview) {
      this._uiStore.resetInteractingElement()
    }
  }

  generateShadowSlideOutputs = (path: OptionsKey<IPageElementBase<ElementTypeEnum.Line>>) => {
    return {
      valueChange: (val: number) => this.update(path, val),
      valueSlideChange: ({ value, dragging }: { value: number; dragging: boolean }) => this.update(path, value, { preview: dragging })
    }
  }

  getSettingSchema(): SettingSchema[] {
    return [
      {
        title: '基础属性',
        icon: 'ss:base',
        interaction: 'none',
        children: [...this._workspaceService.getSettingSchema()]
      },
      // 描边
      {
        title: '描边',
        icon: 'ss:stroke',
        interaction: 'none',
        children: [
          {
            title: '描边样式',
            child: {
              widget: strokeStyleResolver,
              inputs: {
                value: this.stroke$.pipe(map(s => s.style))
              },
              outputs: {
                valueChange: style => this.update('setting.stroke.style', style)
              }
            } as ST<StrokeStyleComponent>
          },
          {
            title: '描边宽度',
            child: {
              widget: numberInputResolver,
              inputs: {
                aceMinValue: 0,
                aceMaxValue: 100,
                useSlide: true,
                value: this.stroke$.pipe(map(s => s.width))
              },
              outputs: {
                valueChange: width => this.updateStrokeWidth(width),
                valueSlideChange: ({ value, dragging }) => this.updateStrokeWidth(value, dragging)
              }
            } as ST<NumberInputComponent>
          },
          {
            title: '描边颜色',
            child: {
              widget: colorRowResolver,
              inputs: {
                value: this.stroke$.pipe(map(s => s.color))
              },
              outputs: {
                valueChange: ({ color, dragging }) => this.update('setting.stroke.color', color, { preview: dragging })
              }
            } as ST<ColorRowComponent>
          },
          // 圆角
          {
            title: '圆角',
            hidden: this.setting$.pipe(map(e => e.category !== 'elbow')),
            child: {
              widget: numberInputResolver,
              inputs: {
                aceMinValue: 0,
                aceMaxValue: 100,
                useSlide: true,
                value: this.element$.pipe(map(s => s.setting.stroke.radius))
              },
              outputs: {
                valueChange: radius => this.update('setting.stroke.radius', radius),
                valueSlideChange: ({ value, dragging }) => this.update('setting.stroke.radius', value, { preview: dragging })
              }
            } as ST<NumberInputComponent>
          },
          // 线条样式
          {
            title: '线条样式',
            children: []
          },
          // 圆角端点
          {
            title: '圆角端点',
            child: {
              widget: buttonToggleResolver,
              inputs: {
                value: this.element$.pipe(map(s => s.setting.stroke.cap)),
                options: [
                  { text: '圆形', value: 'round' },
                  { text: '方形', value: 'square' }
                ]
              },
              outputs: {
                value: val => this.update('setting.stroke.cap', val)
              }
            } as ST<ButtonToggleComponent>
          }
        ]
      }
    ]
  }

  private updateStrokeWidth(width?: number, preview = false) {
    const element = this.element()
    if (element && width !== undefined) {
      const category = element.setting.category
      if (category === 'straight') {
        this._workspaceService.update('size.height', width, { preview: true })
        // 同时需要改变容器高度
        this.update('setting.stroke.width', width, { preview })
      } else {
      }
    }
  }
}
