/**
 * 默认的调整颜色
 */
export const defaultAdjustmentColor = {
  filter: {
    enable: false,
    grayscale: false,
    invert: false,
    sepia: false,
    brownie: false,
    vintage: false,
    sharpen: false,
    emboss: false,
    technicolor: false,
    polaroid: false,
    kodachrome: false,
    blackwhite: false
  },
  color: {
    enable: false,
    brightness: 0,
    contrast: 0,
    saturation: 0,
    vibrance: 0,
    noise: 0,
    pixelate: 1,
    blur: 0,
    hue: 0,
    temperature: 0
  },
  shadow: {
    show: false,
    blur: 10,
    color: { color: '#000000', opacity: 0.5 },
    angle: 45,
    offset: 10
  }
}
