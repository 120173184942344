import { MigrationStrategies } from 'rxdb/dist/types/types/plugins/migration'

export function migration1(oldDoc: any, collection: any) {
  oldDoc.background = {
    locked: false,
    color: oldDoc.backgroundColor,
    image: oldDoc.backgroundImage
  }
  // oldDoc.updatedAt = Date.now()
  oldDoc.version = 1
  delete oldDoc.backgroundImage
  delete oldDoc.backgroundColor
  return oldDoc
}

export function migration2(oldDoc: any, collection: any) {
  // oldDoc.orders = []
  oldDoc.version = 2
  return oldDoc
}

export const pageMigrations: MigrationStrategies = {
  1: migration1,
  2: migration2
}
