import { computed, signal, WritableSignal } from '@angular/core'

import _ from 'lodash'

import { generateUid } from '@libs/algorithm'
import {
  AtomType,
  CreatePageElementParams,
  ElementType,
  ElementTypeEnum,
  GroupType,
  IChildElement,
  IGroupElement,
  IPageElementBase,
  IPageElementSetting,
  IPosition,
  ISize,
  WithId
} from '@libs/payload'

export class PageElementTreeNode<T extends ElementType = ElementType> implements IPageElementBase<T> {
  readonly id: string
  readonly pageId: string
  readonly version: number
  readonly category: T

  protected _position: IPosition
  protected _size: ISize
  protected _scale: number
  protected _rotation: number
  protected _visible: boolean
  protected _locked: boolean
  protected _parent?: string
  protected _updatedAt: number
  protected _setting: WritableSignal<IPageElementSetting[T]>

  constructor(data: IPageElementBase<T>) {
    this.id = data.id
    this.pageId = data.pageId
    this.category = data.category
    this.version = data.version
    this._position = data.position
    this._size = data.size
    this._scale = data.scale
    this._rotation = data.rotation
    this._visible = data.visible
    this._locked = data.locked
    this._parent = data.parent
    this._updatedAt = data.updatedAt
    if (data.parent) {
      this._parent = data.parent
    }
    this._setting = signal(data.setting)
  }

  get position() {
    return this._position
  }

  get size() {
    return this._size
  }

  get scale() {
    return this._scale
  }

  get rotation() {
    return this._rotation
  }

  get visible() {
    return this._visible
  }

  get locked() {
    return this._locked
  }

  get setting() {
    return this._setting()
  }

  get updatedAt() {
    return this._updatedAt
  }

  get parent() {
    return this._parent
  }

  get children() {
    if (this.category !== ElementTypeEnum.Group) {
      return undefined
    } else {
      return (this as IGroupElement).children
    }
  }

  get data(): IPageElementBase<T> {
    return {
      id: this.id,
      pageId: this.pageId,
      category: this.category,
      position: this.position,
      size: this.size,
      scale: this.scale,
      rotation: this.rotation,
      visible: this.visible,
      locked: this.locked,
      setting: this.setting,
      parent: this.parent,
      updatedAt: this.updatedAt,
      version: this.version
    }
  }

  update(data: Partial<IPageElementBase<T>>) {
    this._position = data.position ?? this._position
    this._size = data.size ?? this._size
    this._scale = data.scale ?? this._scale
    this._rotation = data.rotation ?? this._rotation
    this._visible = data.visible ?? this._visible
    this._locked = data.locked ?? this._locked
    this._parent = data.parent ?? this._parent
    if (data.setting) {
      this._setting.set(data.setting)
    }
    this._updatedAt = data.updatedAt ?? Date.now()
    return this
  }

  duplicate(): IPageElementBase<T> {
    return {
      id: generateUid(),
      pageId: this.pageId,
      category: this.category,
      position: {
        ...this.position
      },
      size: { ...this.size },
      scale: this.scale,
      rotation: this.rotation,
      visible: this.visible,
      locked: this.locked,
      setting: _.cloneDeep(this.setting),
      parent: this.parent,
      updatedAt: Date.now(),
      version: this.version
    }
  }

  clone() {
    return new PageElementTreeNode(this.data)
  }
}

export class GroupElementTreeNode extends PageElementTreeNode<GroupType> implements IGroupElement {
  orderedChildren = computed(() => {
    return _.compact(
      this._childrenOrder().map(child => {
        return this._rawChildren().find(c => c.id === child)
      })
    )
  })

  protected _childrenOrder = computed(() => this._setting().children)
  protected _rawChildren = signal<ChildElementTreeNode[]>([])

  constructor(data: IPageElementBase<GroupType>, children: IChildElement[]) {
    super(data)
    this._rawChildren.set(children.map(child => new ChildElementTreeNode(child)))
  }

  override get children() {
    return this.orderedChildren()
  }

  resetChildren(children: IChildElement[]) {
    this._rawChildren.set(children.map(child => new ChildElementTreeNode(child)))
    this.update({
      setting: {
        ...this.setting,
        children: children.map(child => child.id)
      }
    })
  }

  addChild(element: IChildElement, position?: number) {
    const pos = position ?? this._childrenOrder().length
    const children = this._rawChildren()
    children.splice(pos, 0, new ChildElementTreeNode(element))
    this._rawChildren.set(children)
    this.update({
      setting: {
        ...this.setting,
        children: children.map(child => child.id)
      }
    })
  }

  updateChild(element: WithId<Partial<IChildElement>>) {
    const children = this._rawChildren()
    const target = children.find(child => child.id === element.id)
    if (target) {
      target.update(element)
      return true
    } else {
      return false
    }
  }

  override duplicate(): IPageElementBase<GroupType> & { children: IChildElement[] } {
    const children = this.children.map(child => child.duplicate())
    const group = super.duplicate() as IGroupElement

    group.setting.children = children.map(child => child.id)
    children.map(child => {
      child.parent = group.id
    })

    return {
      ...group,
      children: children
    }
  }

  override clone() {
    return new GroupElementTreeNode(this.data, this._rawChildren())
  }

  addChildren(elements: IChildElement[]) {
    const rawChildren = this._rawChildren()
    const newChildren = elements.map(element => new ChildElementTreeNode(element))
    rawChildren.push(...newChildren)
    this._rawChildren.set(rawChildren)

    this.update({
      setting: {
        ...this.setting,
        children: rawChildren.map(child => child.id)
      }
    })
  }
}

export class ChildElementTreeNode<T extends AtomType = AtomType> extends PageElementTreeNode<T> implements IChildElement<T> {
  constructor(data: IChildElement<T>) {
    super(data)
  }

  override get parent() {
    return this._parent as string
  }

  override duplicate(): IChildElement<T> {
    return super.duplicate() as IChildElement<T>
  }
}

export class VirtualGroupElementTreeNode extends PageElementTreeNode<GroupType> implements IGroupElement {
  orderedChildren = computed(() => {
    return _.compact(
      this._childrenOrder().map(child => {
        return this._rawChildren().find(c => c.id === child)
      })
    )
  })

  protected _childrenOrder = computed(() => this._setting().children)
  protected _rawChildren = signal<PageElementTreeNode[]>([])

  constructor(data: IPageElementBase<GroupType>, children: CreatePageElementParams[]) {
    super(data)
    const virtualChildren = VirtualGroupElementTreeNode.createVirtualChildren(children)
    this._rawChildren.set(virtualChildren)
  }

  override get children() {
    return this.orderedChildren()
  }

  static createVirtualChildren(elements: CreatePageElementParams[]) {
    return elements.map(element => {
      if (element.category === ElementTypeEnum.Group) {
        const { children: groupChildren, ...group } = element
        return new GroupElementTreeNode(group as IGroupElement, groupChildren as IChildElement[])
      } else {
        return new PageElementTreeNode(element)
      }
    })
  }

  resetChildren(children: PageElementTreeNode[]) {
    this._rawChildren.set(children)
    this.update({
      setting: {
        ...this.setting,
        children: children.map(child => child.id)
      }
    })
  }

  override clone() {
    const newNode = new VirtualGroupElementTreeNode(this.data, [])
    newNode.resetChildren(this.children)
    return newNode
  }
}
