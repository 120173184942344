import { HttpEvent } from '@angular/common/http'
import { InjectionToken } from '@angular/core'

import { WithDeleted } from 'rxdb'
import { Observable } from 'rxjs'

import {
  Belongs,
  Checkpoint,
  CreateMaterialParams,
  IBaseRes,
  ICreateProjectReq,
  IDataFile,
  IForkImageReq,
  IImageFile,
  IListReq,
  IPaginatedListRes,
  IProject,
  IProjectUpdateReq,
  IResourceDataLink,
  IResourceDataList,
  IResourceTag,
  IResourceTemplate,
  ISearchParams,
  ITemplateSearchParams,
  IUpdateNameReq,
  IUpdateRawReq,
  IUploadDataReq
} from '@libs/payload'

export interface EditorApiService {
  pullData: <T>(
    key: string,
    projectId: string,
    params: { [key: string]: string | number }
  ) => Observable<{ documents: WithDeleted<T>[]; checkpoint: Checkpoint }>
  pushData: <T>(
    key: string,
    projectId: string,
    clientId: string,
    data: {
      assumedMasterState?: {
        id: string
        updatedAt: number
      }
      newDocumentState: T
    }[]
  ) => Observable<WithDeleted<T>[]>
  uploadData: (dataReq: IUploadDataReq) => Observable<HttpEvent<any>>
  uploadImage: (file: File, prefix?: string, fileName?: string, belongs?: Belongs) => Observable<HttpEvent<any>>
  getSuggestResourceData: (keyword: string) => Observable<IResourceDataList>

  getUploadDataList: (listReq?: IListReq) => Observable<IPaginatedListRes<IDataFile>>

  getImageDetail: (id: string) => Observable<IImageFile>

  getDataDetail: (id: string) => Observable<IDataFile>

  getImages: (listReq?: IListReq) => Observable<IPaginatedListRes<IImageFile>>

  updateImageName: (updateNameReq: IUpdateNameReq) => Observable<IBaseRes>

  updateDataName: (updateNameReq: IUpdateNameReq) => Observable<IBaseRes>

  deleteImages: (ids: string[]) => Observable<IBaseRes>

  deleteDatas: (ids: string[]) => Observable<IBaseRes>

  updateDataContent: (updateRawReq: IUpdateRawReq) => Observable<IBaseRes>

  forkImage: (forkImageReq: IForkImageReq & { projectId: string }) => Observable<IImageFile>

  updateProject: (id: string, payload: IProjectUpdateReq) => Observable<IBaseRes>

  getProjectById: (id: string) => Observable<IProject>

  searchResourceData: (searchParams: ISearchParams) => Observable<IResourceDataList>

  getSimilarResourceData: (id: string, title: string) => Observable<IResourceDataList>

  getResourceDataDetail: (link: string) => Observable<IResourceDataLink>

  getTemplateTageList: () => Observable<IResourceTag[]>

  getTemplateList: (params: ITemplateSearchParams) => Observable<IPaginatedListRes<IResourceTemplate>>

  createProject: (payload: ICreateProjectReq) => Observable<IProject>

  saveAsMaterial: (payload: CreateMaterialParams[]) => Observable<IBaseRes>
}

export const EDITOR_API = new InjectionToken<EditorApiService>('EDITOR_API')
