import { computed, signal } from '@angular/core'

import _ from 'lodash-es'
import { BehaviorSubject, combineLatestWith } from 'rxjs'

import { generateUid } from '@libs/algorithm'
import {
  CreatePageElementParams,
  CreatePageParams,
  ElementTypeEnum,
  IChildElement,
  IGroupElement,
  IPageBackground,
  IPageBase,
  IPageElementBase,
  IPageTheme,
  IRootElement,
  ISize,
  UpdatePageElement,
  UpdatePageParams,
  WithId
} from '@libs/payload'

import { GroupElementTreeNode, PageElementTreeNode } from './element-node'

export class PageListNode implements IPageBase {
  id: string
  projectId: string
  // elementCheckPoint: number
  rootElements = computed(() => {
    return this._elementList().map(
      node =>
        ({
          ..._.omit(node, 'children')
        }) as IRootElement
    )
  })

  private readonly _elementList = signal<PageElementTreeNode[]>([])
  // private readonly _injector
  private _name: string
  private _locked: boolean
  private _visible: boolean
  private _background: IPageBackground
  private _theme: IPageTheme
  private _size: ISize
  private _updatedAt: number
  private readonly _version: number
  private _orders: BehaviorSubject<string[]>
  private _rawElements: BehaviorSubject<IPageElementBase[]>
  private _elementsLatestUpdatedAt = signal(0)

  constructor(page: CreatePageParams) {
    this.id = page.id
    this.projectId = page.projectId
    this._name = page.name
    this._locked = page.locked
    this._visible = page.visible
    this._background = page.background
    this._theme = page.theme
    this._size = page.size
    this._updatedAt = page.updatedAt
    this._version = page.version
    this._orders = new BehaviorSubject(page.orders)
    this._rawElements = new BehaviorSubject(page.elements)
    // this._orders.set(page.orders)

    // this._injector = injector
    // this.elementCheckPoint = Date.now()

    // const rootElements = this.parseElements(page.orders, page.elements)
    // this._elementList.set(rootElements)

    this._orders
      .asObservable()
      .pipe(combineLatestWith(this._rawElements.asObservable()))
      .subscribe(([orders, rawElements]) => {
        const orderedElements = this.parseElements(orders, rawElements)
        this._elementList.set(orderedElements)
      })
  }

  get elementTreeNodes() {
    return this._elementList.asReadonly()
  }

  get name(): string {
    return this._name
  }
  get locked(): boolean {
    return this._locked
  }
  get visible(): boolean {
    return this._visible
  }
  get background() {
    return this._background
  }

  get theme() {
    return this._theme
  }
  get size() {
    return this._size
  }
  get updatedAt(): number {
    return this._updatedAt
  }
  get version(): number {
    return this._version
  }
  get orders(): string[] {
    return [...this._orders.value]
  }

  get elements(): IPageElementBase[] {
    return [...this._rawElements.value]
  }

  get elementsLatestUpdatedAt() {
    return this._elementsLatestUpdatedAt.asReadonly()
  }

  get page(): Required<IPageBase> {
    return {
      id: this.id,
      projectId: this.projectId,
      locked: this.locked,
      visible: this.visible,
      background: this.background,
      theme: this.theme,
      size: this.size,
      name: this.name,
      updatedAt: this.updatedAt,
      version: this.version,
      orders: this.orders
    }
  }

  updatePage(params: Omit<UpdatePageParams, 'id'>) {
    this._name = params.name ?? this._name
    this._locked = params.locked ?? this._locked
    this._visible = params.visible ?? this._visible
    this._background = params.background ?? this._background
    this._theme = params.theme ?? this._theme
    this._size = params.size ?? this._size
    this._updatedAt = params.updatedAt ?? Date.now()
    this._orders.next(params.orders ?? this._orders.value)
    // this._orders.set(params.orders ?? this._orders())
    return this._updatedAt
  }

  // getRootElementById(id: string) {
  //   return this._elementList().find(el => el.id === id)
  // }

  // getRootElementIndex(id: string) {
  //   return this._elementList().findIndex(el => el.id === id)
  // }

  addRootElement(element: CreatePageElementParams, position?: number) {
    const pos = position ?? this._elementList().length
    let newElements: IPageElementBase[] = []
    if (element.category === ElementTypeEnum.Group && element.children) {
      const { children, ...group } = element
      newElements = [group, ...children]
    } else {
      newElements = [element]
    }
    const orders = [...this.orders]
    orders.splice(pos, 0, element.id)
    // this._orders.set(orders)
    this._orders.next(orders)
    // this._rawElements.set([...this._rawElements(), ...newElements])
    this._rawElements.next([...this.elements, ...newElements])
    this._updatedAt = Date.now()
    this._elementsLatestUpdatedAt.set(this._updatedAt)
    return newElements
  }

  addRootElements(elements: CreatePageElementParams[], position?: number) {
    const pos = position ?? this._elementList().length
    const newElements: IPageElementBase[] = elements
      .map(el => {
        if (el.children) {
          return [_.omit(el, 'children'), ...el.children]
        } else {
          return [el]
        }
      })
      .flat()

    // this._rawElements.set([...this._rawElements(), ...newElements])
    this._rawElements.next([...this.elements, ...newElements])
    const orders = this.orders
    orders.splice(pos, 0, ...elements.map(el => el.id))
    // this._orders.set(newOrder)
    this._orders.next(orders)
    this._updatedAt = Date.now()
    this._elementsLatestUpdatedAt.set(this._updatedAt)
    return newElements
  }

  updateElement(element: WithId<Partial<IPageElementBase>>) {
    const rawElements = this.elements
    const rawElementIndex = rawElements.findIndex(el => el.id === element.id)
    if (rawElementIndex !== -1) {
      const rawElement = rawElements[rawElementIndex]
      rawElements.splice(rawElementIndex, 1, { ...rawElement, ...element })
      this._rawElements.next(rawElements)
      this._elementsLatestUpdatedAt.set(Date.now())
      this._updatedAt = Date.now()
      return true
    } else {
      return false
    }
  }

  updateElements(elements: UpdatePageElement[]) {
    const rawElements = this.elements
    const success: IPageElementBase[] = []
    elements.forEach(update => {
      const elementIndex = rawElements.findIndex(el => el.id === update.id)
      if (elementIndex !== -1) {
        const updateElement = { ...rawElements[elementIndex], ...update }
        rawElements.splice(elementIndex, 1, updateElement)
        success.push(updateElement)
      }
    })
    this._rawElements.next(rawElements)
    this._elementsLatestUpdatedAt.set(Date.now())
    this._updatedAt = Date.now()
    return success
  }

  deleteElements(...ids: string[]) {
    this._orders.next(this.orders.filter(id => !ids.includes(id)))
    this._rawElements.next(this.elements.filter(el => !(ids.includes(el.id) || (el.parent && ids.includes(el.parent)))))
    this._elementsLatestUpdatedAt.set(Date.now())
    this._updatedAt = Date.now()
    // return this._elementList.reset(this._elementList.filter(el => !ids.includes(el.id)).toArray())
  }

  resetElements(elements: IPageElementBase[]) {
    this._rawElements.next(elements)
    this._orders.next(elements.map(el => el.id))
    this._updatedAt = Date.now()
    this._elementsLatestUpdatedAt.set(Date.now())
  }

  clone() {
    return new PageListNode({ ...this.page, elements: this.elements })
  }

  duplicate() {
    const newPageId = generateUid()
    const elements = this._elementList()
    const orders: string[] = []
    const newElements = elements
      .map(element => {
        if (element instanceof GroupElementTreeNode) {
          const { children, ...group } = element.duplicate()
          orders.push(group.id)
          group.pageId = newPageId
          children.forEach(child => (child.pageId = newPageId))
          return [group as IPageElementBase, ...children]
        } else {
          const newElement = element.duplicate()
          newElement.pageId = newPageId
          orders.push(newElement.id)
          return [newElement]
        }
      })
      .flat()

    return new PageListNode({
      id: newPageId,
      projectId: this.projectId,
      name: this.name,
      locked: this.locked,
      visible: this.visible,
      background: this.background,
      theme: this.theme,
      size: this.size,
      updatedAt: Date.now(),
      elements: newElements,
      orders: orders,
      version: this.version
    })
  }

  resetElementOrders(ids: string[]) {
    this._orders.next(ids)
    this._updatedAt = Date.now()
    return this._updatedAt
  }

  getElementNodeById(id: string): PageElementTreeNode | undefined {
    let target: PageElementTreeNode | undefined
    if (
      this.elementTreeNodes().some(el => {
        if (el.id === id) {
          target = el
          return true
        } else {
          if (el instanceof GroupElementTreeNode) {
            const findChild = el.children.find(child => child.id === id)
            if (findChild) {
              target = findChild
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        }
      })
    ) {
      return target
    } else {
      return undefined
    }
  }

  private parseElements(orders: string[], elements: IPageElementBase[]) {
    const rootElements: PageElementTreeNode[] = []
    const rawElements = [...elements]

    orders.forEach(id => {
      const elementIndex = rawElements.findIndex(el => el.id === id)
      if (elementIndex !== -1) {
        const rootElement = rawElements[elementIndex]
        if (!rootElement.parent) {
          rawElements.splice(elementIndex, 1)
          if (rootElement.category === ElementTypeEnum.Group) {
            rootElements.push(new GroupElementTreeNode(rootElement as IGroupElement, []))
          } else {
            if (!rootElement.parent) {
              rootElements.push(new PageElementTreeNode(rootElement))
            }
          }
        }
      }
    })

    // 剩下的是子元素
    rootElements.forEach(root => {
      if (root instanceof GroupElementTreeNode) {
        const children = root.setting.children.map(childId => {
          return rawElements.find(el => el.id === childId)
        })
        root.resetChildren(_.compact(children) as IChildElement[])
      }
    })

    return rootElements
  }
}
