<div class="h-full w-full">
  @if (category() === 'group') {
    <div class="relative h-full w-full origin-top-left" [style.scale]="scale()">
      @for (child of parsedChildren(); track child.id) {
        <div class="absolute" [style.transform]="'translate(' + child.position.x + 'px, ' + child.position.y + 'px ) rotate(' + child.rotation + 'deg)'">
          <ng-container
            [ngTemplateOutlet]="atomElement"
            [ngTemplateOutletContext]="{ category: child.category, setting: child.setting, scale: child.scale, size: child.size }"
          ></ng-container>
        </div>
      }
    </div>
  } @else {
    <ng-container
      [ngTemplateOutlet]="atomElement"
      [ngTemplateOutletContext]="{ category: category(), setting: setting(), scale: scale(), size: size() }"
    ></ng-container>
  }
</div>
<ng-template #atomElement let-category="category" let-setting="setting" let-scale="scale" let-size="size">
  <div class="origin-top-left" [style.scale]="scale" [style.width.px]="size.width" [style.height.px]="size.height">
    @switch (category) {
      @case ('text') {
        <ace-text-view [setting]="setting" [size]="size"></ace-text-view>
      }
      @case ('chart') {
        <ace-chart [setting]="setting" [size]="size"></ace-chart>
      }
      @case ('shape') {
        <ace-shape [setting]="setting" [size]="size" [scale]="scale"></ace-shape>
      }
      @case ('image') {
        <ace-image-view [setting]="setting" [size]="size" [scale]="scale"></ace-image-view>
      }
      @case ('line') {
        <ace-line [setting]="setting" [size]="size"></ace-line>
      }
      @default {
        <div></div>
      }
    }
  </div>
</ng-template>
