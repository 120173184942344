import { CommonModule } from '@angular/common'
import { AfterViewInit, ChangeDetectionStrategy, Component, computed, ElementRef, inject, input, OnDestroy, Renderer2, signal, viewChild } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'

import '@svgdotjs/svg.filter.js'

import { Path, SVG, Svg, Filter as SVGFilter } from '@svgdotjs/svg.js'
import { isEqual, isString } from 'lodash'
import { combineLatestWith, distinctUntilChanged, filter, map, Subscription } from 'rxjs'
import { roundCorners } from 'svg-round-corners'

import { SingleColor } from '@editorup/charts'
import { ParseColorPipe } from '@libs/ng-shared/pipes'
import { IShapeSetting, ISize } from '@libs/payload'

import { fillColor, getDashArray } from './utils'

@Component({
  selector: 'ace-shape',
  standalone: true,
  imports: [CommonModule, ParseColorPipe],
  template: `
    <div class="relative" #shapeContainerRef [style.height]="size().height + 'px'" [style.width]="size().width + 'px'">
      <!--      实现backdrop-->
      <div class="absolute left-0 top-0  h-full w-full" [style.backdrop-filter]="blur()" [style.clip-path]="'url(#' + backdropFilterId() + ')'"> </div>
      <!--      内嵌其他内容并使用shape的部分功能 比如图片 -->
      <div class="absolute left-0 top-0  z-[6] h-full w-full" [style.clip-path]="'url(#' + backdropFilterId() + ')'">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShapeComponent implements AfterViewInit, OnDestroy {
  setting = input.required<IShapeSetting>()
  size = input.required<ISize>()
  scale = input.required<number>()
  selected = input(false)

  backdropFilterId = signal('')

  shapePath = computed(() => this.setting().path)
  fill = computed(() => this.setting().fill)
  // 缩放时描边宽度不变
  stroke = computed(() => ({ ...this.setting().stroke, width: this.setting().stroke.width / this.scale() }))
  shadow = computed(() => this.setting().shadow)
  radius = computed(() => this.stroke().radius)
  blur = computed(() => `blur(${this.setting().blur}px)`)
  showStroke = computed(() => !!this.stroke().style)

  originalPath = computed(() => {
    const { width, height } = this.size()
    const scale = this.scale()
    const path = new Path({
      d: this.shapePath()
    }).size(width, height)
    // 缩放时圆角的值不变
    return path.plot(roundCorners(path.plot().toString(), this.radius() / scale).path)
  })

  showShadow = computed(() => this.shadow().show)
  showOuterShadow = computed(() => this.showShadow() && this.setting().shadow.type === 'outer')
  showInnerShadow = computed(() => this.showShadow() && this.setting().shadow.type === 'inner')

  // size$ = toObservable(this.size)
  originalPath$ = toObservable(this.originalPath)
  stroke$ = toObservable(this.stroke)
  showStroke$ = toObservable(this.showStroke)
  shadow$ = toObservable(this.shadow)
  showOuterShadow$ = toObservable(this.showOuterShadow)
  showInnerShadow$ = toObservable(this.showInnerShadow)
  fill$ = toObservable(this.fill)

  original = signal<Svg | undefined>(undefined)

  _shapeContainerRef = viewChild.required<ElementRef<HTMLDivElement>>('shapeContainerRef')
  _subscription = new Subscription()
  _borderSubscription = new Subscription()
  _innerShadowSubscription = new Subscription()
  _outerShadowSubscription = new Subscription()
  _renderer2 = inject(Renderer2)

  constructor() {
    toObservable(this.showStroke)
      .pipe(takeUntilDestroyed())
      .subscribe(show => {
        if (show) {
          this.drawBorder()
        }
      })

    this.showOuterShadow$
      .pipe(takeUntilDestroyed(), combineLatestWith(toObservable(this.original).pipe(filter((o): o is Svg => !!o))))
      .subscribe(([show, draw]) => {
        if (show && draw) {
          this.addOuterShadow(draw)
        }
      })

    this.showInnerShadow$
      .pipe(takeUntilDestroyed(), combineLatestWith(toObservable(this.original).pipe(filter((o): o is Svg => !!o))))
      .subscribe(([show, draw]) => {
        if (show && draw) {
          this.addInnerShadow(draw)
        }
      })
  }

  ngAfterViewInit(): void {
    this.drawShape()
  }

  ngOnDestroy() {
    this._subscription.unsubscribe()
    this._borderSubscription.unsubscribe()
    this._innerShadowSubscription.unsubscribe()
    this._outerShadowSubscription.unsubscribe()
  }

  /**
   * 绘制边框
   */
  drawBorder() {
    // 创建 SVG 画布
    const draw = this.createSvg()
    this._renderer2.setStyle(draw.node, 'z-index', '10')

    // 创建原始路径
    const path = this.originalPath().clone()
    // 创建放大的路径
    const cloneEnlargedPath = path.clone()
    // 创建 clip-path
    const clipPath = draw.clip()
    clipPath.add(cloneEnlargedPath)
    // cloneEnlargedPath.attr('shape-rendering', 'crispEdges')
    path.fill('none').attr('vector-effect', 'non-scaling-stroke').clipWith(clipPath).attr('stroke-linecap', 'butt')
    // path.attr('shape-rendering', 'crispEdges')
    draw.add(path)

    this._borderSubscription.add(
      // 监听是否显示边框
      this.showStroke$.pipe(filter(show => !show)).subscribe(() => {
        draw.remove()
        this._borderSubscription.unsubscribe()
        this._borderSubscription = new Subscription()
      })
    )

    this._borderSubscription.add(
      // 保持边框中的path和size同步
      this.originalPath$.subscribe(newPath => {
        const newSize = this.size()
        const newPlot = newPath.plot()
        path
          .plot(newPlot)
          .x((newSize.width - (newPath.width() as number)) / 2)
          .y((newSize.height - (newPath.height() as number)) / 2)
        draw.size(newSize.width, newSize.height)
        draw.viewbox(0, 0, newSize.width, newSize.height)
        cloneEnlargedPath
          .plot(newPlot)
          .x((newSize.width - (newPath.width() as number)) / 2)
          .y((newSize.height - (newPath.height() as number)) / 2)
      })
    )

    this._borderSubscription.add(
      // 监听边框颜色和宽度
      this.stroke$.subscribe(stroke => {
        path.stroke({
          width: stroke.width,
          dasharray: stroke.style ? getDashArray(stroke.style) : ''
        })
      })
    )

    this._borderSubscription.add(
      fillColor(
        draw,
        this.stroke$.pipe(
          map(s => s.color),
          distinctUntilChanged(isEqual)
        ),
        color => {
          path.stroke({
            color: isString(color) ? color : `url(#${color.id()})`
          })
        }
      )
    )
  }

  /**
   * 添加内阴影
   * @param draw 画布
   */
  addInnerShadow(draw: Svg) {
    // 创建一个空白的filter
    const filterer: SVGFilter = draw.filter()
    const group = draw.children()[0]
    group.filterWith(filterer)
    const currentShadow = this.shadow()
    const { dx, dy } = this.getOffset(currentShadow.offset, currentShadow.angle)
    filterer.attr('filterUnits', 'userSpaceOnUse').attr('color-interpolation-filters', 'sRGB').size('140%', '140%').x('-20%').y('-20%')
    filterer.flood('', 0).in('').result('BackgroundImageFix')
    filterer.blend(filterer.$source, 'BackgroundImageFix', 'normal').result('shape')
    filterer.colorMatrix('matrix', '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0').in(filterer.$sourceAlpha).result('hardAlpha')
    const offsetEffect = filterer.offset(dx, dy).in('')
    const blurEffect = filterer.gaussianBlur(currentShadow.blur, currentShadow.blur).in('')
    filterer.composite('', 'hardAlpha', 'arithmetic').attr('k2', '-1').attr('k3', '1')
    const colorEffect = filterer.colorMatrix('matrix', this.caleColorMatrix(currentShadow.color)).in('')
    filterer.blend('', 'shape', 'normal')

    this._innerShadowSubscription.add(
      this.showInnerShadow$.pipe(filter(s => !s)).subscribe(() => {
        filterer.remove()
        this._innerShadowSubscription.unsubscribe()
        this._innerShadowSubscription = new Subscription()
      })
    )

    this._innerShadowSubscription.add(
      this.shadow$
        .pipe(
          map(s => s.color),
          distinctUntilChanged(isEqual)
        )
        .subscribe(color => {
          colorEffect.attr('values', this.caleColorMatrix(color))
        })
    )
    this._innerShadowSubscription.add(
      this.shadow$
        .pipe(
          map(s => s.blur),
          distinctUntilChanged()
        )
        .subscribe(blur => {
          blurEffect.attr('stdDeviation', blur)
        })
    )
    this._innerShadowSubscription.add(
      this.shadow$
        .pipe(
          map(s => ({ offset: s.offset, angle: s.angle })),
          distinctUntilChanged(isEqual)
        )
        .subscribe(offset => {
          const { dx: nx, dy: ny } = this.getOffset(offset.offset, offset.angle)
          offsetEffect.attr('dx', nx).attr('dy', ny)
        })
    )
  }

  /**
   * 添加外阴影
   */
  addOuterShadow(draw: Svg) {
    // 创建一个空白的filter
    let filterer: SVGFilter
    draw.children()[0].filterWith(add => (filterer = add))

    this._outerShadowSubscription.add(
      this.showOuterShadow$.pipe(filter(s => !s)).subscribe(() => {
        filterer.remove()
        this._outerShadowSubscription.unsubscribe()
        this._outerShadowSubscription = new Subscription()
      })
    )

    this._outerShadowSubscription.add(
      this.shadow$.pipe(filter(shadow => shadow.type === 'outer')).subscribe(shadow => {
        const { dx, dy } = this.getOffset(shadow.offset, shadow.angle)

        filterer.clear()
        filterer.attr('filterUnits', 'userSpaceOnUse').attr('color-interpolation-filters', 'sRGB').size('140%', '140%').x('-20%').y('-20%')

        filterer.flood('', 0).in('').result('BackgroundImageFix')
        filterer.colorMatrix('matrix', '0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0').in(filterer.$sourceAlpha).result('hardAlpha')
        filterer.offset(dx, dy).in('')
        const blur = filterer.gaussianBlur(shadow.blur, shadow.blur).in('')
        if (!shadow.behind) {
          blur.composite('hardAlpha', 'out')
        }
        filterer.colorMatrix('matrix', this.caleColorMatrix(shadow.color)).in('')
        filterer.blend('', 'BackgroundImageFix', 'normal').result('effect')
        filterer.blend(filterer.$source, 'effect', 'normal')
      })
    )
  }

  /**
   * 绘制形状
   */
  drawShape() {
    const draw = this.createSvg()
    const group = draw.group()
    const path = this.originalPath().clone()
    // 增强渲染效果
    // path.attr('shape-rendering', 'crispEdges')
    draw.add(group.add(path))

    this._renderer2.setStyle(draw.node, 'z-index', '5')
    // 禁用鼠标事件 存在阴影时，阴影会增加当前shape的交互范围
    this._renderer2.setStyle(draw.node, 'pointer-events', 'none')

    // 设置当前绘制的主节点，并提供信号用于在此节点上绘制阴影
    this.original.set(draw)
    const clipPtah = this.originalPath().clone()
    // 设置背景滤镜 主要为了使用HTMLElement中的backdrop filter 其中的毛玻璃效果
    this.backdropFilterId.set(draw.clip().add(clipPtah).id())

    // 监听背景颜色变化
    this._subscription.add(
      fillColor(draw, this.fill$, color => {
        if (isString(color)) {
          path.fill(color)
        } else {
          path.fill(color)
        }
      })
    )

    // 根据外阴影和内阴影的显示状态，绘制阴影
    this._subscription.add(
      this.originalPath$.pipe(combineLatestWith(this.shadow$)).subscribe(([newPath, shadow]) => {
        const size = this.size()
        path.plot(newPath.plot())
        clipPtah.plot(newPath.plot())
        const rdx = (size.width - (newPath.width() as number)) / 2
        const rdy = (size.height - (newPath.height() as number)) / 2
        if (shadow.type === 'outer' && shadow.show) {
          const { dx, dy } = this.getOffset(shadow.offset, shadow.angle)
          const newFilterRegion = this.calculateFilterRegion({ x: dx, y: dy }, size, shadow.blur)
          // 放大画布并移动到新的位置
          draw.size(newFilterRegion.width, newFilterRegion.height).x(0).y(0).attr('transform', `translate(${newFilterRegion.x}, ${newFilterRegion.y})`)
          // 移动原始路径到新的位置
          path.x(-newFilterRegion.x + rdx).y(-newFilterRegion.y + rdy)
          clipPtah
            .x(-newFilterRegion.x + rdx)
            .y(-newFilterRegion.y + rdy)
            .attr('transform', `translate(${newFilterRegion.x}, ${newFilterRegion.y})`)
        } else {
          draw.size(size.width, size.height).x(0).y(0).attr('transform', '')
          path.x(rdx).y(rdy)
          clipPtah.x(rdx).y(rdy).attr('transform', '')
        }
      })
    )
  }

  private createSvg() {
    // 创建 SVG 画布
    const draw = SVG().addTo(this._shapeContainerRef().nativeElement)
    draw.css('position', 'absolute').css('top', '0').css('left', '0')
    return draw
  }

  /**
   * 计算colorMatrix
   * @param color
   * @private
   */
  private caleColorMatrix(color: SingleColor) {
    const hex = color.color
    const r = parseInt(hex.slice(1, 3), 16) / 255
    const g = parseInt(hex.slice(3, 5), 16) / 255
    const b = parseInt(hex.slice(5, 7), 16) / 255
    const a = color.opacity // 给定的透明度

    // 构建colorMatrix
    return [0, 0, 0, 0, r, 0, 0, 0, 0, g, 0, 0, 0, 0, b, 0, 0, 0, a, 0].join(' ')
  }

  /**
   * 计算滤镜区域
   * @param offset
   * @param size
   * @param blurStdDeviation
   * @private
   */
  private calculateFilterRegion(offset: { x: number; y: number }, size: { width: number; height: number }, blurStdDeviation: number) {
    // 将模糊标准差转换为近似的像素范围
    // 通常，模糊效果的可见范围约为标准差的3倍
    const blurRange = blurStdDeviation * 3

    // 计算滤镜效果的总范围
    const totalRangeX = Math.abs(offset.x) + blurRange
    const totalRangeY = Math.abs(offset.y) + blurRange

    // 计算滤镜区域的x和y（相对于原始元素）
    const x = -Math.max(totalRangeX, blurRange)
    const y = -Math.max(totalRangeY, blurRange)

    // 计算滤镜区域的宽度和高度
    const width = Math.max(totalRangeX, blurRange) * 2 + size.width
    const height = Math.max(totalRangeY, blurRange) * 2 + size.height

    return { x, y, width, height }
  }

  private getOffset(offset: number, angle: number) {
    // 根据角度计算dx和dy
    const dx = offset * Math.cos((angle * Math.PI) / 180)
    const dy = offset * Math.sin((angle * Math.PI) / 180)
    return {
      dx,
      dy
    }
  }
}
