import { inject, Injectable } from '@angular/core'

import { RxDatabase } from 'rxdb'

import { IProjectStorage } from '@libs/payload'

import { ProjectListService } from '../project-list.service'
import { APP_DB, CollectionBase } from './db.base'

@Injectable()
export class ProjectCollectionService extends CollectionBase<IProjectStorage> {
  projectListService = inject(ProjectListService)
  private broadcast = new BroadcastChannel('project-collection')

  constructor() {
    const db = inject(APP_DB) as unknown as RxDatabase
    super(db, 'projects', 0, false)
    this.projectListService.syncer$.subscribe(project => {
      if (project) {
        this.reSync()
      }
    })
  }

  override startReplication() {
    super.startReplication()
    this.broadcast.onmessage = () => {
      this.pullStream.next('RESYNC')
    }
  }

  getProjectById(projectId: string) {
    return this.collection.findOne({ selector: { id: projectId } })
  }

  async changePageOrder(projectId: string, order: string[]) {
    this.savingState.next(true)
    const doc = await this.collection.findOne({ selector: { id: projectId } }).exec()
    if (doc) {
      return doc
        .incrementalPatch({ pages: order })
        .then(() => doc.toMutableJSON())
        .finally(() => this.savingState.next(false))
    } else {
      throw new Error('Project not found')
    }
  }

  deleteProjectPages(projectId: string, pageIds: string[]) {
    this.savingState.next(true)
    return this.collection
      .findOne({ selector: { id: projectId } })
      .exec()
      .then(project => {
        if (project) {
          return project.incrementalPatch({ pages: project.pages.filter(pageId => !pageIds.includes(pageId)) }).then(() => project)
        } else {
          throw new Error('Project not found')
        }
      })
      .finally(() => this.savingState.next(false))
  }

  reSync() {
    // this.pullStream.next('RESYNC')
    if (this.db.isLeader()) {
      this.pullStream.next('RESYNC')
    } else {
      this.broadcast.postMessage('RESYNC')
    }
  }

  upsertProject(project: IProjectStorage) {
    this.savingState.next(true)
    return this.collection.incrementalUpsert(project).finally(() => this.savingState.next(false))
  }
}
