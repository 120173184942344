<div class="absolute origin-top-left overflow-hidden" [style.width.px]="pageSize().width" [style.height.px]="pageSize().height" [style.scale]="pageScale()">
  <div class="elements-container relative h-full w-full overflow-hidden bg-cover bg-center bg-no-repeat">
    <ace-page-background [pageSize]="pageSize()" [background]="background()"></ace-page-background>
    @if (!loading()) {
      @for (el of pageRootElements(); track el.id) {
        <ace-element-container-view [style.z-index]="$index" [data]="el"></ace-element-container-view>
      }
    }
  </div>
</div>

@if (loading()) {
  <ngx-skeleton-loader [theme]="{ width: '200px', height: pageSize().height / pageScale() + 'px', borderRadius: '8px', marginBottom: '0' }" />
}
