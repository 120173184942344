import { CommonModule } from '@angular/common'
import { AfterViewInit, ChangeDetectionStrategy, Component, computed, HostBinding, input, output } from '@angular/core'

import { ChildElementTreeNode, PageElementTreeNode } from '@libs/ng-shared/models'
import { AtomType, ElementTypeEnum, IChildElement, IPosition, ISetting } from '@libs/payload'

import { ChartComponent, ImageViewComponent, LineComponent, ShapeComponent, TextViewComponent } from '../element-view'

@Component({
  selector: 'ace-element-container-view',
  standalone: true,
  imports: [CommonModule, ChartComponent, ImageViewComponent, LineComponent, ShapeComponent, TextViewComponent],
  templateUrl: './element-container-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementContainerViewComponent implements AfterViewInit {
  data = input.required<PageElementTreeNode>()
  standalone = input<boolean>(false)
  standaloneScale = input<number>(1)

  category = computed(() => {
    return this.data().category
  })
  size = computed(() => {
    return this.data().size
  })
  scale = computed(() => {
    return this.data().scale
  })
  position = computed<IPosition>(
    () => {
      if (this.standalone()) {
        return { x: 0, y: 0 }
      } else {
        return this.data().position
      }
    },
    {
      equal: (a: IPosition, b: IPosition) => a.x === b.x && a.y === b.y
    }
  )
  left = computed(() => {
    return this.position().x
  })
  top = computed(() => {
    return this.position().y
  })
  setting = computed(() => {
    return this.data().setting as ISetting
  })
  rotation = computed(() => {
    return this.data().rotation
  })
  children = computed<IChildElement<AtomType>[]>(() => {
    if (this.isGroup()) {
      const elements = (this.data().children as ChildElementTreeNode[]) || []
      return elements.map(el => {
        return el.data as IChildElement<AtomType>
      })
    } else {
      return []
    }
  })
  isGroup = computed(() => {
    return this.data().category === ElementTypeEnum.Group
  })

  parsedChildren = computed<IChildElement[]>(() => {
    const children = this.children()
    if (children) {
      return children.map(child => ({
        ...child,
        scale: child.scale * this.scale(),
        position: {
          x: child.position.x * this.scale(),
          y: child.position.y * this.scale()
        }
      }))
    } else {
      return []
    }
  })

  rendered = output<boolean>()

  @HostBinding('style.transform') get transform() {
    return `translate(${this.left()}px, ${this.top()}px)  rotate(${this.rotation()}deg)`
  }

  @HostBinding('style.display') get display() {
    return this.data().visible ? 'block' : 'none'
  }
  @HostBinding('style.width.px') get width() {
    return this.size().width * this.scale()
  }
  @HostBinding('style.height.px') get height() {
    return this.size().height * this.scale()
  }

  @HostBinding('style.scale') get _standaloneScale() {
    return this.standaloneScale()
  }

  @HostBinding('style.position') get stylePosition() {
    return 'absolute'
  }

  ngAfterViewInit() {
    this.rendered.emit(true)
  }
}
