import { computed, inject, Injectable } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'

import { produce } from 'immer'
import { cloneDeep, isEmpty, isEqual, set } from 'lodash'
import { each } from 'lodash-es'
import { filter, map } from 'rxjs'

import { SettingSchema, ST } from '@editorup/settings'
import { defaultAdjustmentColor } from '@libs/editor-view'
import { ButtonComponent } from '@libs/ng-shared/components'
import { ElementTypeEnum, IImageSetting, IPageElementBase, IPageElementSetting } from '@libs/payload'

import {
  colorRowResolver,
  iconButtonToggleResolver,
  imageFilterResolver,
  numberInputResolver,
  strokeStyleResolver
} from '../../components/setting/setting-widget'
import { ColorRowComponent } from '../../components/setting/setting-widget/color-row'
import { IconButtonToggleComponent } from '../../components/setting/setting-widget/icon-button-toggle'
import { ImageFilterComponent } from '../../components/setting/setting-widget/image-filter'
import { NumberInputComponent } from '../../components/setting/setting-widget/number-input'
import { OptionsKey } from '../../components/setting/setting-widget/setting'
import { StrokeStyleComponent } from '../../components/setting/setting-widget/stroke-style'
import { isImageSetting } from '../../components/setting/utils'
import { StageUiStore } from '../../store/stage-ui.store'
import { WorkspaceService } from '../workspace.service'

type ImageAdjustmentColor = IImageSetting['adjustment']['color']
type ImageAdjustmentFilter = IImageSetting['adjustment']['filter']

@Injectable({
  providedIn: 'root'
})
export class ImageSettingService {
  element = computed(() => {
    const selectedElements = this._uiStore.selectedElements()
    return selectedElements.length === 1 && isImageSetting(selectedElements[0]) ? selectedElements[0] : null
  })

  element$ = toObservable(this.element).pipe(filter((e): e is IPageElementBase<ElementTypeEnum.Image> => !!e))

  shadowSetting = computed(() => this._uiStore.interacting.shadowData.setting(), {
    equal: isEqual
  })

  setting = computed(() => {
    const shadowSetting = this.shadowSetting()
    return shadowSetting && !isEmpty(shadowSetting) ? (shadowSetting as IImageSetting) : this.element()?.setting
  })

  setting$ = toObservable(this.setting).pipe(filter((s): s is IPageElementSetting['image'] => !!s))
  stroke$ = this.element$.pipe(map(e => e.setting.stroke))
  shadow$ = this.element$.pipe(map(e => e.setting.adjustment.shadow))

  private _uiStore = inject(StageUiStore)
  private _workspaceService = inject(WorkspaceService)
  /**
   * grayscale (灰度): 移除所有颜色信息，只保留亮度。
   * invert (反相): 将每个颜色通道的值反转。
   * remove-color (去色): 降低图像的颜色饱和度。
   * sepia (棕褐色): 添加温暖的棕色调。
   * brownie: 增加棕色色调，类似于老照片。
   * brightness (亮度): 调整整体亮度。
   * contrast (对比度): 调整暗部和亮部之间的差异。
   * saturation (饱和度): 调整颜色的强度。
   * vibrance (自然饱和度): 智能增加低饱和度颜色的饱和度。
   * noise (噪点): 添加随机的颜色变化。
   * vintage (复古): 模拟老式照片的颜色效果。
   * pixelate (像素化): 降低图像分辨率，增大像素尺寸。
   * blur (模糊): 柔化颜色过渡。
   * sharpen (锐化): 增强颜色边缘的对比度。
   * emboss (浮雕): 突出颜色边缘，创造3D效果。
   * technicolor: 模拟早期彩色电影的鲜艳色彩。
   * polaroid: 模拟宝丽来相机的独特色彩。
   * blend-color (颜色混合): 将特定颜色与原图混合。
   * gamma (伽马): 调整中间调的亮度。
   * kodachrome: 模拟柯达彩色胶片的鲜艳色彩。
   * blackwhite (黑白): 将图像转换为黑白，保留更多细节。
   * blend-image (图像混合): 将两个图像的颜色信息混合。
   * hue (色相): 改变颜色的基本色调。
   * resize (调整大小): 改变图像尺寸，可能影响颜色细节。
   * @private
   */

  //   'grayscale',
  //     'brownie',
  //     'blackwhite',
  //     'invert',
  //     'sepia',
  //     'vintage',
  //     'sharpen',
  //     'emboss',
  //     'technicolor',
  //     'polaroid',
  //     'kodachrome'

  private readonly numberSetting: Array<{
    key: keyof Omit<ImageAdjustmentColor, 'enable'>
    label: string
    min: number
    max: number
    step: number
    // 在显示和修改时的转换
    // 控制显示值在-100 到 +100之间 并将step调整为200
    transform: {
      show: (val: number) => number
      change: (val: number) => number
    }
  }> = [
    {
      key: 'brightness',
      label: '亮度',
      min: -1,
      max: 1,
      step: 2 / 200,
      transform: {
        show: (val: number) => val * 100,
        change: (val: number) => val / 100
      }
    },
    {
      key: 'contrast',
      label: '对比度',
      min: -1,
      max: 1,
      step: 2 / 200,
      transform: {
        show: (val: number) => val * 100,
        change: (val: number) => val / 100
      }
    },
    {
      key: 'saturation',
      label: '饱和度',
      min: -1,
      max: 1,
      step: 2 / 200,
      transform: {
        show: (val: number) => val * 100,
        change: (val: number) => val / 100
      }
    },
    {
      key: 'vibrance',
      label: '自然饱和度',
      min: -1,
      max: 1,
      step: 2 / 200,
      transform: {
        show: (val: number) => val * 100,
        change: (val: number) => val / 100
      }
    },
    {
      key: 'hue',
      label: '色相',
      min: -2,
      max: 2,
      step: 4 / 200,
      transform: {
        show: (val: number) => val * 200,
        change: (val: number) => val / 200
      }
    },
    {
      key: 'noise',
      label: '噪点',
      min: 0,
      max: 1000,
      step: 1000 / 100,
      transform: {
        show: (val: number) => val / 10,
        change: (val: number) => val * 10
      }
    },
    {
      key: 'pixelate',
      label: '像素化',
      min: 1,
      max: 20,
      step: 19 / 100,
      transform: {
        show: (val: number) => (val - 1) / (19 / 100),
        change: (val: number) => val * (19 / 100) + 1
      }
    },
    {
      key: 'blur',
      label: '模糊',
      min: 0,
      max: 1,
      step: 1 / 100,
      transform: {
        show: (val: number) => val * 100,
        change: (val: number) => val / 100
      }
    },
    {
      key: 'temperature',
      label: '色温',
      min: -1,
      max: 1,
      step: 2 / 200,
      transform: {
        show: (val: number) => val * 100,
        change: (val: number) => val / 100
      }
    }
  ]

  constructor() {}

  update = (path: OptionsKey<IPageElementBase<ElementTypeEnum.Image>>, data: unknown, options?: { preview?: boolean }) => {
    const element = this.element()
    if (this._uiStore.isElementInteractingOnPage() || !element) {
      return
    }
    const { preview = false } = options || {}
    console.log('update', path, data, options)
    if (!path) {
      this._uiStore.setSettingElement(element.id, cloneDeep(data) as IPageElementSetting['image'])
    } else {
      this._uiStore.setSettingElement(
        element.id,
        produce(element, draft => {
          set(draft, path, data)
        }).setting
      )
    }
    if (!preview) {
      this._uiStore.resetInteractingElement()
    }
  }

  generateShadowSlideOutputs = (path: OptionsKey<IPageElementBase<ElementTypeEnum.Image>>) => {
    return {
      valueChange: (val: number) => this.update(path, val),
      valueSlideChange: ({ value, dragging }: { value: number; dragging: boolean }) => this.update(path, value, { preview: dragging })
    }
  }

  getSettingSchema(): SettingSchema[] {
    return [
      {
        title: '基础属性',
        icon: 'ss:base',
        interaction: 'none',
        children: [
          ...this._workspaceService.getSettingSchema(),
          // 翻转
          {
            // 翻转
            title: '翻转',
            child: {
              widget: iconButtonToggleResolver,
              inputs: {
                value: this.setting$.pipe(
                  map(s => {
                    const flip = s.flip
                    if (flip === 'both') return ['horizontal', 'vertical']
                    if (flip === 'none') return []
                    return [flip]
                  })
                ),
                multiple: true,
                options: [
                  { value: 'horizontal', icon: 'editorup:image-horizontal' },
                  { value: 'vertical', icon: 'editorup:image-vertical' }
                ]
              },
              outputs: {
                valueChange: flip => {
                  if (!Array.isArray(flip)) {
                    flip = [flip]
                  }
                  const element = this.element()
                  if (element) {
                    const setting = element.setting
                    const currentFlip = setting.flip
                    const currentFlipList = currentFlip === 'none' ? [] : currentFlip === 'both' ? ['horizontal', 'vertical'] : [currentFlip]
                    const origin = currentFlipList.length > flip.length ? currentFlipList : flip
                    const compare = currentFlipList.length > flip.length ? flip : currentFlipList
                    const change = origin.find(item => !compare.includes(item))
                    const elementSize = element.size
                    this.update(
                      '',
                      produce(setting, draft => {
                        const translate = draft.transform.translate
                        const size = draft.size
                        draft.flip = flip.length === 0 ? 'none' : flip.length === 1 ? (flip[0] as IImageSetting['flip']) : 'both'
                        if (change === 'horizontal') {
                          draft.transform.translate.x = -(size.width - Math.abs(translate.x) - elementSize.width)
                        }
                        if (change === 'vertical') {
                          draft.transform.translate.y = -(size.height - Math.abs(translate.y) - elementSize.height)
                        }
                      })
                    )
                  }
                }
              }
            } as ST<IconButtonToggleComponent>
          },
          // 透明度
          {
            title: '透明度',
            child: {
              widget: numberInputResolver,
              inputs: {
                aceMinValue: 0,
                aceMaxValue: 100,
                useSlide: true,
                value: this.setting$.pipe(map(s => s.opacity * 100))
              },
              outputs: {
                valueChange: val => this.update('setting.opacity', (val || 100) / 100),
                valueSlideChange: ({ value, dragging }) => this.update('setting.opacity', value / 100, { preview: dragging })
              }
            } as ST<NumberInputComponent>
          }
        ]
      },
      // 滤镜效果
      {
        title: '滤镜效果',
        icon: 'ss:filter',
        interaction: 'switch',
        active: this.setting$.pipe(map(s => s.adjustment.filter.enable)),
        activeChange: val => this.update('setting.adjustment.filter.enable', val),
        children: [
          {
            flexible: true,
            child: {
              widget: imageFilterResolver,
              inputs: {
                activeFilters: this.setting$.pipe(
                  map(s => {
                    const filters: Array<keyof ImageAdjustmentFilter> = []
                    each(s.adjustment.filter, (v, k) => {
                      if (k !== 'enable' && v) {
                        filters.push(k as keyof ImageAdjustmentFilter)
                      }
                    })
                    return filters
                  })
                ),
                imageInfo: this.setting$.pipe(map(e => ({ url: e.src, size: e.size })))
              },
              outputs: {
                activeFiltersChange: filters => {
                  const enableFilters: Partial<IImageSetting['adjustment']['filter']> = {}
                  each(filters, f => {
                    enableFilters[f] = true
                  })
                  this.update('setting.adjustment.filter', {
                    ...defaultAdjustmentColor.filter,
                    ...enableFilters,
                    enable: this.setting()?.adjustment.filter.enable
                  })
                }
              }
            } as ST<ImageFilterComponent>
          }
        ]
      },
      {
        title: '颜色调整',
        interaction: 'switch',
        icon: 'ss:colorful',
        active: this.setting$.pipe(map(s => s.adjustment.color.enable)),
        activeChange: val => this.update('setting.adjustment.color.enable', val),
        children: [
          // 数值
          ...this.numberSetting.map(({ key, label, transform: { show, change }, step, min, max }) => ({
            title: label,
            child: {
              widget: numberInputResolver,
              inputs: {
                aceMinValue: min < 0 ? -100 : 0,
                aceMaxValue: 100,
                useSlide: true,
                aceSlideStep: step,
                value: this.setting$.pipe(map(s => show(s.adjustment.color[key])))
              },
              outputs: {
                valueChange: val => this.update(`setting.adjustment.color.${key}`, change(val || 0)),
                valueSlideChange: ({ value, dragging }) => this.update(`setting.adjustment.color.${key}`, change(value), { preview: dragging })
              }
            } as ST<NumberInputComponent>
          })),
          {
            flexible: true,
            child: {
              widget: ButtonComponent,
              inputs: {
                text: '重置颜色',
                align: 'center'
              },
              className: [
                'w-full',
                'border',
                'border-primary-200',
                'rounded-lg',
                'flex',
                'items-center',
                'text-center',
                'justify-center',
                'text-primary-900',
                'text-base',
                'h-8',
                'mt-2'
              ],
              outputs: {
                aceClick: () => {
                  this.update('setting.adjustment.color', {
                    enable: true,
                    brightness: this.numberSetting.find(s => s.key === 'brightness')?.transform.change(0) || 0,
                    contrast: this.numberSetting.find(s => s.key === 'contrast')?.transform.change(0) || 0,
                    saturation: this.numberSetting.find(s => s.key === 'saturation')?.transform.change(0) || 0,
                    vibrance: this.numberSetting.find(s => s.key === 'vibrance')?.transform.change(0) || 0,
                    hue: this.numberSetting.find(s => s.key === 'hue')?.transform.change(0) || 0,
                    noise: this.numberSetting.find(s => s.key === 'noise')?.transform.change(0) || 0,
                    pixelate: this.numberSetting.find(s => s.key === 'pixelate')?.transform.change(0) || 1,
                    blur: this.numberSetting.find(s => s.key === 'blur')?.transform.change(0) || 0,
                    temperature: this.numberSetting.find(s => s.key === 'temperature')?.transform.change(0) || 0
                  })
                }
              }
            } as ST<ButtonComponent>
          }
        ]
      },
      // 颜色调整
      {
        title: '描边',
        interaction: 'switch',
        icon: 'ss:stroke',
        active: this.setting$.pipe(map(s => s.stroke.show)),
        activeChange: val => this.update('setting.stroke.show', val),
        children: [
          {
            title: '描边样式',
            child: {
              widget: strokeStyleResolver,
              inputs: {
                value: this.stroke$.pipe(map(s => s.style))
              },
              outputs: {
                valueChange: style => this.update('setting.stroke.style', style)
              }
            } as ST<StrokeStyleComponent>
          },
          {
            title: '描边宽度',
            child: {
              widget: numberInputResolver,
              inputs: {
                aceMinValue: 0,
                aceMaxValue: 100,
                useSlide: true,
                value: this.stroke$.pipe(map(s => s.width))
              },
              outputs: {
                valueChange: width => this.update('setting.stroke.width', width),
                valueSlideChange: ({ value, dragging }) => this.update('setting.stroke.width', value, { preview: dragging })
              }
            } as ST<NumberInputComponent>
          },
          // 圆角
          {
            title: '圆角',
            child: {
              widget: numberInputResolver,
              inputs: {
                aceMinValue: 0,
                aceMaxValue: 100,
                useSlide: true,
                value: this.stroke$.pipe(map(s => s.radius))
              },
              outputs: {
                valueChange: radius => this.update('setting.stroke.radius', radius),
                valueSlideChange: ({ value, dragging }) => this.update('setting.stroke.radius', value, { preview: dragging })
              }
            } as ST<NumberInputComponent>
          },
          {
            title: '描边颜色',
            child: {
              widget: colorRowResolver,
              inputs: {
                value: this.stroke$.pipe(map(s => s.color))
              },
              outputs: {
                valueChange: ({ color, dragging }) => this.update('setting.stroke.color', color, { preview: dragging })
              }
            } as ST<ColorRowComponent>
          }
        ]
      },
      // 阴影
      {
        title: '阴影',
        interaction: 'switch',
        icon: 'ss:shadow',
        active: this.setting$.pipe(map(s => s.adjustment.shadow.show)),
        activeChange: val => this.update('setting.adjustment.shadow.show', val),
        children: [
          // 颜色
          {
            title: '颜色',
            child: {
              widget: colorRowResolver,
              inputs: {
                gradient: false,
                value: this.shadow$.pipe(map(s => s.color))
              },
              outputs: {
                valueChange: ({ color, dragging }) => this.update('setting.adjustment.shadow.color', color, { preview: dragging })
              }
            } as ST<ColorRowComponent>
          },
          // 偏移
          {
            title: '偏移',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: this.shadow$.pipe(map(s => s.offset)),
                aceMinValue: 0,
                aceMaxValue: 100,
                useSlide: true
              },
              outputs: this.generateShadowSlideOutputs('setting.adjustment.shadow.offset')
            } as ST<NumberInputComponent>
          },
          // 角度
          {
            title: '角度',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: this.shadow$.pipe(map(s => s.angle)),
                aceMinValue: 0,
                aceMaxValue: 360,
                useSlide: true
              },
              outputs: this.generateShadowSlideOutputs('setting.adjustment.shadow.angle')
            } as ST<NumberInputComponent>
          },
          // 模糊
          {
            title: '模糊',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: this.shadow$.pipe(map(s => s.blur)),
                aceMinValue: 0,
                aceMaxValue: 100,
                useSlide: true
              },
              outputs: this.generateShadowSlideOutputs('setting.adjustment.shadow.blur')
            } as ST<NumberInputComponent>
          }
        ]
      }
      // {
      //   title: '颜色调整',
      //   interaction: 'switch',
      //   icon: 'ss:colorful',
      //   active: this.setting$.pipe(map(s => s.adjustment.color.enable)),
      //   activeChange: val => this.update('setting.adjustment.color.enable', val),
      //   children: [

      //
      //     // 调整
      //     ...this.booleanSetting.map<SettingSchema>(({ key, label }) => ({
      //       title: label,
      //       interaction: 'radio',
      //       active: this.setting$.pipe(map(s => s.adjustment.color[key])),
      //       activeChange: val => this.update(`setting.adjustment.color.${key}`, val)
      //     })),
      //
      //   ]
      // }
    ]
  }
}
