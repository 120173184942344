import { Injectable } from '@angular/core'

import { BehaviorSubject, Subject } from 'rxjs'

import { IProjectStorage } from '@libs/payload'

@Injectable({
  providedIn: 'root'
})
export class ProjectListService {
  private syncer = new BehaviorSubject<IProjectStorage | undefined>(undefined)
  constructor() {}

  get syncer$() {
    return this.syncer.asObservable()
  }

  sync(project: IProjectStorage) {
    this.syncer.next(project)
  }
}
