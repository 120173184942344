import { computed, inject, Injectable, signal, Signal } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'

import _ from 'lodash'
import { distinctUntilChanged } from 'rxjs'

import { generateUid } from '@libs/algorithm'
import { PageListNode } from '@libs/ng-shared/models/page-node'
import { CreatePageParams, ElementTypeEnum, IGroupSetting, IPage, IPageBackground } from '@libs/payload'

import { StageUiStore } from '../store/stage-ui.store'
import { ClipboardService } from './clipboard.service'
import { ProjectService } from './project.service'
import { DB_PAGES_VERSION } from './rxdb/db.schema'

@Injectable({
  providedIn: 'root'
})
export class PageService {
  uiStore = inject(StageUiStore)
  projectService = inject(ProjectService)
  clipboardService = inject(ClipboardService)

  page = this.uiStore.onStagePage as Signal<PageListNode>
  pageId = computed(() => this.page().id)

  selectedIds = signal<string[]>([])
  selectedPages = computed(() => {
    return this.pages().filter((p, i) => this.selectedIds().includes(p.id))
  })

  hasPageLocked = computed(() => this.selectedPages().some(p => p.locked))

  // _pageLinkedList = new ReactiveLinkedList<PageListNode>(this.projectService.pageLinkedListNodes(), this._injector)
  pages = this.projectService.pageLinkedListNodes

  constructor() {
    toObservable(this.uiStore.selectedTarget)
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        if (this.uiStore.selectedTarget() !== 'page') this.selectedIds.set([])
      })
  }

  selectPages(ids: string[]) {
    // this.uiStore.resetSelection('page', ...ids)
    this.selectedIds.set(ids)
  }

  selectAll() {
    this.selectPages(this.pages().map(p => p.id))
  }

  clearSelections() {
    this.selectedIds.set([])
  }

  copyPages() {
    if (this.hasPageLocked()) return
    const data = this.selectedPages().map(
      i =>
        ({
          ...i.page,
          elements: i.elements,
          id: '',
          projectId: '',
          prev: null,
          next: null
        }) as Required<IPage>
    )
    this.clipboardService.write('page', data)
  }

  pastePages(data: IPage[]) {
    if (this.hasPageLocked()) return
    const newPages = data.map(page => {
      const pageId = generateUid()
      const timestamp = Date.now()

      const elementMap = new Map<string, string>()

      const elements = page.elements.map(el => {
        const elNewId = generateUid()
        elementMap.set(el.id, elNewId)
        return {
          ...el,
          id: elNewId,
          pageId
        }
      })
      elements.forEach(el => {
        if (el.parent) {
          el.parent = elementMap.get(el.parent) || el.parent
        }
        if (el.category === ElementTypeEnum.Group) {
          ;(el.setting as IGroupSetting).children = _.compact((el.setting as IGroupSetting).children.map(c => elementMap.get(c)))
        }
      })
      return {
        ..._.omit(page, 'elements'),
        id: pageId,
        elements,
        size: this.uiStore.pageSize(),
        orders: _.compact(page.orders.map(order => elementMap.get(order))),
        name: page.name ? page.name + ' - 副本' : '',
        projectId: this.uiStore.currentProject.id(),
        updatedAt: timestamp
      } as CreatePageParams
    })

    const index = this.pages().indexOf(this.selectedPages()[this.selectedPages().length - 1])

    const pages = this.projectService.addPages(newPages, index + 1)
    this.uiStore.resetSelection('page', pages[0].id)
    this.selectPages(newPages.map(i => i.id))
  }

  copyBackground() {
    this.clipboardService.write('background', this.page().background)
  }

  pasteBackground(data: IPageBackground) {
    this.projectService.updatePage({ id: this.pageId(), background: data })
  }

  duplicatePages() {
    if (this.hasPageLocked()) return
    const newPages = this.selectedPages().map(pageNode => pageNode.duplicate())
    const selectedIndex = this.pages().indexOf(this.selectedPages()[this.selectedPages().length - 1])
    this.clearSelections()

    this.selectPages(newPages.map(i => i.id))
    const res = this.projectService.addPages(
      newPages.map(
        item =>
          ({
            ...item.page,
            name: item.page.name ? item.page.name + ' - 副本' : '',
            updatedAt: Date.now(),
            elements: item.elements
          }) as CreatePageParams
      ),
      selectedIndex + 1
    )
    this.uiStore.resetSelection('page', res[0].id)
  }

  deleteBackground() {
    this.projectService.updatePage({
      id: this.page().id,
      background: {
        locked: this.page().background.locked,
        color: undefined,
        image: undefined
      }
    })
  }

  lockBackground() {
    this.projectService.updatePage({
      id: this.page().id,
      background: {
        ...this.page().background,
        locked: true
      }
    })
  }

  unlockBackground() {
    this.projectService.updatePage({
      id: this.page().id,
      background: {
        ...this.page().background,
        locked: false
      }
    })
  }

  deletePages() {
    if (this.hasPageLocked()) return
    const firstIndex = this.pages().indexOf(this.selectedPages()[0])
    const lastIndex = this.pages().indexOf(this.selectedPages()[this.selectedPages().length - 1])
    const firstDeletedPageNode = this.pages()[firstIndex - 1]
    const lastDeletePageNode = this.pages()[lastIndex + 1]
    const newSelectPage = lastDeletePageNode ?? firstDeletedPageNode

    this.projectService.deletePages(this.selectedIds())

    setTimeout(() => {
      if (newSelectPage) {
        this.selectPages([newSelectPage.id])
        this.uiStore.resetSelection('page', newSelectPage.id)
      } else {
        if (this.pages().length === 0) {
          this.addPage(0)
        }
      }
    })
  }

  changePageOrder(ids: string[], position: number) {
    if (this.hasPageLocked()) return
    const pageList = this.pages()
    const pages = _.compact(ids.map(id => pageList.find(p => p.id === id)))

    const _pageList = pageList.filter(page => !ids.includes(page.id))
    _pageList.splice(position, 0, ...pages)

    this.projectService.resetPageOrders(_pageList.map(p => p.id))
  }

  addPage(index?: number) {
    index = index ?? this.pages().length
    const newPage = this.projectService.addPage(
      {
        id: generateUid(),
        projectId: this.uiStore.currentProject.id(),
        name: '',
        locked: false,
        visible: true,
        background: {
          locked: false,
          color: '#FFFFFF'
        },
        size: this.uiStore.pageSize() || { width: 1440, height: 900 },
        theme: this.uiStore.theme(),
        updatedAt: Date.now(),
        orders: [],
        elements: [],
        version: DB_PAGES_VERSION
      },
      index
    )
    if (newPage) {
      this.selectPages([newPage.id])
      this.uiStore.resetSelection('page', newPage.id)
    }
  }

  showPages(ids: string[]) {
    this.projectService.updatePages(
      ids.map(id => ({
        id,
        visible: true
      }))
    )
  }

  hidePages(ids: string[]) {
    this.projectService.updatePages(
      ids.map(id => ({
        id,
        visible: false
      }))
    )
  }

  lockPages(ids: string[]) {
    this.projectService.updatePages(
      ids.map(id => ({
        id,
        locked: true
      }))
    )
  }

  unlockPages(ids: string[]) {
    this.projectService.updatePages(
      ids.map(id => ({
        id,
        locked: false
      }))
    )
  }
}
