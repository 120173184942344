import { computed, inject, Injectable, signal, Signal } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'

import { fromEvent } from 'rxjs'

import { GroupElementTreeNode } from '@libs/ng-shared/models/element-node'
import { PageListNode } from '@libs/ng-shared/models/page-node'

import { StageUiStore } from '../store/stage-ui.store'
import { ElementService } from './element.service'
import { PageService } from './page.service'
import { WorkspaceService } from './workspace.service'

@Injectable({
  providedIn: 'root'
})
export class HotkeyService {
  selectedIds = signal<string[]>([])

  private uiStore = inject(StageUiStore)
  private elementService = inject(ElementService)
  private pageService = inject(PageService)
  private workspaceService = inject(WorkspaceService)

  private page = this.uiStore.onStagePage as Signal<PageListNode>
  private pageId = computed(() => this.page().id)

  constructor() {
    // effect(() => {
    //   console.log('hotkeys: ', this.uiStore.keydown())
    //   this.onHotkey(this.uiStore.keydown())
    // })
    toObservable(this.uiStore.keydown)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        console.log('hotkeys: ', this.uiStore.keydown())
        this.onHotkey(this.uiStore.keydown())
      })

    fromEvent<ClipboardEvent>(document, 'paste')
      .pipe(takeUntilDestroyed())
      .subscribe((e: ClipboardEvent) => {
        if (document.activeElement?.tagName !== 'BODY') {
          // console.log(e)
          if (!e.clipboardData?.types.includes('text/plain')) e.preventDefault()
          return
        }
        if (e.clipboardData) this.extractData(e.clipboardData)
      })
  }

  onKeydown($event: KeyboardEvent) {
    if (document.activeElement?.tagName !== 'BODY') return

    if (!$event.repeat || ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes($event.key)) {
      this.uiStore.setKey($event)
    }

    this.preventDefault($event)
  }

  async onHotkey(keys: Set<string>) {
    const target = this.uiStore.selectedTarget()
    if (keys.has('Delete') || keys.has('Backspace')) {
      if (target === 'element') {
        this.elementService.deleteElements(...this.elementService.selectedIds())
      } else if (target === 'page') {
        this.pageService.deletePages()
      } else if (target === 'background') {
        this.pageService.deleteBackground()
      }
    } else if (keys.has('Shift') && (keys.has('Control') || keys.has('Meta'))) {
      if (keys.has('Z') || keys.has('z')) {
        this.uiStore.redo()
      } else if (keys.has('G') || keys.has('g')) {
        this.elementService.ungroupElement(this.uiStore.selectedRootElements()[0] as GroupElementTreeNode)
      }
    } else if (keys.has('Alt') && (keys.has('Control') || keys.has('Meta'))) {
      if (keys.has(']') || keys.has('‘')) {
        if (this.elementService.canMoveUp()) this.elementService.moveTop()
      } else if (keys.has('[') || keys.has('“')) {
        if (this.elementService.canMoveDown()) this.elementService.moveBottom()
      }
    } else if (keys.has('Control') || keys.has('Meta')) {
      if (keys.has('a')) {
        if (target === 'element' || target === 'background') {
          this.elementService.selectAll()
        } else if (target === 'page') {
          this.pageService.selectAll()
        }
      } else if (keys.has('c')) {
        if (target === 'element') {
          this.elementService.copyElements()
        } else if (target === 'page') {
          this.pageService.copyPages()
        } else if (target === 'background') {
          this.pageService.copyBackground()
        }
      } else if (keys.has('v')) {
        // handle by paste event
        // this.workspaceService.paste()
      } else if (keys.has('d')) {
        if (target === 'element') {
          this.elementService.duplicateElements()
        } else if (target === 'page') {
          this.pageService.duplicatePages()
        }
      } else if (keys.has('x')) {
        this.elementService.copyElements()
        this.elementService.deleteElements(...this.uiStore.selectedRootElements().map(el => el.id))
      } else if (keys.has('z')) {
        this.uiStore.undo()
      } else if (keys.has('g')) {
        this.elementService.createGroupElement(this.uiStore.selectedRootElements())
      } else if (keys.has(']')) {
        if (this.elementService.canMoveUp()) this.elementService.moveUp()
      } else if (keys.has('[')) {
        if (this.elementService.canMoveDown()) this.elementService.moveDown()
      }
    } else if (keys.has('Shift')) {
      if (keys.has('ArrowUp')) {
        this.elementService.moveElement('y', -10)
      } else if (keys.has('ArrowDown')) {
        this.elementService.moveElement('y', 10)
      } else if (keys.has('ArrowLeft')) {
        this.elementService.moveElement('x', -10)
      } else if (keys.has('ArrowRight')) {
        this.elementService.moveElement('x', 10)
      }
    } else if (keys.has('ArrowUp')) {
      this.elementService.moveElement('y', -1)
    } else if (keys.has('ArrowDown')) {
      this.elementService.moveElement('y', 1)
    } else if (keys.has('ArrowLeft')) {
      this.elementService.moveElement('x', -1)
    } else if (keys.has('ArrowRight')) {
      this.elementService.moveElement('x', 1)
    }
  }

  preventDefault(e: Event) {
    const keys = this.uiStore.keydown()
    if (
      keys.has('F1') ||
      keys.has('F2') ||
      keys.has('F3') ||
      keys.has('F4') ||
      keys.has('F5') ||
      keys.has('F6') ||
      keys.has('F7') ||
      keys.has('F8') ||
      keys.has('F9') ||
      keys.has('F10') ||
      keys.has('F11') ||
      keys.has('F12') ||
      (keys.has('Shift') && keys.has('Control') && (keys.has('I') || keys.has('J'))) ||
      (keys.has('Meta') && keys.has('Alt') && (keys.has('∆') || keys.has('Dead'))) ||
      ((keys.has('Control') || keys.has('Meta')) &&
        (keys.has('r') ||
          keys.has('v') ||
          keys.has('f') ||
          keys.has('p') ||
          keys.has('q') ||
          keys.has('1') ||
          keys.has('2') ||
          keys.has('3') ||
          keys.has('4') ||
          keys.has('5') ||
          keys.has('6') ||
          keys.has('7') ||
          keys.has('8') ||
          keys.has('9') ||
          keys.has('0')))
    ) {
    } else {
      e.preventDefault()
    }
  }

  async extractData(sourceData: DataTransfer) {
    // https://github.com/Evercoder/clipboard-inspector/blob/main/index.jsx
    console.log(sourceData)
    if (sourceData.types.includes('Files')) {
      const file = sourceData.files?.[0]
      if (file) {
        this.workspaceService.paste({ sign: 'editorup', type: 'image', data: file })
      }
    } else {
      let text = ''
      let htmlText = ''
      if (sourceData.types.includes('text/html')) {
        const doc = new DOMParser().parseFromString(sourceData.getData('text/html'), 'text/html')
        const spanElement = doc.querySelector('span')
        // result will start with meta tag in macos chrome
        htmlText = spanElement?.dataset['editorupClipboard'] || ''
        if (htmlText) {
          try {
            const result = JSON.parse(htmlText)
            if (result.sign === 'editorup') {
              this.workspaceService.paste(result)
              return
            }
          } catch (e) {
            console.log('failed to parse', e)
          }
        } else {
          htmlText = spanElement?.textContent || ''
        }
      }
      if (sourceData.types.includes('text/plain')) {
        text = sourceData.getData('text/plain')
      }
      if (text || htmlText) {
        this.workspaceService.paste({ sign: 'editorup', type: 'text', data: text || htmlText })
      }
    }
  }
}
