import { computed, inject, InjectionToken, Injector, runInInjectionContext, signal, Signal } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'

import { HotToastService } from '@ngxpert/hot-toast'
import _ from 'lodash'
import {
  ReplicationPullHandler,
  ReplicationPullHandlerResult,
  RxCollection,
  RxConflictHandlerInput,
  RxConflictHandlerOutput,
  RxDatabase,
  RxDocument,
  RxReplicationPullStreamItem,
  WithDeleted
} from 'rxdb'
import type { RxCollectionCreator, RxReplicationWriteToMasterRow } from 'rxdb/dist/types/types'
import { ReplicationPushHandler } from 'rxdb/dist/types/types/plugins/replication'
import { replicateRxCollection, RxReplicationState } from 'rxdb/plugins/replication'
import { BehaviorSubject, bufferToggle, filter, firstValueFrom, map, mergeMap, Observable, of, startWith, Subject, take, tap } from 'rxjs'

import { generateUid } from '@libs/algorithm'
import { AppStore } from '@libs/ng-core/store'
import { env } from '@libs/ng-env'
import { Checkpoint, IPageElementBase, IPageStorage } from '@libs/payload'

import { DocMethods, EditorUpAppCollections, PageCollection, PageElementCollection } from '../../interfaces'
import { EDITOR_API } from '../api'
import { PAGE_ELEMENT_SCHEMA, PAGE_SCHEMA, PROJECT_SCHEMA } from './db.schema'
import { elementMigrations } from './migrations/elements/migration'
import { pageMigrations } from './migrations/pages/migration'

export const APP_DB = new InjectionToken<RxDatabase<EditorUpAppCollections>>('db')

function pageConflictHandler(
  this: RxDatabase<{ pages: PageCollection }>,
  i: RxConflictHandlerInput<IPageStorage>
): Promise<RxConflictHandlerOutput<IPageStorage>> {
  const keys = ['id', 'name', 'projectId', 'visible', 'locked', 'theme', 'background', 'size', 'prev', 'next', 'version', '_deleted', 'orders']
  if (_.isEqual(_.pick(i.newDocumentState, keys), _.pick(i.realMasterState, keys))) {
    return Promise.resolve({
      isEqual: true
    })
  } else {
    if (i.realMasterState.version > i.newDocumentState.version) {
      return this.pages.startMigration(10).then(() => {
        return {
          isEqual: false,
          documentData: i.realMasterState
        }
      })
    } else {
      return Promise.resolve({
        isEqual: false,
        documentData: i.realMasterState
      })
    }
  }
}

function elementConflictHandler(
  this: RxDatabase<{ elements: PageElementCollection }>,
  i: RxConflictHandlerInput<IPageElementBase>
): Promise<RxConflictHandlerOutput<IPageElementBase>> {
  const keys = [
    'id',
    'pageId',
    'prev',
    'next',
    'category',
    'setting',
    'position',
    'size',
    'scale',
    'visible',
    'locked',
    'rotation',
    'updatedAt',
    'parent',
    'version',
    '_deleted'
  ]
  if (_.isEqual(_.pick(i.newDocumentState, keys), _.pick(i.realMasterState, keys))) {
    return Promise.resolve({
      isEqual: true
    })
  } else {
    if (i.realMasterState.version > i.newDocumentState.version) {
      return this.elements.startMigration(10).then(() => {
        return {
          isEqual: false,
          documentData: i.realMasterState
        }
      })
    } else {
      return Promise.resolve({
        isEqual: false,
        documentData: i.realMasterState
      })
    }
  }
}

export class CollectionBase<RxDocType extends { id: string; updatedAt: number; version?: number }> {
  leaderCheckpoint = computed(() => {
    return this.checkpoint()
  })
  initialized$: Observable<boolean>
  isLeader$: Observable<boolean>
  docs: Signal<RxDocument<RxDocType>[]> | undefined
  // protected token = inject(AUTH_SERVICE_TOKEN)
  protected api = inject(EDITOR_API)
  protected toasts = inject(HotToastService)
  protected pullStream = new Subject<RxReplicationPullStreamItem<RxDocType, Checkpoint>>()
  protected replicationState: RxReplicationState<RxDocType, Checkpoint> | undefined
  protected readonly name: string

  protected savingState = new BehaviorSubject<boolean>(false)
  // protected db = inject(APP_DB)
  protected checkpoint = signal<Checkpoint | null>(null)
  protected _collection = signal<RxCollection<RxDocType> | undefined>(undefined)
  private _initialized = signal(false)
  private firstOpen = true
  private eventSource: EventSource | undefined
  private _isLeader = new BehaviorSubject<boolean>(false)
  private clientId = ''
  private appStore = inject(AppStore)
  private injector = inject(Injector)

  constructor(
    protected db: RxDatabase<{ [key: string]: RxCollection<RxDocType> }>,
    name: string,
    protected version: number,
    protected autoStart = true,
    readonly projectId: string = ''
  ) {
    this.name = name
    this.initialized$ = toObservable(this._initialized)
    this.isLeader$ = this._isLeader.asObservable()
    this.addCollection().then(collection => {
      this.replicationState = this.createReplication(this.db[this.name], name === 'elements' ? 20 : 50)
      this._collection.set(collection)
      this._initialized.set(true)
    })

    this.db.waitForLeadership().then(() => {
      this._isLeader.next(true)
      if (this.autoStart) {
        this.clientId = generateUid()
        this.eventSource = this.initEventSource(this.clientId)
      }
    })

    toObservable(this._collection)
      .pipe(
        filter(collection => !!collection),
        map(collection => collection as RxCollection),
        take(1)
      )
      .subscribe(collection => {
        runInInjectionContext(this.injector, () => {
          this.docs = collection.find().$$ as Signal<RxDocument<RxDocType>[]>
          const savingState = this.savingState.asObservable().pipe(startWith(true))
          collection.checkpoint$
            .pipe(
              takeUntilDestroyed(),
              tap(checkpoint => {
                console.log(`******${this.name as string} CheckPoint **********`, checkpoint)
              }),
              bufferToggle(
                savingState.pipe(
                  tap(state => {
                    console.log('saving:', state)
                  }),
                  filter(state => state)
                ),
                () => savingState.pipe(filter(state => !state))
              ),
              filter(checkpoints => checkpoints.length > 0)
            )
            .subscribe(checkpoints => {
              const lastCheckpoint = checkpoints.pop()
              this.checkpoint.set(lastCheckpoint)
            })
        })
      })
  }

  get migrationState$() {
    return this.initialized$.pipe(
      mergeMap(init => {
        if (init) {
          return (this._collection() as RxCollection).getMigrationState().$
        } else {
          return of(null)
        }
      })
    )
  }

  get collection() {
    if (this._collection() === undefined) {
      throw new Error('Collection not initialized')
    } else {
      return this._collection() as RxCollection<RxDocType, DocMethods<RxDocType>>
    }
  }

  get isSaving$() {
    return this.savingState.asObservable()
  }

  get replicaState() {
    if (this.replicationState === undefined) {
      throw new Error('Replication not initialized')
    }
    return this.replicationState as RxReplicationState<RxDocType, Checkpoint>
  }

  // get collectionName() {
  //   return `${this.name}${this.projectId ? `-${this.projectId}` : ''}`
  // }

  destroy() {
    // this.replicaState.cancel()
    if (this.eventSource) {
      this.eventSource.onerror = () => {}
      this.eventSource.close()
    }

    // this.db.destroy()
  }

  startReplication() {
    if (this.eventSource) {
      this.eventSource.close()
    }
    this.clientId = generateUid()
    this.eventSource = this.initEventSource(this.clientId)
    this.replicaState.start()
  }

  protected handlePull: ReplicationPullHandler<RxDocType, Checkpoint> = async (checkpointOrNull: Checkpoint | undefined, batchSize: number) => {
    let pullParams = {
      batchSize,
      id: '',
      updatedAt: 0
    }
    if (checkpointOrNull) {
      pullParams = {
        ...pullParams,
        ...checkpointOrNull
      }
    }

    const data = await firstValueFrom(this.api.pullData<RxDocType>(this.name as string, this.projectId, pullParams))

    return {
      documents: data.documents,
      checkpoint: data.checkpoint
    } as ReplicationPullHandlerResult<RxDocType, Checkpoint>
  }

  protected handlePush: ReplicationPushHandler<RxDocType> = async (docs: RxReplicationWriteToMasterRow<RxDocType>[]) => {
    try {
      const data = await firstValueFrom(
        this.api.pushData(
          this.name as string,
          this.projectId,
          this.clientId,
          docs.map(doc => ({
            assumedMasterState: doc.assumedMasterState
              ? {
                  id: doc.assumedMasterState.id,
                  updatedAt: doc.assumedMasterState.updatedAt ?? 0,
                  version: doc.assumedMasterState.version
                }
              : undefined,
            newDocumentState: doc.newDocumentState
          }))
        )
      )
      return data
    } catch (e: any) {
      if (e.code === 10004) {
        this.toasts.error('您当前的项目版本过低，请刷新页面')
      }
      throw e
    }
  }

  protected checkDocVersion(collection: RxCollection, version: number, doc: WithDeleted<RxDocType>): WithDeleted<RxDocType> {
    let docVersion = (doc.version || 0) as number
    let migrateDoc = doc
    if (docVersion > this.version) {
      // 刷新页面
      this.toasts.error('您当前的项目版本过低，请刷新页面')
      throw new Error(`Document version is higher than current version: ${docVersion} > ${version}`)
    }
    while (docVersion < this.version) {
      if (this.name === 'pages') {
        migrateDoc = pageMigrations[++docVersion](migrateDoc, collection)
      } else {
        migrateDoc = elementMigrations[++docVersion](migrateDoc, collection)
      }
      migrateDoc.updatedAt = Date.now()
    }
    return migrateDoc
  }

  protected async addCollection() {
    if (this.db[this.name]) {
      return this.db[this.name]
    }
    let config: { [key: string]: RxCollectionCreator }
    switch (this.name) {
      case 'projects':
        config = {
          projects: {
            schema: PROJECT_SCHEMA
          }
        }
        break
      case 'pages':
        config = {
          pages: {
            schema: PAGE_SCHEMA,
            autoMigrate: true,
            migrationStrategies: pageMigrations,
            conflictHandler: pageConflictHandler.bind(this.db as unknown as RxDatabase<{ pages: PageCollection }>)
          }
        }
        break
      case 'elements':
        config = {
          elements: {
            schema: PAGE_ELEMENT_SCHEMA,
            autoMigrate: true,
            conflictHandler: elementConflictHandler.bind(
              this.db as unknown as RxDatabase<{
                elements: PageElementCollection
              }>
            ),
            migrationStrategies: elementMigrations
          }
        }
        break
      default:
        config = {}
        break
    }
    const collections = await this.db.addCollections(config)
    return collections[this.name]
  }

  protected createReplication(collection: RxCollection, batchSize = 50) {
    return replicateRxCollection<RxDocType, Checkpoint>({
      collection: collection,
      replicationIdentifier: `${this.projectId}-${env.api.baseUrl}/sync/${this.name}`,
      pull: {
        batchSize: batchSize,
        modifier: this.checkDocVersion.bind(this, collection, this.version),
        handler: this.handlePull,
        stream$: this.pullStream.asObservable()
      },
      push: {
        batchSize: batchSize,
        handler: this.handlePush
      },
      // headers: {
      //   Authorization: `Bearer ${this.token.get()?.token}` || ''
      // },
      autoStart: this.autoStart,
      waitForLeadership: true,
      live: true
    })
  }

  protected initEventSource(clientId: string) {
    const { baseUrl } = env.api
    // const baseUrl = 'http://localhost:7000/'
    const eventSource = new EventSource(
      `${baseUrl}${baseUrl.endsWith('/') ? '' : '/'}sync/${this.name as string}/stream/${this.projectId || this.appStore.user()?.id}?clientId=${clientId}`,
      {
        withCredentials: false
      }
    )

    eventSource.onmessage = event => {
      const eventData = JSON.parse(event.data)
      if (eventData.checkpoint && eventData.documents) {
        this.pullStream.next({
          documents: eventData.documents,
          checkpoint: eventData.checkpoint
        })
      } else if (eventData.pageOrders) {
        // 更新页面顺序
        // this.collection.upsertLocal('orders', eventData.pageOrders)
      }
    }

    eventSource.onerror = event => {
      eventSource.close()
      this.initEventSource(clientId)
    }

    eventSource.onopen = () => {
      if (this.firstOpen) {
        this.firstOpen = false
      } else {
        this.pullStream.next('RESYNC')
      }
    }

    return eventSource
  }
}
