import { Gradient, Stop, Svg } from '@svgdotjs/svg.js'
import { cloneDeep } from 'lodash'
import { Observable } from 'rxjs'

import { IColor, isGradientColor } from '@editorup/charts'
import { parseSingleToString } from '@libs/ng-shared/utils'

/**
 * 观测一个颜色对象，根据颜色对象的类型填充颜色
 * @param draw
 * @param fill$
 * @param callback
 * @private
 */
export function fillColor(draw: Svg, fill$: Observable<IColor>, callback: (color: string | Gradient) => void) {
  let gradient: Gradient
  let stops: Stop[] = []
  return fill$.subscribe(fill => {
    if (isGradientColor(fill)) {
      const colorStops = cloneDeep(fill.color.colorStops).sort((a, b) => a.offset - b.offset)
      // 如果颜色的数量和之前的颜色数量相同，则更新颜色
      if (stops.length === colorStops.length) {
        stops.forEach((stop, index) => {
          stop.update({
            ...colorStops[index],
            opacity: colorStops[index].opacity * fill.opacity
          })
        })
      } else {
        // 否则，删除之前的渐变，重新创建渐变
        gradient?.remove()
        stops = []
        // 创建新的渐变
        gradient = draw
          .gradient(fill.color.type, add => {
            colorStops.forEach(stop => {
              stops.push(add.stop(stop.offset, stop.color, stop.opacity * fill.opacity))
            })
          })
          .size('100%', '100%')
        // 调用回调函数
        callback(gradient)
      }
      // 设置渐变的角度
      const { x1, y1, x2, y2 } = calculateGradientCoordinates(fill.color.angle)
      gradient.attr('x1', x1).attr('y1', y1).attr('x2', x2).attr('y2', y2)
    } else {
      // 如果颜色是单色，则直接调用回调函数
      gradient?.remove()
      // 清空之前的渐变
      if (stops.length) {
        stops = []
      }
      // 清空之前的渐变
      callback(parseSingleToString(fill))
    }
  })
}

/**
 * 计算SVG线性渐变的坐标点
 * @param {number} angle - 渐变的角度（以度为单位）
 * @returns  包含x1, y1, x2, y2的对象，值在0到1之间
 */
function calculateGradientCoordinates(angle: number) {
  // 将角度转换为弧度
  const radians = (angle * Math.PI) / 180

  // 计算终点坐标
  let x2 = Math.cos(radians) * 0.5 + 0.5
  let y2 = Math.sin(radians) * 0.5 + 0.5

  // 计算起点坐标（与终点相对）
  let x1 = 1 - x2
  let y1 = 1 - y2

  // 确保所有值都在0到1之间
  x1 = Math.max(0, Math.min(1, x1))
  y1 = Math.max(0, Math.min(1, y1))
  x2 = Math.max(0, Math.min(1, x2))
  y2 = Math.max(0, Math.min(1, y2))

  return { x1, y1, x2, y2 }
}

export function getDashArray(style: 'solid' | 'dashed' | 'dotted') {
  return style === 'solid' ? 'none' : style === 'dashed' ? '24,4' : '4,4'
}
