import { computed, inject } from '@angular/core'

import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals'
import _ from 'lodash'

import { ElementTypeEnum, IPageElementBase, IParaSetting, IPosition, ISize, ITextSetting } from '@libs/payload'

import { StageUiStore } from './stage-ui.store'

interface ITextState {
  range: Range | null
  editing: boolean
  autoEditing: boolean
  shadowSetting: ITextSetting
  editPosition: IPosition
}

export const TextStore = signalStore(
  withState<ITextState>(() => {
    return {
      range: null,
      editing: false,
      autoEditing: false,
      shadowSetting: null as unknown as ITextSetting,
      editPosition: { x: 0, y: 0 }
    }
  }),
  withComputed((store, uiStore = inject(StageUiStore)) => ({
    element: computed<IPageElementBase<ElementTypeEnum.Text> | null>(() => {
      const selectedElements = uiStore.selectedElements()
      let selectedElement = null

      if (selectedElements.length === 1) {
        selectedElement = selectedElements[0]
        if (selectedElement?.category === 'text') return selectedElement as IPageElementBase<ElementTypeEnum.Text>
      }
      return null
    })
  })),
  withComputed((store, uiStore = inject(StageUiStore)) => ({
    id: computed(() => store.element()?.id),
    locked: computed(() => store.element()?.locked),
    hasParent: computed(() => !!store.element()?.parent),
    multipleSelected: computed(() => uiStore.selectedElements().length > 1),
    setting: computed<ITextSetting | null>(
      () => {
        const element = store.element()
        if (!element) return null
        const setting = uiStore.interacting.shadowData.setting() as ITextSetting
        if (!_.isEmpty(setting)) {
          return setting
        } else {
          return element.setting
        }
      },
      {
        equal: (a: ITextSetting | null, b: ITextSetting | null) => a?.version === b?.version
      }
    ),
    position: computed<IPosition>(
      () => {
        const element = store.element()
        if (!element) return { x: 0, y: 0 }

        const shadowPosition = uiStore.interacting.shadowData.position()
        if (!_.isEmpty(shadowPosition)) {
          return shadowPosition as IPosition
        } else {
          return element.position
        }
      },
      {
        equal: (a: IPosition, b: IPosition) => a.x === b.x && a.y === b.y
      }
    ),
    size: computed<ISize>(
      () => {
        const element = store.element()
        if (!element) return { width: 0, height: 0 }

        const shadowSize = uiStore.interacting.shadowData.size()
        if (!_.isEmpty(shadowSize)) {
          return shadowSize as ISize
        } else {
          return element.size
        }
      },
      {
        equal: (a: ISize, b: ISize) => a.width === b.width && a.height === b.height
      }
    ),
    rotation: computed(() => {
      const element = store.element()
      if (!element) return 0

      const shadowRotation = uiStore.interacting.shadowData.rotation()
      if (!_.isUndefined(shadowRotation)) {
        return shadowRotation
      } else {
        return element.rotation
      }
    }),
    scale: computed(() => {
      const element = store.element()
      if (!element) return 1

      const shadowScale = uiStore.interacting.shadowData.scale()
      if (!_.isUndefined(shadowScale)) {
        return shadowScale as number
      } else {
        return element.scale
      }
    }),
    parent: computed(() => {
      const element = store.element()
      if (!element || !element.parent) return null

      return uiStore.selectedRootElements().find(i => i.id === element.parent)
    })
  })),
  withComputed((store, uiStore = inject(StageUiStore)) => ({
    absolutePosition: computed<IPosition>(
      () => {
        console.log('--------------------------------------', store.position())
        const parent = store.parent()
        if (!parent) return store.position()
        return {
          x: store.position().x * parent.scale + parent.position.x,
          y: store.position().y * parent.scale + parent.position.y
        }
      },
      {
        equal: (a: IPosition, b: IPosition) => a.x === b.x && a.y === b.y
      }
    ),
    absoluteRotation: computed(() => {
      const parent = store.parent()
      if (!parent) return store.rotation()
      return store.rotation() + parent.rotation
    }),
    absoluteScale: computed(() => {
      const parent = store.parent()
      if (!parent) return store.scale()
      return store.scale() * parent.scale
    }),
    /**
     * @returns {number[]} The selected paragraphs' indexes
     */
    selectedParagraphs: computed<number[]>(() => {
      const range = store.range()
      // console.log(store.element(), range)
      if (!store.element() || !range) return []

      const startPara = range.startContainer.parentElement?.closest('ace-text-input-paragraph') as Element
      const endPara = range.endContainer.parentElement?.closest('ace-text-input-paragraph') as Element

      if (!startPara) {
        console.error('range.startContainer.parentElement not found')
        return []
      }

      const paraElements = Array.from((startPara.parentElement as Element).children).filter(i => i.tagName === 'ACE-TEXT-INPUT-PARAGRAPH')

      const startParaIndex = paraElements.indexOf(startPara)
      const endParaIndex = paraElements.indexOf(endPara)

      if (range.collapsed || startPara === endPara) {
        // No text selected, only cursor or only text within one p element selected
        return [startParaIndex]
      } else {
        // Select text across multiple p elements
        const paragraphs: number[] = []
        for (let i = startParaIndex; i <= endParaIndex; i++) {
          paragraphs.push(i)
        }
        return paragraphs
      }
    })
  })),
  withComputed(store => ({
    /**
     * @returns {Map<number, number[]>} The selected chars' indexes
     * @description The key is the paragraph index, the value is the char indexes
     */
    selectedChars: computed<Map<number, number[]>>(() => {
      const range = store.range()
      if (!store.element() || !range) return new Map()

      const setting = store.setting() as ITextSetting

      const startParaIndex = store.selectedParagraphs()[0]
      const endParaIndex = store.selectedParagraphs()[store.selectedParagraphs().length - 1]

      const startChar = range.startContainer.parentElement?.closest('ace-text-input-char') as Element
      const endChar = range.endContainer.parentElement?.closest('ace-text-input-char') as Element

      if (!startChar || !endChar) {
        console.error('range.startContainer.parentElement not found')
        return new Map([[0, [0]]])
      }

      const charsInStartPara = Array.from((startChar.parentElement as Element).children).filter(i => i.tagName === 'ACE-TEXT-INPUT-CHAR')
      const charsInEndPara = Array.from((endChar.parentElement as Element).children).filter(i => i.tagName === 'ACE-TEXT-INPUT-CHAR')

      const startCharIndex = charsInStartPara.indexOf(startChar)
      const endCharIndex = charsInEndPara.indexOf(endChar)

      if (range.collapsed || range.startContainer === range.endContainer) {
        // No text selected, only cursor or only text within one span element selected
        return new Map([[startParaIndex, [startCharIndex]]])
      } else if (startParaIndex === endParaIndex) {
        // Select text across multiple span elements within one p element
        const indexes = Array.from({ length: endCharIndex - startCharIndex + 1 }, (item, i) => startCharIndex + i)
        return new Map([[startParaIndex, indexes]])
      } else {
        // Select text across multiple p elements
        const chars = new Map<number, number[]>()
        for (let i = startParaIndex; i <= endParaIndex; i++) {
          if (i === startParaIndex) {
            chars.set(
              i,
              Array.from({ length: setting.paragraphs[i].chars.length - startCharIndex }, (item, j) => startCharIndex + j)
            )
          } else if (i === endParaIndex) {
            chars.set(
              i,
              Array.from({ length: endCharIndex + 1 }, (item, j) => j)
            )
          } else {
            chars.set(
              i,
              Array.from({ length: setting.paragraphs[i].chars.length }, (item, j) => j)
            )
          }
        }
        return chars
      }
    })
  })),
  withComputed(store => ({
    /**
     * @returns {ITextSetting & { scale: number }} The selected text's config
     */
    selectedConfig: computed<(ITextSetting & { scale: number }) | null>(() => {
      const element = store.element()
      if (!element) return null

      const data = element.setting

      const paragraphs = store.selectedParagraphs()
      const chars = store.selectedChars()

      // selected the whole text
      if (chars.size === 0)
        return {
          ...data,
          scale: element.scale
        }

      // update fontsize after element scale changed
      const config: ITextSetting & { scale: number } = {
        ...data,
        scale: element.scale,
        paragraphs: []
      }
      paragraphs.forEach(index => {
        const newParagraph: IParaSetting = { ...data.paragraphs[index], chars: [] }
        for (const i of chars.get(index) || []) {
          newParagraph.chars.push(data.paragraphs[index].chars[i])
        }
        config.paragraphs.push(newParagraph)
      })
      return config
    })
  })),
  withMethods(store => ({
    updateRange: (range: Range | null) => patchState(store, { range }),
    updateEditing: (editing: boolean) => patchState(store, { editing }),
    updateAutoEditing: (autoEditing: boolean) => patchState(store, { autoEditing }),
    updateShadowSetting: (shadowSetting: ITextSetting) => patchState(store, { shadowSetting }),
    updateEditPosition: (position: IPosition) => patchState(store, { editPosition: position })
  }))
)
