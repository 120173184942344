import { computed, effect, inject, Injectable, signal, Signal } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'

import { produce } from 'immer'
import _, { cloneDeep } from 'lodash'
import { pi, round, subtract } from 'mathjs'
import { combineLatest, distinctUntilChanged } from 'rxjs'

import {
  boundingRect,
  boundingRects,
  childrenRealSetting,
  childrenRelativeSetting,
  generateUid,
  rectVertices,
  scaleSize,
  transformPosition
} from '@libs/algorithm'
import { GroupElementTreeNode, PageElementTreeNode, PageListNode } from '@libs/ng-shared/models'
import {
  CreatePageElementParams,
  CreateVirtualElementParams,
  ElementType,
  ElementTypeEnum,
  GroupType,
  IChartSetting,
  IChildElement,
  IImageSetting,
  ILineSetting,
  IMaterial,
  IPageElementBase,
  IPageElementSetting,
  IPosition,
  IRootElement,
  IShapeSetting,
  ISize,
  ITextSetting
} from '@libs/payload'

import { StageUiStore } from '../store/stage-ui.store'
import { EDITOR_API } from './api'
import { ClipboardService } from './clipboard.service'
import { ProjectService } from './project.service'
import { DB_ELEMENTS_VERSION } from './rxdb/db.schema'

@Injectable({
  providedIn: 'root'
})
export class ElementService {
  alignDistributeStatus = computed(() => this.getAlignDistributeStatus(this.uiStore.selectedRootElements()))

  layers = signal<PageElementTreeNode[]>([])

  selectedIds = signal<string[]>([])
  selectedLayers = computed(() => this.layers().filter(i => this.selectedIds().includes(i.id)))
  hasSelectedLocked = computed(() => this.selectedLayers().some(i => i.locked))

  _projectService = inject(ProjectService)
  _api = inject(EDITOR_API)
  uiStore = inject(StageUiStore)
  clipboardService = inject(ClipboardService)

  page = this.uiStore.onStagePage as Signal<PageListNode>
  pageId = computed(() => this.page().id)

  selectedTarget = this.uiStore.selectedTarget

  constructor() {
    combineLatest([toObservable(this.selectedTarget), toObservable(this.uiStore.selectedRootElements)])
      .pipe(distinctUntilChanged())
      .subscribe(([target, els]) => {
        if (target !== 'element') this.selectedIds.set([])
        else {
          this.selectedIds.set(els.map(el => el.id))
        }
      })

    effect(
      () => {
        this.layers.set([...(this.page()?.elementTreeNodes() || [])].reverse())
      },
      { allowSignalWrites: true }
    )
  }

  selectElements(ids: string[]) {
    this.selectedIds.set(ids)
    this.uiStore.resetSelection('element', ...ids)
  }

  selectAll() {
    this.selectElements(
      this.layers()
        .filter(i => i.visible)
        .map(i => i.id)
    )
  }

  deleteElements(...eIds: string[]) {
    // remove selected element if it is in the deleted elements
    if (this.selectedTarget() === 'element') {
      const selectedIds = this.uiStore.selectedIds().filter(i => !eIds.includes(i))
      if (selectedIds.length !== this.uiStore.selectedIds().length) {
        if (selectedIds.length > 0) {
          this.selectElements(selectedIds)
        } else {
          this.uiStore.resetSelection('background')
        }
      }
    }
    return this._projectService.deletePageElements(this.pageId(), ...eIds)
  }

  copyElements() {
    if (this.hasSelectedLocked()) return

    const data = this.selectedLayers().map(i => {
      const target = this.page().getElementNodeById(i.id)
      if (target) {
        return target.duplicate() as CreatePageElementParams
      } else {
        return undefined
      }
    })
    this.clipboardService.write('element', _.compact(data).reverse())
  }

  pasteElements(data: CreatePageElementParams[]) {
    const timestamp = Date.now()
    const pageSize = this.uiStore.onStagePage()?.size || { width: 1000, height: 1000 }
    const newElements: CreatePageElementParams[] = data.map(element => {
      element.position = this.getOffsetPosition(pageSize, element)
      element.updatedAt = timestamp
      element.pageId = this.pageId()
      if (element.children) {
        element.children.forEach(child => (child.pageId = this.pageId()))
      }
      const elementNode =
        element.category === ElementTypeEnum.Group
          ? new GroupElementTreeNode(_.omit(element, 'children') as IPageElementBase<GroupType>, element.children as IChildElement[])
          : new PageElementTreeNode(element)
      return elementNode.duplicate()
    })

    newElements.forEach(el => {
      if (el.category === ElementTypeEnum.Group) {
        el.children?.forEach(child => {
          if (child.category === 'text') (child.setting as ITextSetting).defaultText = true
        })
      } else if (el.category === 'text') {
        ;(el.setting as ITextSetting).defaultText = true
      }
    })

    this.selectElements(newElements.map(i => i.id))

    this._projectService.addPageElements(this.pageId(), newElements)
  }

  duplicateElements() {
    if (this.hasSelectedLocked()) return

    const pageSize = this.page()?.size || { width: 1000, height: 1000 }

    const newElements: CreatePageElementParams[] = []
    this.selectedLayers().forEach(element => {
      const position = this.getOffsetPosition(pageSize, element)
      const newEl = element.duplicate()
      newEl.position = position
      newElements.push(newEl)
    })

    newElements.forEach(el => {
      if (el.category === ElementTypeEnum.Group) {
        el.children?.forEach(child => {
          if (child.category === 'text') (child.setting as ITextSetting).defaultText = true
        })
      } else if (el.category === 'text') {
        ;(el.setting as ITextSetting).defaultText = true
      }
    })

    this._projectService.addPageElements(this.pageId(), newElements.reverse())
    this.selectElements(newElements.map(e => e.id))
    return newElements
  }

  changeElementOrder(eId: string, targetId: string, direction: 'before' | 'after') {
    const layerIds = this.layers()
      .map(i => i.id)
      .reverse()
    const elIndex = layerIds.indexOf(eId)
    const targetIndex = layerIds.indexOf(targetId)
    if (elIndex === -1 || targetIndex === -1) {
      return false
    }
    layerIds.splice(layerIds.indexOf(eId), 1)
    layerIds.splice(layerIds.indexOf(targetId) + (direction === 'before' ? 0 : 1), 0, eId)
    this._projectService.resetElementsOrder(this.pageId(), layerIds)
    return true
    // return this._projectService.changeElementOrder(this.pageId(), { sourceId: eId, targetId, position: direction })
  }

  hideElements() {
    if (this.hasSelectedLocked()) return

    this._projectService.updatePageElements(
      this.pageId(),
      this.selectedLayers().map(i => ({ id: i.id, visible: false }))
    )
    this.uiStore.resetSelection('background')
  }

  showElements(els: IPageElementBase[]) {
    return this._projectService.updatePageElements(
      this.pageId(),
      els.map(i => ({ id: i.id, visible: true }))
    )
  }

  lockElements() {
    const updates = this.selectedLayers().map(el => {
      return {
        id: el.id,
        locked: true
      }
    })
    return this._projectService.updatePageElements(this.pageId(), updates)
  }

  unlockElements() {
    const updates = this.selectedLayers().map(el => {
      return {
        id: el.id,
        locked: false
      }
    })
    return this._projectService.updatePageElements(this.pageId(), updates)
  }

  updateElementSetting<T extends ElementType>(id: string, setting: IPageElementSetting[T]) {
    this._projectService.updatePageElement(this.pageId(), { id: id, setting: setting })
  }

  canMoveUp() {
    return this.selectedIds().some(id => {
      const index = this.page().orders.indexOf(id)
      return index + this.selectedIds().length < this.page().orders.length
    })
  }

  canMoveDown() {
    return this.selectedIds().some(id => {
      const index = this.page().orders.indexOf(id)
      return index > this.selectedIds().length - 1
    })
  }

  moveUp() {
    const ids: string[] = [...this.selectedIds()].reverse()
    let layerIds = this.layers().map(i => i.id)
    const firstIndex = layerIds.indexOf(ids[0])
    layerIds = layerIds.filter(i => !ids.includes(i))

    layerIds.splice(firstIndex === 0 ? firstIndex : firstIndex - 1, 0, ...ids)

    this._projectService.resetElementsOrder(this.pageId(), layerIds.reverse())
  }

  moveTop() {
    const ids: string[] = [...this.selectedIds()].reverse()
    let layerIds = this.layers().map(i => i.id)
    layerIds = layerIds.filter(i => !ids.includes(i))
    layerIds.unshift(...ids)

    this._projectService.resetElementsOrder(this.pageId(), layerIds.reverse())
  }

  moveDown() {
    const ids: string[] = [...this.selectedIds()].reverse()
    let layerIds = this.layers().map(i => i.id)
    const lastIndex = layerIds.indexOf(ids[ids.length - 1])
    layerIds = layerIds.filter(i => !ids.includes(i))

    layerIds.splice(lastIndex === layerIds.length - 1 + ids.length ? lastIndex - ids.length + 1 : lastIndex - ids.length + 2, 0, ...ids)

    this._projectService.resetElementsOrder(this.pageId(), layerIds.reverse())
  }

  moveBottom() {
    const ids: string[] = [...this.selectedIds()].reverse()
    let layerIds = this.layers().map(i => i.id)
    layerIds = layerIds.filter(i => !ids.includes(i))
    layerIds.push(...ids)

    this._projectService.resetElementsOrder(this.pageId(), layerIds.reverse())
  }

  moveElement(axles: 'x' | 'y', offset: number) {
    if (this.hasSelectedLocked() || this.selectedTarget() !== 'element') return

    let position = { x: 0, y: 0 }
    let id = ''
    if (this.uiStore.selectedIds().length === 1) {
      id = this.uiStore.selectedIds()[0]
      position = this.uiStore.selectedRootElements()[0].position
    } else if (this.uiStore.virtualElement()) {
      id = this.uiStore.virtualElement()?.id as string
      position = this.uiStore.virtualElement()?.position as IPosition
    } else return

    const { x, y } = position
    this.uiStore.setInteractShadowData(id, {
      position: {
        x: axles === 'x' ? x + offset : x,
        y: axles === 'y' ? y + offset : y
      }
    })
    this.uiStore.resetInteractingElement()
  }

  createTextElement(text?: string, position?: IPosition, size?: ISize) {
    text = text || '添加文本'
    size = size || { width: 128, height: 45 }
    position = position || {
      x: this.uiStore.pageSize().width / 2 - size.width / 2,
      y: this.uiStore.pageSize().height / 2 - size.height / 2
    }

    const page = this._projectService.getPageById(this.pageId())
    if (!page || page.locked) {
      return
    }
    const setting: ITextSetting = {
      version: Date.now(),
      autoSize: true,
      defaultText: true,
      // direction: 'vertical-rl',
      direction: 'horizontal-tb',
      textShadow: false,
      textShadowColor: {
        color: '#000000',
        opacity: 0.4
      },
      textShadowBlur: 10,
      textShadowOffset: 10,
      textShadowAngle: 45,
      paragraphs: [
        {
          textAlign: 'start',
          lineHeight: 1.4,
          chars: [
            {
              text,
              fontFamily: 'Misans 中等',
              fontSize: 32,
              color: {
                color: '#000000',
                opacity: 1
              },
              fontWeight: 'normal',
              fontStyle: 'normal',
              // textDecoration: ['line-through', 'underline'],
              textDecoration: [],
              textStroke: false,
              textStrokeColor: {
                color: '#44e296',
                opacity: 1
              },
              textStrokeWidth: 10,
              letterSpacing: 0
            }
            //     {
            //       text: '添加文本',
            //       fontFamily: 'MiSans',
            //       fontSize: 82,
            //       color: {
            //         color: '#000000',
            //         opacity: 1
            //       },
            //       fontWeight: 'normal',
            //       fontStyle: 'normal',
            //       // textDecoration: ['line-through', 'underline'],
            //       textDecoration: [],
            //       textStroke: false,
            //       textStrokeColor: {
            //         color: '#441296',
            //         opacity: 1
            //       },
            //       textStrokeWidth: 10,
            //       letterSpacing: 0
            //     }
            //   ]
            // },
            // {
            //   textAlign: 'center',
            //   lineHeight: 1.4,
            //   chars: [
            //     {
            //       text: '添加文本',
            //       fontFamily: 'MiSans',
            //       fontSize: 32,
            //       color: {
            //         color: '#000000',
            //         opacity: 1
            //       },
            //       fontWeight: 'normal',
            //       fontStyle: 'normal',
            //       // textDecoration: ['line-through', 'underline'],
            //       textDecoration: [],
            //       textStroke: false,
            //       textStrokeColor: {
            //         color: '#44e296',
            //         opacity: 1
            //       },
            //       textStrokeWidth: 10,
            //       letterSpacing: 0
            //     },
            //     {
            //       text: '添加文本',
            //       fontFamily: 'MiSans',
            //       fontSize: 82,
            //       color: {
            //         color: '#000000',
            //         opacity: 1
            //       },
            //       fontWeight: 'normal',
            //       fontStyle: 'normal',
            //       // textDecoration: ['line-through', 'underline'],
            //       textDecoration: [],
            //       textStroke: false,
            //       textStrokeColor: {
            //         color: '#441296',
            //         opacity: 1
            //       },
            //       textStrokeWidth: 10,
            //       letterSpacing: 0
            //     }
          ]
        }
      ]
    }
    const element: CreatePageElementParams<ElementTypeEnum.Text> = {
      id: generateUid(),
      pageId: this.pageId(),
      category: ElementTypeEnum.Text,
      position,
      size,
      scale: 1,
      rotation: 0,
      visible: true,
      locked: false,
      setting,
      updatedAt: Date.now(),
      version: DB_ELEMENTS_VERSION
    }
    return this._projectService.addPageElement(this.pageId(), element)
  }

  /**
   * 添加图片模版数据
   * @param setting
   */
  createImageElement(position: IPosition, size: ISize, setting: IImageSetting) {
    const page = this._projectService.getPageById(this.pageId())
    if (!page || page.locked) {
      return
    }

    const id = generateUid()
    const imageElement: CreatePageElementParams<ElementTypeEnum.Image> = {
      id,
      pageId: page.id,
      category: ElementTypeEnum.Image,
      position: position,
      size: size,
      scale: 1,
      rotation: 0,
      visible: true,
      locked: false,
      setting: setting,
      updatedAt: Date.now(),
      version: DB_ELEMENTS_VERSION
    }
    this._projectService.addPageElement(page.id, imageElement)
    return imageElement
  }

  /**
   * 添加形状模版数据
   */
  createShapeElement(position: IPosition, size: ISize, setting: IShapeSetting) {
    const page = this._projectService.getPageById(this.pageId())
    if (!page || page.locked) {
      return
    }

    const id = generateUid()
    const shapeElement: CreatePageElementParams<ElementTypeEnum.Shape> = {
      id,
      pageId: page.id,
      category: ElementTypeEnum.Shape,
      position: position,
      size: size,
      scale: 1,
      rotation: 0,
      visible: true,
      locked: false,
      setting: setting,
      updatedAt: Date.now(),
      version: DB_ELEMENTS_VERSION
    }

    this._projectService.addPageElement(page.id, shapeElement)
    return shapeElement
  }

  /**
   * 添加线条模版数据
   */
  createLineElement(position: IPosition, size: ISize, setting: ILineSetting) {
    const page = this._projectService.getPageById(this.pageId())
    if (!page || page.locked) {
      return
    }

    const id = generateUid()
    const lineElement: CreatePageElementParams<ElementTypeEnum.Line> = {
      id,
      pageId: page.id,
      category: ElementTypeEnum.Line,
      position: position,
      size: size,
      scale: 1,
      rotation: 0,
      visible: true,
      locked: false,
      setting: setting,
      updatedAt: Date.now(),
      version: DB_ELEMENTS_VERSION
    }

    this._projectService.addPageElement(page.id, lineElement)
    return lineElement
  }

  /**
   * 添加图表模版数据
   * @param chart
   */
  createChartElement(position: IPosition, size: ISize, setting: IChartSetting) {
    const page = this._projectService.getPageById(this.pageId())
    if (!page || page.locked) {
      return
    }

    const id = generateUid()
    const chartElement: CreatePageElementParams<ElementTypeEnum.Chart> = {
      id,
      pageId: page.id,
      category: ElementTypeEnum.Chart,
      position: position,
      size: size,
      scale: 1,
      rotation: 0,
      visible: true,
      locked: false,
      setting: setting,
      updatedAt: Date.now(),
      version: DB_ELEMENTS_VERSION
    }

    this._projectService.addPageElement(page.id, chartElement)
    return chartElement
  }

  createVirtualGroupElement(elements: PageElementTreeNode[]) {
    const rotation = this.uiStore.virtualElement()?.rotation ?? 0

    let childrenElements: IPageElementBase[] = []
    const childrenOrder: string[] = []
    const { x, y, width, height } = boundingRects(
      elements.map(element => {
        // childrenOrder.push(element.id)
        return {
          position: element.position,
          size: {
            width: element.size.width,
            height: element.size.height
          },
          rotation: element.rotation * (pi / 180),
          scale: element.scale
        }
      }),
      rotation * (pi / 180)
    )

    const createGroupElement: IRootElement<GroupType> = {
      id: `virtual_${generateUid()}`,
      category: ElementTypeEnum.Group,
      pageId: this.page().id,
      position: { x: NaN, y: NaN },
      size: { width: 0, height: 0 },
      scale: 1,
      rotation,
      visible: true,
      locked: false,
      setting: {
        version: Date.now(),
        children: []
      },
      updatedAt: Date.now(),
      version: DB_ELEMENTS_VERSION
    }
    createGroupElement.position.x = x
    createGroupElement.position.y = y
    createGroupElement.size.width = width
    createGroupElement.size.height = height

    childrenElements = elements.map(el => {
      childrenOrder.push(el.id)
      return {
        ...el.data,
        position: {
          x: el.position.x - x,
          y: el.position.y - y
        },
        children: el.children
      } as CreatePageElementParams
    })
    createGroupElement.setting.children = childrenOrder

    return { ...createGroupElement, children: childrenElements } as CreateVirtualElementParams
  }

  createGroupElement(elements: PageElementTreeNode[]) {
    const page = this._projectService.getPageById(this.pageId())
    if (!page || page.locked || elements.length < 2 || elements.some(e => e.parent)) {
      return
    }
    const timestamp = Date.now()
    const groups: IPageElementBase<GroupType>[] = []
    const targetChildren: IPageElementBase[] = []

    elements.forEach(el => {
      if (el instanceof GroupElementTreeNode) {
        groups.push(el.data)
        targetChildren.push(
          ...childrenRealSetting(
            el.data,
            el.children.map(child => child.data as IPageElementBase)
          )
        )
      } else {
        targetChildren.push(el.data)
      }
    })

    const rotation = groups[0]?.rotation ?? this.uiStore.virtualElement()?.rotation ?? 0

    const childrenOrder: string[] = []
    const { x, y, width, height } = boundingRects(
      targetChildren.map(element => {
        childrenOrder.push(element.id)
        return {
          position: element.position,
          size: {
            width: element.size.width,
            height: element.size.height
          },
          rotation: element.rotation * (pi / 180),
          scale: element.scale
        }
      }),
      rotation * (pi / 180)
    )

    let targetGroup: IRootElement<GroupType> = _.cloneDeep(groups[0])
    if (targetGroup) {
      targetGroup.scale = 1
      targetGroup.position.x = x
      targetGroup.position.y = y
      targetGroup.size.width = width
      targetGroup.size.height = height
      targetGroup.setting.children = childrenOrder
    } else {
      targetGroup = {
        id: generateUid(),
        category: ElementTypeEnum.Group,
        pageId: this.page().id,
        position: {
          x,
          y
        },
        size: {
          width,
          height
        },
        scale: 1,
        rotation,
        visible: true,
        locked: false,
        setting: {
          version: Date.now(),
          children: childrenOrder
        },
        updatedAt: timestamp,
        version: DB_ELEMENTS_VERSION
      }
    }

    const children = this.createGroupChildren(targetGroup, targetChildren)

    // if (targetGroup) {
    //   const group = {
    //     ...targetGroup,
    //     position: targetGroup.position,
    //     size: targetGroup.size
    //   }
    //   this._projectService.addGroupElementChildren(group, childrenRelativeSetting(group, children))
    //   this.selectElements([targetGroup.id])
    //   return targetGroup
    // } else {
    const group: Required<CreatePageElementParams<GroupType>> = {
      ...targetGroup,
      children: childrenRelativeSetting(targetGroup, children)
    }

    if (groups[0]) {
      this._projectService.addGroupElementChildren(group, [...targetChildren.map(e => e.id), ...groups.slice(1).map(g => g.id)])
    } else {
      this._projectService.groupPageElements(
        page.id,
        group,
        elements.map(e => e.id)
      )
    }

    this.selectElements([group.id])
    return group
    // }
  }

  /**
   * 添加素材到页面
   * @param materials
   */
  addMaterial(materials: IMaterial[]) {
    const root = materials.find(i => !i.parent)
    if (!root) {
      throw new Error('根元素不存在')
    }
    const defaultSize = scaleSize(root.size, root.scale)
    const defaultPosition = {
      x: this.page().size.width / 2 - defaultSize.width / 2,
      y: this.page().size.height / 2 - defaultSize.height / 2
    }
    const timestamp = Date.now()
    const rootId = generateUid()
    const rootElmenet: CreatePageElementParams = {
      ...root,
      id: rootId,
      pageId: this.pageId(),
      position: defaultPosition,
      locked: false,
      visible: true,
      updatedAt: timestamp,
      version: DB_ELEMENTS_VERSION
    }
    const childrenElements = materials
      .filter(i => i.parent === root.id)
      .map(i => {
        return {
          ...i,
          id: generateUid(),
          pageId: this.pageId(),
          parent: rootId,
          locked: false,
          visible: true,
          updatedAt: timestamp,
          version: DB_ELEMENTS_VERSION
        }
      })
    this._projectService.addPageElements(this.pageId(), [rootElmenet, ...childrenElements])
    return rootElmenet
  }

  getOffsetPosition(pageSize: ISize, element: IPageElementBase) {
    const size = scaleSize(element.size, element.scale)
    const rotateRed = (element.rotation * Math.PI) / 180
    const vertices = rectVertices(element.position, size, rotateRed)
    const { x: originX, y: originY } = vertices.nw
    const originCenter = { x: element.position.x + size.width / 2, y: element.position.y + size.height / 2 }
    const newNw = { x: originX, y: originY }

    if (newNw.x + 10 > pageSize.width) {
      newNw.x -= 10
    } else {
      newNw.x += 10
    }
    const deltaX = newNw.x - originX

    if (newNw.y + 10 > pageSize.height) {
      newNw.y -= 10
    } else {
      newNw.y += 10
    }
    const deltaY = newNw.y - originY
    const newCenter = {
      x: originCenter.x + deltaX,
      y: originCenter.y + deltaY
    }

    return transformPosition([newNw.x, newNw.y], [newCenter.x, newCenter.y], -rotateRed)
  }

  /**
   * 元素对齐和分布状态
   * @returns { left: boolean; right: boolean; center: boolean } 对齐状态
   * @description
   * 1. alignLeft: 是否可以与左侧对齐
   * 2. alignRight: 是否可以与右侧对齐
   * 3. alignHorizontal: 是否可以水平对齐
   * 4. alignTop: 是否可以与顶部对齐
   * 5. alignBottom: 是否可以与底部对齐
   * 6. alignVertical: 是否可以垂直对齐
   * 7. distributeHorizontal: 是否可以水平分布
   * 8. distributeVertical: 是否可以垂直分布
   * @param elements
   */
  getAlignDistributeStatus(elements: IPageElementBase[]): {
    alignVertical: boolean
    distributeVertical: boolean
    alignLeft: boolean
    distributeHorizontal: boolean
    alignRight: boolean
    alignHorizontal: boolean
    alignTop: boolean
    alignBottom: boolean
  } {
    const can = (a: number, b: number) => Math.abs(subtract(a, b)) > 0.01
    const elementsRects = this.getElementsRects(elements)
    const alignRect = this.alignRect(elementsRects)
    const canAlignLeft = elementsRects.some(rect => can(rect.position.x, alignRect.x))
    const canAlignRight = elementsRects.some(rect => can(rect.position.x + rect.size.width, alignRect.x + alignRect.width))
    const canAlignTop = elementsRects.some(rect => can(rect.position.y, alignRect.y))
    const canAlignBottom = elementsRects.some(rect => can(rect.position.y + rect.size.height, alignRect.y + alignRect.height))
    const canAlignHorizontal = elementsRects.some(rect => can(rect.position.y + rect.size.height / 2, alignRect.y + alignRect.height / 2))
    const canAlignVertical = elementsRects.some(rect => can(rect.position.x + rect.size.width / 2, alignRect.x + alignRect.width / 2))
    let canDistributeHorizontally = false
    if (elementsRects.length > 2) {
      const sorted = elementsRects.sort((a, b) => a.position.x - b.position.x)
      const gap = round(sorted[1].position.x - (sorted[0].position.x + sorted[0].size.width), 4)

      for (let i = 1; i < elements.length - 1; i++) {
        if (can(round(sorted[i + 1].position.x - (sorted[i].position.x + sorted[i].size.width), 4), gap)) {
          canDistributeHorizontally = true
          break
        }
      }
    } else if (elementsRects.length === 2 || elementsRects.length === 0) {
      canDistributeHorizontally = false
    } else {
      canDistributeHorizontally = canAlignVertical
    }

    let canDistributeVertically = false
    if (elementsRects.length > 2) {
      const sorted = elementsRects.sort((a, b) => a.position.y - b.position.y)
      const gap = round(sorted[1].position.y - (sorted[0].position.y + sorted[0].size.height), 4)

      for (let i = 1; i < elements.length - 1; i++) {
        if (can(round(sorted[i + 1].position.y - (sorted[i].position.y + sorted[i].size.height), 4), gap)) {
          canDistributeVertically = true
          break
        }
      }
    } else if (elementsRects.length === 2 || elementsRects.length === 0) {
      canDistributeVertically = false
    } else {
      canDistributeVertically = canAlignHorizontal
    }
    return {
      alignLeft: canAlignLeft,
      alignRight: canAlignRight,
      alignHorizontal: canAlignHorizontal,
      alignTop: canAlignTop,
      alignBottom: canAlignBottom,
      alignVertical: canAlignVertical,
      distributeHorizontal: canDistributeHorizontally,
      distributeVertical: canDistributeVertically
    }
  }

  /**
   * 左对齐
   * @param elements
   * @private
   */
  alignLeft(elements: IPageElementBase[]) {
    const elementsRects = this.getElementsRects(elements)
    const page = this.page()
    if (elementsRects.length > 0 && page) {
      // 找到最左侧的position 并修改所有元素的x
      const left = elementsRects.length === 1 ? 0 : Math.min(...elementsRects.map(e => e.position.x))
      this.updatePosition(
        elementsRects,
        elementsRects.map(e => ({
          id: e.id,
          position: {
            x: left,
            y: e.position.y
          }
        }))
      )
    }
  }

  /**
   * 右对齐
   * @param elements
   * @private
   */
  alignRight(elements: IPageElementBase[]) {
    const elementsRects = this.getElementsRects(elements)
    const page = this.page()
    if (elementsRects.length > 0 && page) {
      // 找到最右侧的position 并修改所有元素的x
      const right = elementsRects.length === 1 ? page.size.width : Math.max(...elementsRects.map(e => e.position.x + e.size.width))
      this.updatePosition(
        elementsRects,
        elementsRects.map(e => ({
          id: e.id,
          position: {
            x: right - e.size.width,
            y: e.position.y
          }
        }))
      )
    }
  }

  /**
   * 水平居中
   * @param elements
   * @private
   */
  alignHorizontal(elements: IPageElementBase[]) {
    const elementsRects = this.getElementsRects(elements)
    const page = this.page()
    if (elementsRects.length > 0 && page) {
      // 所有元素在水平线分布，如果选择元素为1个那么相对于页面水平居中，如果大于1那么计算多个元素的中性点位置并将元素的中心点对齐
      let centerY: number
      if (elementsRects.length === 1) {
        centerY = page.size.height / 2
      } else {
        const minY = Math.min(...elementsRects.map(e => e.position.y))
        const maxY = Math.max(...elementsRects.map(e => e.position.y + e.size.height))
        centerY = minY + (maxY - minY) / 2
      }
      this.updatePosition(
        elementsRects,
        elementsRects.map(e => ({
          id: e.id,
          position: {
            x: e.position.x,
            y: centerY - e.size.height / 2
          }
        }))
      )
    }
  }

  /**
   * 顶部对齐
   * @param elements
   * @private
   */
  alignTop(elements: IPageElementBase[]) {
    const elementsRects = this.getElementsRects(elements)
    const page = this.page()
    if (elementsRects.length > 0 && page) {
      // 找到最顶部的position 并修改所有元素的y
      const top = elementsRects.length === 1 ? 0 : Math.min(...elementsRects.map(e => e.position.y))
      this.updatePosition(
        elementsRects,
        elementsRects.map(e => ({
          id: e.id,
          position: {
            x: e.position.x,
            y: top
          }
        }))
      )
    }
  }

  /**
   * 底部对齐
   * @param elements
   * @private
   */
  alignBottom(elements: IPageElementBase[]) {
    const elementsRects = this.getElementsRects(elements)
    const page = this.page()
    if (elementsRects.length > 0 && page) {
      // 找到最底部的position 并修改所有元素的y
      const bottom = elementsRects.length === 1 ? page.size.height : Math.max(...elementsRects.map(e => e.position.y + e.size.height))
      this.updatePosition(
        elementsRects,
        elementsRects.map(e => ({
          id: e.id,
          position: {
            x: e.position.x,
            y: bottom - e.size.height
          }
        }))
      )
    }
  }

  /**
   * 垂直居中
   * @param elements
   * @private
   */
  alignVertical(elements: IPageElementBase[]) {
    const elementsRects = this.getElementsRects(elements)
    const page = this.page()
    if (elementsRects.length > 0 && page) {
      // 所有元素在垂直线分布，如果选择元素为1个那么相对于页面垂直居中，如果大于1那么计算多个元素的中性点位置并将元素的中心点对齐
      let centerX: number
      if (elementsRects.length === 1) {
        centerX = page.size.width / 2
      } else {
        const minX = Math.min(...elementsRects.map(e => e.position.x))
        const maxX = Math.max(...elementsRects.map(e => e.position.x + e.size.width))
        centerX = minX + (maxX - minX) / 2
      }
      this.updatePosition(
        elementsRects,
        elementsRects.map(e => ({
          id: e.id,
          position: {
            x: centerX - e.size.width / 2,
            y: e.position.y
          }
        }))
      )
    }
  }

  /**
   * 垂直间距分布
   */
  distributeVerticalSpacing(elements: IPageElementBase[]) {
    const realElements = this.getElementsRects(elements)

    // 只有一个元素时，相当于水平居中
    if (realElements.length === 1) {
      // 相当于水平居中
      this.alignHorizontal(elements)
    }
    // 最少需要3个元素
    const page = this.page()
    if (realElements.length > 2 && page) {
      const sorted = cloneDeep(realElements).sort((a, b) => a.position.y - b.position.y)
      // realElements.sort((a, b) => a.position.y - b.position.y)

      // 计算出第一个元素的的下边和最后一个元素的上边之间的距离
      const first = sorted[0]
      const last = sorted[sorted.length - 1]
      // 距离总和
      const total = last.position.y - (first.position.y + first.size.height)
      // 排除第一个和最后一个元素，这两个元素不需要移动位置
      const updateElements = sorted.slice(1, sorted.length - 1)
      // 计算出剩余的空间
      const lostSpace = updateElements.reduce((prev, cur) => prev - cur.size.height, total)
      // 将剩余空间平均给予剩余元素
      const average = lostSpace / (sorted.length - 1)

      // 计算每个元素的位置
      updateElements.forEach((element, index) => {
        const prev = sorted[index]
        element.position.y = prev.position.y + prev.size.height + average
      })

      // 提交更新
      this.updatePosition(
        realElements,
        updateElements.map(e => ({
          id: e.id,
          position: {
            x: e.position.x,
            y: e.position.y
          }
        }))
      )
    }
  }

  /**
   * 水平间距分布
   */
  distributeHorizontalSpacing(elements: IPageElementBase[]) {
    const realElements = this.getElementsRects(elements)

    // 只有一个元素时，相当于垂直居中
    if (realElements.length === 1) {
      // 相当于垂直居中
      this.alignVertical(elements)
    }
    // 最少需要3个元素
    const page = this.page()
    if (realElements.length > 2 && page) {
      const sorted = cloneDeep(realElements).sort((a, b) => a.position.x - b.position.x)

      // 计算出第一个元素的的右边和最后一个元素的左边之间的距离
      const first = sorted[0]
      const last = sorted[sorted.length - 1]
      // 距离总和
      const total = last.position.x - (first.position.x + first.size.width)
      // 排除第一个和最后一个元素，这两个元素不需要移动位置
      const updateElements = sorted.slice(1, sorted.length - 1)
      // 计算出剩余的空间
      const lostSpace = updateElements.reduce((prev, cur) => prev - cur.size.width, total)
      // 将剩余空间平均给予剩余元素
      const average = lostSpace / (sorted.length - 1)

      // 计算每个元素的位置
      updateElements.forEach((element, index) => {
        const prev = sorted[index]
        element.position.x = prev.position.x + prev.size.width + average
      })

      // 提交更新
      this.updatePosition(
        realElements,
        sorted.map(e => ({
          id: e.id,
          position: {
            x: e.position.x,
            y: e.position.y
          }
        }))
      )
    }
  }

  ungroupElement(element: GroupElementTreeNode) {
    const group = element
    const children = childrenRealSetting<IPageElementBase>(
      group.data,
      group.children.map(child => child.data)
    )
    this._projectService.ungroupPageElement(
      element.pageId,
      element.id,
      children.map(child => ({
        ...child,
        parent: undefined
      }))
    )

    this.selectElements(children.map(e => e.id))
  }

  /**
   * 保存为素材
   * @param element
   */
  saveAsMaterial() {
    const target = this.selectedLayers()[0]
    let elements: IPageElementBase[] = [target.data]
    if (target.children) {
      elements = elements.concat(target.children)
    }
    this._api.saveAsMaterial(
      elements.map(el => ({
        id: el.id,
        category: el.category,
        position: el.parent ? el.position : { x: 0, y: 0 },
        size: el.size,
        scale: el.scale,
        rotation: el.rotation,
        setting: el.setting,
        parent: el.parent
      }))
    )
  }

  /**
   * 更新元素位置
   * @param elementsRects 元素的外接矩形位置数据 {id: string, position: IPosition, size: ISize}[]
   * @param data 元素位置数据 {position: IPosition, id: string}
   * @private
   */
  private updatePosition(elementsRects: { id: string; position: IPosition; size: ISize }[], data: Array<{ id: string; position: IPosition }>) {
    const page = this.page()

    if (page) {
      const payload = produce(data, draft => {
        draft.forEach((d, index) => {
          const originElement = page.getElementNodeById(d.id)
          const realElement = elementsRects.find(e => e.id === d.id)
          if (originElement && realElement) {
            // 计算realElement的移动距离
            const offsetX = d.position.x - realElement.position.x
            const offsetY = d.position.y - realElement.position.y

            d.position.x = originElement.position.x + offsetX
            d.position.y = originElement.position.y + offsetY
          }
        })
      })
      this._projectService.updatePageElements(page.id, payload)
      this.uiStore.updateSelectedElements(payload)
    }
  }

  private getElementsRects(elements: IPageElementBase[]) {
    return elements.map(element => {
      // 旋转后的外接矩形位置
      const rect = boundingRect(
        element.position,
        produce(element.size, draft => {
          draft.width *= element.scale || 1
          draft.height *= element.scale || 1
        }),
        (Math.PI * element.rotation) / 180
      )

      return {
        id: element.id,
        position: { x: rect.left, y: rect.top },
        size: {
          width: rect.width,
          height: rect.height
        }
      }
    })
  }

  /**
   * 计算当前选中元素对应的分布矩形区域
   * 当选中一个1相对于画布
   * 当选中多个时，根据元素的位置计算一个最大的外接矩形
   */
  private alignRect(rects: { position: IPosition; size: ISize }[]) {
    const page = this.page()
    if (!page || rects.length === 0) {
      return { x: 0, y: 0, width: 0, height: 0 }
    }
    if (rects.length === 1) {
      return { x: 0, y: 0, ...page.size }
    } else {
      const minX = Math.min(...rects.map(e => e.position.x))
      const minY = Math.min(...rects.map(e => e.position.y))
      const maxX = Math.max(...rects.map(e => e.position.x + e.size.width))
      const maxY = Math.max(...rects.map(e => e.position.y + e.size.height))
      return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY
      }
    }
  }

  private createGroupChildren(group: IRootElement<GroupType>, children: IPageElementBase[]): IChildElement[] {
    const timestamp = Date.now()
    return children.map(element => {
      return {
        ...element,
        parent: group.id,
        position: {
          x: element.position.x,
          y: element.position.y
        },
        updatedAt: timestamp,
        version: DB_ELEMENTS_VERSION
      } as IChildElement
    })
  }
}
