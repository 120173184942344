import { inject, Injectable } from '@angular/core'

import { SettingSchema } from '@editorup/settings'

import { WorkspaceService } from '../workspace.service'

/**
 * 组选配置
 */
@Injectable({
  providedIn: 'root'
})
export class GroupSettingService {
  workspaceService = inject(WorkspaceService)

  getSettingSchema(): SettingSchema[] {
    return [
      {
        title: '基础属性',
        icon: 'ss:base',
        interaction: 'none',
        children: [...this.workspaceService.getSettingSchema()]
      }
    ]
  }
}
