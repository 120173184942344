import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, effect, ElementRef, input, viewChild } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'

import { combineLatestWith } from 'rxjs'

import {
  BarProgressChart,
  BasicBarChart,
  BasicColumnChart,
  BasicLineChart,
  BasicPieChart,
  BasicRadarChart,
  ButterflyChart,
  CascadedAreaChart,
  ChartType,
  CheckInBubbleChart,
  ComposeWaterfallChart,
  DescartesHeatmapChart,
  DifferenceArrowBarChart,
  DifferenceArrowColumnChart,
  DonutProgressChart,
  FunnelChart,
  GroupedBarChart,
  GroupedColumnChart,
  JadeJueChart,
  MixedLineAndGroupedColumnChart,
  MixedLineAndStackedColumnChart,
  RiverChart,
  RoseChart,
  SankeyChart,
  SingleLayerTreemapChart,
  StackedAreaChart,
  StackedBarChart,
  StackedColumnChart,
  SymbolBarChart,
  SymbolColumnChart,
  VoronoiChart,
  WordCloudChart
} from '@editorup/charts'
import { IChartSetting, ISize } from '@libs/payload'

@Component({
  selector: 'ace-chart',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './chart.component.html',
  styles: `
    :host {
      display: block;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartComponent {
  setting = input.required<IChartSetting>()
  size = input.required<ISize>()
  selected = input(false)
  chartRef = viewChild.required<ElementRef<HTMLDivElement>>('chartRef')

  private chart:
    | BasicColumnChart
    | GroupedBarChart
    | GroupedColumnChart
    | BasicLineChart
    | CascadedAreaChart
    | StackedAreaChart
    | StackedColumnChart
    | StackedBarChart
    | MixedLineAndGroupedColumnChart
    | MixedLineAndStackedColumnChart
    | DifferenceArrowColumnChart
    | DifferenceArrowBarChart
    | RiverChart
    | BasicPieChart
    | RoseChart
    | DescartesHeatmapChart
    | CheckInBubbleChart
    | BasicRadarChart
    | SingleLayerTreemapChart
    | SankeyChart
    | JadeJueChart
    | FunnelChart
    | DonutProgressChart
    | BarProgressChart
    | BasicBarChart
    | ComposeWaterfallChart
    | WordCloudChart
    | ButterflyChart
    | VoronoiChart
    | SymbolColumnChart
    | SymbolBarChart
    | undefined

  private chartSelected = false
  private type: ChartType | undefined

  constructor() {
    toObservable(this.setting)
      .pipe(combineLatestWith(toObservable(this.size)), takeUntilDestroyed())
      .subscribe(([setting, size]) => {
        console.log('chart setting', setting, size)
        requestAnimationFrame(() => {
          this.updateOption(setting, false)
          this.updateSize(size.width, size.height)
          if (this.chartSelected) {
            this.chart?.openEvent()
          }
        })
      })

    effect(() => {
      this.chartSelected = this.selected()
      console.log(this.chartSelected)
      if (this.chartSelected) {
        this.chart?.openEvent()
      } else {
        this.chart?.closeEvent()
      }
    })
  }

  updateOption = (setting: IChartSetting, update = false) => {
    // 增加重新渲染的条件判断
    if (!this.chart || setting.props.type !== this.type) {
      // 仅当切换图表类型和初次加载时render一次图表
      this.type = setting.props.type
      const render = this.getChartRender(setting.props.type)
      this.chart = new render(this.chartRef().nativeElement)
    }
    this.chart.setOption(setting, update)
  }

  updateSize = (width: number, height: number) => {
    this.chart?.update({ width, height })
  }

  getChartRender(type: string) {
    switch (type) {
      case 'basic-pie':
        return BasicPieChart
      case 'grouped-bar':
        return GroupedBarChart
      case 'grouped-column':
        return GroupedColumnChart
      case 'stacked-bar':
        return StackedBarChart
      case 'stacked-column':
        return StackedColumnChart
      case 'basic-line':
        return BasicLineChart
      case 'cascaded-area':
        return CascadedAreaChart
      case 'stacked-area':
        return StackedAreaChart
      case 'river-area':
        return RiverChart
      case 'mixed-line-grouped-column':
        return MixedLineAndGroupedColumnChart
      case 'mixed-line-stacked-column':
        return MixedLineAndStackedColumnChart
      case 'difference-arrow-column':
        return DifferenceArrowColumnChart
      case 'difference-arrow-bar':
        return DifferenceArrowBarChart
      case 'basic-column':
        return BasicColumnChart
      case 'rose-pie':
        return RoseChart
      case 'descartes-heatmap':
        return DescartesHeatmapChart
      case 'check-in-bubble':
        return CheckInBubbleChart
      case 'basic-radar':
        return BasicRadarChart
      case 'single-layer-treemap':
        return SingleLayerTreemapChart
      case 'sankey':
        return SankeyChart
      case 'jade-jue':
        return JadeJueChart
      case 'funnel':
        return FunnelChart
      case 'donut-progress':
        return DonutProgressChart
      case 'bar-progress':
        return BarProgressChart
      case 'basic-bar':
        return BasicBarChart
      case 'compose-waterfall':
        return ComposeWaterfallChart
      case 'word-cloud':
        return WordCloudChart
      case 'butterfly':
        return ButterflyChart
      case 'voronoi':
        return VoronoiChart
      case 'symbol-column':
        return SymbolColumnChart
      case 'symbol-bar':
        return SymbolBarChart
      default:
        return BasicPieChart
    }
  }
}
