import { HttpEvent, HttpEventType } from '@angular/common/http'
import { inject, Injectable, signal, WritableSignal } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'

import { BehaviorSubject, from, map, Observable, switchMap } from 'rxjs'
import * as XLSX from 'xlsx'

import { ConfirmationDialogComponent } from '@libs/ng-shared/components'

import { EDITOR_API } from '../../services'
import { UploadData, UploadImage } from './upload.type'

interface UploadQueue {
  queue: (UploadData | UploadImage)[]
  uploadStatus: 'idle' | 'uploading' | 'success' | 'error'
  uploadComplete: WritableSignal<boolean>
}

interface SwitchTabParams {
  componentId?: string
  tab?: number
}

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  // 传给数据面板的文件
  file = signal<UploadData>({} as UploadData)
  // 文件上传
  allowUploadImage = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'image/gif']
  allowUploadData = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']

  // 错误信息
  typeErrors: string[] = []
  sizeErrors: string[] = []
  uploadErrors: string[] = []

  imageList = signal<UploadImage[]>([])
  dataList = signal<UploadData[]>([])

  uploadSuccessSubject = new BehaviorSubject<{ fileId: string; componentId: string; type: string }>({ fileId: '', componentId: '', type: '' })
  uploadSuccess$ = this.uploadSuccessSubject.asObservable()

  activeTabSubject = new BehaviorSubject<number>(0) // 默认'数据面板'
  activeTab$ = this.activeTabSubject.asObservable()

  private apiService = inject(EDITOR_API)
  private dialog = inject(MatDialog)

  // 当前上传文件
  private _currentFile: UploadData | UploadImage | null = null
  //上传队列，不同的组件调用，会生成不同的对列
  private uploadQueues = new Map<string, UploadQueue>()

  //每个调用上传服务的组件都需要单独维护一个上传队列
  generateQueueId(): string {
    return 'queue-' + Date.now() + '-' + Math.random().toString(36)
  }

  // 上传成功后并未从后端获取数据刷新，用fid标识唯一，更新文件列表
  updateFileStatus(file: UploadData | UploadImage) {
    if ('preview' in file) {
      // 更新图片列表
      this.imageList.update(prevList => prevList.map(item => (item.fid === file.fid ? { ...item, ...file } : item)))
    } else if ('raw' in file) {
      // 更新数据列表
      this.dataList.update(prevList => prevList.map(item => (item.fid === file.fid ? { ...item, ...file } : item)))
    }
  }

  setFile(data: UploadData) {
    this.file.set(data)
  }

  //统一处理错误
  handleUploadError() {
    if (this.typeErrors.length > 0) {
      // 显示类型错误弹窗
      console.error('类型错误:', this.typeErrors)
      this.typeErrDialog(this.typeErrors)
    }

    if (this.sizeErrors.length > 0) {
      // 显示格式错误弹窗
      console.error('格式错误:', this.sizeErrors)
      this.sizeErrDialog(this.sizeErrors)
    }

    if (this.uploadErrors.length > 0) {
      // 显示上传过程中出错的弹窗
      console.error('上传过程中出错:', this.uploadErrors)
      this.failUploadDialog(this.uploadErrors)
    }
    this.typeErrors = []
    this.sizeErrors = []
    this.uploadErrors = []
  }

  // 获取或创建调用组件对应的上传队列
  getUploadQueue(componentId: string): UploadQueue {
    let uploadQueue = this.uploadQueues.get(componentId)
    if (!uploadQueue) {
      uploadQueue = {
        queue: [],
        uploadStatus: 'idle',
        uploadComplete: signal(false)
      }
      this.uploadQueues.set(componentId, uploadQueue)
    }
    return uploadQueue
  }

  //无需解析文件直接添加到对列
  async addFilesToQueuePassPre(file: UploadData, componentId: string) {
    const uploadQueue = this.getUploadQueue(componentId)
    file = this.creatFileAttr(file) as UploadData
    this.dataList.update(list => [file as UploadData, ...list])
    uploadQueue.queue.push(file)
    await this.switchTab({ componentId })
    this.processUploadQueue(componentId)
  }

  //无需构造缩略图直接添加到对列
  async addImgaeToQueuePassPre(file: UploadImage, componentId: string) {
    const uploadQueue = this.getUploadQueue(componentId)
    file = this.creatFileAttr(file) as UploadImage
    this.imageList.update(list => [file as UploadImage, ...list])
    uploadQueue.queue.push(file)
    await this.switchTab({ componentId })
    this.processUploadQueue(componentId)
  }

  //处理文件并添加到对列中
  async addFilesToQueue(files: File | File[], componentId: string) {
    const uploadQueue = this.getUploadQueue(componentId)
    const newFiles = Array.isArray(files) ? files : [files]
    if (newFiles && newFiles.length > 0) {
      // 使用 Promise.all 并行处理文件
      await Promise.all(
        newFiles.map(async file => {
          if (!this.typeChecked(file)) {
            this.typeErrors.push(file.name)
            return
          }
          if (this.isFileSizeValid(file)) {
            const fileWithProgress: UploadData | UploadImage = this.creatFileAttr(file)
            if (this.allowUploadImage.includes(file.type)) {
              try {
                ;(fileWithProgress as UploadImage).preview = await this.readImage(file)
                // 添加到图片列表
                this.imageList.update(list => [fileWithProgress as UploadImage, ...list])
              } catch (error) {
                console.error('读取图像预览出错:', error)
                this.uploadErrors.push(file.name)
              }
            } else if (this.allowUploadData.includes(file.type)) {
              try {
                ;(fileWithProgress as UploadData).raw = await this.getReadData(file)
                // 添加到数据列表
                this.dataList.update(list => [fileWithProgress as UploadData, ...list])
              } catch (error) {
                console.error('读取数据文件内容出错:', error)
                this.uploadErrors.push(file.name)
              }
            }
            // 添加到上传队列
            uploadQueue.queue.push(fileWithProgress)
          } else {
            this.sizeErrors.push(file.name)
            return
          }
        })
      )
      //切换tab
      await this.switchTab({ componentId })
      // 开始上传队列中的文件
      this.processUploadQueue(componentId)
    }
  }
  // 上传失败手动重试
  async retryUpload(file: UploadData | UploadImage, componentId: string) {
    this.uploadFile(file, componentId)
  }

  // 处理上传队列
  private processUploadQueue(componentId: string) {
    const uploadQueue = this.uploadQueues.get(componentId)
    if (!uploadQueue) {
      return
    }
    if (uploadQueue.queue.length > 0) {
      this._currentFile = uploadQueue.queue.shift() as UploadData | UploadImage // 取出队列中的第一个文件
      this.uploadFile(this._currentFile, componentId) // 上传文件
    } else if (uploadQueue.queue.length === 0 && uploadQueue.uploadStatus !== 'uploading') {
      uploadQueue.uploadComplete.set(true)
      //全部上传完成后，统一弹窗
      this.handleUploadError()
      //删除对列
      this.uploadQueues.delete(componentId)
    }
  }

  // 处理后的文件进行上传
  private uploadFile(file: UploadData | UploadImage, componentId: string) {
    const uploadQueue = this.uploadQueues.get(componentId)
    if (!uploadQueue) {
      return
    }
    uploadQueue.uploadStatus = 'uploading'
    file.status = 'uploading'
    const uploadObservable$ = this.getUploadObservable(file)
    uploadObservable$.subscribe({
      next: (event: HttpEvent<any>) => {
        if (event.type === HttpEventType.UploadProgress) {
          if (event.total !== undefined) {
            file.progress = Math.round((100 * event.loaded) / event.total)
            this.updateFileStatus(file)
          }
        } else if (event.type === HttpEventType.Response) {
          file.id = event.body.id
          file.timeAgo = '刚刚'
          file.status = 'done'
          file.hoverCheckbox = this.isBatchOperation(file, 'hoverCheckbox') //批量操作时上传成功后要显示checkbox
          file.isEdit = this.isBatchOperation(file, 'isEdit') //正在预览数据时，上传成功后不可操作checkbox和more
          uploadQueue.uploadStatus = 'success'
          this.uploadSuccessSubject.next({ fileId: event.body.id, componentId, type: 'preview' in file ? 'image' : 'data' }) // 调用上传成功后返回的文件ID
          this.updateFileStatus(file)
          this.processUploadQueue(componentId) // 上传完成后继续处理队列中的下一个文件
        }
      },
      error: () => {
        file.status = 'error'
        this.updateFileStatus(file)
        uploadQueue.uploadStatus = 'error'
        this.uploadErrors.push(file.name)
        this.processUploadQueue(componentId)
      }
    })
  }

  // 构造需要上传的文件的fid,后续通过fid进行刷新前的文件唯一标识，刷新后fid为空，以id为唯一标识
  private creatFileAttr(file: File | UploadData | UploadImage): UploadData | UploadImage {
    return {
      ...file,
      status: 'ready',
      fid: file.name + Date.now(),
      name: file.name
    }
  }
  //根据列表中是否所有的checkbox都未true，判断是否是批量操作
  private isBatchOperation(file: UploadData | UploadImage, attr: string): boolean {
    const listToCheck = 'preview' in file ? this.imageList() : 'raw' in file ? this.dataList() : null

    // 如果没有列表或列表中只有一个文件，则不需要进行批量操作
    if (!listToCheck || listToCheck.length <= 1) {
      return false
    }
    // 使用 every 方法检查除当前文件外，所有文件的 hoverCheckbox 是否都是 true
    return listToCheck.every(
      (item: UploadData | UploadImage) =>
        item.fid === file.fid || // 当前文件，跳过检查
        item[attr as keyof (UploadData | UploadImage)] // 检查其他文件的 hoverCheckbox 是否为 true
    )
  }
  // 根据文件类型获取上传 Observable
  private getUploadObservable(file: UploadData | UploadImage): Observable<HttpEvent<any>> {
    if ('preview' in file) {
      return this.getImageUploadObservable(file)
    } else if ('raw' in file) {
      return this.apiService.uploadData({ name: file.name, raw: file.raw })
    } else {
      throw new Error('Invalid file type')
    }
  }

  // 格式检查
  private typeChecked(file: File): boolean {
    const allowUpload = [...this.allowUploadData, ...this.allowUploadImage]
    return allowUpload.includes(file.type)
  }

  // 上传的内容的大小检查，不超过 25M
  private isFileSizeValid(file: File): boolean {
    return file.size <= 25 * 1024 * 1024
  }

  // 获取缩略图，生成前端临时 link
  private readImage(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        const preview = URL.createObjectURL(file)
        resolve(preview)
      }
      reader.onerror = error => {
        reject(error)
      }
      reader.readAsDataURL(file)
    })
  }

  // 解析 excel 文件
  private async readData(file: File): Promise<string[][]> {
    return new Promise<string[][]>((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event: any) => {
        try {
          const data = new Uint8Array(event.target.result)
          const workbook = XLSX.read(data, { type: 'array' })
          if (workbook.SheetNames.length === 0) {
            reject('没有找到sheet')
          }
          const firstSheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[firstSheetName]
          const jsonData = XLSX.utils.sheet_to_json(worksheet)
          const stringArray: string[][] = jsonData.map((row: any) => Object.values(row).map(String))
          resolve(stringArray)
        } catch (err) {
          reject(err)
        }
      }
      reader.onerror = error => {
        reject(error)
      }
      reader.readAsArrayBuffer(file)
    })
  }

  // 解析 CSV 文件
  private async parseCSVFile(file: File): Promise<string[][]> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event: any) => {
        try {
          const csvContent = event.target.result as string
          const rows = csvContent.split('\n').map(row => row.split(',')) // 按行和逗号分割
          resolve(rows)
        } catch (err) {
          reject(err)
        }
      }
      reader.onerror = error => {
        reject(error)
      }
      reader.readAsText(file)
    })
  }

  // 获取解析后的数据
  private async getReadData(file: File): Promise<string> {
    let raw: string[][] = []
    if (file.type === 'text/csv') {
      try {
        raw = await this.parseCSVFile(file)
      } catch (err) {
        console.error('解析csv出错', err)
        throw err
      }
    } else {
      try {
        raw = await this.readData(file)
      } catch (err) {
        console.error('解析excel出错', err)
        throw err
      }
    }
    return JSON.stringify(raw)
  }

  // 获取图片文件上传的 Observable
  private getImageUploadObservable(file: UploadImage): Observable<HttpEvent<any>> {
    // 从预览 URL 获取 Blob 对象
    return this.fetchImageBlob(file.preview as string).pipe(
      // 将 Blob 转换为 File 对象
      map(blob => new File([blob], file.name, { type: blob.type })),
      // 调用 API 服务上传图片文件
      switchMap(imageFile => this.apiService.uploadImage(imageFile))
    )
  }
  //通过图片url获取blob对象
  private fetchImageBlob(url: string): Observable<Blob> {
    return from(fetch(url).then(res => res.blob()))
  }

  // 格式错误报错弹窗
  private typeErrDialog(list: string[]) {
    const errorList = list.map(error => `• ${error}`).join('<br>')
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: '格式错误',
        message: `部分上传文件无法解析格式。请重新选择JPG、PNG、WEBP、GIF、CSV、XLS、XLSX格式的文件上传。<br><br> ${errorList}`,
        actions: {
          confirm: {
            show: true,
            label: '已知晓'
          }
        }
      }
    })
  }

  // 大小错误报错弹窗
  private sizeErrDialog(list: string[]) {
    const errorList = list.map(error => `• ${error}`).join('<br>')
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: '文件大小错误',
        message: `上传文件的大小不能超过 25M。请压缩大小再上传这些文件：<br><br> ${errorList}`,
        actions: {
          confirm: {
            show: true,
            label: '已知晓'
          }
        }
      }
    })
  }

  //上传失败报错弹窗
  private failUploadDialog(list: string[]) {
    const errorList = list.map(error => `• ${error}`).join('<br>')
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: '上传失败',
        message: `上传文件失败。请稍后再上传这些文件：<br><br> ${errorList}`,
        actions: {
          confirm: {
            show: true,
            label: '已知晓'
          }
        }
      }
    })
  }

  //根据文件类型判断是否需要切换上传面板
  private async switchTab(param: SwitchTabParams) {
    if (param.tab) {
      this.activeTabSubject.next(param.tab)
    } else if (param.componentId) {
      // 根据 componentId 和文件类型判断 tab 值
      const files = this.getUploadQueue(param.componentId).queue
      let newTab: number = this.activeTabSubject.value
      const hasImage = files.some(file => 'preview' in file)
      const hasData = files.some(file => 'raw' in file)

      if (hasImage && hasData) {
        newTab = this.activeTabSubject.value
      } else if (hasImage) {
        newTab = 1
      } else if (hasData) {
        newTab = 0
      }
      this.activeTabSubject.next(newTab)
    }
  }
}
