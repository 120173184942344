import { computed, inject, Injectable, Signal, signal, WritableSignal } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'

import { patchState, signalState } from '@ngrx/signals'
import _ from 'lodash-es'
import { WithDeleted } from 'rxdb'
import { buffer, combineLatestWith, debounceTime, filter, firstValueFrom, map, merge, skip, startWith, Subscription, take, tap, zip } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import { GroupElementTreeNode, PageListNode } from '@libs/ng-shared/models'
import {
  CreatePageElementParams,
  CreatePageParams,
  GroupType,
  IGroupElement,
  IPage,
  IPageElementBase,
  IProjectStorage,
  IRootElement,
  UpdatePageElement,
  UpdatePageParams,
  WithId
} from '@libs/payload'

import { PageCollectionService, PageElementCollectionService, ProjectCollectionService } from './rxdb'
import { DB_PAGES_VERSION } from './rxdb/db.schema'

@Injectable()
export class ProjectService {
  isReplicating = computed(() => this.status() !== 'idle')
  currentPages = computed(() => {
    const arr = this._pageLinkedList()
    const pages: Array<IPage> = arr.map((pageNode, index) => {
      const page: IPage = {
        ...pageNode.page,
        elements: pageNode.elements,
        prev: index === 0 ? null : arr[index - 1].id,
        next: index === arr.length - 1 ? null : arr[index + 1].id
      }
      return page
    })
    return pages
  })

  historyPreviousSteps = computed(() => {
    return this._history.previous().length
  })

  historyFutureSteps = computed(() => {
    return this._history.future().length
  })

  isUpgrading = computed(() => this._migrationState.pages() === 'RUNNING' || this._migrationState.elements() === 'RUNNING')

  private status: WritableSignal<'receiving' | 'sending' | 'idle'> = signal('idle')
  private _initialized = signal(false)
  private _projectCollectionService = inject(ProjectCollectionService)
  private _pageDbService = inject(PageCollectionService)
  private _elementCollectionService = inject(PageElementCollectionService)
  private _pageLinkedList = signal<PageListNode[]>([])

  private _subscription = new Subscription()
  private _history = signalState<{
    previous: IPage[][]
    future: IPage[][]
  }>({
    previous: [],
    future: []
  })

  private _migrationState = signalState({
    pages: '',
    elements: ''
  })
  private _currentProject = this._projectCollectionService.getProjectById(this.projectId).$$ as Signal<IProjectStorage>
  private _pageOrders = computed(
    () => {
      return this._currentProject()?.pages || []
    },
    { equal: _.isEqual }
  )

  private _initialized$ = toObservable(this._initialized)

  constructor() {
    merge(this._pageDbService.migrationState$.pipe(filter(state => !!state)), this._elementCollectionService.migrationState$.pipe(filter(state => !!state)))
      .pipe(takeUntil(toObservable(this.isUpgrading).pipe(filter(upgrading => !upgrading))))
      .subscribe(state => {
        patchState(this._migrationState, {
          [state.collectionName]: state.status
        })
      })

    this._projectCollectionService.isLeader$
      .pipe(
        filter(isLeader => isLeader),
        take(1)
      )
      .subscribe(() => {
        this._projectCollectionService.startReplication()
      })

    this._pageDbService.initialized$
      .pipe(
        filter(initialized => initialized),
        combineLatestWith(this._elementCollectionService.initialized$.pipe(filter(initialized => initialized))),
        take(1)
      )
      .subscribe(() => {
        // 设置同步状态
        this._subscription.add(
          merge(
            merge(this._pageDbService.replicaState.received$, this._elementCollectionService.replicaState.received$).pipe(
              tap(() => this.status.set('receiving'))
            ),
            merge(this._pageDbService.replicaState.sent$, this._elementCollectionService.replicaState.sent$).pipe(tap(() => this.status.set('sending')))
          )
            .pipe(
              buffer(
                merge(
                  this._pageDbService.replicaState.active$.pipe(filter(active => !active)),
                  this._elementCollectionService.replicaState.active$.pipe(filter(active => !active))
                )
              )
            )
            .subscribe(() => {
              this.status.set('idle')
            })
        )
        // 从数据库读写数据初始化pagelist
        firstValueFrom(
          zip(
            this._pageDbService.collection.find({ selector: {} }).$.pipe(filter(docs => docs.length > 0)),
            this._elementCollectionService.collection.find({ selector: {} }).$
          )
        ).then(() => this.resetPageList())

        // 监听数据库变化，更新pagelist
        const dbChange$ = merge(
          this._pageDbService.collection.checkpoint$.pipe(map(checkpoint => ({ collection: 'pages', checkpoint }))),
          this._elementCollectionService.collection.checkpoint$.pipe(map(checkpoint => ({ collection: 'elements', checkpoint }))),
          this._projectCollectionService.collection.checkpoint$.pipe(map(checkpoint => ({ collection: 'projects', checkpoint })))
        )

        this._subscription.add(
          dbChange$
            .pipe(
              debounceTime(100),
              // 排除由当前client引起的变化
              filter(({ collection, checkpoint }) => {
                if (collection === 'pages') {
                  return !_.isEqual(this._pageDbService.leaderCheckpoint(), checkpoint)
                } else if (collection === 'elements') {
                  return !_.isEqual(this._elementCollectionService.leaderCheckpoint(), checkpoint)
                } else if (collection === 'projects') {
                  return !_.isEqual(this._projectCollectionService.leaderCheckpoint(), checkpoint)
                }
                return false
              }),
              tap(change => {
                console.log('**************Db Change********************', change)
              })
            )
            .subscribe(() => {
              this.resetPageList()
            })
        )

        // 监听page collection接受的远端变化
        const receivePages$ = this._pageDbService.replicaState.received$.pipe(
          startWith(true),
          tap(data => console.log('**************Receive Page********************', data))
        )

        // 监听element collection接受的远端变化
        const receiveElements$ = this._elementCollectionService.replicaState.received$.pipe(
          startWith(true),
          tap(data => console.log('**************Receive Element********************', data))
        )

        const receiveProject$ = this._projectCollectionService.replicaState.received$.pipe(
          startWith(true),
          tap(data => console.log('**************Receive Project********************', data))
        )
        const dbChangesSubscription = receivePages$
          .pipe(combineLatestWith(receiveElements$, receiveProject$), skip(1))
          .pipe(debounceTime(100))
          .subscribe(data => {
            console.log('**************Reset Page List********************')
            this.resetPageList()
          })
        this._subscription.add(dbChangesSubscription)
      })
  }

  get initialized$() {
    return this._initialized$
  }

  get projectId() {
    return this._pageDbService.projectId
  }

  get pageLinkedListNodes() {
    return this._pageLinkedList.asReadonly()
  }

  destroy() {
    this._subscription.unsubscribe()
    this._elementCollectionService.destroy()
    this._pageDbService.destroy()
  }

  /**
   * 新增页面
   * @param params 新页面
   * @param position 插入位置，默认队尾
   */
  addPage(params: CreatePageParams, position?: number) {
    const page = {
      ...params,
      updatedAt: params.updatedAt || Date.now()
    }

    position = position ?? this._pageLinkedList().length
    if (position <= this._pageLinkedList().length && position >= 0) {
      this.pushState(this.currentPages())
      const pageList = this._pageLinkedList()
      const newPage = new PageListNode(page)
      pageList.splice(position, 0, newPage)
      this._pageLinkedList.set([...pageList])

      try {
        this._pageDbService
          .addPage({
            ...newPage.page,
            prev: null,
            next: null,
            version: DB_PAGES_VERSION
          })
          .then(() => {
            const pageOrder = this._pageLinkedList().map(node => node.id)
            Promise.all([
              this._projectCollectionService.changePageOrder(this.projectId, pageOrder),
              this._elementCollectionService.addElements(newPage.elements)
            ]).catch(error => {
              throw error
            })
          })
          .catch(error => {
            throw error
          })
        return newPage
      } catch (error) {
        console.error(error)
        this.undo()
        return null
      }
    } else {
      return null
    }
  }

  /**
   * 批量插入连续页面
   * @param params 新增页面列表
   * @param position 插入位置，默认队尾
   */
  addPages(params: CreatePageParams[], position?: number) {
    position = position || this._pageLinkedList().length
    if (position <= this._pageLinkedList().length && position >= 0) {
      this.pushState(this.currentPages())

      try {
        const pageList = this._pageLinkedList()
        const newPages = params.map(page => new PageListNode(page))
        pageList.splice(position, 0, ...newPages)
        this._pageLinkedList.set([...pageList])

        const elements = newPages.map(page => page.elements).flat()

        const pageOrder = this._pageLinkedList().map(node => node.id)
        this._projectCollectionService
          .changePageOrder(this.projectId, pageOrder)
          .then(() => {
            this._pageDbService
              .addPages([
                ...newPages.map(page => ({
                  ...page.page,
                  version: DB_PAGES_VERSION,
                  next: null,
                  prev: null
                }))
              ])
              .then(result => {
                this._elementCollectionService.addElements(elements).catch(error => {
                  throw error
                })
              })
              .catch(error => {
                throw error
              })
          })
          .catch(e => {
            throw new Error(`add pages failed: ${e}`)
          })

        return newPages
      } catch (e) {
        console.error(e)
        this.undo()
        return []
      }
    } else {
      throw new Error('invalid position')
    }
  }

  /**
   * 页面顺序重排
   * @param ids 页面ID列表
   */
  resetPageOrders(ids: string[]) {
    // const timeStamp = Date.now()
    this.pushState(this.currentPages())

    this._pageLinkedList.set(_.compact(ids.map(id => this._pageLinkedList().find(node => node.id === id))))
    this._projectCollectionService.changePageOrder(this.projectId, ids).catch(error => {
      console.error(error)
      this.undo()
    })
  }

  updatePage(page: UpdatePageParams, skipHistory = false) {
    let pageList = this._pageLinkedList()
    const pageNodeIndex = pageList.findIndex(node => node.id === page.id)
    const pageNode = pageList[pageNodeIndex]?.clone()

    if (pageNode) {
      if (!skipHistory) {
        this.pushState(this.currentPages())
      }

      const updatedAt = pageNode.updatePage(page)
      pageList = pageList.map(node => (node.id === page.id ? pageNode : node))
      this._pageLinkedList.set(pageList)
      this._pageDbService.updatePage({ ...page, updatedAt }).catch(error => {
        if (!skipHistory) {
          this.undo()
        }
        throw new Error(`update page ${page.id} failed: ${error}`)
      })
    }
  }

  resetPageOrder(ids: string[]) {
    this.pushState(this.currentPages())
    this._projectCollectionService.changePageOrder(this.projectId, ids).catch(error => {
      console.error(error)
      this.undo()
    })
  }

  updatePages(pages: UpdatePageParams[]) {
    this.pushState(this.currentPages())

    const pageList = this._pageLinkedList()
    const updatePages: PageListNode[] = []
    const newPageList = pageList.map(pageNode => {
      const updatePage = pages.find(page => page.id === pageNode.id)
      if (updatePage) {
        const newPage = pageNode.clone()
        newPage.updatePage(updatePage)
        updatePages.push(newPage)
        return newPage
      } else {
        return pageNode
      }
    })
    this._pageLinkedList.set([...newPageList])

    return this._pageDbService.upsertPages(updatePages.map(page => ({ ...page.page, prev: null, next: null })))
  }

  /**
   * 删除页面
   * @param id
   */
  deletePage(id: string) {
    this.pushState(this.currentPages())
    const pageList = this._pageLinkedList()
    const deleteIndex = pageList.findIndex(node => node.id === id)
    pageList.splice(deleteIndex, 1)
    this._pageLinkedList.set([...pageList])

    try {
      this._pageDbService
        .removePage(id)
        .then(() => {
          Promise.all([
            this._elementCollectionService.removeElements({ pageIds: [id] }),
            this._projectCollectionService.deleteProjectPages(this.projectId, [id])
          ]).catch(error => {
            throw error
          })
        })
        .catch(error => {
          throw error
        })
    } catch (error) {
      console.error(error)
      this.undo()
    }
  }

  /**
   * 删除多个页面
   * @param ids
   */
  deletePages(ids: string[]) {
    this.pushState(this.currentPages())
    const pageList = this._pageLinkedList()
    const newPages = pageList.filter(node => !ids.includes(node.id))
    this._pageLinkedList.set([...newPages])
    try {
      this._pageDbService
        .removePages(ids)
        .then(result => {
          const successIds = result.success.map(page => page.id)
          Promise.all([
            this._elementCollectionService.removeElements({ pageIds: successIds }),
            this._projectCollectionService.deleteProjectPages(this.projectId, successIds)
          ]).catch(error => {
            throw error
          })
        })
        .catch(error => {
          throw error
        })
    } catch (error) {
      console.error(error)
      this.undo()
    }
  }

  /**
   * 删除同一页面内的元素
   * @param pageId 页面ID
   * @param ids 元素ID列表
   */
  deletePageElements(pageId: string, ...ids: string[]) {
    const pageList = this._pageLinkedList()

    const pageNodeIndex = pageList.findIndex(node => node.id === pageId)
    const pageNode = pageList[pageNodeIndex]?.clone()
    if (pageNode) {
      this.pushState(this.currentPages())
      pageNode.deleteElements(...ids)
      pageList.splice(pageNodeIndex, 1, pageNode)
      this._pageLinkedList.set([...pageList])

      try {
        this._elementCollectionService
          .removeElements({ ids })
          .then(result => {
            const errorIds = result.error.map(err => {
              return err.documentId
            })
            const pageElementOrder = pageNode.orders.filter(id => !errorIds.includes(id))
            this._pageDbService.updatePage({ id: pageId, orders: pageElementOrder, updatedAt: pageNode.updatedAt }).catch(error => {
              // this._pageDbService.resetPageElementOrders(pageId, pageElementOrder, pageNode.updatedAt).catch(error => {
              throw error
            })
          })
          .catch(error => {
            throw error
          })
      } catch (e) {
        console.error(e)
        this.undo()
      }
    } else {
      throw new Error(`Page not found: ${pageId}`)
    }
  }

  async addPageElement(pageId: string, element: CreatePageElementParams, position?: number) {
    const pageList = this._pageLinkedList()
    const pageNodeIndex = pageList.findIndex(node => node.id === pageId)
    const pageNode = pageList[pageNodeIndex]?.clone()
    if (pageNode) {
      this.pushState(this.currentPages())

      const newElements = pageNode.addRootElement(element, position)
      pageList.splice(pageNodeIndex, 1, pageNode)
      this._pageLinkedList.set([...pageList])

      try {
        return this._pageDbService
          .updatePage({
            id: pageId,
            orders: pageNode.orders,
            updatedAt: pageNode.updatedAt
          })
          .then(() => {
            return this._elementCollectionService
              .addElements(newElements)
              .then(() => {
                return element
              })
              .catch(error => {
                throw error
              })
          })
          .catch(error => {
            throw error
          })
      } catch (e) {
        console.error(e)
        this.undo()
        return Promise.reject('add page element error')
      }
    } else {
      return Promise.reject('page not found')
    }
  }

  addPageElements(pageId: string, elements: CreatePageElementParams[], position?: number, skipHistory = false) {
    const pageList = this._pageLinkedList()
    const pageNodeIndex = pageList.findIndex(node => node.id === pageId)
    const pageNode = pageList[pageNodeIndex]?.clone()
    if (pageNode) {
      const current = this.currentPages()
      if (!skipHistory) {
        this.pushState(current)
      }

      const newElements = pageNode.addRootElements(elements, position)
      pageList.splice(pageNodeIndex, 1, pageNode)
      this._pageLinkedList.set([...pageList])

      try {
        return this._elementCollectionService
          .addElements(newElements)
          .then(result => {
            const errorIds = result.error.map(err => {
              return err.documentId
            })

            const pageElementOrder = pageNode.orders.filter(id => !errorIds.includes(id))
            return this._pageDbService
              .updatePage({
                id: pageId,
                orders: pageElementOrder,
                updatedAt: pageNode.updatedAt
              })
              .then(() => {
                return elements
              })
              .catch(error => {
                throw error
              })
          })
          .catch(error => {
            throw error
          })
      } catch (e) {
        if (!skipHistory) {
          this.undo()
        }
        return Promise.reject('add page elements error')
      }
    } else {
      return Promise.reject('add page elements error: page not found')
    }
  }

  updatePageElement(pageId: string, element: UpdatePageElement, skipHistory = false) {
    const pageList = this._pageLinkedList()
    const nodeIndex = pageList.findIndex(node => node.id === pageId)
    const pageNode = pageList[nodeIndex].clone()
    if (pageNode) {
      const existElement = pageNode.elements.find(e => e.id === element.id)
      if (existElement) {
        if (!skipHistory) {
          this.pushState(this.currentPages())
        }
        if (pageNode.updateElement({ ...element, updatedAt: Date.now() })) {
          pageList.splice(nodeIndex, 1, pageNode)
          this._pageLinkedList.set([...pageList])
          return this._elementCollectionService
            .updateElement({
              pageId,
              ...element
            })
            .catch(e => {
              if (!skipHistory) {
                console.error(`Update element ${element.id} failed: `, e)
                this.undo()
              }
            })
        } else {
          throw new Error(`Update element ${element.id} failed`)
        }
      } else {
        throw new Error(`Element not found: ${element.id}`)
      }
    } else {
      throw new Error(`Page not found: ${pageId}`)
    }
  }

  updatePageElements(pageId: string, elements: UpdatePageElement[], skipHistory = false) {
    const pageList = this._pageLinkedList()
    const nodeIndex = pageList.findIndex(node => node.id === pageId)
    const pageNode = pageList[nodeIndex].clone()
    if (pageNode) {
      if (!skipHistory) {
        this.pushState(this.currentPages())
      }
      const timestamp = Date.now()

      const updateElements = elements.map(el => ({ ...el, updatedAt: timestamp }))
      const result = pageNode.updateElements(updateElements)
      pageList.splice(nodeIndex, 1, pageNode)
      this._pageLinkedList.set([...pageList])
      return this._elementCollectionService.updateElements(pageId, result).catch(() => this.undo())
    } else {
      throw new Error(`Page not found: ${pageId}`)
    }
  }

  groupPageElements(pageId: string, group: Required<CreatePageElementParams<GroupType>>, elementIds: string[]) {
    const pageList = this._pageLinkedList()
    const pageNodeIndex = pageList.findIndex(node => node.id === pageId)
    const pageNode = pageList[pageNodeIndex].clone()
    if (pageNode) {
      let position = 0
      this.pushState(this.currentPages())

      const originElements = pageNode.rootElements()
      elementIds.map(id => {
        const index = pageNode.rootElements().findIndex(i => i.id === id)
        if (index > position) {
          position = index
        }
      })
      const reversePosition = pageNode.rootElements().length - position - 1
      pageNode.deleteElements(...elementIds)

      const insertPosition = pageNode.rootElements().length - reversePosition

      const newElements = pageNode.addRootElement(group, insertPosition)
      const newGroupElement = newElements[0]
      const childrenElement = newElements.slice(1)

      const deleteNodeIds: string[] = []
      originElements.forEach(origin => {
        const element = pageNode.elements.find(e => e.id === origin.id)
        if (element) {
        } else {
          deleteNodeIds.push(origin.id)
        }
      })

      pageList.splice(pageNodeIndex, 1, pageNode)
      this._pageLinkedList.set([...pageList])
      try {
        if (newGroupElement && childrenElement) {
          this._elementCollectionService
            .addElements([newGroupElement])
            .then(result => {
              if (result.error.length === 0) {
                Promise.all([
                  this._elementCollectionService.removeElements({ ids: deleteNodeIds }),
                  this._elementCollectionService.updateElements(pageId, childrenElement)
                ])
                  .then(() => {
                    this._pageDbService.updatePage({ id: pageId, orders: pageNode.orders, updatedAt: pageNode.updatedAt }).catch(err => {
                      // this._pageDbService.resetPageElementOrders(pageId, pageNode.orders, pageNode.updatedAt).catch(err => {
                      throw err
                    })
                  })
                  .catch(e => {
                    throw e
                  })
              } else {
                throw new Error('Group element add failed')
              }
            })
            .catch(e => {
              throw e
            })
        } else {
          throw new Error('Group element add failed')
        }
      } catch (e) {
        console.error(e)
        this.undo()
      }
    } else {
      throw new Error(`Page not found: ${pageId}`)
    }
  }

  ungroupPageElement(pageId: string, groupId: string, children: IRootElement[]) {
    const pageList = this._pageLinkedList()
    const pageNodeIndex = pageList.findIndex(node => node.id === pageId)
    const pageNode = pageList[pageNodeIndex].clone()

    if (!pageNode) throw new Error(`Page not found: ${pageId}`)

    const groupNode = pageNode.getElementNodeById(groupId)
    if (!groupNode) throw new Error(`Element not found: ${groupId}`)
    if (!(groupNode instanceof GroupElementTreeNode)) {
      throw new Error(`Element is not a group: ${groupId}`)
    }

    this.pushState(this.currentPages())

    const position = pageNode.orders.indexOf(groupId)
    pageNode.deleteElements(groupId)
    pageNode.addRootElements(children, position)

    pageList.splice(pageNodeIndex, 1, pageNode)
    this._pageLinkedList.set([...pageList])

    const updateElements: WithId<Partial<WithDeleted<IPageElementBase>>>[] = [
      ...children,
      {
        id: groupId,
        _deleted: true
      }
    ]
    try {
      this._elementCollectionService
        .updateElements(pageId, updateElements)
        .then(result => {
          // this._pageDbService.resetPageElementOrders(pageId, pageNode.orders, pageNode.updatedAt).catch(err => {
          this._pageDbService.updatePage({ id: pageId, orders: pageNode.orders, updatedAt: pageNode.updatedAt }).catch(err => {
            throw err
          })
        })
        .catch(err => {
          throw err
        })
    } catch (e) {
      console.error(e)
      this.undo()
    }
  }

  addGroupElementChildren(group: IGroupElement, targetIds: string[]) {
    const pageList = this._pageLinkedList()
    const pageNodeIndex = pageList.findIndex(node => node.id === group.pageId)
    const pageNode = pageList[pageNodeIndex]?.clone()

    if (pageNode) {
      // const timestamp = Date.now()
      const groupElement = pageNode.getElementNodeById(group.id)
      if (groupElement && groupElement instanceof GroupElementTreeNode) {
        this.pushState(this.currentPages())

        const updateElements = []
        const deleteElements: string[] = []

        updateElements.push(_.omit(group, 'children'))
        targetIds.forEach(id => {
          const element = pageNode.getElementNodeById(id)
          if (element) {
            const isGroup = element instanceof GroupElementTreeNode
            if (isGroup) {
              deleteElements.push(id)
            } else {
              const newChild = group.children.find(child => child.id === element.id)
              if (newChild) {
                updateElements.push(newChild)
              }
            }
          }
        })
        pageNode.updateElements(updateElements)
        pageNode.deleteElements(...deleteElements)
        pageList.splice(pageNodeIndex, 1, pageNode)
        this._pageLinkedList.set([...pageList])
        return this._elementCollectionService
          .updateElements(group.pageId, [...updateElements, ...deleteElements.map(id => ({ id, _deleted: true }))])
          .then(() => {
            return this._pageDbService
              .updatePage({ id: group.pageId, orders: pageNode.orders, updatedAt: pageNode.updatedAt })
              .then(() => group)
              .catch(() => {
                this.undo()
              })
          })
          .catch(() => {
            this.undo()
          })
      } else {
        throw new Error(`Element not found: ${group.id}`)
      }
    } else {
      throw new Error(`Page not found: ${group.pageId}`)
    }
  }

  resetElementsOrder(pageId: string, ids: string[]) {
    const pageList = this._pageLinkedList()
    const pageNodeIndex = pageList.findIndex(node => node.id === pageId)
    const pageNode = pageList[pageNodeIndex].clone()
    if (pageNode) {
      this.pushState(this.currentPages())

      const updatedAt = pageNode.resetElementOrders(ids)
      pageList.splice(pageNodeIndex, 1, pageNode)
      this._pageLinkedList.set([...pageList])

      this._pageDbService.updatePage({ id: pageId, orders: ids, updatedAt }).catch(() => {
        this.undo()
      })
    } else {
      throw new Error(`Page not found: ${pageId}`)
    }
  }

  undo() {
    if (this.historyPreviousSteps() === 0) {
      return
    }

    const recent = this._history.previous()[this.historyPreviousSteps() - 1] as IPage[]
    patchState(this._history, {
      previous: this._history.previous().slice(0, this.historyPreviousSteps() - 1),
      future: [...this._history.future(), this.currentPages()]
    })
    // const orderedPages = orderedArrayToListNodes(recent)
    const newOrders: string[] = []
    const orderedPageNodes = recent.map(page => {
      newOrders.push(page.id)
      // const currentPageNode = this._pageLinkedList.getNodeById(page.id)
      // if (currentPageNode && currentPageNode.value.updatedAt === page.updatedAt) {
      //   const elementsEqual = page.elements.every((element, index) => {
      //     const currentElement = currentPageNode.value.flatElements().find(e => e.id === element.id)
      //     if (currentElement) {
      //       return currentElement.updatedAt === element.updatedAt
      //     } else {
      //       return false
      //     }
      //   })
      //   if (elementsEqual) {
      //     return currentPageNode.value
      //   }
      // }
      return new PageListNode(page)
    })

    this._pageLinkedList.set([...orderedPageNodes])
    return Promise.all([
      this._projectCollectionService.changePageOrder(this.projectId, newOrders),
      this._pageDbService.reset(recent.map(page => _.omit(page, 'elements'))),
      this._elementCollectionService.reset(
        _.chain(recent)
          .map(page => page.elements)
          .flatten()
          .value()
      )
    ])
  }

  redo() {
    if (this.historyFutureSteps() === 0) {
      return
    }

    const recent = this._history.future()[this.historyFutureSteps() - 1]
    patchState(this._history, {
      previous: [...this._history.previous(), this.currentPages()],
      future: this._history.future().slice(0, this.historyFutureSteps() - 1)
    })

    // const orderedPages = orderedArrayToListNodes(recent)
    const newOrders: string[] = []
    const orderedPageNodes = recent.map(page => {
      newOrders.push(page.id)
      // const currentPageNode = this._pageLinkedList.getNodeById(page.id)
      // if (currentPageNode && currentPageNode.value.updatedAt === page.updatedAt) {
      //   const elementsEqual = page.elements.every((element, index) => {
      //     const currentElement = currentPageNode.value.flatElements().find(e => e.id === element.id)
      //     if (currentElement) {
      //       return currentElement.updatedAt === element.updatedAt
      //     } else {
      //       return false
      //     }
      //   })
      //   if (elementsEqual) {
      //     return currentPageNode.value
      //   }
      // }
      return new PageListNode(page)
    })

    this._pageLinkedList.set(orderedPageNodes)
    return Promise.all([
      this._projectCollectionService.changePageOrder(this.projectId, newOrders),
      this._pageDbService.reset(recent.map(page => _.omit(page, 'elements'))),
      this._elementCollectionService.reset(
        _.chain(recent)
          .map(page => page.elements)
          .flatten()
          .value()
      )
    ])
  }

  getPageById(id: string) {
    const pageList = this._pageLinkedList()
    const pageNode = pageList.find(node => node.id === id)
    if (pageNode) {
      return {
        ...pageNode.page,
        elements: pageNode.elements
      } as IPage
    } else {
      return undefined
    }
  }

  private pushState(state: IPage[]) {
    // this._history.previous.push(state)
    // this._history.future = []
    patchState(this._history, {
      previous: [...this._history.previous(), state],
      future: []
    })
  }

  /**
   * 更新pagelist
   * @private
   */
  private resetPageList() {
    if (!this._pageDbService.docs || !this._elementCollectionService.docs) {
      return
    }
    const pages = this._pageDbService.docs().map(doc => doc.toMutableJSON())
    const pageOrders = this._pageOrders()
    const orderedPages = _.compact(pageOrders.map(pageId => pages.find(page => page.id === pageId)))
    let elements = this._elementCollectionService.docs().map(doc => doc.toMutableJSON())

    const pageList = orderedPages.map(doc => {
      const pageElements: IPageElementBase[] = []
      elements = elements.filter(content => {
        if (content.pageId === doc.id) {
          pageElements.push(content)
          return false
        } else {
          return true
        }
      })

      return new PageListNode({
        ...doc,
        elements: pageElements,
        orders: doc.orders
      })
    })
    this._pageLinkedList.set([...pageList])
    if (!this._initialized()) {
      this._initialized.set(true)
    }
  }
}
