import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core'

import { ISize, ITextSetting } from '@libs/payload'

import { TextCharComponent } from './text-char/text-char.component'
import { TextParagraphComponent } from './text-paragraph/text-paragraph.component'

@Component({
  selector: 'ace-text-view',
  standalone: true,
  imports: [CommonModule, TextCharComponent, TextParagraphComponent],
  template: `
    <div #text class="pointer-events-none w-max whitespace-break-spaces break-words" [style.writing-mode]="setting().direction">
      <div>
        @for (paragraph of setting().paragraphs; let i = $index; track i) {
          <ace-text-paragraph
            [textSetting]="setting()"
            [paraSetting]="paragraph"
            [editing]="false"
            [preview]="true"
            [horizontalMode]="horizontalMode()"
            [size]="size()"
          ></ace-text-paragraph>
        }
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextViewComponent {
  setting = input.required<ITextSetting>()
  size = input.required<ISize>()
  horizontalMode = computed(() => this.setting().direction === 'horizontal-tb')
}
