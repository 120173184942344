import { CommonModule } from '@angular/common'
import { AfterViewInit, ChangeDetectionStrategy, Component, computed, ElementRef, inject, input, OnDestroy, Renderer2, signal, viewChild } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'

import { SVG } from '@svgdotjs/svg.js'
import { isString, omit } from 'lodash'
import { isEqual } from 'lodash-es'
import { distinctUntilChanged, map, Subscription } from 'rxjs'

import { documentMouseMoveListener } from '@libs/ng-shared/utils'
import { ILineSetting, ISize } from '@libs/payload'

import { fillColor, getDashArray } from '../shape'

@Component({
  selector: 'ace-line',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div #elementRef [style.width]="size().width + 'px'" [style.height]="size().height + 'px'">
      @if (selected()) {
        @for (handler of handlerList(); track $index) {
          <div
            class=" absolute h-4 w-4 cursor-pointer  rounded-full border border-black bg-white"
            [style.transform]="'translate(-50%, -50%) scale(' + 1 + ')'"
            [style]="handler.style"
            (mousedown)="handlerMouseDown($index)"
          ></div>
        }
      }
    </div>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineComponent implements AfterViewInit, OnDestroy {
  element = inject(ElementRef)
  // uiStore = inject(StageUiStore)
  // zoom = this.uiStore.zoom
  size = input.required<ISize>()
  selected = input(false)
  setting = input.required<ILineSetting>()

  stroke = computed(() => this.setting().stroke)
  category = computed(() => this.setting().category)
  path = computed(() => {
    const points = this.setting().points
    const category = this.category()
    if (category === 'straight') {
      const [start, end] = points
      return [['M', start[0], start[1]].join(' '), ['L', end[0], end[1]].join(' ')].join(',')
    } else {
      return ''
    }
  })
  stroke$ = toObservable(this.stroke)
  size$ = toObservable(this.size)

  elementRef = viewChild.required<ElementRef<HTMLDivElement>>('elementRef')

  _renderer2 = inject(Renderer2)
  _subscription = new Subscription()

  handlerList = computed<
    Array<{
      type: 'start' | 'end' | 'middle'
      style: object
    }>
  >(() => {
    const category = this.category()
    // const plot = this.plot()

    // if (category === 'straight' && plot.length) {
    //   return plot.map((point, index) => {
    //     const [, x, y] = point
    //     return {
    //       type: index === 0 ? 'start' : 'end',
    //       style: {
    //         left: `${x}px`,
    //         top: `${y}px`
    //       }
    //     }
    //   })
    // } else {
    return []
    // }
  })

  dragging = signal(false)
  dragging$ = toObservable(this.dragging)
  ngAfterViewInit() {
    const draw = SVG().addTo(this.elementRef().nativeElement)
    const line = draw.path()
    const group = draw.group().add(line)
    // const interactionLine = draw.path().stroke({
    //   color: 'none',
    //   opacity: 0,
    //   width: 57,
    //   linecap: 'butt'
    // })
    // draw.add(interactionLine)
    draw.add(group)
    this._renderer2.setStyle(draw.node, 'overflow', 'visible')
    this._renderer2.setStyle(draw.node, 'pointer-events', 'none')
    this._renderer2.setStyle(line.node, 'pointer-events', 'none')
    // this._renderer2.setStyle(interactionLine.node, 'pointer-events', 'auto')

    this._subscription.add(
      this.stroke$
        .pipe(
          map(s => omit(s, 'color')),
          distinctUntilChanged((prev, next) => isEqual(prev, next))
        )
        .subscribe(stroke => {
          line
            .stroke({
              width: stroke.width,
              dasharray: getDashArray(stroke.style),
              linecap: stroke.cap
            })
            .y(stroke.width / 2)
          // interactionLine
          //   .stroke({
          //     width: Math.max(57, stroke.width)
          //   })
          //   .y(stroke.width / 2)
        })
    )

    this._subscription.add(
      fillColor(
        draw,
        this.stroke$.pipe(
          map(s => s.color),
          distinctUntilChanged(isEqual)
        ),
        color => {
          if (isString(color)) {
            line.stroke({ color })
          } else {
            line.stroke({ color: `url(#${color.id()})` })
          }
        }
      )
    )
  }

  ngOnDestroy() {
    this._subscription.unsubscribe()
  }

  handlerMouseDown(index: number) {
    let startX: number, startY: number
    documentMouseMoveListener({
      start: event => {
        this.dragging.set(true)
        startX = event.screenX
        startY = event.screenY
      },
      move: event => {},
      end: event => {
        console.log('end', event.screenX)
        this.dragging.set(false)
      }
    })
  }
}
