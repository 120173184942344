import { MigrationStrategies } from 'rxdb/dist/types/types/plugins/migration'

import { ElementTypeEnum, IPageElementBase } from '@libs/payload'

export function migration1(oldDoc: any, collection: any) {
  oldDoc.version = 1
  return oldDoc
}

export function migration2(oldDoc: any, collection: any): IPageElementBase<ElementTypeEnum.Image> {
  console.log('Migrating image element', oldDoc)
  oldDoc.version = 2
  if (oldDoc.category === 'image') {
    const setting = oldDoc.setting
    const filterSrc = setting.filterSrc
    const filterVersion = setting.filterVersion
    const shadow = setting.shadow
    const flip = setting.adjustment.flip
    const opacity = setting.adjustment.opacity

    delete setting.filterSrc
    delete setting.filterVersion
    delete setting.shadow
    delete setting.adjustment.flip
    delete setting.adjustment.opacity

    setting.flip = flip
    setting.opacity = opacity
    setting.adjustment.src = filterSrc
    setting.adjustment.version = filterVersion
    setting.adjustment.shadow = shadow
    setting.adjustment.filter = {
      enable: setting.adjustment.enable || false,
      grayscale: setting.adjustment.color.grayscale,
      invert: setting.adjustment.color.invert,
      sepia: setting.adjustment.color.sepia,
      brownie: setting.adjustment.color.brownie,
      vintage: setting.adjustment.color.vintage,
      sharpen: setting.adjustment.color.sharpen,
      emboss: setting.adjustment.color.emboss,
      technicolor: setting.adjustment.color.technicolor,
      polaroid: setting.adjustment.color.polaroid,
      kodachrome: setting.adjustment.color.kodachrome,
      blackwhite: setting.adjustment.color.blackwhite
    }
    setting.adjustment.color = {
      enable: setting.adjustment.color.enable,
      brightness: setting.adjustment.color.brightness,
      contrast: setting.adjustment.color.contrast,
      saturation: setting.adjustment.color.saturation,
      vibrance: setting.adjustment.color.vibrance,
      noise: setting.adjustment.color.noise,
      pixelate: setting.adjustment.color.pixelate,
      blur: setting.adjustment.color.blur,
      hue: setting.adjustment.color.hue
    }
  }
  return oldDoc
}

export const elementMigrations: MigrationStrategies = {
  1: migration1,
  2: migration2
}
