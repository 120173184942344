import { inject, Injectable } from '@angular/core'

import { asyncScheduler, Subject, throttleTime } from 'rxjs'

import { IPosition, IShadowElement } from '@libs/payload'

import { StageUiStore } from '../store/stage-ui.store'
import { TextStore } from '../store/text.store'

@Injectable({
  providedIn: 'root'
})
export class TextService {
  private textStore = inject(TextStore)
  private uiStore = inject(StageUiStore)

  // input event
  private inputSubject$ = new Subject<null>()

  private setting = this.textStore.setting

  private scale = this.textStore.scale

  private zoom = this.uiStore.zoom

  private position = this.textStore.position

  private size = this.textStore.size

  private rotation = this.textStore.rotation

  private parent = this.textStore.parent

  constructor() {
    // TODO inactive 会丢失duration内的信息
    this.inputSubject$
      .pipe(
        // skip(1),
        throttleTime(300, asyncScheduler, { leading: false, trailing: true })
      )
      .subscribe(() => {
        console.log('this.uiStore.resetInteractingElement')

        // console.log((this.uiStore.interacting.shadowData().setting as ITextSetting).paragraphs[0].chars[0].text)
        // if (!this.uiStore.interacting.id()) debugger

        this.uiStore.resetInteractingElement()
      })
  }

  resizeText(sourceType: 'config' | 'size' | 'input' | 'focus' = 'config') {
    const setting = this.setting()
    if (!setting) return

    // console.log('---------------------------resize text---------------------------')
    let el = document.querySelector('.editing-text')
    if (!el) el = document.querySelector('.selected-text')
    if (!el) return

    const scale = this.scale()
    const parentScale = this.parent()?.scale || 1
    const position = this.position()
    const parentPosition = this.parent()?.position || { x: 0, y: 0 }
    let x = parentPosition.x + position.x * parentScale
    let y = parentPosition.y + position.y * parentScale
    const rotation = this.rotation()
    const parentRotation = this.parent()?.rotation || 0
    const rotated = rotation + parentRotation !== 0
    const currentWidth = this.size().width * scale * parentScale
    const currentHeight = this.size().height * scale * parentScale

    const horizontal = setting.direction === 'horizontal-tb'
    const pageWidth = this.uiStore.onStagePage()?.size.width || 0
    const pageHeight = this.uiStore.onStagePage()?.size.height || 0
    const pageZoom = this.zoom()

    // const pEl = el.querySelector('ace-text-input-paragraph p.top-p') as HTMLElement
    const pEl = el.querySelector('p.top-p') as HTMLElement
    const pShadowEl = el.querySelector('p.shadow-p') as HTMLElement

    // 放大后会有误差，+1 px
    const beyond = x < -1 || x + currentWidth > pageWidth + 1 || y < -1 || y + currentHeight > pageHeight + 1
    // const beyond = x < 0 || x + currentWidth > pageWidth || y < 0 || y + currentHeight > pageHeight

    const autoSize = setting.autoSize && !rotated && !beyond && sourceType !== 'size'

    const data: Partial<IShadowElement> = {}
    // autoSize时先取max-content，再去计算各种位置
    let rect
    // 用来设置size，超出时会重新计算
    let width, height

    if (autoSize) {
      // 位置随时会移动，所以每次都要重置
      if (horizontal) {
        // 重置顶边时的最大宽度
        pEl.style.maxWidth = 'unset'
        // 重置超出画布时的固定宽度
        pEl.style.width = 'max-content'
        pEl.style.height = 'auto'
      } else {
        pEl.style.maxHeight = 'unset'
        pEl.style.height = 'max-content'
        pEl.style.width = 'auto'
      }

      // autoSize时先取max-content，再去计算各种位置
      rect = el.getBoundingClientRect()
      // 用来设置size，超出时会重新计算
      width = rect.width / pageZoom
      height = rect.height / pageZoom

      const textAlign = this.textStore.selectedConfig()?.paragraphs[0].textAlign
      if (horizontal) {
        let maxWidth = 0

        if (textAlign === 'center') {
          const centerX = currentWidth / 2 + x
          if (centerX <= pageWidth / 2) {
            x = centerX - width / 2
            if (x < 0) {
              width = centerX * 2
              x = 0
              maxWidth = width
            }
          } else {
            x = centerX - width / 2
            if (x + width > pageWidth) {
              width -= (x + width - pageWidth) * 2
              x = pageWidth - width
              maxWidth = width
            }
          }
        } else if (textAlign === 'end') {
          x = currentWidth + x - width
          if (x < 0) {
            width = width + x
            x = 0
            maxWidth = width
          }
        } else {
          if (x + width > pageWidth) {
            width = pageWidth - x
            maxWidth = width
          }
        }

        pEl.style.width = width / scale / parentScale + 'px'
        // 顶边时会设置最大宽度，需要重新计算高度
        if (maxWidth !== 0) {
          pEl.style.maxWidth = width / scale / parentScale + 'px'
          rect = el.getBoundingClientRect()
          height = rect.height / pageZoom
        }
      } else {
        let maxHeight = 0

        if (textAlign === 'center') {
          const centerY = currentHeight / 2 + y
          if (centerY <= pageHeight / 2) {
            y = centerY - height / 2
            if (y < 0) {
              height = centerY * 2
              y = 0
              maxHeight = height
            }
          } else {
            y = centerY - height / 2
            if (y + height > pageHeight) {
              height -= (y + height - pageHeight) * 2
              y = pageHeight - height
              maxHeight = height
            }
          }
        } else if (textAlign === 'end') {
          y = currentHeight + y - height
          if (y < 0) {
            height = height + y
            y = 0
            maxHeight = height
          }
        } else {
          if (y + height > pageHeight) {
            height = pageHeight - y
            maxHeight = height
          }
        }

        pEl.style.height = height / scale / parentScale + 'px'
        if (maxHeight !== 0) {
          pEl.style.maxHeight = height / scale / parentScale + 'px'
          rect = el.getBoundingClientRect()
          width = rect.width / pageZoom
        }

        if (Math.abs(width - currentWidth) > 1) {
          x -= width - currentWidth
        }
      }

      if (this.parent()) {
        x = (x - parentPosition.x) / parentScale
        y = (y - parentPosition.y) / parentScale
      }

      data.position = {
        x,
        y
      }
    } else {
      if (horizontal) {
        width = currentWidth
        // autoSize 时固定宽度
        pEl.style.maxWidth = this.size().width + 'px'
        // 超出画布时，固定宽度
        pEl.style.width = this.size().width + 'px'
        pEl.style.height = 'auto'
        // 宽度确定后，再自适应高度
        rect = el.getBoundingClientRect()
        if (rotated) {
          height = this.getActualHeight(rect.width / pageZoom, rect.height / pageZoom, currentWidth, rotation + parentRotation)
        } else {
          height = rect.height / pageZoom
        }
      } else {
        height = currentHeight
        pEl.style.maxHeight = this.size().height + 'px'
        pEl.style.height = this.size().height + 'px'
        pEl.style.width = 'auto'
        rect = el.getBoundingClientRect()
        if (rotated) {
          width = this.getActualWidth(rect.width / pageZoom, rect.height / pageZoom, currentHeight, rotation + parentRotation)
        } else {
          width = rect.width / pageZoom
        }
        if (rotation === 0 && Math.abs(width - currentWidth) > 1) {
          x -= width - currentWidth
          data.position = {
            x,
            y
          }
        }
      }

      if (rotation !== 0) {
        data.position = this.adjustPositionForResizeWithRotation(
          position.x,
          position.y,
          currentWidth / parentScale,
          currentHeight / parentScale,
          width / parentScale,
          height / parentScale,
          rotation,
          horizontal
        )
      }
    }

    data.size = {
      width: width / scale / parentScale,
      height: height / scale / parentScale
    }

    if (sourceType === 'size') {
      this.uiStore.setInteractShadowData(this.textStore.id() as string, {
        ...this.uiStore.interacting.shadowData(),
        setting: {
          ...this.setting(),
          autoSize: false,
          version: Date.now()
        }
      })

      const rowChanged = (!horizontal && Math.abs(currentWidth - width) * pageZoom > 1) || (horizontal && Math.abs(currentHeight - height) * pageZoom > 1)
      if (!rowChanged) return
    }

    this.uiStore.setInteractShadowData(this.textStore.id() as string, {
      ...this.uiStore.interacting.shadowData(),
      ...data
    })

    const resized = Math.abs(currentWidth - width) * pageZoom > 1 || Math.abs(currentHeight - height) * pageZoom > 1

    if (sourceType === 'focus' && resized) {
      this.uiStore.resetInteractingElement()
    }

    if (sourceType === 'input') {
      // sync by throttle
      // console.error('this.textContent.next(null)', this.uiStore.interacting.id())
      // if (!this.uiStore.interacting.id()) debugger
      this.inputSubject$.next(null)
    }
  }

  getActualHeight(rectWidth: number, rectHeight: number, elementWidth: number, angleDegrees: number) {
    const angleRadians = Math.abs(angleDegrees) * (Math.PI / 180)

    const sinTheta = Math.sin(angleRadians)
    const cosTheta = Math.cos(angleRadians)

    const actualHeight = (rectHeight - elementWidth * sinTheta) / cosTheta

    return Math.abs(actualHeight)
  }

  getActualWidth(rectWidth: number, rectHeight: number, elementHeight: number, angleDegrees: number) {
    const angleRadians = Math.abs(angleDegrees) * (Math.PI / 180)

    const sinTheta = Math.sin(angleRadians)
    const cosTheta = Math.cos(angleRadians)

    const actualWidth = (rectWidth - elementHeight * sinTheta) / cosTheta

    return Math.abs(actualWidth)
  }

  /**
   * Adjust position for resize with rotation
   * @param x current position.x
   * @param y current position.y
   * @param w current size.width
   * @param h current size.height
   * @param newW new size.width
   * @param newH new size.height
   * @param angleDegrees rotation
   * @param horizontal whether horizontal
   * @returns new position
   */
  adjustPositionForResizeWithRotation(
    x: number,
    y: number,
    w: number,
    h: number,
    newW: number,
    newH: number,
    angleDegrees: number,
    horizontal: boolean
  ): IPosition {
    const radians = Math.atan(h / w)
    const radius = Math.sqrt((w / 2) ** 2 + (h / 2) ** 2)
    const radiusNew = Math.sqrt((newW / 2) ** 2 + (newH / 2) ** 2)
    const radiansNew = Math.atan(newH / newW)

    // calculate the current center point
    const xCenter = x + w / 2
    const yCenter = y + h / 2

    let xCenterNew, yCenterNew
    if (horizontal) {
      const angleRadians = radians + angleDegrees * (Math.PI / 180)

      // calculate the current left top point
      x = xCenter - radius * Math.cos(angleRadians)
      y = yCenter - radius * Math.sin(angleRadians)

      const angleRadiansNew = radiansNew + angleDegrees * (Math.PI / 180)

      // calculate the new center point
      xCenterNew = x + Math.cos(angleRadiansNew) * radiusNew
      yCenterNew = y + Math.sin(angleRadiansNew) * radiusNew
    } else {
      const angleRadians = radians - angleDegrees * (Math.PI / 180)

      // calculate the current right top point
      x = xCenter + radius * Math.cos(angleRadians)
      y = yCenter - radius * Math.sin(angleRadians)

      const angleRadiansNew = radiansNew - angleDegrees * (Math.PI / 180)

      // calculate the new center point
      xCenterNew = x - Math.cos(angleRadiansNew) * radiusNew
      yCenterNew = y + Math.sin(angleRadiansNew) * radiusNew
    }
    // calculate the new position
    const newX = xCenterNew - newW / 2
    const newY = yCenterNew - newH / 2

    return { x: newX, y: newY }
  }
}
