import { VirtualGroupElementTreeNode } from '@libs/ng-shared/models'
import { Direction, Direction2, IPosition, IProject, IShadowElement, ISize } from '@libs/payload'

/**
 * 辅助线类型
 */
export enum GuideType {
  horizontal = 'horizontal',
  vertical = 'vertical'
}

/**
 * 辅助线
 */
export interface Guide {
  id: string
  type: GuideType
  position: number
  hidden?: boolean
}

export enum ChangeAction {
  Setting = 2,
  Page = 1,
  None = 0
}

export type targetType = 'page' | 'element' | 'background'

export interface IStageUi {
  currentProject: IProject
  onStagePageId: string // 工作页面id
  // onStagePage: PageListNode | undefined // 工作页面
  selectedTarget: targetType // 选中的对象类型
  selectedIdsSet: Set<string> // 选中的对象id
  highLightIds: string[] // 高亮的对象id
  virtualElement: VirtualGroupElementTreeNode | undefined // 虚拟元素，用于显示多选元素的外框
  guideLines: Guide[] // 辅助线
  pageOffset: IPosition // page距离最上边和最左边的距离
  zoom: number // 页面缩放比例
  ruleEnable: boolean // 是否显示标尺
  guideLocked: boolean
  keydown: Set<string> // 按下的键
  interacting: {
    id: string
    moving: {
      offset: IPosition // 鼠标点击位置与元素左上角的偏移
      position: IPosition | undefined // 移动中的位置
    } // 移动中的元素
    resizing: {
      handler: Direction | Direction2 // 调整大小的方向
      startPosition: IPosition // 调整大小的起始位置
      endPosition: IPosition | undefined // 调整大小的结束位置
      size: ISize | undefined // 调整后的大小
    } // 调整大小中的元素
    scaling: {
      handler: Direction // 缩放的方向
      startPosition: IPosition // 缩放起始位置
      endPosition: IPosition | undefined // 缩放中的位置
    } // 缩放中的元素
    rotating: {
      origin: IPosition // 旋转中心
      position: IPosition | undefined // 旋转中的鼠标位置
      // rotation: number | undefined // 旋转角度
    } // 旋转中的元素
    // setting: ITextSetting | IImageSetting | IChartSetting | IShapeSetting | ILineSetting | undefined
    shadowData: IShadowElement
  }
  marqueeSelectArea: { top: number; left: number; right: number; bottom: number } | null
}

export interface IGuidLine {
  type: GuideType
  position: number
  from: number
  to: number
}
