import { Injectable } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import _ from 'lodash'
import { RxDatabase } from 'rxdb'

import { IPage, IPageStorage, UpdatePageParams } from '@libs/payload'

import { CollectionBase } from './db.base'
import { DB_PAGES_VERSION } from './db.schema'

@Injectable()
export class PageCollectionService extends CollectionBase<Required<IPageStorage>> {
  // pages$ = this.collection.find({ selector: {} }).$$ as Signal<PageDocument[]>

  constructor(private _route: ActivatedRoute) {
    const projectID = _route.snapshot.params['projectId'] as string
    const db = _route.snapshot.data['projectDB'] as RxDatabase

    super(db, 'pages', DB_PAGES_VERSION, true, projectID)
    // this.startReplication()
  }

  async updatePage(change: UpdatePageParams) {
    this.savingState.next(true)
    if (_.has(change, 'orders')) {
      if (!change.orders) {
        throw new Error('Orders cannot be empty')
      }
    }
    const doc = await this.collection.findOne(change.id).exec()
    if (doc) {
      return doc
        .incrementalPatch({
          ...change,
          updatedAt: change.updatedAt || Date.now()
        })
        .finally(() => this.savingState.next(false))
    } else {
      throw new Error('Page not found')
    }
  }

  async upsertPages(pages: IPageStorage[]) {
    this.savingState.next(true)

    const timestamp = Date.now()
    return this.collection
      .bulkUpsert(
        pages.map(page => ({
          ...page,
          updatedAt: page.updatedAt || timestamp
        }))
      )
      .finally(() => this.savingState.next(false))
  }

  async removePage(id: string) {
    const doc = await this.collection.findOne(`${id}`).exec()
    // const timestamp = Date.now()
    if (doc) {
      this.savingState.next(true)
      return doc.incrementalRemove().finally(() => this.savingState.next(false))
    } else {
      return new Promise((_resolve, reject) => {
        reject('Document not found')
      })
    }
  }

  async removePages(ids: string[]) {
    this.savingState.next(true)
    return this.collection.bulkRemove(ids).finally(() => this.savingState.next(false))
  }

  async addPage(page: IPageStorage) {
    this.savingState.next(true)

    const timestamp = Date.now()

    return this.collection
      .incrementalUpsert({
        ...page,
        // createdAt: timestamp,
        updatedAt: page.updatedAt || timestamp
      })
      .finally(() => this.savingState.next(false))
  }

  async addPages(pages: IPageStorage[]) {
    this.savingState.next(true)

    const timestamp = Date.now()
    return this.collection
      .bulkInsert(
        pages.map(page => ({
          ...page,
          // createdAt: timestamp,
          updatedAt: page.updatedAt || timestamp,
          version: DB_PAGES_VERSION
        }))
      )
      .finally(() => this.savingState.next(false))
  }

  async reset(pages: Omit<IPage, 'elements'>[]) {
    if (!this.docs) {
      throw new Error('Collection not initialized')
    }
    const docs = this.docs()

    this.savingState.next(true)

    const updatedAt = Date.now()
    const deleteIds = docs.filter(doc => !pages.find(c => c.id === doc.id)).map(doc => doc.id)

    return await Promise.all([
      this.collection.bulkRemove(deleteIds),
      this.collection.bulkUpsert(
        pages.map(page => ({
          ...page,
          updatedAt
        }))
      )
    ]).finally(() => this.savingState.next(false))
    // return this.upsertPages(pages)
  }
}
