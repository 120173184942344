import { inject, Injectable } from '@angular/core'
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop'

import _ from 'lodash'
import { divide as MathDivide } from 'mathjs'
import { combineLatest, Observable, of } from 'rxjs'
import { combineLatestWith, distinctUntilChanged, map, switchMap } from 'rxjs/operators'

import { IColor } from '@editorup/charts'
import { SettingSchema, ST } from '@editorup/settings'
import { ElementTypeEnum, ICharSetting, IPageElementBase, IParaSetting, ITextSetting } from '@libs/payload'

import {
  colorRowResolver,
  iconButtonToggleResolver,
  numberInputResolver,
  selectFontResolver,
  selectNumberResolver
} from '../../components/setting/setting-widget'
import { ColorRowComponent } from '../../components/setting/setting-widget/color-row'
import { IconButtonToggleComponent } from '../../components/setting/setting-widget/icon-button-toggle'
import { NumberInputComponent } from '../../components/setting/setting-widget/number-input'
import { SelectFontComponent } from '../../components/setting/setting-widget/select-font'
import { SelectNumberComponent } from '../../components/setting/setting-widget/select-number'
import { StageUiStore } from '../../store/stage-ui.store'
import { TextStore } from '../../store/text.store'
import { TextService } from '../text.service'
import { WorkspaceService } from '../workspace.service'

@Injectable({
  providedIn: 'root'
})
export class TextSettingService {
  uiStore = inject(StageUiStore)
  textStore = inject(TextStore)
  textService = inject(TextService)
  workspaceService = inject(WorkspaceService)

  textElement = this.textStore.element

  elementId = this.textStore.id

  range = this.textStore.range

  selectedParagraphs = this.textStore.selectedParagraphs

  selectedChars = this.textStore.selectedChars

  elementScale = this.textStore.scale
  elementScale$ = toObservable(this.elementScale)
  shadowScale$ = toObservable(this.uiStore.interacting.shadowData.scale)
  zoom = this.uiStore.zoom
  position = this.textStore.position
  size = this.textStore.size

  selectedConfig = this.textStore.selectedConfig
  selectedConfig$ = toObservable(this.selectedConfig).pipe(takeUntilDestroyed(), distinctUntilChanged())

  locked$ = toObservable(this.textStore.locked).pipe(takeUntilDestroyed(), distinctUntilChanged())
  hasParent$ = toObservable(this.textStore.hasParent).pipe(takeUntilDestroyed(), distinctUntilChanged())
  // disabled$ = combineLatest([this.locked$, this.hasParent$]).pipe(
  //   map(([locked, hasParent]) => locked || hasParent),
  disabled$ = combineLatest([this.locked$]).pipe(
    map(([locked]) => !!locked),
    takeUntilDestroyed(),
    distinctUntilChanged()
  )

  getConfigObservable(
    level: 'text' | 'paragraph' | 'char',
    key: keyof ITextSetting | keyof IParaSetting | keyof ICharSetting | 'textStyle',
    scale: boolean = false
  ) {
    const observable = this.selectedConfig$.pipe(
      map(config => {
        if (!config) return undefined

        let data: any
        switch (level) {
          case 'text':
            data = (config as ITextSetting)[key as keyof ITextSetting]
            break
          case 'paragraph': {
            const values = new Set<ITextSetting['paragraphs'][number][keyof IParaSetting]>()
            ;(config as ITextSetting).paragraphs.forEach(p => values.add(p[key as keyof IParaSetting]))
            if (values.size === 1) data = Array.from(values)[0]
            break
          }
          case 'char': {
            if (key === 'textStyle') {
              const styles = {
                bold: true,
                italic: true,
                underline: true,
                lineThrough: true
              }
              config.paragraphs.forEach(p => {
                p.chars.forEach(c => {
                  if (c.fontWeight !== 'bold') styles.bold = false
                  if (c.fontStyle !== 'italic') styles.italic = false
                  if (!c.textDecoration.includes('underline')) styles.underline = false
                  if (!c.textDecoration.includes('line-through')) styles.lineThrough = false
                })
              })
              const activeStyles: string[] = []
              if (styles.bold) activeStyles.push('bold')
              if (styles.italic) activeStyles.push('italic')
              if (styles.underline) activeStyles.push('underline')
              if (styles.lineThrough) activeStyles.push('line-through')
              data = activeStyles
            } else if (key === 'color' || key === 'textStrokeColor') {
              const _key = key as keyof ICharSetting
              const values: Array<IColor> = []
              config.paragraphs.forEach(p => {
                p.chars.forEach(c => {
                  values.push(c[_key] as IColor)
                })
              })
              if (values.every(i => i.color === values[0].color && i.opacity === values[0].opacity)) data = Array.from(values)[0]
            } else {
              const _key = key as keyof ICharSetting
              const values = new Set<ITextSetting['paragraphs'][number]['chars'][number][keyof ICharSetting]>()
              config.paragraphs.forEach(p => {
                p.chars.forEach(c => {
                  // if (c[_key]) values.add(c[_key])
                  values.add(c[_key])
                })
              })
              if (values.size === 1) data = Array.from(values)[0]
            }
            // else {
            // if (typeof Array.from(values)[0] === 'boolean') data = false
            // else data = ''
            // }
            break
          }
        }
        // return scale ? this.multiply(data) : data
        return data
      })
    )
    if (scale) return this.multiply(observable)
    return observable
  }

  multiply = (source$: Observable<number | number[] | undefined>): Observable<number | number[] | undefined> =>
    this.elementScale$.pipe(
      combineLatestWith(source$, this.shadowScale$),
      switchMap(([scale = 1, value, shadowScale]) => {
        const scaleValue = shadowScale === undefined ? scale : shadowScale
        return of(value === undefined || isNaN(Number(value)) ? value : Array.isArray(value) ? value.map(v => v * scaleValue) : value * scaleValue)
      })
    )

  divide = (a: unknown) => {
    const val = Number(a)
    if (isNaN(Number(val))) {
      return a
    }
    return MathDivide(val, this.elementScale())
  }

  updateText = <T extends ITextSetting | IParaSetting | ICharSetting, K extends keyof ITextSetting | keyof IParaSetting | keyof ICharSetting | 'textStyle'>(
    level: 'text' | 'paragraph' | 'char',
    key: any,
    _data: any,
    preview: boolean = false,
    scale: boolean = false
  ) => {
    if (!this.textElement()) return
    const textElement = this.textElement() as IPageElementBase<ElementTypeEnum.Text>
    const setting = _.cloneDeep(textElement.setting)
    const data = scale ? this.divide(_data) : _data

    switch (level) {
      case 'text':
        if (key === 'direction' && data !== setting.direction) {
          const width = textElement.size.width
          const height = textElement.size.height
          this.uiStore.setInteractShadowData(this.elementId() as string, {
            ...this.uiStore.interacting.shadowData(),
            size: {
              width: height,
              height: width
            }
          })
        }

        ;(setting as any)[key] = data
        break
      case 'paragraph': {
        const selectedParagraphs = this.selectedChars().size === 0 ? setting.paragraphs : this.selectedParagraphs().map(i => setting.paragraphs[i])
        selectedParagraphs.forEach(p => {
          ;(p as any)[key] = data
        })
        break
      }
      case 'char':
        if (this.selectedChars().size === 0) {
          setting.paragraphs.forEach(p => {
            p.chars.forEach(c => {
              if (key === 'textStyle') {
                c.fontWeight = data.includes('bold') ? 'bold' : 'normal'
                c.fontStyle = data.includes('italic') ? 'italic' : 'normal'
                c.textDecoration = []
                if (data.includes('underline')) c.textDecoration.push('underline')
                if (data.includes('line-through')) c.textDecoration.push('line-through')
              } else (c as any)[key] = data
            })
          })
        } else {
          this.selectedParagraphs().forEach(index => {
            this.selectedChars()
              .get(index)
              ?.forEach(i => {
                const prop = setting.paragraphs[index].chars[i] as any
                if (key === 'textStyle') {
                  prop.fontWeight = data.includes('bold') ? 'bold' : 'normal'
                  prop.fontStyle = data.includes('italic') ? 'italic' : 'normal'
                  prop.textDecoration = []
                  if (data.includes('underline')) prop.textDecoration.push('underline')
                  if (data.includes('line-through')) prop.textDecoration.push('line-through')
                } else (prop as any)[key] = data
              })
          })
        }
        break
      default:
        break
    }

    this.uiStore.setSettingElement(this.elementId() as string, setting)

    // queueMicrotask(() => {
    requestAnimationFrame(() => {
      // setTimeout(() => {
      this.textService.resizeText()
      if (!preview) {
        // console.log('this.uiStore.resetInteractingElement()')
        this.uiStore.resetInteractingElement()
      }
    })
    // })
  }

  getSettingSchema(): SettingSchema[] {
    const updateText = this.updateText.bind(this)

    return [
      {
        title: '基础属性',
        icon: 'ss:base',
        interaction: 'none',
        children: [
          ...this.workspaceService.getSettingSchema({
            handler: {
              beforeCommit: path => {
                return new Promise(resolve => {
                  if (path === 'size') {
                    // setTimeout(() => {
                    queueMicrotask(() => {
                      this.textService.resizeText('size')

                      this.uiStore.setInteractShadowData(this.elementId() as string, {
                        ...this.uiStore.interacting.shadowData(),
                        setting: {
                          ...this.textElement()?.setting,
                          autoSize: false,
                          version: Date.now()
                        }
                      })
                      resolve(true)
                    })
                  } else {
                    resolve(true)
                  }
                })
              }
            }
          })
        ]
      },
      {
        title: '文本',
        icon: 'ss:text',
        interaction: 'none',
        children: [
          {
            title: '方向',
            child: {
              widget: iconButtonToggleResolver,
              inputs: {
                value: this.getConfigObservable('text', 'direction'),
                multiple: false,
                disabled: this.disabled$,
                options: [
                  {
                    value: 'horizontal-tb',
                    icon: 'custom:text-horizontal'
                  },
                  {
                    value: 'vertical-rl',
                    icon: 'custom:text-vertical'
                  }
                ]
              },
              outputs: {
                valueChange: val => updateText('text', 'direction', val)
              }
            } as ST<IconButtonToggleComponent>
          },
          {
            title: '对齐',
            child: {
              widget: iconButtonToggleResolver,
              inputs: {
                value: this.getConfigObservable('paragraph', 'textAlign'),
                multiple: false,
                disabled: this.disabled$,
                options: [
                  {
                    value: 'start',
                    icon: 'ss:align-left'
                  },
                  {
                    value: 'center',
                    icon: 'ss:align-center'
                  },
                  {
                    value: 'end',
                    icon: 'ss:align-right'
                  },
                  {
                    value: 'justify',
                    icon: 'ss:align-justify'
                  }
                ]
              },
              outputs: {
                valueChange: val => updateText('paragraph', 'textAlign', val)
              }
            } as ST<IconButtonToggleComponent>
          },
          {
            title: '行间距',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: this.getConfigObservable('paragraph', 'lineHeight'),
                aceMinValue: 0.5,
                aceMaxValue: 2.5,
                aceNumberPrecision: 2,
                aceSlideStep: 0.01,
                useSlide: true,
                aceDisabled: this.disabled$
              },
              outputs: {
                valueChange: val => updateText('paragraph', 'lineHeight', val),
                valueSlideChange: val => updateText('paragraph', 'lineHeight', val.value, val.dragging)
              }
            } as ST<NumberInputComponent>
          },
          {
            title: '字间距',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: this.getConfigObservable('char', 'letterSpacing'),
                aceMinValue: -0.4,
                aceMaxValue: 4,
                aceNumberPrecision: 1,
                aceSlideStep: 0.1,
                useSlide: true,
                aceDisabled: this.disabled$
              },
              outputs: {
                valueChange: val => updateText('char', 'letterSpacing', val),
                valueSlideChange: val => updateText('char', 'letterSpacing', val.value, val.dragging)
              }
            } as ST<NumberInputComponent>
          },
          {
            title: '字体',
            child: {
              widget: selectFontResolver,
              inputs: {
                value: this.getConfigObservable('char', 'fontFamily')
                // aceDisabled: this.disabled$
              },
              outputs: {
                valueChange: val => updateText('char', 'fontFamily', val)
              }
            } as ST<SelectFontComponent>
          },
          {
            title: '字号',
            child: {
              widget: selectNumberResolver,
              inputs: {
                value: this.getConfigObservable('char', 'fontSize', true),
                // aceDisabled: this.disabled$,
                // aceMinValue: 0,
                // aceMaxValue: 120,
                aceOptions: [12, 14, 16, 18, 21, 24, 28, 32, 36, 42, 48, 56, 64, 72, 80, 88, 96, 104, 120]
              },
              outputs: {
                valueChange: val => updateText('char', 'fontSize', val as number, false, true)
              }
            } as ST<SelectNumberComponent>
          },
          {
            title: '颜色',
            child: {
              widget: colorRowResolver,
              inputs: {
                value: this.getConfigObservable('char', 'color')
                // aceDisabled: this.disabled$
              },
              outputs: {
                valueChange: val => updateText('char', 'color', val.color as IColor)
              }
            } as ST<ColorRowComponent>
          },
          {
            title: '样式',
            child: {
              widget: iconButtonToggleResolver,
              inputs: {
                value: this.getConfigObservable('char', 'textStyle'),
                multiple: true,
                disabled: this.disabled$,
                options: [
                  {
                    value: 'bold',
                    icon: 'ss:text-bold'
                  },
                  {
                    value: 'italic',
                    icon: 'ss:text-italic'
                  },
                  {
                    value: 'line-through',
                    icon: 'ss:text-line-through'
                  },
                  {
                    value: 'underline',
                    icon: 'ss:text-underline'
                  }
                ]
              },
              outputs: {
                valueChange: val => updateText('char', 'textStyle', val)
              }
            } as ST<IconButtonToggleComponent>
          }
        ]
      },
      {
        title: '描边',
        icon: 'ss:stroke',
        interaction: 'switch',
        active: this.getConfigObservable('char', 'textStroke') as unknown as boolean,
        activeChange: state => updateText('char', 'textStroke', state),
        children: [
          {
            title: '描边颜色',
            child: {
              widget: colorRowResolver,
              inputs: {
                value: this.getConfigObservable('char', 'textStrokeColor'),
                // aceDisabled: this.disabled$,
                gradient: false
              },
              outputs: {
                valueChange: val => updateText('char', 'textStrokeColor', val.color as IColor)
              }
            } as ST<ColorRowComponent>
          },
          {
            title: '描边粗细',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: this.getConfigObservable('char', 'textStrokeWidth'),
                aceDisabled: this.disabled$,
                aceMinValue: 0,
                aceMaxValue: 100,
                useSlide: true
              },
              outputs: {
                valueChange: val => updateText('char', 'textStrokeWidth', val),
                valueSlideChange: val => updateText('char', 'textStrokeWidth', val.value, val.dragging)
              }
            } as ST<NumberInputComponent>
          }
        ]
      },
      {
        title: '整体投影',
        icon: 'ss:shadow',
        interaction: 'switch',
        active: this.getConfigObservable('text', 'textShadow') as unknown as boolean,
        activeChange: state => updateText('text', 'textShadow', state),
        children: [
          {
            title: '颜色',
            child: {
              widget: colorRowResolver,
              inputs: {
                value: this.getConfigObservable('text', 'textShadowColor'),
                // aceDisabled: this.disabled$,
                gradient: false
              },
              outputs: {
                valueChange: val => updateText('text', 'textShadowColor', val.color as IColor, val.dragging)
              }
            } as ST<ColorRowComponent>
          },
          {
            title: '偏移',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: this.getConfigObservable('text', 'textShadowOffset'),
                aceDisabled: this.disabled$,
                aceMinValue: 0,
                aceMaxValue: 100,
                useSlide: true
              },
              outputs: {
                valueChange: val => updateText('text', 'textShadowOffset', val),
                valueSlideChange: val => updateText('text', 'textShadowOffset', val.value, val.dragging)
              }
            } as ST<NumberInputComponent>
          },
          {
            title: '角度',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: this.getConfigObservable('text', 'textShadowAngle'),
                aceDisabled: this.disabled$,
                aceMinValue: 0,
                aceMaxValue: 360,
                useSlide: true
              },
              outputs: {
                valueChange: val => updateText('text', 'textShadowAngle', val),
                valueSlideChange: val => updateText('text', 'textShadowAngle', val.value, val.dragging)
              }
            } as ST<NumberInputComponent>
          },
          {
            title: '模糊',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: this.getConfigObservable('text', 'textShadowBlur'),
                aceDisabled: this.disabled$,
                aceMinValue: 0,
                aceMaxValue: 100,
                useSlide: true
              },
              outputs: {
                valueChange: val => updateText('text', 'textShadowBlur', val),
                valueSlideChange: val => updateText('text', 'textShadowBlur', val.value, val.dragging)
              }
            } as ST<NumberInputComponent>
          }
        ]
      }
    ]
  }
}
