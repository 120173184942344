export const env = {
  production: true,
  api: {
    baseUrl: 'https://api.editorup.com',
    refreshTokenEnabled: true,
    refreshTokenType: 're-request'
  },
  websocket: {
    endpoint: 'http://localhost:6008/api/v1/ws',
    reconnectInterval: 5 // retry interval, unit: second
  }
}
