import { computed, effect, inject, Injector, runInInjectionContext } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals'
import _, { remove } from 'lodash'

import { boundingRects, childrenRealSetting, childrenRelativeSetting } from '@libs/algorithm'
import { withLogger } from '@libs/ng-core/store'
// import { withLogger } from '#core/store/features'
import { PageElementTreeNode, VirtualGroupElementTreeNode } from '@libs/ng-shared/models'
import {
  CreatePageElementParams,
  CreateVirtualElementParams,
  Direction,
  Direction2,
  ElementSettingType,
  IChartSetting,
  IImageSetting,
  ILineSetting,
  IPageElementBase,
  IPosition,
  IProjectUpdateReq,
  IShadowElement,
  IShapeSetting,
  ISize,
  ITextSetting,
  UpdatePageElement
} from '@libs/payload'

import { EDITOR_API, ProjectService } from '../services'
import { ChangeAction, Guide, IStageUi, targetType } from './index'

interface RuleState {
  [key: string]: { locked: boolean; lines: Guide[]; enable: boolean }
}

const initialStageUi: Omit<IStageUi, 'currentProject'> = {
  onStagePageId: '',
  // onStagePage: undefined,
  selectedTarget: 'page',
  selectedIdsSet: new Set([]),
  highLightIds: [],
  guideLines: [],
  virtualElement: undefined,
  pageOffset: {
    x: 0,
    y: 0
  },
  zoom: 1,
  ruleEnable: true,
  guideLocked: false,
  keydown: new Set([]),
  interacting: {
    id: '',
    moving: {
      offset: { x: NaN, y: NaN },
      position: undefined
    },
    resizing: {
      handler: 's',
      startPosition: { x: NaN, y: NaN },
      endPosition: undefined,
      size: undefined
    },
    scaling: {
      handler: 'nw',
      startPosition: { x: NaN, y: NaN },
      endPosition: undefined
    },
    rotating: {
      origin: { x: NaN, y: NaN },
      position: undefined
      // rotation: undefined
    },
    // setting: undefined,
    shadowData: {
      position: {},
      size: {},
      scale: undefined,
      rotation: undefined,
      setting: {}
    }
  },
  marqueeSelectArea: null
}

export const StageUiStore = signalStore(
  withState<IStageUi>(() => {
    const route = inject(ActivatedRoute)
    const project = inject(ProjectService)
    const currentProject = route.snapshot.data['project']
    if (currentProject) {
      const page = project.getPageById(currentProject.pages[0])
      const ruleState: RuleState = JSON.parse(localStorage.getItem('rule_state') || '{}')
      const projectId = currentProject.id
      const current = ruleState[projectId]
      const guideState = {
        guideLocked: current?.locked || false,
        ruleEnable: current?.enable || false,
        guideLines: current?.lines || []
      }
      if (page) {
        // const onStagePage = new PageListNode(page)
        return {
          ...initialStageUi,
          currentProject,
          // onStagePage,
          ...guideState
        } as IStageUi
      } else {
        return {
          ...initialStageUi,
          currentProject,
          ...guideState
        } as IStageUi
      }
    } else {
      throw new Error('No project data found')
    }
  }),
  withComputed((store, projectService = inject(ProjectService)) => ({
    onStagePage: computed(() => {
      return projectService.pageLinkedListNodes().find(pageNode => pageNode.id === store.onStagePageId())
    }),
    // 是否可以开始在page上移动元素
    isMovingOnPageReady: computed(() => {
      const { x, y } = store.interacting.moving().offset
      return !(isNaN(x) || isNaN(y))
    }),
    // 是否可以开始在page上调整元素缩放
    isScalingOnPageReady: computed(() => {
      const { x, y } = store.interacting.scaling.startPosition()
      return !(isNaN(x) || isNaN(y))
    }),
    // 是否可以开始在page上调整元素大小
    isResizingOnPageReady: computed(() => {
      const { x, y } = store.interacting.resizing.startPosition()
      return !(isNaN(x) || isNaN(y))
    }),
    // 是否可以开始在page上旋转元素
    isRotatingOnPageReady: computed(() => {
      const { x, y } = store.interacting.rotating.origin()
      return !(isNaN(x) || isNaN(y))
    })
  })),
  withComputed(store => ({
    // 当前页面尺寸
    pageSize: computed(() => {
      return store.onStagePage()?.size || { width: 1440, height: 900 }
    }),
    // 当前页面主题
    theme: computed(() => {
      return store.onStagePage()?.theme || { colors: [] }
    }),
    // 选中的元素或页面id
    selectedIds: computed<string[]>(() => Array.from(store.selectedIdsSet())),
    // 选中的元素
    selectedElements: computed(() => {
      if (store.selectedTarget() === 'element') {
        const elements: IPageElementBase[] = store.onStagePage()?.elements || []
        return elements.filter(element => store.selectedIdsSet().has(element.id))
      } else {
        return []
      }
    }),

    selectedRootElements: computed<PageElementTreeNode[]>(() => {
      if (store.selectedTarget() === 'element') {
        // const groups: GroupElementTreeNode[] = []
        const onStagePage = store.onStagePage()
        if (onStagePage) {
          const selectedIds = Array.from(store.selectedIdsSet().values())
          return _.chain(selectedIds)
            .sort((a, b) => {
              return onStagePage.orders.indexOf(a) - onStagePage.orders.indexOf(b)
            })
            .map(id => {
              const selected = onStagePage.getElementNodeById(id)
              if (selected) {
                if (selected.parent) {
                  return onStagePage.getElementNodeById(selected.parent)
                } else {
                  return selected
                }
              } else {
                return null
              }
            })
            .compact()
            .uniqBy('id')
            .value()
          // onStagePage.childElements().forEach(child => {
          //   if (store.selectedIdsSet().has(child.id)) {
          //     const parent = onStagePage.getRootElementById(child.parent)
          //     if (parent) {
          //       groups.push(parent as GroupElementTreeNode)
          //     }
          //   }
          // })
          // return _.chain(onStagePage.elementTreeNodes())
          //   .filter(el => store.selectedIdsSet().has(el.id))
          //   .concat(groups)
          //   .uniqBy('id')
          //   .value()
        } else {
          return []
        }
      } else {
        return []
      }
    }),
    // 交互中的元素
    interactingElement: computed<PageElementTreeNode | undefined>(() => {
      const elementId = store.interacting.id()
      if (elementId === store.virtualElement()?.id) {
        return store.virtualElement()
      } else {
        const page = store.onStagePage()
        if (page) {
          return page.getElementNodeById(elementId)
        } else {
          return undefined
        }
      }
    }),

    // 是否有元素正在移动
    isMovingElement: computed(
      () => {
        const position = store.interacting.shadowData.position()
        if (!_.isEmpty(position)) {
          if (store.isMovingOnPageReady()) {
            return ChangeAction.Page
          } else {
            return ChangeAction.Setting
          }
        }
        return ChangeAction.None
      },
      { equal: () => false }
    ),

    // 是否有元素正在缩放
    isScalingElement: computed(
      () => {
        return store.interacting.shadowData.scale() !== undefined ? ChangeAction.Page : ChangeAction.None
      },
      { equal: () => false }
    ),

    // 是否有元素正在调整大小
    isResizingElement: computed(
      () => {
        const size = store.interacting.shadowData.size()
        if (!_.isEmpty(size)) {
          if (!store.isResizingOnPageReady()) {
            return ChangeAction.Setting
          } else {
            return ChangeAction.Page
          }
        }
        return ChangeAction.None
      },
      { equal: () => false }
    ),
    // 是否有元素正在旋转
    isRotatingElement: computed(
      () => {
        const rotation = store.interacting.shadowData.rotation()
        if (rotation !== undefined) {
          if (!store.isRotatingOnPageReady()) {
            return ChangeAction.Setting
          } else {
            return ChangeAction.Page
          }
        } else {
          return ChangeAction.None
        }
      },
      { equal: () => false }
    ),
    // 是否按住shift键
    isShiftKeydown: computed(() => store.keydown().has('ShiftLeft') || store.keydown().has('ShiftRight'))
  })),
  withComputed(store => ({
    // 是否有正在交互的元素
    isElementInteracting: computed(
      () => {
        // return !!store.interacting().id
        const setting = store.interacting.shadowData.setting()
        return (
          store.isResizingElement() !== ChangeAction.None ||
          store.isRotatingElement() !== ChangeAction.None ||
          store.isMovingElement() !== ChangeAction.None ||
          store.isScalingElement() !== ChangeAction.None ||
          !_.isEmpty(setting)
        )
      },
      { equal: () => false }
    ),
    // 是否有正在page上交互的元素
    isElementInteractingOnPage: computed(() => {
      return (
        store.isRotatingElement() === ChangeAction.Page ||
        store.isResizingElement() === ChangeAction.Page ||
        store.isScalingElement() === ChangeAction.Page ||
        store.isMovingElement() === ChangeAction.Page
      )
    }),
    isElementReadyInteractionOnPage: computed(() => {
      return store.isResizingOnPageReady() || store.isRotatingOnPageReady() || store.isMovingOnPageReady() || store.isScalingOnPageReady()
    })
    // keydown: computed(() => store.keydown().values(), {
    //   equal: (a, b) => _.isEqual(a, b)
    // })
  })),
  withComputed((store, project = inject(ProjectService)) => ({
    canUndo: computed(() => project.historyPreviousSteps() > 0),
    canRedo: computed(() => project.historyFutureSteps() > 0)
  })),
  withMethods(store => ({
    updateVirtualElement: () => {
      const virtualElement = store.virtualElement()?.clone()
      const elements = store.selectedRootElements()

      if (virtualElement) {
        const { x, y, width, height } = boundingRects(
          elements.map(el => ({
            position: el.position,
            size: el.size,
            rotation: el.rotation * (Math.PI / 180),
            scale: el.scale
          })),
          virtualElement.rotation * (Math.PI / 180)
        )

        virtualElement.update({
          position: { x, y },
          size: { width, height },
          updatedAt: Date.now()
        })
        const newChildren = childrenRelativeSetting(
          virtualElement.data,
          elements.map(el => ({
            ...el.data,
            position: {
              x: el.position.x,
              y: el.position.y
            },
            children: el.children
          }))
        ) as CreatePageElementParams[]
        virtualElement.resetChildren(VirtualGroupElementTreeNode.createVirtualChildren(newChildren))

        patchState(store, {
          virtualElement
        })
      }
    }
  })),
  withMethods((store, project = inject(ProjectService), api = inject(EDITOR_API), injector = inject(Injector)) => ({
    updateProject: (id: string, data: IProjectUpdateReq) => {
      api.updateProject(id, data).subscribe(() => {
        patchState(store, {
          currentProject: {
            ...store.currentProject(),
            ...data
          }
        })
      })
    },
    updateOnStagePage: (page: string) => {
      patchState(store, { onStagePageId: page })
    },
    updateSelectedElements: (elements: UpdatePageElement[]) => {
      const page = store.onStagePage()
      if (page) {
        project.updatePageElements(page.id, elements)
      }
    },
    /**
     * 重置选中对象
     * @param target page: 选中页面, element: 选中元素
     * @param ids 选中的对象id
     */
    resetSelection: (target: targetType, ...ids: string[]) => {
      const newState: Partial<IStageUi> = {
        selectedTarget: target,
        selectedIdsSet: new Set(ids),
        interacting: initialStageUi.interacting,
        virtualElement: undefined
      }
      if (target === 'page') {
        if (ids.length === 0 && store.selectedTarget() === 'page') {
          return
        }
        if (ids.length === 1) {
          const page = project.getPageById(ids[0])
          if (page) {
            newState.onStagePageId = page.id
          }
        }
      } else if (target === 'element') {
        if (_.isEqual(ids, store.selectedIds())) {
          return
        }
      }
      patchState(store, newState)
    },
    /**
     * 添加选中对象
     * @param ids
     */
    addSelection: (...ids: string[]) => patchState(store, { selectedIdsSet: new Set([...store.selectedIdsSet(), ...ids]) }),
    /**
     * 移除选中对象
     * @param ids
     */
    removeSelection: (...ids: string[]) => {
      const selectedIds = new Set([...store.selectedIds()].filter(id => !ids.includes(id)))
      if (selectedIds.size === 0 && store.selectedTarget() === 'element') {
        patchState(store, { selectedTarget: 'background', selectedIdsSet: selectedIds })
      } else {
        patchState(store, { selectedIdsSet: selectedIds })
      }
    },
    /**
     * 设置虚拟元素
     * @param element
     */
    setVirtualElement: (element: CreateVirtualElementParams) => {
      const { children, ...group } = element
      patchState(store, { virtualElement: new VirtualGroupElementTreeNode(group, children) })
    },
    /**
     * 清除虚拟元素
     */
    clearVirtualElement: () => patchState(store, { virtualElement: undefined }),
    setGuides: (lines: Guide[]) => {
      patchState(store, { guideLines: lines })
    },
    // addGuide: guid => {},
    setPageOffset: (offset: IPosition) =>
      patchState(store, {
        pageOffset: offset
      }),
    // setPageSize: (size: { width: number; height: number }) => patchState(store, { pageSize: size }),
    // setTheme: (theme: { colors: string[] }) => patchState(store, { theme: theme }),
    setPageZoom: (zoom: number) => {
      patchState(store, { zoom: Number(zoom.toFixed(2)) })
    },
    setRuleEnable: (enable: boolean) => {
      patchState(store, { ruleEnable: enable })
    },
    setGuideLocked: (locked: boolean) => {
      patchState(store, { guideLocked: locked })
    },
    /**
     * 清空交互元素, 触发数据库更新
     */
    resetInteractingElement: (options?: { skipHistory: boolean }) => {
      const element = store.interactingElement()
      const pageId = store.onStagePage()?.id
      const shadowData = store.interacting.shadowData()
      if (pageId && element && !_.isEqual(shadowData, initialStageUi.interacting.shadowData)) {
        const virtualElement = store.virtualElement()?.clone()
        if (virtualElement && virtualElement.id === element.id) {
          const children = virtualElement.children || []
          const { position, size, rotation, scale } = store.interacting.shadowData()
          const vPosition = (_.isEmpty(position) ? virtualElement.position : position) as IPosition
          const vScale = scale ?? 1
          const vRotation = (!_.isUndefined(rotation) ? rotation - virtualElement.rotation : 0) * (Math.PI / 180)
          const vSize = (_.isEmpty(size) ? virtualElement.size : size) as ISize

          const realChildren = childrenRealSetting<IPageElementBase>(
            {
              ...virtualElement.data,
              position: vPosition,
              size: vSize,
              rotation: rotation ?? virtualElement.rotation,
              scale: vScale
            },
            children.map(child => ({
              ...child.data,
              position: child.position,
              size: child.size,
              rotation: child.rotation,
              scale: child.scale,
              children: child.children
            }))
          )

          project
            .updatePageElements(
              pageId,
              realChildren.map(child => ({
                id: child.id,
                position: child.position,
                size: child.size,
                scale: child.scale,
                rotation: child.rotation,
                setting: child.setting
              })),
              !!options?.skipHistory
            )
            .then(() => {
              virtualElement.update({
                size: {
                  width: vSize.width * vScale,
                  height: vSize.height * vScale
                },
                position: vPosition,
                rotation: rotation ?? virtualElement.rotation,
                scale: 1
              })

              const newChildren = childrenRelativeSetting(virtualElement.data, realChildren)

              virtualElement.resetChildren(VirtualGroupElementTreeNode.createVirtualChildren(newChildren))

              patchState(store, {
                interacting: {
                  ...initialStageUi.interacting,
                  id: store.interacting.id()
                },
                virtualElement: virtualElement
              })
            })
        } else {
          const props: UpdatePageElement = { id: element.id }
          if (!_.isEmpty(shadowData.position)) {
            const { x, y } = shadowData.position as IPosition
            props.position = {
              x: Number(x.toFixed(2)),
              y: Number(y.toFixed(2))
            }
          }
          if (!_.isEmpty(shadowData.size)) {
            const { width, height } = shadowData.size as ISize
            props.size = {
              width: Number(width.toFixed(2)),
              height: Number(height.toFixed(2))
            }
          }
          if (!_.isUndefined(shadowData.scale)) {
            props.scale = shadowData.scale
          }
          if (!_.isUndefined(shadowData.rotation)) {
            props.rotation = Number(shadowData.rotation.toFixed(2))
          }
          if (!_.isEmpty(shadowData.setting)) {
            props.setting = shadowData.setting as ElementSettingType
          }
          if (_.keys(props).length > 1) {
            const originInteracting = store.interacting()
            project.updatePageElement(element.pageId, props, !!options?.skipHistory).then(() => {
              runInInjectionContext(injector, () => {
                const newStore = inject(StageUiStore)
                const tempShadowData = newStore.interacting.shadowData()
                if (_.isEqual(tempShadowData, originInteracting.shadowData)) {
                  patchState(store, { interacting: initialStageUi.interacting })
                } else {
                  patchState(store, {
                    interacting: {
                      ...initialStageUi.interacting,
                      id: store.interacting.id(),
                      shadowData: tempShadowData
                    }
                  })
                }
              })
            })
          } else {
            patchState(store, { interacting: initialStageUi.interacting })
          }
        }
      } else {
        patchState(store, { interacting: initialStageUi.interacting })
      }
    },
    /**
     * 设置移动中的元素
     * @param id
     * @param change offset: 初始偏移量, position: 移动中的位置
     */
    setDraggingElement: (id: string, change: { offset?: IPosition; position?: IPosition }) => {
      if (change.offset) {
        const { x: offsetX, y: offsetY } = change.offset
        patchState(store, {
          interacting: {
            ...(initialStageUi.interacting as IStageUi['interacting']),
            id,
            moving: {
              offset: {
                x: Number(offsetX.toFixed(2)),
                y: Number(offsetY.toFixed(2))
              },
              position: undefined
            },
            shadowData: initialStageUi.interacting.shadowData
          }
        })
      } else if (change.position) {
        const offset = store.interacting.moving.offset()
        const { x, y } = change.position
        const positionX = Number(x.toFixed(2))
        const positionY = Number(y.toFixed(2))
        if (!store.isMovingOnPageReady()) {
          // 如果从setting面板设置的元素移动，需要同时更新shadowData
          patchState(store, {
            interacting: {
              ...(initialStageUi.interacting as IStageUi['interacting']),
              id,
              moving: {
                offset,
                position: {
                  x: positionX,
                  y: positionY
                }
              },
              shadowData: {
                ...store.interacting.shadowData(),
                position: {
                  x: positionX,
                  y: positionY
                }
              }
            }
          })
        } else {
          patchState(store, {
            interacting: {
              ...(initialStageUi.interacting as IStageUi['interacting']),
              id,
              moving: {
                offset,
                position: {
                  x: positionX,
                  y: positionY
                }
              },
              shadowData: store.interacting.shadowData()
            }
          })
        }
      }
    },
    /**
     * 设置调整大小中的元素
     * @param id
     * @param change handler: 方向, start: 起始位置, end: 结束位置
     */
    setResizingElement: (id: string, change: { handler?: Direction | Direction2; start?: IPosition; end?: IPosition; size?: ISize }) => {
      if (change.handler && change.start) {
        const { x: startX, y: startY } = change.start
        patchState(store, {
          interacting: {
            ...(initialStageUi.interacting as IStageUi['interacting']),
            id,
            resizing: {
              handler: change.handler,
              startPosition: {
                x: Number(startX.toFixed(2)),
                y: Number(startY.toFixed(2))
              },
              endPosition: undefined,
              size: undefined
            },
            shadowData: store.interacting.shadowData()
          }
        })
      } else if (change.end) {
        const { startPosition, handler } = store.interacting.resizing()
        const { x, y } = change.end
        patchState(store, {
          interacting: {
            ...(initialStageUi.interacting as IStageUi['interacting']),
            id,
            resizing: {
              startPosition,
              handler: handler,
              endPosition: {
                x: Number(x.toFixed(2)),
                y: Number(y.toFixed(2))
              },
              size: undefined
            },
            shadowData: store.interacting.shadowData()
          }
        })
      } else if (change.size) {
        const { width, height } = change.size
        const initialInteracting = initialStageUi.interacting as IStageUi['interacting']
        patchState(store, {
          interacting: {
            ...initialInteracting,
            id,
            resizing: {
              handler: initialInteracting.resizing.handler,
              startPosition: initialInteracting.resizing.startPosition,
              endPosition: undefined,
              size: {
                width: Number(width.toFixed(2)),
                height: Number(height.toFixed(2))
              }
            },
            shadowData: {
              ...store.interacting.shadowData(),
              size: {
                width: Number(width.toFixed(2)),
                height: Number(height.toFixed(2))
              }
            }
          }
        })
      }
    },
    /**
     * 设置缩放中的元素
     * @param id
     * @param change handler: 方向, position: 位置
     */
    setScalingElement: (id: string, change: { handler?: Direction; startPosition?: IPosition; position?: IPosition }) => {
      if (change.handler && change.startPosition) {
        const { x: startX, y: startY } = change.startPosition
        patchState(store, {
          interacting: {
            ...(initialStageUi.interacting as IStageUi['interacting']),
            id,
            scaling: {
              handler: change.handler,
              startPosition: {
                x: Number(startX.toFixed(2)),
                y: Number(startY.toFixed(2))
              },
              endPosition: undefined
            },
            shadowData: store.interacting.shadowData()
          }
        })
      } else if (change.position) {
        const { handler, startPosition } = store.interacting.scaling()
        const { x: endX, y: endY } = change.position
        patchState(store, {
          interacting: {
            ...(initialStageUi.interacting as IStageUi['interacting']),
            id,
            scaling: {
              handler,
              startPosition,
              endPosition: {
                x: Number(endX.toFixed(2)),
                y: Number(endY.toFixed(2))
              }
            },
            shadowData: store.interacting.shadowData()
          }
        })
      }
    },
    /**
     * 设置正在修改setting的元素
     * @param id
     * @param setting
     */
    setSettingElement: (id: string, setting: ITextSetting | IImageSetting | IChartSetting | IShapeSetting | ILineSetting) => {
      patchState(store, {
        interacting: {
          ...(initialStageUi.interacting as IStageUi['interacting']),
          id,
          // setting,
          shadowData: {
            ...store.interacting.shadowData(),
            setting: {
              ...setting,
              version: Date.now()
            }
          }
        }
      })
    },
    /**
     * 设置旋转中的元素
     * @param id
     * @param change origin: 旋转中心, endPosition: 旋转中的鼠标位置, rotation: 旋转角度
     */
    setRotatingElement: (id: string, change: { origin?: IPosition; endPosition?: IPosition; rotation?: number }) => {
      const initialInteraction = initialStageUi.interacting as IStageUi['interacting']
      if (change.origin) {
        patchState(store, {
          interacting: {
            ...initialInteraction,
            id,
            rotating: {
              origin: {
                x: Number(change.origin.x.toFixed(2)),
                y: Number(change.origin.y.toFixed(2))
              },
              position: undefined
              // rotation: undefined
            },
            shadowData: store.interacting.shadowData()
          }
        })
      } else if (change.endPosition) {
        if (!store.isRotatingOnPageReady()) {
          return
        }
        const x = Number(change.endPosition.x.toFixed(2))
        const y = Number(change.endPosition.y.toFixed(2))

        patchState(store, {
          interacting: {
            ...initialInteraction,
            id,
            rotating: {
              origin: store.interacting.rotating.origin(),
              position: { x, y }
            },
            shadowData: {
              ...store.interacting.shadowData()
            }
          }
        })
      } else if (!_.isUndefined(change.rotation)) {
        patchState(store, {
          interacting: {
            ...initialInteraction,
            id,
            rotating: {
              origin: initialInteraction.rotating.origin,
              position: undefined
              // rotation: Number(change.rotation.toFixed(2))
            },
            shadowData: { ...store.interacting.shadowData(), rotation: Number(change.rotation.toFixed(2)) }
          }
        })
      }
    },
    /**
     * 设置正在交互的元素的中间状态
     * @param id
     * @param data
     */
    setInteractShadowData: (id: string, data: Partial<IShadowElement>) => {
      patchState(store, {
        interacting: {
          ...store.interacting(),
          id,
          shadowData: {
            ...(initialStageUi.interacting as IStageUi['interacting']).shadowData,
            ...data
          }
        }
      })
    },
    setMarqueeSelectArea: (area: { top: number; left: number; right: number; bottom: number } | null) => {
      patchState(store, { marqueeSelectArea: area })
    },
    highLightElement: (id: string) => {
      if (store.marqueeSelectArea()) return
      if (store.highLightIds().includes(id)) return
      if (store.selectedIdsSet().has(id)) return
      patchState(store, {
        highLightIds: [...store.highLightIds(), id]
      })
    },
    removeHighLightElement: (id: string) => {
      if (store.marqueeSelectArea()) return
      if (!store.highLightIds().includes(id)) return
      patchState(store, {
        highLightIds: store.highLightIds().filter(highlightId => highlightId !== id)
      })
    },
    setHighLight: (ids: string[]) => {
      patchState(store, { highLightIds: ids })
    },
    /**
     * 设置按下的键
     * @param key
     */
    setKey: ($event: KeyboardEvent) => {
      const meta = $event.metaKey
      const ctrl = $event.ctrlKey
      const shift = $event.shiftKey
      const alt = $event.altKey
      const keys = [$event.key]
      if (meta) {
        keys.push('Meta')
      }
      if (ctrl) {
        keys.push('Control')
      }
      if (shift) {
        keys.push('Shift')
      }
      if (alt) {
        keys.push('Alt')
      }
      patchState(store, { keydown: new Set(keys) })
    },

    /**
     * redo
     */
    redo: () => {
      project.redo()
      patchState(store, { interacting: initialStageUi.interacting })
    },
    /**
     * undo
     */
    undo: () => {
      project.undo()
    }
  })),
  withHooks({
    onInit: store => {
      // 还原
      const projectId = store.currentProject().id
      const projectService = inject(ProjectService)
      effect(
        () => {
          const pages = projectService.pageLinkedListNodes()
          const onStagePageId = store.onStagePageId()
          if (!onStagePageId && pages.length > 0) {
            store.updateOnStagePage(pages[0].id)
          } else {
            const onStagePage = pages.find(page => page.id === onStagePageId)
            if (onStagePage) {
              store.updateOnStagePage(onStagePage.id)
            }
          }
        },
        {
          allowSignalWrites: true
        }
      )
      effect(() => {
        // 记录当前项目刻度尺的
        const cache: RuleState = JSON.parse(localStorage.getItem('rule_state') || '{}')
        const locked = store.guideLocked()
        const enable = store.ruleEnable()
        const lines = store.guideLines()
        if (!locked && !enable && lines.length === 0) {
          localStorage.removeItem('rule_state')
        } else {
          cache[projectId] = {
            locked: store.guideLocked(),
            lines: store.guideLines(),
            enable: store.ruleEnable()
          }
          localStorage.setItem('rule_state', JSON.stringify(cache))
        }
      })
    },
    onDestroy: () => {
      console.log('stage ui destroy')
    }
  })
  // withLogger('StageUiStore')
)
