import { computed, inject, Injectable } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'

import { produce } from 'immer'
import { cloneDeep, set } from 'lodash'
import { filter, map } from 'rxjs'

import { SettingSchema, ST } from '@editorup/settings'

import { ElementTypeEnum, IPageElementBase, IPageElementSetting } from '../../../../payload/src/element.interface'
import { buttonToggleResolver, colorRowResolver, numberInputResolver, selectNumberResolver, strokeStyleResolver } from '../../components/setting/setting-widget'
import { radiusOptions } from '../../components/setting/setting-widget/border-radius'
import { ButtonToggleComponent } from '../../components/setting/setting-widget/button-toggle'
import { ColorRowComponent } from '../../components/setting/setting-widget/color-row'
import { NumberInputComponent } from '../../components/setting/setting-widget/number-input'
import { SelectNumberComponent } from '../../components/setting/setting-widget/select-number'
import { OptionsKey } from '../../components/setting/setting-widget/setting'
import { StrokeStyleComponent } from '../../components/setting/setting-widget/stroke-style'
import { isShapeSetting } from '../../components/setting/utils'
import { StageUiStore } from '../../store/stage-ui.store'
import { WorkspaceService } from '../workspace.service'

@Injectable({
  providedIn: 'root'
})
export class ShapeSettingService {
  uiStore = inject(StageUiStore)
  workspaceService = inject(WorkspaceService)

  element = computed(() => {
    const selectedElements = this.uiStore.selectedElements()
    return selectedElements.length === 1 && isShapeSetting(selectedElements[0]) ? selectedElements[0] : null
  })

  element$ = toObservable(this.element).pipe(filter((e): e is IPageElementBase<ElementTypeEnum.Shape> => !!e))
  stroke$ = this.element$.pipe(map(e => e.setting.stroke))
  shadow$ = this.element$.pipe(map(e => e.setting.shadow))

  constructor() {}

  update = (path: OptionsKey<IPageElementBase<ElementTypeEnum.Shape>>, data: unknown, options?: { preview?: boolean }) => {
    const element = this.element()
    if (this.uiStore.isElementInteractingOnPage() || !element) {
      return
    }
    const { preview = false } = options || {}

    console.log('update shape setting', path, data, options)
    if (!path) {
      this.uiStore.setSettingElement(element.id, cloneDeep(data) as IPageElementSetting['shape'])
    } else {
      this.uiStore.setSettingElement(
        element.id,
        produce(element, draft => {
          set(draft, path, data)
        }).setting
      )
    }
    if (!preview) {
      this.uiStore.resetInteractingElement()
    }
  }

  generateShadowSlideOutputs = (path: OptionsKey<IPageElementBase<ElementTypeEnum.Shape>>) => {
    return {
      valueChange: (val: number) => this.update(path, val),
      valueSlideChange: ({ value, dragging }: { value: number; dragging: boolean }) => this.update(path, value, { preview: dragging })
    }
  }

  getSettingSchema(): SettingSchema[] {
    return [
      {
        title: '基础属性',
        icon: 'ss:base',
        interaction: 'none',
        children: [...this.workspaceService.getSettingSchema()]
      },
      // 填充
      {
        title: '填充',
        icon: 'ss:fill',
        interaction: 'none',
        children: [
          // 颜色
          {
            title: '颜色',
            child: {
              widget: colorRowResolver,
              inputs: {
                value: this.element$.pipe(map(s => s.setting.fill))
              },
              outputs: {
                valueChange: ({ color, dragging }) => this.update('setting.fill', color, { preview: dragging })
              }
            } as ST<ColorRowComponent>
          },
          // 毛玻璃
          {
            title: '毛玻璃',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: this.element$.pipe(map(s => s.setting.blur)),
                aceMinValue: 0,
                aceMaxValue: 100,
                useSlide: true
              },
              outputs: {
                valueChange: blur => this.update('setting.blur', blur),
                valueSlideChange: ({ value, dragging }) => this.update('setting.blur', value, { preview: dragging })
              }
            } as ST<NumberInputComponent>
          }
        ]
      },
      // 描边
      {
        title: '描边',
        icon: 'ss:stroke',
        interaction: 'none',
        children: [
          {
            title: '描边样式',
            child: {
              widget: strokeStyleResolver,
              inputs: {
                value: this.stroke$.pipe(map(s => s.style))
              },
              outputs: {
                valueChange: style => this.update('setting.stroke.style', style)
              }
            } as ST<StrokeStyleComponent>
          },
          {
            title: '描边宽度',
            hidden: this.stroke$.pipe(map(s => !s.style)),
            child: {
              widget: numberInputResolver,
              inputs: {
                aceMinValue: 0,
                aceMaxValue: 100,
                useSlide: true,
                value: this.stroke$.pipe(map(s => s.width))
              },
              outputs: {
                valueChange: width => this.update('setting.stroke.width', width),
                valueSlideChange: ({ value, dragging }) => this.update('setting.stroke.width', value, { preview: dragging })
              }
            } as ST<NumberInputComponent>
          },
          {
            title: '描边颜色',
            hidden: this.stroke$.pipe(map(s => !s.style)),
            child: {
              widget: colorRowResolver,
              inputs: {
                value: this.stroke$.pipe(map(s => s.color))
              },
              outputs: {
                valueChange: ({ color, dragging }) => this.update('setting.stroke.color', color, { preview: dragging })
              }
            } as ST<ColorRowComponent>
          },
          // 圆角
          {
            title: '圆角',
            child: {
              widget: selectNumberResolver,
              inputs: {
                aceOptions: radiusOptions,
                value: this.element$.pipe(map(s => s.setting.stroke.radius))
              },
              outputs: {
                valueChange: radius => this.update('setting.stroke.radius', radius)
              }
            } as ST<SelectNumberComponent>
          }
        ]
      },

      {
        title: '整体投影',
        icon: 'ss:shadow',
        interaction: 'switch',
        active: this.shadow$.pipe(map(s => s.show)),
        activeChange: state => this.update('setting.shadow.show', state),
        children: [
          // 类型
          {
            title: '类型',
            child: {
              widget: buttonToggleResolver,
              inputs: {
                value: this.shadow$.pipe(map(s => s.type)),
                options: [
                  { text: '内阴影', value: 'inner' },
                  { text: '外阴影', value: 'outer' }
                ]
              },
              outputs: {
                value: val => this.update('setting.shadow.type', val)
              }
            } as ST<ButtonToggleComponent>
          },
          // 颜色
          {
            title: '颜色',
            child: {
              widget: colorRowResolver,
              inputs: {
                gradient: false,
                value: this.shadow$.pipe(map(s => s.color))
              },
              outputs: {
                valueChange: ({ color, dragging }) => this.update('setting.shadow.color', color, { preview: dragging })
              }
            } as ST<ColorRowComponent>
          },
          // 偏移
          {
            title: '偏移',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: this.shadow$.pipe(map(s => s.offset)),
                aceMinValue: 0,
                aceMaxValue: 100,
                useSlide: true
              },
              outputs: this.generateShadowSlideOutputs('setting.shadow.offset')
            } as ST<NumberInputComponent>
          },
          // 角度
          {
            title: '角度',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: this.shadow$.pipe(map(s => s.angle)),
                aceMinValue: 0,
                aceMaxValue: 360,
                useSlide: true
              },
              outputs: this.generateShadowSlideOutputs('setting.shadow.angle')
            } as ST<NumberInputComponent>
          },
          // 模糊
          {
            title: '模糊',
            child: {
              widget: numberInputResolver,
              inputs: {
                value: this.shadow$.pipe(map(s => s.blur)),
                aceMinValue: 0,
                aceMaxValue: 100,
                useSlide: true
              },
              outputs: this.generateShadowSlideOutputs('setting.shadow.blur')
            } as ST<NumberInputComponent>
          },
          // 透明
          {
            title: '透明',
            hidden: this.shadow$.pipe(map(s => s.type === 'inner')),
            interaction: 'radio',
            active: this.shadow$.pipe(map(s => s.behind)),
            activeChange: state => this.update('setting.shadow.behind', state)
          }
        ]
      }
    ]
  }
}
