import { ExtractDocumentTypeFromTypedRxJsonSchema, RxJsonSchema, toTypedRxJsonSchema } from 'rxdb'

export const DB_PAGES_VERSION = 2
export const DB_ELEMENTS_VERSION = 2

const PROJECT_SCHEMA_LITERAL = {
  title: 'project schema',
  version: 0,
  description: 'describes a project',
  primaryKey: 'id',
  type: 'object',
  default: {
    pages: []
  },
  properties: {
    id: {
      type: 'string',
      maxLength: 40
    },
    pages: {
      type: 'array',
      items: {
        type: 'string'
      }
    },
    updatedAt: {
      type: 'number'
    }
  },
  required: ['id', 'updatedAt', 'pages']
} as const

const projectSchemaTyped = toTypedRxJsonSchema(PROJECT_SCHEMA_LITERAL)
export type RxProjectDocumentType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof projectSchemaTyped>
export const PROJECT_SCHEMA: RxJsonSchema<RxProjectDocumentType> = PROJECT_SCHEMA_LITERAL

const PAGE_SCHEMA_LITERAL = {
  title: 'page schema',
  version: DB_PAGES_VERSION,
  description: 'describes a page',
  primaryKey: 'id',
  type: 'object',
  default: {
    size: {
      width: 900,
      height: 500
    },
    theme: {
      colors: []
    },
    locked: false,
    visible: true,
    background: {
      locked: false,
      color: '#ffffff'
    },
    version: DB_PAGES_VERSION,
    orders: []
  },
  properties: {
    id: {
      type: 'string',
      maxLength: 40
    },
    name: {
      type: 'string'
    },
    projectId: {
      type: 'string'
    },
    updatedAt: {
      type: 'number'
    },
    theme: {
      type: 'object',
      properties: {
        colors: {
          type: 'array',
          items: {
            type: 'string'
          }
        }
      }
    },
    locked: {
      type: 'boolean'
    },
    visible: {
      type: 'boolean'
    },
    background: {
      type: 'object',
      properties: {
        locked: {
          type: 'boolean'
        },
        color: {
          type: 'string'
        },
        image: {
          type: 'string'
        }
      }
    },
    prev: {
      type: 'string'
    },
    next: {
      type: 'string'
    },
    size: {
      type: 'object',
      properties: {
        width: {
          type: 'number'
        },
        height: {
          type: 'number'
        }
      }
    },
    version: {
      type: 'number'
    },
    orders: {
      type: 'array',
      items: {
        type: 'string'
      }
    }
  },
  required: ['id', 'projectId', 'updatedAt', 'background', 'version', 'orders']
} as const
const pageSchemaTyped = toTypedRxJsonSchema(PAGE_SCHEMA_LITERAL)
export type RxPageDocumentType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof pageSchemaTyped>
export const PAGE_SCHEMA: RxJsonSchema<RxPageDocumentType> = PAGE_SCHEMA_LITERAL

const PAGE_ELEMENT_SCHEMA_LITERAL = {
  title: 'page element schema',
  version: DB_ELEMENTS_VERSION,
  description: 'describes a page element',
  primaryKey: 'id',
  type: 'object',
  default: {
    rotation: 0,
    visible: true,
    locked: false
  },
  properties: {
    id: {
      type: 'string',
      maxLength: 40
    },
    pageId: { type: 'string' },
    updatedAt: {
      type: 'number'
    },
    category: {
      type: 'string'
    },
    position: {
      type: 'object',
      properties: {
        x: {
          type: 'number'
        },
        y: {
          type: 'number'
        }
      }
    },
    size: {
      type: 'object',
      properties: {
        width: {
          type: 'number'
        },
        height: {
          type: 'number'
        }
      }
    },
    scale: {
      type: 'number'
    },
    rotation: {
      type: 'number'
    },
    visible: {
      type: 'boolean'
    },
    locked: {
      type: 'boolean'
    },
    setting: {
      type: 'object',
      properties: {
        version: {
          type: 'number'
        },
        direction: {
          type: 'string'
        },
        autoSize: {
          type: 'boolean'
        },
        textShadow: {
          type: 'boolean'
        },
        textShadowColor: {
          type: 'object'
        },
        textShadowBlur: {
          type: 'number'
        },
        textShadowOffset: {
          type: 'number'
        },
        textShadowAngle: {
          type: 'number'
        },
        paragraphs: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              charts: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    text: {
                      type: 'string'
                    },
                    fontSize: {
                      type: 'number'
                    },
                    fontWeight: {
                      type: 'string'
                    },
                    fontFamily: {
                      type: 'string'
                    },
                    fontStyle: {
                      type: 'string'
                    },
                    color: {
                      type: 'object'
                    },
                    textDecoration: {
                      type: 'array',
                      items: {
                        type: 'string'
                      }
                    },
                    textStroke: {
                      type: 'boolean'
                    },
                    textStrokeColor: {
                      type: 'object'
                    },
                    textStrokeWidth: {
                      type: 'number'
                    },
                    letterSpacing: {
                      type: 'number'
                    }
                  }
                }
              },
              textAlign: {
                type: 'string'
              },
              lineHeight: {
                type: 'number'
              }
            }
          }
        },
        src: {
          type: 'string'
        },
        alt: {
          type: 'string'
        },
        flip: {
          type: 'string'
        },
        opacity: {
          type: 'number'
        },
        size: {
          type: 'object',
          properties: {
            width: {
              type: 'number'
            },
            height: {
              type: 'number'
            }
          }
        },
        fit: {
          type: 'string'
        },
        transform: {
          type: 'object',
          properties: {
            translate: {
              type: 'object',
              properties: {
                x: {
                  type: 'number'
                },
                y: {
                  type: 'number'
                }
              }
            },
            scale: {
              type: 'number'
            }
          }
        },
        type: {
          type: 'string'
        },
        data: {
          type: 'array'
        },
        pipe: {
          type: 'string'
        },
        props: {
          type: 'object'
        },
        name: {
          type: 'string'
        },
        stroke: {
          type: 'object'
        },
        adjustment: {
          type: 'object'
        },
        path: {
          type: 'string'
        },
        fill: {
          type: 'object'
        },
        blur: {
          type: 'number'
        },
        shadow: {
          type: 'object',
          properties: {
            show: {
              type: 'boolean'
            },
            blur: {
              type: 'number'
            },
            type: {
              type: 'string'
            },
            color: {
              type: 'object'
            },
            angle: {
              type: 'number'
            },
            offset: {
              type: 'number'
            },
            behind: {
              type: 'boolean'
            }
          }
        },
        category: {
          type: 'string'
        },
        points: {
          type: 'array',
          items: {
            type: 'array',
            items: {
              type: 'number'
            }
          }
        }
      }
    },
    prev: {
      type: 'string'
    },
    next: {
      type: 'string'
    },
    parent: {
      type: 'string'
    },
    version: {
      type: 'number'
    }
  },
  required: ['id', 'updatedAt', 'pageId', 'category', 'position', 'size', 'scale', 'rotation', 'visible', 'locked']
} as const
const pageElementSchemaTyped = toTypedRxJsonSchema(PAGE_ELEMENT_SCHEMA_LITERAL)
export type RxPageElementDocumentType = ExtractDocumentTypeFromTypedRxJsonSchema<typeof pageElementSchemaTyped>
export const PAGE_ELEMENT_SCHEMA: RxJsonSchema<RxPageElementDocumentType> = PAGE_ELEMENT_SCHEMA_LITERAL
